import React, { Component } from 'react'
import AssignItemsModalInstruments from '../../components/modals/AssignItemsModalInstruments'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import { TextInput, Picker } from '../../components/commons/forms'
import {
  getItemsPerCategory,
  setItems
} from '../../actions/ItemAction'
import Confirmation from '../../components/Confirmation'
import { getError, getArrayError } from '../../utilities'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import Inbodyloader from '../../components/loader/Inbodyloader'
import ViewItemModal from '../../components/modals/ViewItemModal'

export class RegisterNewTray extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      name: '',
      specialty: '',
      selected_items: [],
      change: false,
      errors: [],
      isOnEdit: false,
      editName: '',
      tray_id: null,
      loading: 0,
      isReverse: false,
      selectedItem:null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),

    }
  }

  componentDidMount = () => {
    let id = this.props.router.params.id
    if(id){
      let url = `/api/admin/tray/${id}`
      axios.get(url).then((res) => {
        
        let tray = res.data.data
        console.log('tray', tray)
        this.setState({
          name: tray.name,
          selected_items: tray.items,
          specialty: tray.specialty.id,
          isOnEdit:true,
          editName: tray.name,
          tray_id: tray.id,
        })
      }).catch((error) => {
        console.log(error)
        console.log(error.response)
      })
    }
  }

  componentDidUpdate = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  removeItem = (id) => {
    let { selected_items } = this.state
    _.remove(selected_items, (e) => { return e.id == id})

    this.setState({
      selected_items: selected_items
    })
  }

  setItems = (items) => {
    console.log('return', items)
    this.setState({
      selected_items: items
    })
  }

  saveTray = () => {
    const { name,specialty,selected_items } = this.state
    let edit = false
    let id = this.props.router.params.id
    if(id){
      edit = true
    }

   
    console.log('sp2', specialty)

    const form = new FormData
    form.append('name',name)
      form.append('specialty_id',specialty)

    selected_items.map ( item => {
      form.append('items[]', item.id)
      form.append('qty[]', item.qty)
    })
    if(!edit){
      axios.post(`api/admin/tray`,form).then( result => {
        console.log(result)
        this.props.router.push({
          pathname: '/medical-inventory-instruments',
          state: {
            active: 'instrument-trays'
          }
        })
      }).catch( error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors
        })
      })
    }else{
      let id = this.props.router.params.id
      form.append('_method', 'PATCH')
      let url = `/api/admin/tray/${id}`

      axios.post(url, form).then((res) => {
        this.props.router.push({
          pathname: '/medical-inventory-instruments',
          state: {
            active: 'instrument-trays'
          }
  
        })
      }).catch((error) => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors
        })
        
      })
    }
  }

  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ selected_items: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }
 
  render() {
    const { name, specialty, selected_items, errors } = this.state 
    const { items, loading } = this.props
    const options = items.map ( item => {
      return {id:item.id,name:item.name}
    })
    options.unshift({id:'',name:'Select Specialty'})

    let item_list = selected_items.map((item) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr>
          <td className="" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>{item.catalog_number}</td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className=" px-1">
            <a href="#" className="text-dark fs-16" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`} onClick={() => {
                    this.setState({
                      selectedItem: item,
                      unique:
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15),
                    }, () => {
                      $('.popover').popover('hide')
                      $('#viewItemModal-35').modal('show')
                    })

                  }}>
              {item.name}
            </a>
          </td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.company} </p> </td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.category.name}</p> </td>
          <td className="px-0 text-center w60" style={{paddingLeft: '5px !important', paddingRight: '5px !important'}}>{item.size ? item.size : ''}</td> 
          <td className="px-0 text-center w60" style={{paddingLeft: '5px !important', paddingRight: '5px !important'}}>{item.qty}</td> 
          <td><a href="#" onClick={() => {this.removeItem(item.id)}}>Remove</a></td>
         
        </tr>
      )
    })


    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12"> 
            <h1 className="font-weight-bold mb30">
              {
                this.state.isOnEdit ? `Edit tray [${this.state.editName}]` : 'Register a new tray' 
              }
              
            </h1>
            <h4 className="mb10">Tray information</h4>
          </div>
        </div>
        <div className="row my20">
          <div className="col-md-5">
            <div className="form-group">
              <TextInput
                label={'Name *'}
                value={name}
                placeholder={'Tray name'}
                errorMessage={getError(errors, 'name')}
                containerClass={`mb30`}
                onValueChange={event => {
                  this.setState({ name: event.target.value })
                }}
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-group">
              <Picker
                errorMessage={getError(errors, 'specialty_id')}
                containerClass={`mb30`}
                onChange={event => {
                  this.setState({ specialty: event.target.value })
                }}
                label={`Assign specialty*`}
                value={specialty}
                data={options}
              />
            </div>
          </div>
        </div>
        <div className="row mt20">
          <div className="col-md-12">
            <div className="w-100 d-flex">
              <h4 className="mb20 float-left">Tray item contents</h4>
              <div className="ml-auto">
                <a href="#" className="btn btn-outline-dark mr10 px30" 
                  data-toggle="modal" 
                  data-target="#assignItemsModalCenter" 
                  onClick={() => {
                    this.setState({
                      change: !this.state.change
                    }, () => {this.props.getItemsPerCategory('all', this.state.selected_items, 2)})}}>Assign Items</a>
              </div>
            </div>
            <div className="border" style={{ minHeight: '300px' }}>
              <table className="table table-striped table-hover table-hover-bold ">
                <thead>
                  <tr>
                    <th onClick={() => this.sortBy('catalog_number')} className="w120 c-pointer">
                      <i className="fa fa-sort  mr5" />
                      Catalogue #
                    </th>
                    <th className="w300 c-pointer" onClick={() => this.sortBy('name')}>
                      <i className="fa fa-sort mr5 " />
                      Name
                    </th>
                    <th onClick={() => this.sortBy('company')} className="c-pointer">
                      <i className="fa fa-sort mr5 " />
                      Company
                    </th>
                    <th onClick={() => this.sortBy('category')} className="c-pointer">
                      <i className="fa fa-sort mr5 " />
                      Category
                    </th>
                    <th className="w50"> Size </th>
                    <th className="w50"> Quantity </th>

                    <th className="w80"> Action </th>
                  </tr>
                </thead>
                <tbody>
                  {item_list}
                </tbody>
              </table>
            </div>
            <div className="w-100 mt30">
              <a href="#" className="btn btn-dark mr10 px30 mt20" onClick={this.saveTray.bind(this)}>Update</a>
              <button className="btn btn-outline-dark mr10 px30 mt20" data-toggle="modal" data-target="#cancelModal">Cancel</button>
            </div>
          </div>
        </div>
        
        <Confirmation
          title="Cancel"
          message="Are you sure you want to cancel?"
          confirmText="Yes"
          cancelText="Cancel"
          confirm={() => {
            this.props.router.push({
                pathname: '/medical-inventory-instruments',
                state:{
                  active: 'instrument-trays'
                }
              })
          }}
          idModal="cancelModal"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />
        <AssignItemsModalInstruments assign={this.setItems} selected_items={this.state.selected_items} data={this.state.change}/>
        <ViewItemModal 
          idModal="viewItemModal-35"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal-35').modal('hide')
          }}
        />
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return({
    errors: {},
    items: state.specialty.items,
    loading: state.item.loading
  })
}

export default connect(mapStateToProps, { getItemsPerCategory, setItems })(RegisterNewTray)
