import React, { Component } from 'react'
import SurgeonForm from '../../components/surgeon_form'
import SurgeonPreview from '../../components/surgeon_preview'
import { connect } from 'react-redux'
import Confirmation from '../../components/Confirmation'
import Notification from '../../components/Notification'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import {
  createSurgeon,
  clearSurgeonData,
  setIsNext,
  surgeonUpdate,
  getSurgeon
} from '../../actions/SurgeonActions'
import SpecialtySection from '../../components/specialty_section'
import $ from 'jquery'

class SurgeonEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isNext: false,
      progress: 0,
      page: 1,
    }
  }

  componentWillMount = () => {
  
    this.props.getSurgeon(this.props.params.id)
  }

  componentDidMount = () => {
    this.setState({ 
      progress: 1
    })
    console.log('props', this.props)

    window.onpopstate = (event) => {
      event.preventDefault()
      console.log('============================================')
      console.log(event)
      console.log('============================================')
      if (this.props.isNext) {
        this.back()
      } else {
        $('.cancel-button').click();
      }

    }

    
  }

  componentWillReceiveProps = (nextProps) => {
    console.log('dsdsad',nextProps.surgeon)

    if(this.props.surgeon != nextProps.surgeon){
      const {
        avatar,
        contact,
        contact_number,
        enviroment_on,
        firstname,
        handedness,
        id,
        inner_glove,
        lastname,
        music,
        outer_glove,
        title,
      } = nextProps.surgeon
  
      
      // this.props.surgeonUpdate({
      //   prop: 'surgeon',
      //   value: this.props.router.location.state,
      // })
  
      // this.props.surgeonUpdate({
      //     prop: 'isNext',
      //     value: false,
      //   })
      this.props.surgeonUpdate({
          prop:'environment',
          value: enviroment_on ? 'normal':'silent'
      })
  
      this.props.surgeonUpdate({
        prop: 'firstname',
        value: firstname,
      })
      this.props.surgeonUpdate({
          prop: 'lastname',
          value: lastname,
        })
      this.props.surgeonUpdate({
        prop: 'avatar',
        value: avatar,
      })
      this.props.surgeonUpdate({
        prop: 'mobile',
        value: contact_number,
      })
      this.props.surgeonUpdate({
          prop: 'glove_inner',
          value: inner_glove,
        })
        this.props.surgeonUpdate({
          prop: 'glove_outer',
          value: outer_glove,
        })
      this.props.surgeonUpdate({
        prop: 'mobile',
        value: contact_number,
      })
      this.props.surgeonUpdate({
        prop: 'title',
        value: title,
      })
  
      this.props.surgeonUpdate({
        prop: 'handness',
        value: handedness,
      })
      this.props.surgeonUpdate({
        prop: 'music',
        value: music,
      })

      this.setState({
        progress: 0
      })
    }
  }

  renderRightSide() {
    if (this.props.isNext) return <SpecialtySection />
 
    return <SurgeonForm isEditMode={true}/>
  }
  back = () => {
    this.props.setIsNext(false)
  }

  render() {
    return (
      <div className="container">
        <WholeBodyloader progress={this.state.progress}/>
        <div className="row">
          <div className="col-md-12 d-block d-md-none">
            <h1 className="h1bold page-title">
              Edit Surgeon
            </h1>
            <h4 className="h2med page-sub-title">Personal Information</h4>
          </div>
          <div className="col-lg-3 col-md-4 mb30">
            <SurgeonPreview />
          </div>
          <div className="col">
            {this.renderRightSide()}
            <div className="form-group m-0">
              <h5 className="">&nbsp;</h5>
              {this.props.isNext ? (
                <div
                  className="btn btn-primary mr30 m-xsx0"
                  data-toggle="modal"
                  data-target="#notificationModal"
                >
                  Save surgeon
                </div>
              ) : (
                <div
                    className="btn btn-primary mr30 mt10 m-xsx0"
                  onClick={() => {
                    this.props.createSurgeon()
                    window.history.pushState({ page: 1 }, "", "#surgeon_specialties");

                  }}
                >
                  Save and continue
                </div>
              )}
              {this.props.isNext ? (
                <button
                  className="btn btn-outline-primary mr30 mt10 m-xsx0 m-xst20"
                  onClick={() => {
                    this.back()
                  }}
                >
                  Back
                </button>
              ) : (
                <button
                    className="btn btn-outline-primary cancel-button mr30 mt10  m-xsx0 m-xst20"
                  data-toggle="modal"
                  data-target="#confirmationModal"
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
          <Confirmation
            title="Cancel"
            message="Are you sure you want to cancel?"
            confirmText="Yes"
            cancelText="No"
            confirm={() => {
              this.props.router.goBack()
            }}
            cancel={() => {
              $('confirmModal').modal('hide')
            }}
          />
          <Notification
            title="Success"
            message="Surgeon successfully save."
            confirmText="Ok"
            confirm={() => {
              this.props.router.push({pathname: '/user-database/surgeons', state:{tab:'surgeons'}})
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isNext: state.surgeon.isNext,
    surgeon: state.surgeon.surgeon
  }
}

export default connect(
  mapStateToProps,
  { createSurgeon, clearSurgeonData, setIsNext, surgeonUpdate, getSurgeon }
)(SurgeonEdit)
