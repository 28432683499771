import React, { Component } from 'react'
import { Link } from 'react-router'
import {
  CardSection,
  PositionAreas,
} from '../../components/positioning_equipments'
import $ from 'jquery'
import { connect } from 'react-redux'
import {
  getPositions,
  getPositioningDetails,
} from '../../actions/PostioningActions'
import { Picker } from '../../components/commons/forms'
import { getError, dataURItoBlob } from '../../utilities'

import { Media } from '../../components/commons/elements/display'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      position: '',
      selected_item: null,
      position_name: '',
      isArchive: false,
    }
  }

  componentDidMount() {
    $('.scroll-to-top').fadeOut(0)
    $('.surgeon-card').click(function() {
      window.location.href = 'C:/corh/corh_home/surgeon_profile.html'
    })
    $('td').click(function() {
      var prop = $(this)
        .find('input')
        .prop('checked')
      $(this)
        .find('input')
        .prop('checked', prop == true ? false : true)
    })

    $(window).scroll(function() {
      if ($(this).scrollTop() > 350) {
        $('.scroll-to-top').fadeIn()
      } else {
        $('.scroll-to-top').fadeOut()
      }
    })
    //Click event to scroll to top
    $('.scroll-to-top').click(function() {
      $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 100)
      return false
    })

    this.props.getPositions()

    if (this.props.location.state) {
      if (this.props.location.state.id) {
        this.props.getPositioningDetails(this.props.location.state.id)
      }
    }

    this.setState({
      position: this.props.location.state ? this.props.location.state.id : '',
    })
  }

  positioningContent = () => {
    const { position } = this.state
    const { position_details } = this.props
    if (position != '') {
      let area_list = position_details.map(detail => {
        return (
          <CardSection
            data={{
              name: detail[0].position.name,
              code: detail[0].position.code,
              tableData: detail,
            }}
            onViewMedia={(event, item) => {
              console.log('itemsssss', item)
              event.preventDefault()
              this.setState({ selected_item: item }, () => {
                $('#viewMedia').modal('show')
              })
            }}
          />
        )
      })
      return (
        <div className="row">
          <div className="col-md-9 mx-auto pt30 px0">
            <h4 className="mb-0 d-flex align-items-end">
              {this.state.position_name.length > 0
                ? this.state.position_name
                : '[Position title]'}
              {this.state.isArchive ? (
                ''
              ) : (
                <Link
                  to={{
                    pathname: `/positioning-equipments/update/${position}`,
                  }}
                  className="btn btn-link ml-auto p-0 align-items-end text-dark"
                  onClick={() => {
                    this.props.getPositioningDetails(this.state.position, 1)
                  }}
                >
                  Edit notes
                </Link>
              )}
            </h4>
          </div>
          <div className="col-md-9 mx-auto profile-card pt-0 px0">
            <div className="accordion" id="itemListAccordion">
              <PositionAreas details={this.props.position_details} />
              {area_list}
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  viewPositionMedia = () => {
    const { selected_item } = this.state
    return (
      <div
        className="modal fade"
        id="viewMedia"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="viewInjuryModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: 880 }}
        >
          <div
            className="modal-content"
            style={{ paddingBottom: '0 !important' }}
          >
            <div
              className=" bg-light pt20 px20"
              style={{ padding: '0 !important' }}
            >
              {selected_item ? (
                <div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <Media
                    title={`Viewing the media of [${selected_item.item.name}]`}
                    data={selected_item.media}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { positions } = this.props
    console.log(positions)
    const options = positions.normal.map((item, index) => {
      return (
        <option key={`options-${index}`} value={item.id}>
          {`${item.name} ${item.draft ? '- [ Draft]' : ''}`}
        </option>
      )
    })

    const archives = positions.archive.map((item, index) => {
      return (
        <option key={`options-${index}`} value={item.id}>
          {`${item.name} ${item.draft ? '- [ Draft]' : ''}`}
        </option>
      )
    })
    return (
      <div className="container">
        {this.viewPositionMedia()}
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">
              Positioning equipment page
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="scroll-to-top c-pointer">
              <i className="fa fa-chevron-up fs20" />
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="form-group m-0">
                  <select
                    className="form-control m-0 mb30"
                    value={this.state.position}
                    onChange={event => {
                      let selected_pos = positions.normal.find(
                        position => position.id == event.target.value
                      )
                      this.setState({
                          isArchive: false,
                        })
                      if (!selected_pos) {
                        selected_pos = positions.archive.find(
                          position => position.id == event.target.value
                        )
                        this.setState({
                          isArchive: true,
                        })
                      }
                      this.setState(
                        {
                          position: event.target.value,
                          position_name: selected_pos ? selected_pos.name : '',
                        },
                        () => {
                          this.props.getPositioningDetails(this.state.position)
                        }
                      )
                    }}
                  >
                    <option value={''}> Assign a position*</option>
                    {options}
                    {positions.archive.length > 0 ? (
                      <optgroup label="Archives List">{archives}</optgroup>
                    ) : (
                      ''
                    )}
                  </select>
                </div>
              </div>
            </div>
            {this.positioningContent()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    positions: state.positioning.positions,
    position_details: state.positioning.position_details,
    position_master: state.positioning.position_master,
  }
}

export default connect(
  mapStateToProps,
  { getPositions, getPositioningDetails }
)(Index)
