import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import error_img from '../../../../assets/images/error_img.png'
import Vimeo from '@u-wave/react-vimeo'
import MediaOnly from './MediaOnly';
import $ from 'jquery'
import WholeBodyloader from '../../../loader/WholeBodyloader'
import BigPictureModal from '../../../modals/BigPictureModal'


export class ViewInjuryModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading:1,
            galleryRef: null,
            thumbRef: null,
            selected_media: {},
            unique:
                Math.random()
                    .toString(36)
                    .substring(2, 15) +
                Math.random()
                    .toString(36)
                    .substring(2, 15),
        } 

        this.setSelectedMedia = this.setSelectedMedia.bind(this)
    }
    componentDidMount() {
        setTimeout(()=>{
            this.setState({
                loading:0
            })
        }, 1000)
    }
    setSelectedMedia(media) {
        this.setState({
            selected_media: media
        }, () => {
            $("#bigPictureModal").modal('show')
            setTimeout(() => {
                $('.modal-backdrop').last().addClass('opacity-9 z-index-2k')
            }, 200);
        })

    } 
    render() {
        const { idModal, title, data } = this.props
        console.log('dattaaaaa', this.props)
        return (
            <div>
                <WholeBodyloader progress={this.state.loading}/>
                <BigPictureModal idModal="bigPictureModal" data={this.state.selected_media} />
                <div className="modal fade" id={idModal ? idModal : 'viewMediaModalCenter'} tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content border-0 pb0">
                            <div className="modal-header border-0 bg-white">
                                <h5 className="modal-title mb0 h3bold pb0">Injury prevention</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body border-0 bg-white  rounded pb30">

                                {/* {data.length > 0 ? this.mediapreview(data) : null}
 */}
                                <MediaOnly data={data} styleClass="shadow-none" no_bigpiture={true} setSelectedMedia={this.setSelectedMedia} key={this.state.unique} />  
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
