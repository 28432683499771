import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getItemsPerCategory } from '../../actions/ItemAction'
import ReactDOMServer from 'react-dom/server'
import { Images, PressureMap, Medias } from '../../components/positioning'
import { Link } from 'react-router'
import AssignItemsModalInstruments from '../../components/modals/AssignItemsModalInstruments'
import { getError, getArrayError } from '../../utilities'
import { TextInput } from '../../components/commons/forms'
import axios from 'axios'

export class EditPositioningEquipment extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selected_items: [],
      change: false,
      name: ''
    }
  }


  componentDidMount = () => {
    axios.get('/api/admin/positioning-equipment-areas').then((res) => {
      console.log('res', res)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
  
  setItems = (items) => {
    this.setState({
      selected_items: items
    })
  }

  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ selected_items: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  addNotesModal = () => {
    return(
      <div className="modal" id="addNotesModal" role="dialog" aria-labelledby="addNotesModalTitle" aria-hidden="true">
        <div className="modal-dialog  modal-xl px30" role="document">
          <div className="modal-content animated fadeIn">

          </div>
        </div>
      </div>
    )
  }

  addMediaModal = () => {
    return(
      <div className="modal" id="addMediaModal" role="dialog" aria-labelledby="addMediaModalTitle" aria-hidden="true">
        <div className="modal-dialog  modal-xl px30" role="document">
          <div className="modal-content animated fadeIn">
            <div className="modal-body pt30 px30 pb15">
              <h5 className="mb20 mx-auto font-weight-bold">Add Media </h5>
              <Medias />
              
            </div>
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                data-dismiss="modal"
              >
                Save
              </div>
              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  render() {
    const { selected_items } = this.state

    let errors = []

    let item_list = selected_items.map((item) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr>
          <td className="" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>{item.catalog_number}</td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className=" px-1">
            <Link href={`/item/${item.id}`} target="_blank" className="text-dark fs-16" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}>
              {item.name}
            </Link>
          </td>
          <td className="px-0 text-center w60" style={{paddingLeft: '5px !important', paddingRight: '5px !important'}}>{item.qty}</td> 
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.category.name}</p> </td>
          <td><a href="#" data-toggle="modal" data-target="#addNotesModal">Edit</a></td>
          <td><a href="#" data-toggle="modal" data-target="#addMediaModal" >Edit</a></td>
        </tr>
      )
    })

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">[Add new / Edit] - Positioning page</h1> 
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 mx-auto">
            <div className="row">
              <div className="col-md-12">
                <ul className="list-unstyled d-inline-flex align-items-center w-100 justify-content-end toolbar-options">
                  <li className="border-right border-dark h-75 d-flex align-items-center"><Link to={'/positioning-equipments'} className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100">Cancel</Link></li>  
                  <li className="border-right border-dark h-75 d-flex align-items-center"><a href="#" className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100">Save</a></li>
                  <li className="border-right border-dark h-75 d-flex align-items-center"><a href="#" className="mx15 text-dark font-weight-bold d-flex align-items-center h-100">Preview</a></li>
                  <li className="mx15 "><a className="btn btn-primary m-0 btn-sm" href="positioning_equipment.html">Update</a></li>
                </ul>
              </div>
              <div className="col-md-5">
               
                <div className="form-group mb30">
                  <label>Filter by category</label>
                  <select className="form-control" name="category" id="category">
                    <option />
                    <option />
                  </select>
                </div>
              </div>  
            </div>
            <div className="row"> 
              <div className="col-md-12">
                <div className="card border-0 rounded-0">
                  <div className="card-body">
                    <h6 className="d-flex align-items-end mb20">
                      Item list
                      <div className="btn btn-outline-primary ml-auto" 
                        data-toggle="modal" 
                        data-target="#assignItemsModalCenter"
                        onClick={() => {
                          this.setState({
                            change: !this.state.change
                          }, () => {this.props.getItemsPerCategory('all', this.state.selected_items, 2)})
                        }}>Assign items</div> 
                    </h6>
                    <table className="table table-striped border border-dark" style={{minHeight: '300px'}}>
                      <thead>
                        <tr>
                          <th onClick={() => this.sortBy('catalog_number')} className="w120 c-pointer">
                            <i className="fa fa-sort  mr5" />
                            Catalogue #
                          </th>
                          <th className="w300 c-pointer" onClick={() => this.sortBy('name')}>
                            <i className="fa fa-sort mr5 " />
                            Name
                          </th>
                          <th className="px-1 w50">Qty</th>
                          <th onClick={() => this.sortBy('category')} className="c-pointer">
                            <i className="fa fa-sort mr5 " />
                            Category
                          </th>
                          <th className="px-1 w250">Notes</th>
                          <th className="px-1 text-center">Media</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item_list.length > 0 ? item_list : <tr>
                          <td colSpan={6} className="text-center"><i>Complete your list by Assigning items above</i></td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
        {this.addNotesModal()}   
        {this.addMediaModal()}               
        <AssignItemsModalInstruments assign={this.setItems} selected_items={this.state.selected_items} data={this.state.change}/>
      </div>
    )
  }
}

export default connect(null, { getItemsPerCategory })(EditPositioningEquipment)
