import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';



class ImageBox extends React.Component{

render(){
  return (
    <Card>
     
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="200"
          image={this.props.theName.icon}
         
        />
        <CardContent>
          <Typography align="center" component="p" style={{margin : "10px", fontSize : "16px"}}>
          {this.props.theName.name}
          </Typography>
          <Typography align="center" component="p" style={{margin : "10px", fontSize : "16px"}}>
          {this.props.theName.catalog_number}
          </Typography>
          <Typography align="center" component="p" style={{margin : "10px", fontSize : "16px"}}>
          { this.props.theName.size}
          </Typography>
          <Typography align="center" component="p" style={{margin : "10px", fontSize : "16px"}}>
          {this.props.theName.company}
          </Typography>
          <Typography align="center" component="p" style={{margin : "10px", fontSize : "16px"}}>
          {this.props.theName.category}
          </Typography>
        </CardContent>
        </Card>
  )
 }
}



export default ImageBox;