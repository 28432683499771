import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loginUpdate, loginUser, setUser } from '../../actions'
import axios from 'axios'
import TextInput from '../../components/commons/forms/TextInput'
import { Link } from 'react-router'
import { BASE_URL } from '../../config/constant'
import logo from '../../assets/images/logo-gradient.png'
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      loading: false,
    }
  }

  componentWillMount() {
    axios.defaults.baseURL = BASE_URL
  }

  componentDidMount() {
    const { location } = this.props

    if (location) {
      if (location.state) {
        if (location.state.email) {
          this.props.loginUpdate({
            prop: 'email',
            value: location.state.email,
          })
        }
      }
    }
  }

  onSubmit = () => {
    this.setState({
      loading: true,
      error: '',
    })
    const { email, password } = this.props
    const data = {
      login: email,
      password,
    }

    let url = 'api/admin/login'
    axios
      .post(url, data)
      .then(result => {
        console.log(result)

        const token = result.data.access_token
        this.props.loginUpdate({
          prop: 'token',
          value: result.data.access_token,
        })
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

        axios
          .get('api/admin/me')
          .then(result => {
            this.props.loginUpdate({
              prop: 'user',
              value: result.data.data,
            })

            this.props.loginUpdate({
              prop: 'password',
              value: '',
            })

            this.setState(
              {
                loading: false,
              },
              () => {
                this.props.router.push({
                  pathname: '/',
                })
              }
            )
          })
          .catch(errors => {
            console.log(errors)
            this.setState({
              error: 'Something went wrong',
              loading: false,
            })
          })
      })
      .catch(errors => {
        this.setState({
          error: 'These credentials do not match our records.',
          loading: false,
        })
      })
  }

  render() {
    return (
      <main role="main" className="flex-shrink-0 d-flex">
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="card shadow">
                <div className="card-body p30 text-center">
                  <div className="font-weight-bold d-flex justify-content-center mb30">
                    <img src={logo} className="w150" />
                  </div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p className="mx-auto mb30">
                        Welcome to CORH. Not yet a member? Sign up and start
                        your{' '}
                        <Link
                          to={{
                            pathname: `/register`,
                          }}
                        >
                          <u>Free trial</u>
                        </Link>{' '}
                        today!
                      </p>
                    </div>
                  </div>

                  <TextInput
                    label={'Email or username'}
                    value={this.props.email}
                    errorMessage={this.state.error}
                    containerClass={`mb30 text-left`}
                    onValueChange={event => {
                      this.props.loginUpdate({
                        prop: 'email',
                        value: event.target.value,
                      })
                    }}
                    onKeyPress={event => {
                      if (event.key == 'Enter') this.onSubmit()
                    }}
                  />

                  <TextInput
                    label={'Password'}
                    value={this.props.username}
                    errorMessage={this.props.errors}
                    containerClass={`mb30 text-left`}
                    inputType={'password'}
                    onValueChange={event => {
                      this.props.loginUpdate({
                        prop: 'password',
                        value: event.target.value,
                      })
                    }}
                    onKeyPress={event => {
                      if (event.key == 'Enter') this.onSubmit()
                    }}
                  />
                  <div
                    className="btn btn-block btn-primary mb30"
                    onClick={this.onSubmit.bind(this)}
                  >
                    Login
                  </div>
                  <p className="m-0">
                    <Link
                      to={{
                        pathname: `/reset-password`,
                      }}
                      className="font-weight-normal"
                    >
                      <u>Forgotten password</u>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { email, password, loading, error } = auth

  return { email, password, loading, error }
}

export default connect(
  mapStateToProps,
  { loginUpdate, loginUser }
)(Login)
