
import {
    SURGEON_UPDATE,
    SURGEON_CREATE,
    SURGEON_CREATE_SUCCESS,
    SURGEON_CREATE_FAIL,
    SET_ISNEXT,
    CREATE_SURGEON_SPECIALTY_SUCCESS,
    CLEAR_SUREGON_DATA,
    GET_SURGEON,
    GET_SURGEONS,
    FETCH_SURGEON
} from '../actions/types';
  
  
  const INITIAL_STATE = {
    firstname: '',
    lastname: '',
    title: '',
    mobile: '',
    handness: '',
    glove_inner: {},
    glove_outer: {},
    environment: '',
    errors:[],
    music: 0,
    loading: false,
    avatar: null,
    surgeon: {},
    isNext: false,
    surgeons: []
    
  };
  
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CLEAR_SUREGON_DATA:
        return {...state, ...INITIAL_STATE}
      case SURGEON_UPDATE:
        return { ...state, [action.payload.prop]: action.payload.value };
       case SURGEON_CREATE_FAIL:
        return { ...state, errors: action.payload, loading: false };
      case SURGEON_CREATE:
        return { ...state, loading: true, errors: [] };
      case SURGEON_CREATE_SUCCESS:
        return { ...state, surgeon: action.payload, isNext: true};
      case SET_ISNEXT:
        return {...state, isNext: action.status}
      case CREATE_SURGEON_SPECIALTY_SUCCESS:
        return {...state, surgeon: action.surgeon}
      case GET_SURGEON:
        return {...state, surgeon: action.surgeon}
      case GET_SURGEONS:
        return {...state, surgeons: action.surgeons, loading:false}
      case FETCH_SURGEON:
      return {...state, loading: true}

      default:
        return state;
    }
  
  };
  
  