import React from 'react';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import {closeProduct} from '../../actions/roomSetupAction'
import {connect} from 'react-redux'


class TopPart extends React.Component {




   render(){
  return (
   
                    <Grid /* Root Grid */
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={24}
               

            >        
                <Grid item sm={8}>
                    <Typography variant="h6">
                        <b>{this.props.theProps.name}</b>
                    </Typography>
                </Grid>
                <Grid item sm={3}>
                <Card>
                        <CardHeader
                avatar={
                    <Avatar>
                    R
                    </Avatar>
                }
                title={
                    <Typography gutterBottom>Authored by</Typography>
                }
                subheader={
                    <Typography gutterBottom>
                        <b>{this.props.theProps.author}</b>
                    </Typography>
                }
                disableTypography
                />
                    </Card>
                </Grid>

                <Grid item sm>
                <Link
                component="button"
                variant="body1"
                onClick={() => {
                this.props.closeProduct();
                }}
                >
                Close x
                </Link>
              
           
                </Grid>

            </Grid>
  )
 }
}


export default connect(null,{closeProduct})(TopPart);