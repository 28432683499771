import React, { Component } from 'react'
import { Picker } from '../commons/forms'
import { connect } from 'react-redux'
import _ from 'lodash'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server'
import {
  getMedicalInventories,
  getItemsPerCategory,
  setItems,
  getMedicalInventoryCategories,
} from '../../actions/ItemAction'
import { getBodyAreas } from '../../actions/PostioningActions'
import ReviewItems from './ReviewItemsPositioning'
import ViewItemModal from '../../components/modals/ViewItemModal'
import InfiniteScroll from 'react-infinite-scroll-component'

export class AssignItemsPositioning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inventory: 1,
      category: 'all',
      categories: [],
      counters: {
        supplies: 0,
        instruments: 0,
        equipments: 0,
      },
      keyword: '',
      selected_items: [],
      change: false,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      page: 1,
    }
    this.searchData = _.debounce(this.getItemResults, 600)

  }

  componentDidMount = () => {
    this.props.getBodyAreas()
    this.props.getMedicalInventories()
    this.props.getItemsPerCategory(
      'all',
      this.state.selected_items,
      this.state.inventory,
      '',
      this.state.page
    )
    this.setState({
      selected_items: this.props.selected_items,
    })
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.change != nextProps.change) {
      this.setState({
        inventory: 1,
        category: 'all',
        page: 1,
        keyword: '',
      })

      this.setState(
        {
          selected_items: nextProps.selected_items,
        },
        () => {
          this.props.getMedicalInventoryCategories(1)
          this.props.getItemsPerCategory(
            'all',
            this.state.selected_items,
            this.state.inventory,
            '',
            this.state.page
          )
        }
      )

      $('#supplies-tab').tab('show')
    }
  }

  componentDidUpdate = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')

    let counters = {
      supplies: 0,
      instruments: 0,
      equipments: 0,
    }

    this.state.selected_items.map(item => {
      switch (item.category.medical_inventory_id) {
        case 1:
          counters.supplies++
          break
        case 2:
          counters.instruments++
          break
        case 3:
          counters.equipments++
          break
        default:
          break
      }
    })

    if (!_.isEqual(counters, this.state.counters)) {
      this.setState({
        counters: counters,
      })
    }
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState(
      {
        page: page + 1,
      },
      () => {
        this.props.getItemsPerCategory(
          this.state.category,
          this.state.selected_items,
          this.state.inventory,
          '',
          this.state.page
        )
      }
    )
  }

  checkItem = id => {
    let { selected_items } = this.state
    const { items } = this.props

    let item = items.find(e => e.id === id)
    if (item) {
      if (!item.qty) {
        item.qty = 1
      }
    } else {
      item = selected_items.find(e => e.id === id)
    }

    if (selected_items.find(e => e.id === id)) {
      this.setState({
        selected_items: selected_items.filter(x => x.id !== id),
        counters: {
          supplies:
            this.state.counters.supplies +
            (item.category.medical_inventory_id == 1 ? -1 : 0),
          instruments:
            this.state.counters.instruments +
            (item.category.medical_inventory_id == 2 ? -1 : 0),
          equipments:
            this.state.counters.equipments +
            (item.category.medical_inventory_id == 3 ? -1 : 0),
        },
      })
    } else {
      this.setState({
        selected_items: [...selected_items, item],
        counters: {
          supplies:
            this.state.counters.supplies +
            (item.category.medical_inventory_id == 1 ? 1 : 0),
          instruments:
            this.state.counters.instruments +
            (item.category.medical_inventory_id == 2 ? 1 : 0),
          equipments:
            this.state.counters.equipments +
            (item.category.medical_inventory_id == 3 ? 1 : 0),
        },
      })
    }
  }

  checkItemExistence = id => {
    let { selected_items } = this.state

    let exist = false
    if (selected_items.filter(e => e.id === id).length > 0) {
      exist = true
    } else {
      exist = false
    }

    return exist
  }

  isChecked = id => {
    let { selected_items } = this.state
    const { items } = this.props

    let item_local = selected_items.find(e => e.id === id)
    let item_store = items.find(e => e.id === id)

    let checked = false
    if (item_local) {
      if (item_local.prn) {
        checked = true
      }
    } else {
      if (item_store) {
        if (item_store.prn) {
          checked = true
        }
      }
    }

    return checked
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  setSelected = selected_items => {
    this.setState({
      selected_items: selected_items,
    })
  }

  getItemResults = () => {
    this.props.getItemsPerCategory(
      this.state.category,
      this.state.selected_items,
      this.state.inventory.id,
      this.state.keyword,
      this.state.page
    )
  }
  searchItems = ({ target: { value } }) => {
    this.setState({keyword: value, page: 1}, () => {this.searchData()})
  }

  render() {
    const { medical_inventories, items } = this.props
    let { counters } = this.state

    const search = _.debounce(() => {
      this.props.getItemsPerCategory(
        this.state.category,
        this.state.selected_items,
        this.state.inventory.id,
        this.state.keyword,
        this.state.page
      )
    }, 500)

    const itemlist = items.map((item, index) => {
      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })
      return (
        <tr key={`item-key-${index}}`}>
          <td className="w140 px10">{item.catalog_number}</td>
          <td
            className="w230 font-weight-bold c-pointer text-dark fs-16 px10"
            onClick={() => {
              this.setState(
                {
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                },
                () => {
                  $(`#${this.state.unique}`).modal('show')
                }
              )
            }}
          >
            <span
              data-container="body"
              data-trigger="hover"
              data-toggle="popover"
              data-placement="right"
              data-html="true"
              data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            >
              {item.name}
            </span>
          </td>
          <td className="w130 px10">
            {' '}
            <p className="m-0 p-0 float-left w-100 fs14">
              {item.company}{' '}
            </p>{' '}
          </td>
          <td className="w120 px10">
            {' '}
            <p className="m-0 p-0 float-left w-100 fs14">
              {item.category ? item.category.name : ''}
            </p>{' '}
          </td>
          <td className="w80 text-center px10">{item.size}</td>
          <td className="w80 text-center pt15 px10">
            <a
              href="#"
              className="text-dark mt15"
              onClick={event => {
                event.preventDefault()
                this.checkItem(item.id)
              }}
            >
              {this.checkItemExistence(item.id) ? 'Remove' : 'Add'}
            </a>
          </td>
        </tr>
      )
    })

    return (
      <div>
        <div
          className={`modal fade`}
          id="assignItemsModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="assignItemsModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl px30"
            role="document"
          >
            <div
              className={`modal-content ${
                this.change ? 'modal-opacity-0' : ''
              }`}
            >
              <div className="modal-body pt30 px30 pb0">
                <h5 className="m-0 mx-auto font-weight-bold pb20">
                  Assigning items{' '}
                </h5>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item mr30">
                    <a
                      class="nav-link active border-0 font-weight-bold bg-transparent px-0 py5 fs16"
                      id="supplies-tab"
                      data-toggle="tab"
                      href="#supplies"
                      role="tab"
                      aria-controls="supplies"
                      aria-selected="true"
                      style={{ textDecoration: 'none' }}
                      onClick={event => {
                        this.setState(
                          {
                            inventory: medical_inventories[0].id,
                            category: 'all',
                            keyword: '',
                            page: 1,
                          },
                          () => {
                            this.props.getMedicalInventoryCategories(
                              this.state.inventory
                            )
                            this.props.getItemsPerCategory(
                              this.state.category,
                              this.state.selected_items,
                              this.state.inventory,
                              '',
                              this.state.page
                            )
                          }
                        )
                      }}
                    >
                      Supplies
                      <span className="badge badge-secondary badge-pill counter-pill ml15">
                        {counters.supplies}
                      </span>
                    </a>
                  </li>
                  <li class="nav-item mr30">
                    <a
                      class="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs16"
                      id="instruments-tab"
                      data-toggle="tab"
                      href="#instruments"
                      role="tab"
                      aria-controls="instruments"
                      aria-selected="true"
                      style={{ textDecoration: 'none' }}
                      onClick={event => {
                        this.setState(
                          {
                            inventory: medical_inventories[1].id,
                            category: 'all',
                            keyword: '',
                            page: 1,
                          },
                          () => {
                            this.props.getMedicalInventoryCategories(
                              this.state.inventory
                            )
                            this.props.getItemsPerCategory(
                              this.state.category,
                              this.state.selected_items,
                              this.state.inventory,
                              '',
                              this.state.page
                            )
                          }
                        )
                      }}
                    >
                      Instruments
                      <span className="badge badge-secondary badge-pill counter-pill ml15">
                        {counters.instruments}
                      </span>
                    </a>
                  </li>
                  <li class="nav-item mr30">
                    <a
                      class="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs16"
                      id="equipments-tab"
                      data-toggle="tab"
                      href="#equipments"
                      role="tab"
                      aria-controls="equipments"
                      aria-selected="true"
                      style={{ textDecoration: 'none' }}
                      onClick={event => {
                        this.setState(
                          {
                            inventory: medical_inventories[2].id,
                            category: 'all',
                            keyword: '',
                            page: 1,
                          },
                          () => {
                            this.props.getMedicalInventoryCategories(
                              this.state.inventory
                            )
                            this.props.getItemsPerCategory(
                              this.state.category,
                              this.state.selected_items,
                              this.state.inventory,
                              '',
                              this.state.page
                            )
                          }
                        )
                      }}
                    >
                      Equipments
                      <span className="badge badge-secondary badge-pill counter-pill ml15">
                        {counters.equipments}
                      </span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane animated fadeIn active"
                    id="supplies"
                    role="tabpanel"
                    aria-labelledby="supplies-tab"
                  >
                    <div className="row pt20">
                      <div className="col-lg-4 form-group mb30">
                        <label className="mb10">Search</label>
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Start typing name, catalogue #..."
                          onChange={this.searchItems.bind(this)}
                          value={this.state.keyword}
                        />
                      </div>
                      <div className="col-lg-4 form-group mb30">
                        <Picker
                          containerClass={`mb30`}
                          onChange={event => {
                            this.setState(
                              {
                                category: event.target.value,
                                keyword: '',
                                page: 1,
                              },
                              () => {
                                this.props.getItemsPerCategory(
                                  this.state.category,
                                  this.state.selected_items,
                                  this.state.inventory,
                                  '',
                                  this.state.page
                                )
                              }
                            )
                          }}
                          label={`Filter by category`}
                          value={this.state.category}
                          data={this.props.inventory_categories}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="float-left w-100 of-auto">
                          <table className="table table-striped table-fixed border min-table-width-795">
                            <thead className="bg-dark text-white text-left">
                              <tr>
                                <th
                                  onClick={() => this.sortBy('catalog_number')}
                                  class="mw140 px10"
                                >
                                  Catalogue # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('name')}
                                  class="mw230 px10"
                                >
                                  Name # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('company')}
                                  class="mw130 px10"
                                >
                                  Company # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('category')}
                                  class="mw120 px10"
                                >
                                  Category <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('size')}
                                  class="mw80 px10 text-center"
                                >
                                  Size <i class="fa fa-sort ml5" />
                                </th>
                                <th class="mw80 px10" />
                              </tr>
                            </thead>
                            <tbody
                              style={{
                                float: 'left',
                                width: '100%',
                                overflow: 'hidden',
                                maxHeight: '400px',
                                height: '400px',
                              }}
                            >
                              <InfiniteScroll
                                dataLength={this.props.items.length}
                                next={() => {
                                  this.getMoreItems()
                                }}
                                hasMore={this.props.more}
                                loader={
                                  <p className="px5 py5 text-center">
                                    <b>Loading</b>
                                  </p>
                                }
                                height={400}
                                endMessage={
                                  <p className="px5 py5 text-center">
                                    <b>Nothing to load</b>
                                  </p>
                                }
                              >
                                {itemlist}
                              </InfiniteScroll>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane animated fadeIn "
                    id="instruments"
                    role="tabpanel"
                    aria-labelledby="instruments-tab"
                  >
                    <div className="row pt20">
                      <div className="col-lg-4 form-group mb30">
                        <label className="mb10">Search</label>
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Start typing name, catalogue #..."
                          onChange={this.searchItems.bind(this)}
                          value={this.state.keyword}
                        />
                      </div>
                      <div className="col-lg-4 form-group mb30">
                        <Picker
                          containerClass={`mb30`}
                          onChange={event => {
                            this.setState(
                              {
                                category: event.target.value,
                                keyword: '',
                                page: 1,
                              },
                              () => {
                                this.props.getItemsPerCategory(
                                  this.state.category,
                                  this.state.selected_items,
                                  this.state.inventory,
                                  '',
                                  this.state.page
                                )
                              }
                            )
                          }}
                          label={`Filter by category`}
                          value={this.state.category}
                          data={this.props.inventory_categories}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="w-100 float-left of-auto">
                          <table className="table table-striped table-fixed border  min-table-width-795">
                            <thead className="bg-dark text-white text-left">
                              <tr>
                                <th
                                  onClick={() => this.sortBy('catalog_number')}
                                  class="mw140 px10"
                                >
                                  Catalogue # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('name')}
                                  class="mw230 px10"
                                >
                                  Name # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('company')}
                                  class="mw130 px10"
                                >
                                  Company # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('category')}
                                  class="mw120 px10"
                                >
                                  Category <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('size')}
                                  class="mw80 px10 text-center"
                                >
                                  Size <i class="fa fa-sort ml5" />
                                </th>
                                <th class="mw80 px10" />
                              </tr>
                            </thead>
                            <tbody
                              style={{
                                float: 'left',
                                width: '100%',
                                overflow: 'hidden',
                                maxHeight: '400px',
                                height: '400px',
                              }}
                            >
                              <InfiniteScroll
                                dataLength={this.props.items.length}
                                next={() => {
                                  this.getMoreItems()
                                }}
                                hasMore={this.props.more}
                                loader={
                                  <p className="px5 py5 text-center">
                                    <b>Loading</b>
                                  </p>
                                }
                                height={400}
                                endMessage={
                                  <p className="px5 py5 text-center">
                                    <b>Nothing to load</b>
                                  </p>
                                }
                              >
                                {itemlist}
                              </InfiniteScroll>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane animated fadeIn "
                    id="equipments"
                    role="tabpanel"
                    aria-labelledby="equipments-tab"
                  >
                    <div className="row pt20">
                      <div className="col-lg-4 form-group mb30">
                        <label className="mb10">Search</label>
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Start typing name, catalogue #..."
                          onChange={this.searchItems.bind(this)}
                          value={this.state.keyword}
                        />
                      </div>
                      <div className="col-lg-4 form-group mb30">
                        <Picker
                          containerClass={`mb30`}
                          onChange={event => {
                            this.setState(
                              {
                                category: event.target.value,
                                keyword: '',
                                page: 1,
                              },
                              () => {
                                this.props.getItemsPerCategory(
                                  this.state.category,
                                  this.state.selected_items,
                                  this.state.inventory,
                                  '',
                                  this.state.page
                                )
                              }
                            )
                          }}
                          label={`Filter by category`}
                          value={this.state.category}
                          data={this.props.inventory_categories}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="w-100 float-left of-auto">
                          <table className="table table-striped table-fixed border min-table-width-795">
                            <thead className="bg-dark text-white text-left">
                              <tr>
                                <th
                                  onClick={() => this.sortBy('catalog_number')}
                                  class="w140 px10"
                                >
                                  Catalogue # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('name')}
                                  class="w230 px10"
                                >
                                  Name # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('company')}
                                  class="w130 px10"
                                >
                                  Company # <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('category')}
                                  class="w120 px10"
                                >
                                  Category <i class="fa fa-sort ml5" />
                                </th>
                                <th
                                  onClick={() => this.sortBy('size')}
                                  class="w80 px10 text-center"
                                >
                                  Size <i class="fa fa-sort ml5" />
                                </th>
                                <th class="w80 px10" />
                              </tr>
                            </thead>
                            <tbody
                              style={{
                                float: 'left',
                                width: '100%',
                                overflow: 'hidden',
                                maxHeight: '400px',
                                height: '400px',
                              }}
                            >
                              <InfiniteScroll
                                dataLength={this.props.items.length}
                                next={() => {
                                  this.getMoreItems()
                                }}
                                hasMore={this.props.more}
                                loader={
                                  <p className="px5 py5 text-center">
                                    <b>Loading</b>
                                  </p>
                                }
                                height={400}
                                endMessage={
                                  <p className="px5 py5 text-center">
                                    <b>Nothing to load</b>
                                  </p>
                                }
                              >
                                {itemlist}
                              </InfiniteScroll>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body mb-0 px30 pb0 pt30 border-0 justify-content-start">
                <div
                  className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                  onClick={() => {
                    this.setState({
                      change: !this.state.change,
                      unique:
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15),
                    })
                    $('#assignItemsModalCenter').modal('hide')
                    $('#reviewItemsModalCenter').modal('show')
                  }}
                >
                  Review items
                </div>
                <div
                  className="btn btn-light border-dark px30 mt-0 mb-0 float-left m-xst20"
                  data-dismiss="modal"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReviewItems
          selected_items={this.state.selected_items}
          checkItem={this.checkItem}
          checkItemExistence={this.checkItemExistence}
          setSelected={this.setSelected}
          body_areas={this.props.body_areas}
          assign={this.props.assign}
          change={this.state.change}
        />

        <ViewItemModal
          idModal={this.state.unique}
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal').modal('hide')
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    inventory_categories: state.item.inventory_categories,
    medical_inventories: state.item.medical_inventories,
    items: state.item.items,
    body_areas: state.positioning.body_areas,
    more: state.item.more,
  }
}

export default connect(
  mapStateToProps,
  {
    getMedicalInventories,
    getItemsPerCategory,
    setItems,
    getBodyAreas,
    getMedicalInventoryCategories,
  }
)(AssignItemsPositioning)
