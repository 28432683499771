import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getMedicalInventories,
  getMedicalInventoryCategories,
  getItems,
  setItems,
  getItemsPerCategory,
} from '../../actions/ItemAction'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router'
import { Picker } from '../commons/forms'
import $ from 'jquery'
import _ from 'lodash'
import AllocateItemsProcedureInstrument from './AllocateItemsProcedureInstrument'
import InfiniteScroll from "react-infinite-scroll-component"
import ViewItemModal from './ViewItemModal';

export class AssignItemsProcedureInstrument extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selected_items: [],
      category: 'all',
      inventory: 2,
      isReverse: false,
      keyword: '',
      page: 1,
      selectedItem:[]
    }
    this.searchData = _.debounce(this.getItemResults, 600)

  }

  componentDidMount = () => {
    this.props.getMedicalInventoryCategories(2)
    this.props.getItemsPerCategory('all', this.props.selected_items, this.state.inventory, '', this.state.page)
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.change != nextProps.change){
      this.setState({
        inventory: 2,
        keyword: '',
        category: 'all',
        selected_items: nextProps.selected_items
      })
      this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory, '',this.state.page)
    }
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState({
      page: page + 1
    }, () => { this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page) })
  }

  checkItem = (id) => {
    let { selected_items } = this.state
    const { items } = this.props

    let item = items.find((e) => e.id === id)
    if(item){
      if(!item.qty){
        item.qty = 1
      }
    }else{
      item = selected_items.find((e) => e.id === id)
    }
    
    if(selected_items.find((e) => e.id === id)){
      this.setState({
        selected_items: selected_items.filter(x => x.id !== id),
      })
    }else{
      selected_items.unshift(item)
      this.setState({
        selected_items: selected_items,
      })
    }
  }

  checkItemExistence = (id) => {
    let { selected_items } = this.state

    let exist = false
    if(selected_items.filter((e) => e.id === id).length > 0){
      exist = true
    }else{
      exist = false
    }

    return exist
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  setSelected = (selected_items) => {
    this.setState({
      selected_items: selected_items
    })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  getCategories = () => {
    let i = this.props.item_categories
    let x = _.find(i, (e) => {return e.id == ''})
    if(!x){
      i.unshift({id: '', name: 'Select category'})
    }

    return i
  }

  getItemResults = () => {
    this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory.id, this.state.keyword, this.state.page)
  }
  searchItems = ({ target: { value } }) => {
    this.setState({keyword: value, page: 1}, () => {this.searchData()})
  }

  

  render() {
    

    let itemlist = this.props.items.map((item, index) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr key={`item-${index}`}>
          <td className="w150 px10">{item.catalog_number}</td>
          <td className="w250 font-weight-bold c-pointer text-dark fs-16 px10"  
              data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`} onClick={() => {

                this.setState({
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15)
                }, () => {
                    $(`#${this.state.unique}`).modal('show');

                })
              }}> 
                {item.name} 
            </td>
          <td className=" w150 px10"> <p className="m-0 p-0 float-left w-100 fs14">{item.company} </p> </td>
          <td className=" w150 px10"> <p className="m-0 p-0 float-left w-100 fs14">{item.category ? item.category.name : ''}</p> </td>
          <td className=" text-center w80">{item.size}</td> 
          <td className="w80 text-center pt15 px10">
            <a href="#" className="text-dark mt15" onClick={(event) =>  {event.preventDefault(); this.checkItem(item.id)}}>{this.checkItemExistence(item.id) ? 'Remove' : 'Add'}</a>
          </td>
        </tr>
      )
    })

    return (
      <div>
        <div className="modal fade" id="assignItemsModalCenter" tabIndex={-1} role="dialog" aria-labelledby="assignItemsModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body pt30 px30 pb0">
                <h5 className="m-0 h3bold pb0">Step 1 - Assign items</h5>
              </div>
              <div className="modal-body px30 pt-0 pb30">
                <div className="row">   
                  <div className="col-lg-4 form-group mb30">
                    <label className="mb10">Search</label>
                    <input type="text" name="search" 
                      className="form-control" 
                      placeholder="Start typing name, catalogue #..." 
                      onChange={this.searchItems.bind(this)}
                      value={this.state.keyword}

                      
                    />
                  </div>
                  <div className="col-lg-4 form-group m-0">
                    <Picker
                      containerClass={`mb30`}
                      onChange={event => {
                        this.setState({ category: event.target.value, keyword: '', page: 1}, () => {
                          this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                        })
                      }}
                      label={`Filter by category`}
                      value={this.state.category}
                      data={this.props.inventory_categories}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="w-100 of-auto">
                        <table className="table table-striped table-fixed border min-table-width-870">
                          <thead className="bg-dark text-white text-left">
                            <tr>
                              <th onClick={() => this.sortBy('catalog_number')} className="w150 px10 c-pointer">Catalogue # <i className="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('name')} className="w250 px10 c-pointer">Name # <i className="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('company')} className="w150 px10 c-pointer">Company # <i className="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('category')} className="w150 px10 c-pointer">Category <i className="fa fa-sort ml5"></i></th>
                              <th className="px10 w80 text-center">Size</th>
                              <th className="px10 w85" />
                            </tr>
                          </thead>
                          <tbody style={{ float: 'left', width: '100%', overflow: 'hidden', maxHeight: '400px', height: '400px' }}>
                            <InfiniteScroll
                              dataLength={this.props.items.length}
                              next={() => { this.getMoreItems() }}
                              hasMore={this.props.more}
                              loader={
                                <p className="px5 py5 text-center">
                                  <b>Loading</b>
                                </p>
                              }
                              height={400}
                              endMessage={
                                <p className="px5 py5 text-center">
                                  <b>Nothing to load</b>
                                </p>
                              }
                            >
                              {itemlist}
                            </InfiniteScroll>
                          </tbody>
                        </table>
                    </div>
                  </div>
                </div> 
              </div>
              <div className="modal-body mb-0 px30 pb0 pt0 border-0 justify-content-start"> 
                <div className="btn btn-dark mr30 mt-0 px30 mb-0 float-left" onClick={()=>{
                    $("#assignItemsModalCenter").modal('hide')
                    $("#allocateItemsInstrument").modal('show')
                }}>Allocate items</div>
                <div className="btn btn-light border-dark px30 mt-0 mb-0 float-left m-xst30" data-dismiss="modal">Cancel</div>
              </div>
            </div>
          </div>
        </div>
        <AllocateItemsProcedureInstrument
          checkItem={this.checkItem}
          checkItemExistence={this.checkItemExistence}
          setSelected={this.setSelected}
          assignNewState={this.props.assignNewState}
          selected_items={this.state.selected_items}
          item_categories={this.getCategories()}
          stage_master={this.props.stage_master}
        />
        <ViewItemModal idModal={this.state.unique} item={this.state.selectedItem} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    medical_inventories: state.item.medical_inventories,
    inventory_categories: state.item.inventory_categories,
    items: state.item.items,
    more: state.item.more
  })
}

export default connect(mapStateToProps, { getItems, setItems, getMedicalInventories, getMedicalInventoryCategories, getItemsPerCategory})(AssignItemsProcedureInstrument)
