import React, { Component } from 'react'
import axios from 'axios'
import TextInput from '../../components/commons/forms/TextInput'
import { Link } from 'react-router'
import { getError } from '../../utilities'
import Notification from '../../components/Notification'
import $ from 'jquery'
import { BASE_URL } from '../../config/constant';

class ConfirmResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      new_password: '',
      new_password_confirmation: '',
      loading: false,
      resetDetail: null,
      message: '',
    }
  }

  componentWillMount() {
    
    axios.defaults.baseURL = BASE_URL
  }
  componentDidMount = () => {
    const { token } = this.props.router.params

    axios
      .get(`api/find/${token}`)
      .then(result => {
        this.setState({
          resetDetail: result.data,
        })
      })
      .catch(error => {
        this.props.router.push({
          location: '/login',
        })
      })
  }

  onSubmit = () => {
    const { resetDetail, new_password, new_password_confirmation } = this.state

    axios
      .post(`api/reset`, {
        email: resetDetail.email,
        token: resetDetail.token,
        password: new_password,
        password_confirmation: new_password_confirmation,
      })
      .then(result => {
        console.log(result)
        this.setState(
          {
            message: 'Password successfully reset you can now login!',
          },
          () => {
            $('#notificationModal').modal('show')
          }
        )
      })
      .catch(error => {
        if (error.response.status == 422) {
          this.setState({
            errors: error.response.data.errors,
          })
        } else {
          this.setState(
            {
              message:
                'Something went wrong please contact the customer service',
            },
            () => {
              $('#notificationModal').modal('show')
            }
          )
        }
      })
  }
  render() {
    return (
      <main role="main" className="flex-shrink-0 d-flex">
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="card shadow">
                <div className="card-body p30 text-center">
                  <div className="font-weight-bold d-flex justify-content-center mb30">
                    <h1 className="bg-secondary px-2">CORH</h1>
                  </div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p className="mx-auto mb30">
                        Welcome to CORH. Not yet a member? Sign up and start
                        your{' '}
                        <Link
                          to={{
                            pathname: `/register`,
                          }}
                        >
                          <u>Free trial</u>
                        </Link>{' '}
                        today!
                      </p>
                    </div>
                  </div>

                  <TextInput
                    label={'New Password'}
                    inputType={'password'}
                    value={this.state.new_password}
                    errorMessage={getError(this.state.errors, 'password')}
                    containerClass={`mb30 text-left`}
                    onValueChange={event => {
                      this.setState({
                        new_password: event.target.value,
                      })
                    }}
                    onKeyPress={event => {
                      if (event.key == 'Enter') this.onSubmit()
                    }}
                  />

                  <TextInput
                    label={'New Password Confirmation'}
                    inputType={'password'}
                    value={this.state.new_password_confirmation}
                    errorMessage={''}
                    containerClass={`mb30 text-left`}
                    onValueChange={event => {
                      this.setState({
                        new_password_confirmation: event.target.value,
                      })
                    }}
                    onKeyPress={event => {
                      if (event.key == 'Enter') this.onSubmit()
                    }}
                  />
                  <div
                    className="btn btn-block btn-primary mb30"
                    onClick={this.onSubmit.bind(this)}
                  >
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Notification
            title="Password Reset"
            message={this.state.message}
            confirmText="OK"
            confirm={() => {
              this.props.router.push({
                location: '/login',
              })
            }}
          />
        </div>
      </main>
    )
  }
}

export default ConfirmResetPassword
