import React, { Component } from 'react'

export class QuicknotesStandard extends Component {
  render() {
    const { quicknotes } = this.props
    return (
      <div className="card p-0 shadow-sm border-0 mt30">  
        <div className="card-body pt-0 px15 pb-0">  
          <div className="row">
            <did className="col-md-6 pt20"> 
              <p className="font-weight-bold mb15">{quicknotes.title ? quicknotes.title : 'No Specified.'}</p>
              <div className="of-auto h220 pr10"
                dangerouslySetInnerHTML={{
                  __html: quicknotes.contents[0].elements[0].content
                }}
              />
            </did>
            <did className="col-md-6 p-0"> 
              <img src={quicknotes.contents[1].elements[0].gallery.thumbnail} className="bg-dark w-100 h300" />
            </did>
          </div>
        </div>
      </div>
    )
  }
}

export default QuicknotesStandard
