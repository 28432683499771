import React, { Component } from 'react'
import { Standard, Text, Media, StandarMediaView } from './commons/elements/display'
import TextEditor from './TextEditor'
import MediaEditor from './MediaEditor'
import StandardEditor from './StandardEditor'
import _ from 'lodash'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'

export class StandardElementor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      standardData: [],
      editMode: true,
      preview: false,
      errors: []
    }

    const SortableItem = SortableElement(({ media, index2 }) => {
      const index = index2
      switch (media.type) {
        case 'text':
          return (
            <li>
              <TextEditor
                data={{
                  title: media.title,
                  content: media.contents.caption,
                }}
                validation={{
                  errors: this.props.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                textContentChange={event => {
                  this.textContentChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        case 'media':
          return (
            <li>
              <MediaEditor
                data={{
                  title: media.title,
                  medias: media.contents,
                }}
                validation={{
                  errors: this.props.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onContentTitleChange={(event, i) => {
                  this.onContentTitleChange(event, i, index)
                }}
                onContentCaptionChange={(event, i) => {
                  this.onContentCaptionChange(event, i, index)
                }}
                onContentMediaChange={(event, i) => {
                  this.onContentMediaChange(event, i, index)
                }}
                onContentThumbnailChange={(event, i) => {
                  this.onContentThumbnailChange(event, i, index)
                }}
                addNewMedia={event => this.addNewMedia(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                onDelete={event => this.deleteElement(event, index)}
                onDeleteMediaElement={this.deleteMediaElement}
              />
            </li>
          )
          break
        case 'standard':
          return (
            <li>
              <StandardEditor
                data={{
                  title: media.title,
                  text: media.contents && media.contents.caption,
                  media: {
                    thumbnail:media.contents && media.contents.gallery ? media.contents.gallery.thumbnail : '',
                    source:media.contents && media.contents.gallery ? media.contents.gallery.source : '',
                  },
                }}
                validation={{
                  errors: this.props.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onStandardTextChange={event => {
                  this.standardTextChange(event, index)
                }}
                onStandardThumbnailChange={thumbnail => {
                  this.standardThumbnailChange(thumbnail, index)
                }}
                onMediaChange={event => {
                  this.mediaStandardChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                progressMedia={() => {}}
                progressComplete={() => {}}
              />
            </li>
          )
          break
        default:
          break
      }
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              media={value}
            />
          ))}
        </ul>
      )
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.setNewState(({ standardData }) => ({
      standardData: arrayMove(standardData, oldIndex, newIndex),
    }))
  }

  newSectionMenu = () => {
    return (
      <div className="row mt30">
        <div className="col-12">
          <label
            className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 cursor-pointer py30"
            style={{ border: '2px dashed rgb(132, 134, 136)' }}
          >
            {/* <img src="img.png" class="h50" id="">  */}
            <div className="row w-100">
              <div className="col-md-12 text-center">
                <h4 className="m-0">Add a new section:</h4>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newTextSection()
                  }}
                >
                  <u>Text only</u>
                </div>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newMediaSection()
                  }}
                >
                  <u>Pictures and videos</u>
                </div>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newStandardSection()
                  }}
                >
                  <u>Text and Media</u>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    )
  }

  newTextSection = () => {
    const { standardData } = this.props
    let textsection = {
      title: null,
      type: 'text',
      contents: {
        caption: '',
      },
    }
    standardData.push(textsection)
    this.props.setNewState({
      standardData: standardData,
    })
  }

  deleteElement = (event, index) => {
    const { standardData } = this.props
    event.preventDefault()
    standardData.splice(index, 1)
    this.props.setNewState({
      standardData: standardData,
    })
  }

  duplicateElement = (event, index) => {
    const { standardData } = this.props
    event.preventDefault()
    standardData.push(standardData[index])
    this.props.setNewState({
      standardData: standardData,
    })
  }

  deleteMediaElement = (event, index, subIndex) => {
    event.preventDefault()
    const { standardData } = this.props

    standardData[index].contents.splice(subIndex, 1)

    this.props.setNewState({
      standardData: standardData.splice(0),
    })
  }

  newMediaSection = () => {
    const { standardData } = this.props
    let mediasection = {
      title: null,
      type: 'media',
      contents: [
        {
          title: null,
          caption: null,
          gallery: {},
        },
      ],
    }
    standardData.push(mediasection)
    this.props.setNewState({
      standardData: standardData,
    })
  }

  newStandardSection = () => {
    const { standardData } = this.props
    let standardsection = {
      title: null,
      type: 'standard',
      contents: {
        caption: '',
        gallery: {
          source: null,
          thumbnail: null,
        },
      },
    }

    standardData.push(standardsection)
    this.props.setNewState({
      standardData: standardData,
    })
  }

  titleChange = (event, index) => {
    const { standardData } = this.props
    standardData[index].title = event.target.value

    this.props.setNewState({
      standardData: standardData,
    })
  }

  textContentChange = (event, index) => {
    const { standardData } = this.props
    standardData[index].contents.caption = event.editor.getData()

    this.props.setNewState({
      standardData: standardData,
    })
  }

  standardTextChange = (event, index) => {
    const { standardData } = this.props
    standardData[index].contents.caption = event.editor.getData()

    this.props.setNewState({
      standardData: standardData,
    })
  }

  mediaStandardChange = (event, index) => {
    const { standardData } = this.props
    standardData[index].contents.gallery.source = event.data
    standardData[index].contents.gallery.type = event.type
    if (event.type == 'image')
      standardData[index].contents.gallery.thumbnail = event.data

    this.props.setNewState({
      standardData: standardData,
    })
  }

  standardThumbnailChange = (thumbnail, index) => {
    const { standardData } = this.props
    standardData[index].contents.gallery.thumbnail = thumbnail
    this.props.setNewState({
      standardData: standardData,
    })
  }

  addNewMedia = (event, index) => {
    event.preventDefault()
    const { standardData } = this.props
    standardData[index].contents.push({
      gallery: {
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      },
    })
    
    this.props.setNewState({
      standardData: standardData,
    })
  }

  onContentTitleChange = (event, item_index, index) => {
    const { standardData } = this.props
    standardData[index].contents[item_index].gallery.title = event.target.value

    this.props.setNewState({
      standardData: standardData,
    })
  }

  onContentCaptionChange = (event, item_index, index) => {
    const { standardData } = this.props
    standardData[index].contents[
      item_index
    ].gallery.caption = event.editor.getData()
    
    this.props.setNewState({
      standardData: standardData,
    })
  }

  onContentMediaChange = (event, item_index, index) => {
    const { standardData } = this.props
    standardData[index].contents[item_index].gallery.source = event.data
    standardData[index].contents[item_index].gallery.type = event.type
    if (event.type == 'image')
      standardData[index].contents[item_index].gallery.thumbnail = event.data
    
      this.props.setNewState({
      standardData: standardData,
    })
  }

  onContentThumbnailChange = (event, item_index, index) => {
    const { standardData } = this.props
    standardData[index].contents[item_index].gallery.thumbnail = event

    this.props.setNewState({
      standardData: standardData,
    })
  }

  renderEditMode = () => {
    const { preview } = this.state
    const { standardData } = this.props

    if(preview){
      return (
        this.renderView()
      )
    }else{
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="accordion" id="itemListAccordion">
              {
                <this.SortableList
                  items={standardData}
                  onSortEnd={this.onSortEnd}
                  distance={1}
                />
              }
              {this.newSectionMenu()}
            </div>
          </div>
        </div>
      )
    }

    
  }

  renderView = () => {
    const { standardData } = this.props

    const viewData = standardData.map(item => {
      switch (item.type) {
        case 'text':
          return (
            <Text
              classStyle="mt30"
              data={{
                title: item.title,
                caption: item.contents.caption,
              }}
            />
          )
        case 'media':
          /* return(
            <Media
              classStyle="mt30"
             data={item.contents} 
              title={item.title} 
              innerProps={`gallery`}
            /> */
            
          return (
            <StandarMediaView
              classStyle="mt30"
              data={item.contents} 
              title={item.title} 
              innerProps={`gallery`} />
          ) 
        case 'standard':
          let media = item.contents
          return(
            <Standard classStyle="mt30" data={{title: item.title, caption: media.caption, source: media.gallery ? media.gallery.source : '', type: media.gallery ? media.gallery.type : ''}} />
          )
        default:
          return
      }
    })

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="accordion" id="itemListAccordion">
            {viewData}
          </div>
        </div>
      </div>
    )
  }

  render() {
    console.log('xxx', this.props)

    return (
      <div className="elementor mx-auto">
        {this.props.edit ? this.renderEditMode() : this.renderView()}
      </div>
    )
  }
}

export default StandardElementor
