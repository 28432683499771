import { 
  SEARCH_ITEM, GET_ITEMS, SET_ITEM_MEDICAL_INVENTORY, GET_MEDICAL_INVENTORIES, ITEM_ERROR,GET_INVENTORY_CATEGORIES
} from "./types"
import axios from 'axios'
import $ from 'jquery'

export const getItems = (id, page) => {
  return(dispatch, getState) => {
    const { items } = getState().item
    dispatch({
      type: GET_ITEMS,
      loading: true,
      items: []
    })

    let url = `api/admin/items?medical_inventory_id=${id}&medical_category_id=all&query=&paginate=15&page=${page}`
    console.log('url', url)

    axios.get(url).then((res) => {
      console.log(res)
      dispatch({
        type: GET_ITEMS,
        loading: false,
        items: res.data.data
        // items: page === 1 ? res.data.data : [...items, ...res.data.data]
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const setItems = (items) => {
  return(dispatch) => {
    dispatch({
      type: GET_ITEMS,
      loading: false,
      items: items
    })
  }
}

export const getItemsPerCategory = (id, selected = [], id2=1, keyword='', page = 1) => {
  return(dispatch, getState) => {
    let { items } = getState().item

    let url = `api/admin/items?medical_category_id=${id}&medical_inventory_id=${id2}&query=${keyword}&paginate=15&page=${page}`
    axios.get(url).then((res) => {
      let temp = page === 1 ? res.data.data : [...items, ...res.data.data]

      selected.map((selected) => {
        temp.map((item, index) => {
          if(selected.id == item.id){
            temp[index] = selected
          }
        })
      })

      let more = res.data.data.length == 15

      dispatch({
        type: GET_ITEMS,
        loading: false,
        items: temp.splice(0),
        more: more
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })

  }
}

export const getMedicalInventories = () => {
  return(dispatch) => {
    let url = 'api/admin/medical-inventories'
    dispatch({
      type: GET_MEDICAL_INVENTORIES,
      medical_inventories: [],
      loading: true
    })

    axios.get(url).then((res) => {
      res.data.data.map((e) => {
        e.categories.unshift({id: 'all', name: 'All'})
      })
      dispatch({
        type: GET_MEDICAL_INVENTORIES,
        medical_inventories: res.data.data,
        loading: false
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  } 
}

export const setItemMedicalInventory = (id, name) => {
  return(dispatch) => {
    dispatch({
      type: SET_ITEM_MEDICAL_INVENTORY,
      medical_inventory_id: id,
      medical_inventory_name: name 
    })
  }
}

export const getItem = (id) => {
  return(dispatch) => {

  }
}

export const searchItem = (item, keyword) => { 
  return(dispatch) => {
    dispatch({
      type: SEARCH_ITEM
    })
  }  
}

export const registerCategory = (medical_inventory_id, category) => {
  return(dispatch) => {
    dispatch({
      type: ITEM_ERROR,
      errors: []
    })

    let data = new FormData()
    data.append('medical_inventory_id', medical_inventory_id)
    data.append('name[]', category)

    let url = '/api/admin/medical-categories'

    axios.post(url, data).then((res) => {
      //fetch medical-inventories
      fetchMedicalInventories(dispatch)
      $('#registerNewCategory').modal('hide')

    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: ITEM_ERROR,
        errors: error.response.data.errors
      })
    })

    
  }
}

export const getMedicalInventoryCategories = (id) => {
  return(dispatch) => {
    let url = `api/admin/medical-categories-by-inventory/${id}`

    axios.get(url).then((res) => {
      res.data.data.unshift({
        id: 'all',
        name: 'All'
      })
      dispatch({
        type: GET_INVENTORY_CATEGORIES,
        inventory_categories: res.data.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response.data.errors)
    })
  }
}

export const deleteCategory = (category_id) => {
  return(dispatch) => {
    dispatch({
      type: ITEM_ERROR,
      errors: []
    })

    let url = `/api/admin/medical-categories/${category_id}`

    axios.post(url, {_method: 'DELETE'}).then((res) => {
      fetchMedicalInventories(dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: ITEM_ERROR,
        errors: error.response.data.errors
      })
    })

    
  }
}

export const updateCategory = (category_id, category) => {
  return(dispatch) => {
    dispatch({
      type: ITEM_ERROR,
      errors: []
    })

    let data = new FormData()
    data.append('_method', 'PATCH')
    data.append('name', category)

    let url = `/api/admin/medical-categories/${category_id}`

    axios.post(url, data).then((res) => {
      fetchMedicalInventories(dispatch)
      $('#updateCategoryModal').modal('hide')
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: ITEM_ERROR,
        errors: error.response.data.errors
      })
    })

  }
}

const fetchMedicalInventories = (dispatch) => {
  let url = 'api/admin/medical-inventories'
  dispatch({
    type: GET_MEDICAL_INVENTORIES,
    medical_inventories: [],
    loading: true
  })

  axios.get(url).then((res) => {
    dispatch({
      type: GET_MEDICAL_INVENTORIES,
      medical_inventories: res.data.data,
      loading: false
    })
  }).catch((error) => {
    console.log(error)
    console.log(error.response)
    dispatch({
      type: GET_MEDICAL_INVENTORIES,
      medical_inventories: [],
      loading: false
    })
  })
}