import React, { Component } from 'react'

export default class Notifier extends Component {

    constructor(props) {
        super(props)

        this.state = {
            show: false
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props != nextProps) {
            this.setState({
                show: nextProps.show
            })
        }
    }

    render() {
        const { show, message, type, styleClass } = this.props

        var icon = 'mdi-information';
        if (this.state.show) {
            if (type == 'success') {
                icon = 'mdi-check-circle'
            } else if (type == 'error') {
                icon = 'mdi-information'
            } else if (type == 'info') {
                icon = 'mdi-information'
            } else if (type == 'warning') {
                icon = 'mdi-information'
            }
            return (
                <div className={`notifier-div ${type} fs16 font-weight-bold ${styleClass} d-flex`}>
                    <div className="d-flex justify-content-center align-items-center w-100 float-left">
                        <i className={`mdi ${icon} mr5 fs20`}></i>
                        {message}
                    </div>
                    <a href="javascript:;" className="close-notification" onClick={() => {
                        this.setState({
                            show: false
                        })

                        if (this.props.toggleShow) {
                            this.props.toggleShow(false)
                        }
                    }}>
                        <i className="mdi mdi-close"></i>
                    </a>
                </div>
            )
        } else {
            return null;
        }
    }
}
