import React, { Component } from 'react'
import MediaInput from '../MediaInput'
import { connect } from 'react-redux'
import { positioningUpdate } from '../../actions'
class PressureMap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      progressMedia: [],
    }
  }

  addMoreMedia() {
    const { pressure_maps } = this.props

    pressure_maps.push({
      title: '',
      caption: '',
      media: '',
      position_id: '',
      media_type: '',
      id: 0,
      show: true,
    })
    const value = pressure_maps.splice(0)

    this.props.positioningUpdate({ prop: 'pressure_maps', value })
  }

  titleChange(event, index) {
    const { pressure_maps } = this.props

    pressure_maps[index].title = event.target.value

    const value = pressure_maps.splice(0)

    this.props.positioningUpdate({ prop: 'pressure_maps', value })
  }

  captionChange(event, index) {
    const { pressure_maps } = this.props

    pressure_maps[index].caption = event.editor.getData()

    const value = pressure_maps.splice(0)

    this.props.positioningUpdate({ prop: 'pressure_maps', value })
  }

  onDelete(event, index) {
    event.preventDefault()

    const { pressure_maps } = this.props

    pressure_maps[index].show = false

    const value = pressure_maps.splice(0)
    this.props.setNewState({
      errors: [],
    })
    this.props.positioningUpdate({ prop: 'pressure_maps', value })
  }

  mediaChange(event, index) {
    const { pressure_maps } = this.props

    pressure_maps[index].source = event.data
    pressure_maps[index].type = event.type
    if (event.type == 'image') pressure_maps[index].thumbnail = event.data

    const value = pressure_maps.splice(0)

    this.props.positioningUpdate({ prop: 'pressure_maps', value })
  }

  thumbnailChange(thumbnail, index) {
    const { pressure_maps } = this.props

    pressure_maps[index].thumbnail = thumbnail

    const value = pressure_maps.splice(0)

    this.props.positioningUpdate({ prop: 'pressure_maps', value })
  }
  render() {
    const media_items = this.props.pressure_maps.map((item, index) => {
      return item.show ? (
        <MediaInput
          key={`media_key_${index}`}
          validation={{
            errors: this.props.errors,
            prefix: 'map_title',
            index: index,
            field: 'map_title',
            media_field: 'map_media',
          }}
          media_data={item}
          onTitleChange={event => this.titleChange(event, index)}
          onCaptionChange={event => this.captionChange(event, index)}
          onMediaChange={event => this.mediaChange(event, index)}
          onDelete={event => this.onDelete(event, index)}
          thumbnailChange={thumbnail => this.thumbnailChange(thumbnail, index)}
          progressMedia={() => {
            this.setState({
              progressMedia: [...this.state.progressMedia, true],
            })
          }}
          progressComplete={() => {
            this.setState({
              progressMedia: [...this.state.progressMedia, true],
            })
          }}
        />
      ) : null
    })
    return (
      <div className="row mb30">
        <div className="col-md-12">
          <label>Injury prevention information</label>
        </div>
        <div className="col-md-12">
          {media_items}
          <div className="row mb-0">
            <div className="col-12">
              <label
                className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 cursor-pointer h50 py30"
                style={{ border: '2px dashed rgb(132, 134, 136)' }}
                onClick={this.addMoreMedia.bind(this)}
              >
                {/* <img src="img.png" className="h50" id="">  */}
                Add more image or video +
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ positioning }) => {
  const { pressure_maps } = positioning
  return { pressure_maps }
}

export default connect(
  mapStateToProps,
  { positioningUpdate }
)(PressureMap)
