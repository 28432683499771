import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getBodyAreas } from '../../actions/PostioningActions'

class PositionAreas extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        body_areas: []
      } 
    }
   
    componentDidMount = () => {
      this.props.getBodyAreas()
      
    }

    componentWillReceiveProps = () => {
     
    }

    getLabel = (code) => {
      const { details }  = this.props
			let length = 'N/A'
			
      details.map(detail => {
        if(detail[0].position.code == code){
          length =  detail.length
        }
      })

      return length
    }
    
    render() {

      const { details }  = this.props
      
      console.log('det', details)
        return (
			<div className="card p-0 shadow-sm border-0 mt20" id="home">
				<div className="card-header bg-white p15 border-0" id="note-headeing-1">
					<h5 className="mb-0 d-flex align-items-center">
						Items required by position
                      </h5>
				</div>
				<div className="card-body pt-0 mt5 px15">

					<div class="row mb10">
						<div class="col-md-5 mx-auto d-flex justify-content-center align-items-baseline">


							<div class="w60 px5 mb5 d-flex justify-content-center flex-column">
								<a class="border-0 px20 h60 d-flex align-items-center justify-content-center mb5">&nbsp;</a>

								<a href="#left_shoulder" class="border border-dark px20 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('left_shoulder')}
								</a>
								<a href="#left_arm" class="border border-dark px20 h100 d-flex align-items-center justify-content-center mb5"> 
									{this.getLabel('left_arm')}
								</a>
								<a href="#left_hand" class="border border-dark px20 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('left_hand')}
								</a>
							</div>

							<div class="w150 mb5 d-flex justify-content-center flex-column align-items-baseline">
								<a href="#head" class="border border-dark w150 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('head')}
								</a>
								<a href="#neck_upper_torso" class="border border-dark w150 h90 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('neck_upper_torso')}
								</a>
								<a href="#abdomen" class="border border-dark w150 h90 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('abdomen')}
								</a>
								<a href="#hip" class="border border-dark w150 h60 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('hip')}
								</a>

								<div class="row h100 d-flex justify-content-between m-0 w-100">
									<div class="col-md-6 pl-0 pr5">
										<a href="#left_leg" class="border border-dark w-100 h70 d-flex align-items-center justify-content-center mb5">
											{this.getLabel('left_leg')}
										</a>
										<a href="#left_foot" class="border border-dark w-100 h30 d-flex align-items-center justify-content-center mb5">
											{this.getLabel('left_foot')}
										</a>
									</div>
									<div class="col-md-6 pr-0 pl5">
										<a href="#right_leg" class="border border-dark w-100 h70 d-flex align-items-center justify-content-center mb5">
											{this.getLabel('right_leg')}
										</a>
										<a href="#right_foot" class="border border-dark w-100 h30 d-flex align-items-center justify-content-center mb5">
											{this.getLabel('right_foot')}
										</a>
									</div>

								</div>

							</div>
							<div class="w60 px5 mb5 d-flex justify-content-center flex-column">
								<a class="border-0 px20 h60 d-flex align-items-center justify-content-center mb5">&nbsp;</a>

								<a href="#right_shoulder" class="border border-dark px20 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('right_shoulder')}
								</a>
								<a href="#right_arm" class="border border-dark px20 h100 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('right_arm')}
								</a>
								<a href="#right_hand" class="border border-dark px20 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('right_hand')}
								</a>
							</div>

						</div>
					</div>

					{/* <div className="row">
						<div className="col-md-5 mx-auto d-flex flex-column justify-content-center">
							<div className="w-100 h60 mb5 mx-auto d-flex justify-content-center">
								<a href="#head" className="border border-dark w150 h-100 d-flex align-items-center justify-content-center">
									{this.getLabel('head')}
								</a>
							</div>
							<div className="w-100 h40 mb5 mx-auto d-flex justify-content-center">
								<a href="#left_shoulder" className="border border-dark w50 h-100 mx5 d-flex align-items-center justify-content-center">
									{this.getLabel('left_shoulder')}
								</a>
								<a href="#neck_upper_torso" className="border border-dark w150 h-100 d-flex align-items-center justify-content-center">
									{this.getLabel('neck_upper_torso')}
								</a>
								<a href="#right_shoulder" className="border border-dark w50 h-100 mx5 d-flex align-items-center justify-content-center">
									{this.getLabel('right_shoulder')}
								</a>
							</div>
							<div className="w-100 h90 mb5 mx-auto d-flex justify-content-center">
								<a href="#left_arm" className="border border-dark w50 h-100 mx5 d-flex align-items-center justify-content-center">
									{this.getLabel('left_arm')}
								</a>
								<a href="#abdomen" className="border border-dark w150 h-100 d-flex align-items-center justify-content-center">
									{this.getLabel('abdomen')}
								</a>
								<a href="#right_arm" className="border border-dark w50 h-100 mx5 d-flex align-items-center justify-content-center">
									{this.getLabel('right_arm')}
								</a>
							</div>
							<div className="w-100 h60 mb5 mx-auto d-flex justify-content-center">
								<a href="#left_hand" className="border border-dark w50 h-100 mx5 d-flex align-items-center justify-content-center">
									{this.getLabel('left_hand')}

								</a>
								<a href="#hip" className="border border-dark w150 h-100 d-flex align-items-center justify-content-center">
									{this.getLabel('hip')}
								</a>
								<a href="#right_hand" className="border border-dark w50 h-100 mx5 d-flex align-items-center justify-content-center">
									{this.getLabel('right_hand')}

								</a>
							</div>
							<div className="w-100 h60 mb5 mx-auto d-flex justify-content-center">
								<a href="#left_leg" className="border border-dark w70 h-100 mr5 d-flex align-items-center justify-content-center">
									{this.getLabel('left_leg')}

								</a>
								<a href="#right_leg" className="border border-dark w70 h-100 d-flex align-items-center justify-content-center">
									{this.getLabel('right_leg')}
								</a>
							</div>
							<div className="w-100 h30 mb5 mx-auto d-flex justify-content-center">
								<a href="#left_foot" className="border border-dark w70 h-100 mr5 d-flex align-items-center justify-content-center">
									{this.getLabel('left_foot')}
								</a>
								<a href="#right_foot" className="border border-dark w70 h-100 d-flex align-items-center justify-content-center">
									{this.getLabel('right_foot')}
								</a>
							</div>
						</div>
					</div>
					 */}
				</div>
			</div>
        );
    }
}

const mapStateToProps = (state) => {
  return({
    body_areas: state.positioning.body_areas
  })
}

  export default connect(mapStateToProps, {getBodyAreas})(PositionAreas);