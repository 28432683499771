import React, { Component } from 'react'
import CKEditor from 'ckeditor4-react'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import img from '../assets/images/img.png'
import VideoThumbnail from 'react-video-thumbnail'
import ImageCropper from './commons/elements/inputs/ImageCropper'
import $ from 'jquery'
import { TextInput } from './commons/forms'
import { getArrayError } from '../utilities'
import CircleLoading from './loader/CircleLoading'
import { uploadImage } from '../utilities/upload-image'
import { uploadVideo } from '../utilities/upload-video'
import axios from 'axios'

class MediaInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source:'',
      file: null,
      file_raw: '',
      croppedFile: null,
      show: false,
      isNext: false,
      gloveData: [1, 2],
      page: 1,
      filename: '',
      selected: null,
      image: img,
      type: 'image',
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      loading: false,
      progress: 0,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.setState({
      source
    })
  }

  componentWillUnmount = () => {
    console.log('unmountedrd')
  }

  handleClick(e) {
    this.refs.file.click()
  }
  onFileChange = () => {
    this.setState({
      progress: 0,
    })
    
    if (this.refs.file.files[0].type.split('/')[0] == 'image') {
     
      var reader = new FileReader()
      var url = reader.readAsDataURL(this.refs.file.files[0])
      reader.onloadend = () => {
        this.setState({
          file_raw: reader.result,
          type: 'image',
          // image: reader.result
        }, () => {
          $(`#imageCropperStandard${this.state.unique}`).modal({
            backdrop: 'static',
            keyboard: false,
            show: true,
          })
        })
      }
    } else {
      if (!this.refs.file.files[0]) {
        this.setState({
          show: false,
          file: null,
          croppedFile: null,
          isNext: false,
          page: 1,
        })
        return
      }
      

      const fileData = this.refs.file.files[0]

      this.props.progressMedia()
      uploadVideo(
        fileData,
        progress => {
          this.setState({
            progress: Math.round((progress.loaded * 100) / progress.total),
          })
        },
        this.state.source.token

      )
        .then(result => {
          console.log(' this.props', result)
          if (typeof this.props.thumbnailChange === 'function') {
            this.props.thumbnailChange(result.thumbnail)
          } else {
            this.props.onStandardThumbnailChange(result.thumbnail)
          }

          this.props.onMediaChange({
            data: result.source,
            type: 'video',
          })
          this.setState(
            {
              loading: false,
              progress: 0,
            },
            () => {
              this.props.progressComplete()
            }
          )
        })
        .catch(error => {
          console.log(error)
        })

      return
    }
  }
  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    let filename = this.state.file.name
    reader.onload = () => {
      this.props.onMediaChange({ data: reader.result, type: 'image' })
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
        filename: filename,
        image: reader.result,
      })
    }
  }
  renderCropper() {
    const { file, loading,progress } = this.state
    const { media_data, validation } = this.props
    console.log(validation.media_field)
    let error = getArrayError(
      validation.errors,
      validation.media_field ? validation.media_field : 'images',
      validation.index
    )

    if (progress > 0) return <CircleLoading progress={progress} />
    if (!file) {
      if (media_data.source) {
        return (
          <label
            className={`col-xl-4 col-lg-5 d-flex justify-content-center align-items-center bg-light of-hidden m-0 c-pointer text-center  preview-media-section  ${
              error ? 'is-invalid' : ''
            }`}
            style={
              error
                ? { border: '2px dashed red' }
                : { border: '2px dashed rgb(132, 134, 136)' }
            }
          >
            <span className="change_media_label">
              Change {this.state.type == 'image' ? 'image' : 'video'}
            </span>
            <input
              type="file"
              name="image"
              accept="video/*,image/*"
              className="w300"
              style={{ zIndex: -1, position: 'absolute' }}
              onClick={event => {
                event.target.value = null
              }}
              onChange={this.onFileChange}
              ref="file"
              required
            />
            {this.state.type == 'image' ? (
              <img src={media_data.thumbnail} class="w-100" />
            ) : (
              <VideoThumbnail
                videoUrl={this.props.media_data.source}
                thumbnailHandler={thumbnail =>
                  this.props.thumbnailChange(thumbnail)
                }
              />
            )}
          </label>
        )
      }

      return (
        <label
          className={`col-xl-4 col-lg-5 d-flex justify-content-center align-items-center bg-light of-hidden m-0 c-pointer text-center  ${
            error ? 'is-invalid' : ''
          }`}
          style={
            error
              ? { border: '2px dashed red' }
              : { border: '2px dashed rgb(132, 134, 136)' }
          }
        >
          <input
            type="file"
            name="image"
            accept="video/*,image/*"
            className="w300"
            style={{ zIndex: -1, position: 'absolute' }}
            onClick={event => {
              event.target.value = null
            }}
            onChange={this.onFileChange}
            ref="file"
            required
          />
          {/* <img src="img.png" class="w-100" id="" v-if="map.type == ''">  */}
          Add image or video +
        </label>
      )
    } else {
      if (this.state.show) {
        return (
          <Cropper
            size={[80, 45]}
            file={this.state.file}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog className="modal-dialog-centered" />}
            onChange={this.croppeFile}
            circle={false}
            locale="en-US"
            options={{
              aspectRatio: 7 / 4,
            }}
          />
        )
      }
    }
  }
  render() {
    const { validation } = this.props
    return (
      <div className="card p-0 rounded-0 border-0 shadow-none mb30">
        <div className="card-header py-0 px-0 rounded-0 border-0 shadow-none d-flex bg-secondary pl-0 flex-wrap flex-sm-nowrap justify-content-end justify-content-md-start">
          <div className="d-flex mh50 order-3 order-sm-0 flex-fill">
            <div className="font-weight-bold ml-auto pl15 d-flex align-items-center justify-content-end text-dark">
              Title
            </div>
            <TextInput
              value={this.props.media_data.title}
              placeholder={'e.g. Images'}
              errorMessage={getArrayError(
                validation.errors,
                validation.field,
                validation.index
              )}
              containerClass={`w-100 mb0 non-rounded-input mx10 d-flex align-items-center`}
              onValueChange={event => {
                this.props.onTitleChange(event)
              }}
            />
          </div>
          <a
            href="#"
            className="font-weight-bold ml-auto pl30 pr15 d-flex align-items-center justify-content-end text-dark p-xsx15 m-xsx0 justify-content-center  order-2 order-xs-2 mh50"
            onClick={(event) => {
              const { source } = this.state
              source.cancel('Operation canceled by the user.')
              this.props.onDelete(event)
            }}
          >
            <u>Delete Media</u>
          </a>
        </div>
        <div className="card-body py0 rounded-0 border-0 shadow-none">
          <div className="row d-flex">
            {this.renderCropper()}

            <div className="col px-0 h-100">
              <CKEditor
                data={this.props.media_data.caption}
                onChange={this.props.onCaptionChange}
                config={{
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', '-'],
                    [
                      'NumberedList',
                      'BulletedList',
                      '-',
                      // 'Outdent',
                      // 'Indent',
                      // '-',
                      'JustifyLeft',
                      'JustifyCenter',
                      'JustifyRight',
                      // 'JustifyBlock',
                      '-',
                    ],
                    [
                      //'Table', 'HorizontalRule',
                      'Link',
                      'Smiley',
                    ],
                    ['Format'],
                    ['TextColor', 'BGColor'],
                  ],
                }}
              />
            </div>
          </div>
        </div>

        <ImageCropper
          id={`imageCropperStandard${this.state.unique}`}
          image={this.state.file_raw}
          aspectRatio={3 / 2}
          width={720}
          height={480}
          getImage={image => {
            this.props.progressMedia()
            uploadImage(image, progress => {
              this.setState({
                progress: Math.round((progress.loaded * 100) / progress.total),
              })
            },
            this.state.source.token
            ).then(result => {
              this.props.onMediaChange({ data: result.data, type: 'image' })

              this.props.thumbnailChange(image)
              this.setState(
                {
                  loading: false,
                  progress: 0,
                },
                () => {
                  this.props.progressComplete()
                }
              )
            })
            this.setState({
              show: false,
              file: null,
              croppedFile: null,
              isNext: false,
              page: 1,
              image: image,
            })
          }}
        />
      </div>
    )
  }
}

export default MediaInput
