import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router';
import { fetchPositions, openTable, fetchItems, closeTable, openModal, postPositions, postStagePositions, clearRoom } from '../actions/roomSetupAction'
import Unity, { UnityContent } from "react-unity-webgl";
import FullDialogue from './FullDialogue';
import EquipInfo from './EquipInfo';
import WholeBodyloader from './loader/WholeBodyloader';
import $ from 'jquery'
import { ListItemSecondaryAction } from '@material-ui/core';


class Frame extends React.Component {

  constructor(props) {
    super(props); 
    browserHistory.listen(location => {   
      if(location.path != window.location.path){ 
        this.quitThis()
      }
    });

    this.state = {
      loading:1,
      isEditMode: false,
      room_id: null
    }

    this.unityContent = new UnityContent(
      "../../Build/RoomSetup.json",
      "../../Build/UnityLoader.js"
    );
    
    //Opens the "Assigning items" modal to select a product
    this.unityContent.on("openTable", () => { 
      $("#assignItemsRoomSetupModalCenter").modal('show') 
    });

    //runs after a room has completed loading
    this.unityContent.on("roomLoaded", () => {
      this.props.setLoadingState(0) 
    });

    
    this.unityContent.on("callSaveModal", () => {  
    });

    this.unityContent.on("exportInfo", (id) => {
      this.props.openModal(JSON.parse(id));
    });


    // callback when unity finish loading 
    this.unityContent.on("loaded", () => { 
      setTimeout(() => {
        this.setState({
          loading: 0
        })
      }, 1800);
      this.props.setLoadingState(0)
    });

    //isEditMode
    

    // callback from unity exporting current positions of items in unity frame
    this.unityContent.on("exportSave", (exportData) => {
      //alert('save');
      //console.log(JSON.parse(exportData)); 
      const formData = new FormData()
      const exported = JSON.parse(exportData);
      exported.Position.map(data => {
        formData.append('positions[]', data) 
        return formData
      })

      exported.itemID.map(data => {
        formData.append('item_ids[]', data)
        return formData
      })

      if (this.props.stage){
        formData.append('room_id', this.props.room_id)
        formData.append('draft', 0)
        this.props.postStagePositions(formData, this.props.room_id);
      }else{
        this.props.postPositions(formData, this.props.room_id);
      }
    });
  } 

  // this will clean the room
  clearRoom(){
    this.unityContent.send(
      "SaveManager",
      "clearRoom"
    )
  }

  componentWillReceiveProps(nextProps) {     
    // clears room if nextProps has clear_room
    if (nextProps.clear_room) {
      this.clearRoom()
    }

    if (nextProps.room_id > 0) {
      this.setState({
        room_id: nextProps.room_id,
      })
      if ((nextProps.posts != this.props.posts) && this.state.loading == 0) {
        console.log(1)
        setTimeout(() => {
          this.loadThis();
        }, 200);
      }
    }

    if (nextProps.save_info) {
      this.saveThis()
    }

    if (nextProps.edit_mode != null) {
      if (nextProps.edit_mode) {
        this.setEditMode(true)
      } else {
        this.setEditMode(false)
      }
    }

    if (!nextProps.modalOpen) {
      $("#assignItemsRoomSetupModalCenter").modal('hide')
    }

  } 
  
  componentDidMount() {
    //this.props.fetchPositions(1);
    this.props.fetchItems();
    this.setEditMode(false)
    //this.setEditMode()
  }

  // saves the current positions of items in frame
  saveThis = () => {
    this.unityContent.send(
      "SaveManager",
      "Save",
    )
  }

  // this will close the current unity instance 
  quitThis = () => {
    this.unityContent.send(
      "SaveManager",
      "Quit",
    ) 
  }

  setEditMode = (condition) => {
    if(condition){
      this.unityContent.send(
        "SaveManager",
        "setEditModeTrue",
      )  
    }else{
      this.unityContent.send(
        "SaveManager",
        "setEditModeFalse",
      )
    }
    
  }

  getEquip = (item) => { 
    this.unityContent.send(
      "Camera",
      "spawEquipment",
      JSON.stringify(item)
    );
    //console.log(item);
    this.props.closeTable();
  }

  loadThis = () => {
    const { posts } = this.props
    //console.log('loadThis', this.props)
    
    this.unityContent.send(
      "SaveManager",
      "clearRoom"
    )
    posts.map(post => {
      //console.log(post);
      this.unityContent.send(
        "SaveManager",
        "loadDefault",
        JSON.stringify(post)
      )
      return post
    });  
    if (!posts.length) {
      this.props.setLoadingState(0)
    }
  };

  render() {

    return (
      <div>
        <WholeBodyloader progress={this.state.loading} />
        <Unity unityContent={this.unityContent} />
        <FullDialogue getEquip={this.getEquip} />
        <EquipInfo />

      </div>
    )
  }
}
//everytime a reducer runs, mapStateToProps will be called again
const mapStateToProps = state => { //the state will have a property called "posts" according sa decalred reducers index

  return {
    posts: state.posts.room_setup_positions,
    items: state.posts.items,
    save_info: state.posts.save_info,
    edit_mode: state.posts.edit_mode,
    modalOpen: state.posts.modalOpen,
    clear_room: state.posts.clear_room
  }

}

export default connect(mapStateToProps, { fetchPositions, openTable, fetchItems, closeTable, openModal, postPositions, clearRoom, postStagePositions })(Frame);