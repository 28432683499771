import React, { Component } from 'react'
import axios from 'axios'
import $ from 'jquery'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import ViewItemModal from '../modals/ViewItemModal'
import Confirmation from '../../components/Confirmation'
import Notifier from '../../components/commons/table/Notifier'
import Inbodyloader from '../loader/Inbodyloader'

class Trays extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trays: [],
      selectedTray: null,
      editMode: false,
      tray_specialties: [],
      item_categories: [],
      search: '',
      selectedItemCategory: null,
      selectedSpecialty: null,
      selectedItem: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      notify_status: '',
      message: '',
      show: false,
      isReserse: false,
      isReverseItems: false,
      trayListLoading: 0,
      trayItemListLoading: 0,
    }
  }

  componentDidMount() {
    this.getTrays()
    this.getTraySpecialties()
  }
  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div class="row">
          <div class="col-md-12 text-center pb30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )

    return (
      <div class="row">
        <div class="col-md-12 text-center mt30">
          <h3 class="mb-15">Nothing else to see...</h3>
          <p class="">
            Missing something? Add new items via{' '}
            <a
              href="javascript:;"
              onClick={() => {
                this.setState({
                  isEditMode: true,
                })
              }}
            >
              Edit database
            </a>
          </p>
        </div>
      </div>
    )
  }

  getTrays() {
    const { search, selectedSpecialty } = this.state
    this.setState({
      trayListLoading: 1,
    })
    axios
      .get(
        `api/admin/tray?specialty=${
          selectedSpecialty == null ? '' : selectedSpecialty
        }&query=${search}`
      )
      .then(result => {
        console.log(result)
        this.setState({
          trays: result.data.data,
          trayListLoading: 0,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  getTraySpecialties() {
    axios
      .get(`api/admin/tray-specialties`)
      .then(result => {
        console.log(result)
        this.setState({
          tray_specialties: result.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  getItemCategories(id) {
    axios
      .get(`api/admin/tray-item-category/${id}`)
      .then(result => {
        console.log(result)
        this.setState({
          item_categories: result.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  componentDidUpdate() {}

  specialtyChange(event) {
    event.preventDefault()
    this.setState(
      {
        selectedSpecialty: event.target.value,
      },
      () => {
        this.getTrays()
      }
    )
  }

  itemCategoryChange(event) {
    this.setState({
      selectedItemCategory: event.target.value,
    })
  }
  searchTray(event) {
    event.preventDefault()
    this.setState(
      {
        search: event.target.value,
      },
      () => {
        this.getTrays()
      }
    )
  }

  compareBy(key) {
    if (key == 'specialty') {
      return function(a, b) {
        if (a.specialty.name < b.specialty.name) return -1
        if (a.specialty.name > b.specialty.name) return 1
        return 0
      }
    }

    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }

    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  sortBy(key) {
    let arrayCopy = [...this.state.trays]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ trays: arrayCopy, isReverse: !this.state.isReverse })
  }
  sortByItems(key) {
    let origArray = this.state.selectedTray
    let arrayCopy = [...this.state.selectedTray.items]
    if (this.state.isReverseItems) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    origArray.items = arrayCopy
    this.setState({
      selectedTray: this.state.selectedTray,
      isReverseItems: !this.state.isReverseItems,
    })
  }

  deleteItem(event) {
    event.preventDefault()
    const { selectedTray } = this.state
    axios
      .post(`api/admin/tray/${selectedTray.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          trays: this.state.trays.filter(i => i.id != selectedTray.id),
          isCreate: false,
          notify_status: 'positive',
          message: 'Tray success fully deleted!',
          show: true,
          selectedTray: null,
        })
        setTimeout(() => {
          this.setState({
            show: false,
          })
          if ($('input:checked')) {
            $('input:checked').checked = false
          }
        }, 5000)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  getItems = id => {
    this.setState(
      {
        loading: true,
        
      },
      () => {
        axios
          .get(`api/tray/${id}`)
          .then(result => {
            console.log(result)
            this.setState({
              selectedTray: result.data.data,
              loading: false,
            })
          })
          .catch(error => {
            console.log(error)
          })
      }
    )
  }

  render() {
    const { trays, selectedTray } = this.state
    let trayItemList = null
    if (selectedTray) {
      trayItemList = selectedTray.items.map((item, index) => {
        if (this.state.selectedItemCategory) {
          if (this.state.selectedItemCategory != item.medical_category_id) {
            return null
          }
        }
        let tags = item.tags.map(tag => {
          return ReactDOMServer.renderToStaticMarkup(
            <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
              {tag.name}
            </span>
          )
        })
        if (this.state.editMode) {
          return (
            <tr>
              <td
                className="w200 px10"
                data-container="body"
                data-trigger="hover"
                data-toggle="popover"
                data-placement="right"
                data-html="true"
                data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
              >
                <div
                  class="btn btn-link text-left py-0 fs14"
                  onClick={() => {
                    this.setState(
                      {
                        selectedItem: item,
                        unique:
                          Math.random()
                            .toString(36)
                            .substring(2, 15) +
                          Math.random()
                            .toString(36)
                            .substring(2, 15),
                      },
                      () => {
                        $('#viewItemModal1').modal('show')
                        $('.pover').popover('hide')
                      }
                    )
                  }}
                >
                  {item.name}
                </div>
              </td>
              <td>{item.qty}</td>
              <td className="text-center">{item.size ? item.size : '-'}</td>
            </tr>
          )
        } else {
          return (
            <tr>
              <td>{item.catalog_number}</td>
              <td
                className="w200 px10"
                data-container="body"
                data-trigger="hover"
                data-toggle="popover"
                data-placement="right"
                data-html="true"
                data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
              >
                <div
                  class="btn btn-link text-left py-0 fs14"
                  onClick={() => {
                    this.setState(
                      {
                        selectedItem: item,
                        unique:
                          Math.random()
                            .toString(36)
                            .substring(2, 15) +
                          Math.random()
                            .toString(36)
                            .substring(2, 15),
                      },
                      () => {
                        $('.popover').popover('hide')
                        $('#viewItemModal1').modal('show')
                      }
                    )
                  }}
                >
                  {item.name}
                </div>
              </td>
              <td>{item.qty}</td>
              <td>{item.category.name}</td>
              <td className="text-center">{item.size ? item.size : '-'}</td>
            </tr>
          )
        }
      })

      if (selectedTray.items.length == 0) {
        if (this.state.editMode) {
          trayItemList = (
            <tr>
              <td colSpan={3}>
                <b>No items found.</b>
              </td>
            </tr>
          )
        } else {
          trayItemList = (
            <tr>
              <td colSpan={5}>
                <b>No items found.</b>
              </td>
            </tr>
          )
        }
      }
    }

    const filterSpecialty = this.state.tray_specialties.map((data, index) => {
      return <option value={data.id}>{data.name}</option>
    })

    const filterCategories = this.state.item_categories.map((data, index) => {
      return <option value={data.id}>{data.name}</option>
    })

    const trayList = this.state.trays.map((tray, index) => {
      return (
        <tr
          onClick={() => {
            this.getItems(tray.id)
            this.getItemCategories(tray.id)
            $(`#tray_list_row_${tray.id}`)
              .find('label')
              .click()
            setTimeout(() => {
              $('[data-toggle="popover"]').popover()
              $('[data-toggle="popover"]').popover('hide')
            }, 1000)
            $('html, body').animate(
              { scrollTop: $('#trays').offset().top - 20 },
              0
            )
          }}
          className={
            this.state.selectedTray == tray
              ? 'active-row c-pointer'
              : ' c-pointer'
          }
          id={`tray_list_row_${tray.id}`}
        >
          <td className="text-left w50 pr-0" key={`tray-key-${index}`}>
            <div
              className="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: '-35px', paddingLeft: '25px' }}
            >
              <input
                type="radio"
                name="radiobtn"
                id={tray.id}
                className="custom-control-input c-pointer"
              />
              <label
                className="custom-control-label  c-pointer"
                htmlFor={tray.id}
              >
                &nbsp;
              </label>
            </div>
          </td>
          <td className="font-weight-bold">{tray.name}</td>
          <td>{tray.specialty.name}</td>
          {this.state.editMode ? (
            <td>
              <Link
                to={{
                  pathname: `/medical-inventory-instruments/edit-tray/${
                    tray.id
                  }`,
                }}
                className="mr10"
              >
                Edit
              </Link>
              <a
                href="javascript:;"
                data-target="#deleteTrayModal"
                data-toggle="modal"
                onClick={e => {
                  e.stopPropagation()
              //    this.setState({ selectedTray: tray })
                }}
              >
                Delete
              </a>
            </td>
          ) : null}
        </tr>
      )
    })
    return (
      <div
        className="container"
        className={`tab-pane ${this.props.activeTab ? 'active' : ''}`}
        id="items"
        role="tabpanel"
        aria-labelledby="items-tab"
      >
        <ViewItemModal
          idModal="viewItemModal1"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal1').modal('hide')
          }}
        />
        <Confirmation
          title="Delete Tray"
          message="Deleting tray will remove it from the database and will have to be added back manually."
          confirmText="Delete Tray"
          cancelText="Cancel"
          idModal="deleteTrayModal"
          confirm={this.deleteItem.bind(this)}
          cancel={() => {
            $('deleteTrayModal').modal('hide')
          }}
        />

        <div className="row mt30 pb30" id="trays">
          <div
            className={`mb30 pb30 ${
              this.state.editMode ? 'col-md-6' : 'col-md-4'
            }`}
          >
            {this.state.editMode ? (
              <div className="row">
                <div className="form-group m-0 col-md-6">
                  <h6 className="mb10">Search for a tray</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Start typing name, catalogue #..."
                    onChange={this.searchTray.bind(this)}
                  />
                </div>
                <div className="form-group m-0 col-md-6">
                  <h6 className="mb10">Filter trays by specialty</h6>
                  <select
                    className="form-control"
                    onChange={this.specialtyChange.bind(this)}
                  >
                    <option value="">All</option>
                    {filterSpecialty}
                  </select>
                </div>
              </div>
            ) : (
              <div className="form-group m-0 w-75">
                <h6 className="mb10">Filter trays by specialty</h6>
                <select
                  className="form-control"
                  onChange={this.specialtyChange.bind(this)}
                >
                  <option value="">All</option>
                  {filterSpecialty}
                </select>
              </div>
            )}
            <div className="card h-100 mt20 rounded-0">
              <div className="card-body p-0 rounded-0">
                <Inbodyloader progress={this.state.trayListLoading} />
                <table className="table">
                  <thead>
                    <tr>
                      <th> </th>
                      <th
                        className="c-pointer"
                        onClick={() => this.sortBy('name')}
                      >
                        <i className="fa fa-sort mr5" />
                        Tray Name
                      </th>
                      <th
                        className="c-pointer"
                        onClick={() => this.sortBy('specialty')}
                      >
                        <i className="fa fa-sort mr5" />
                        Specialty
                      </th>
                      {this.state.editMode ? <th>Edits</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    <Notifier
                      status={this.state.notify_status}
                      message={this.state.message}
                      show={this.state.show}
                      cols={4}
                    />
                    {trayList}
                  </tbody>
                  <tfoot />
                </table>
              </div>
            </div>
          </div>
          <div className="col mb30 pb30">
            <div className="d-flex flex-wrap">
              {this.state.editMode ? (
                ''
              ) : (
                <div className="form-group m-0 w300">
                  <h6 className="mb10">Filter items by</h6>
                  <select
                    className="form-control"
                    onChange={this.itemCategoryChange.bind(this)}
                  >
                    <option value="">All</option>
                    {filterCategories}
                  </select>
                </div>
              )}
              <div className="ml-auto">
                <h6 className="mb10">&nbsp;</h6>

                {this.state.editMode ? (
                  <Link
                    to="/medical-inventory-instruments/new-tray"
                    className="btn btn-outline-dark mr20 px30"
                  >
                    Register new tray
                  </Link>
                ) : (
                  ''
                )}

                {this.state.editMode ? (
                  <a
                    href="javascript:;"
                    className="btn btn-dark mr10 px30"
                    onClick={() => {
                      this.setState({
                        editMode: false,
                      })
                    }}
                  >
                    Update
                  </a>
                ) : (
                  <a
                    href="javascript:;"
                    className="btn btn-outline-dark mr10 px30"
                    onClick={() => {
                      this.setState(
                        {
                          editMode: true,
                        },
                        () => {
                          $('[data-toggle="popover"]').popover()
                          $('[data-toggle="popover"]').popover('hide')
                        }
                      )
                    }}
                  >
                    Edit database
                  </a>
                )}
              </div>
            </div>
            <div className="card h-100 mt20 rounded-0">
              <div className="card-body p-0 rounded-0">
                <Inbodyloader progress={this.state.trayItemListLoading} />
                <table className="table">
                  <thead>
                    {this.state.editMode ? (
                      <tr>
                        <th
                          className="w250 c-pointer"
                          onClick={() => this.sortByItems('name')}
                        >
                          <i className="fa fa-sort mr5" />
                          Item Name
                        </th>
                        <th
                          className="c-pointer"
                          onClick={() => this.sortByItems('qty')}
                        >
                          <i className="fa fa-sort mr5" />
                          Quantity
                        </th>
                        <th
                          className="c-pointer"
                          onClick={() => this.sortByItems('size')}
                        >
                          <i className="fa fa-sort mr5" />
                          Size
                        </th>
                      </tr>
                    ) : (
                      <tr>
                        <th
                          className="w130 c-pointer"
                          onClick={() => this.sortByItems('catalog_number')}
                        >
                          <i className="fa fa-sort mr5" />
                          Catalogue #
                        </th>
                        <th
                          className="w250 c-pointer"
                          onClick={() => this.sortByItems('name')}
                        >
                          <i className="fa fa-sort mr5" />
                          Item Name
                        </th>
                        <th
                          className="w100 c-pointer"
                          onClick={() => this.sortByItems('qty')}
                        >
                          <i className="fa fa-sort mr5" />
                          Quantity
                        </th>
                        <th
                          className="c-pointer"
                          onClick={() => this.sortByItems('category')}
                        >
                          <i className="fa fa-sort mr5" />
                          Category
                        </th>
                        <th
                          className="w80 c-pointer"
                          onClick={() => this.sortByItems('size')}
                        >
                          <i className="fa fa-sort mr5" />
                          Size
                        </th>
                      </tr>
                    )}
                  </thead>
                  <tbody>{trayItemList}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Trays
