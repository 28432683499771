import React, { Component } from 'react'
import 'rc-cropping/assets/index.css'
import _ from 'lodash'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import 'rc-dialog/assets/index.css'
import { connect } from 'react-redux'
import { surgeonUpdate } from '../actions'
import SpecailtySection from './specialty_section'
import axios from 'axios'
import $ from 'jquery'
import { TextInput } from './commons/forms'
import { getError } from '../utilities/'

import { ErrorText } from '../components/validations/error'
import ImagePicker from './commons/elements/inputs/ImagePicker';

class SurgeonForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      croppedFile: null,
      show: false,
      isNext: false,
      gloveData: [],
      page: '',
      filename: '',
      selected: null,
      errors: [],
      keyword: '',
      glove_error: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover()
    $(document).on('click', '.rc-dialog-close', function() {
      $('.rc-btn-ghost').click()
    })

    $('#gloves-modal').on('hidden.bs.modal', () => {
      this.setState({
        selected: null,
      })
    })
  }

  handleClick(e) {
    this.setState({
      show: true,
    })
    this.refs.file.click()
  }
  onSubmit() {
    const { surgeon } = this.props
    
    console.log(surgeon)
    this.setState({
      isNext: true,
    })
  }

  onFileChange = () => {
    this.setState({ file: this.refs.file.files[0] })
  }
  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: '',
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    let filename = this.state.file.name
    reader.onload = () => {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: '',
        filename: filename,
      })
      this.props.surgeonUpdate({
        prop: 'avatar',
        value: reader.result,
      })
    }
  }

  gloveModalOpen(hand) {
    this.setState({
      hand,
      keyword: '',
      glove_error: false
    })
    axios
      .get(`api/gloves?search=&page=${this.state.page}`)
      .then(result => {
        this.setState({
          gloveData: result.data.data,
        })
      })
      .catch(error => console.log(error))
  }

  renderCropper() {
    const { croppedFile, file } = this.state

    if (!file) {
      if (this.state.filename) {
        return (
          <div className="d-inline-flex align-items-center w-100">
            <input
              type="file"
              onChange={this.onFileChange}
              ref="file"
              className="d-none"
            />
            <button className="btn btn-outline-primary mw125" onClick={this.handleClick}>
              Change
          </button>
          </div>
        )
      }

      return (
        <div>
          <input
            type="file"
            onChange={this.onFileChange}
            ref="file"
            className="d-none"
          />
          <button className="btn btn-primary mw125" onClick={this.handleClick}>
            Upload
          </button>
        </div>
      )
    } else {
      if (this.state.show) {
        return (
          <Cropper
            size={[64, 64]}
            file={this.state.file}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog className="modal-dialog-centered" />}
            onChange={this.croppeFile}
            circle
          />
        )
      }
    }
  }
   isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  renderGlove = () => {
    console.log('suurgeon', this.props.surgeon)

    console.log('suurgeonglove', this.props.surgeon.glove_inner)
 
    if (!this.isEmpty(this.props.surgeon.glove_inner)) {
      return (
        <div className="d-inline-flex align-items-center">
          <p className="m0">{this.props.surgeon.glove_inner.name}</p>
          <button
            className="btn btn-link pl10 py0"
            data-toggle="modal"
            data-target="#gloves-modal"
            onClick={this.gloveModalOpen.bind(this, 'glove_inner')}
          >
            Edit
          </button>
        </div>
      )
    } else {
      return (
        <button
          className="btn btn-primary mw125"
          data-toggle="modal"
          data-target="#gloves-modal"
          onClick={this.gloveModalOpen.bind(this, 'glove_inner')}
        >
          Add
        </button>
      )
    }
  }

  renderOuterGlove = () => {
    if (!this.isEmpty(this.props.surgeon.glove_outer)) {
      return (
        <div className="d-inline-flex align-items-center">
          <p className="m0">{this.props.surgeon.glove_outer.name}</p>
          <button
            className="btn btn-link pl10 py0"
            data-toggle="modal"
            data-target="#gloves-modal"
            onClick={this.gloveModalOpen.bind(this, 'glove_outer')}
          >
            Edit
          </button>
        </div>
      )
    } else {
      return (
        <button
          className="btn btn-outline-primary mw125"
          data-toggle="modal"
          data-target="#gloves-modal"
          onClick={this.gloveModalOpen.bind(this, 'glove_outer')}
        >
          Add
        </button>
      )
    }
  }

  getGloves = text => {
    let url = `api/gloves?search=${text}&page=`
    axios
      .get(url)
      .then(res => {
        this.setState({
          gloveData: res.data.data,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  renderContent() {
    const { surgeon } = this.props

    console.log(this.state)

    if (this.state.isNext) {
      return <SpecailtySection />
    }
    const { errors} = this.props.surgeon

    return (
      <div>
        <div className="form-group mb20">
          <TextInput
            label={'First name (Optional)'}
            value={surgeon.firstname}
            placeholder={'Firstname'}
            errorMessage={getError(errors, 'firstname')}
            containerClass={`mb30`}
            onValueChange={event => {
              this.props.surgeonUpdate({
                prop: 'firstname',
                value: event.target.value,
              })
            }}
            onKeyPress={event => {
              if (event.key == 'Enter') this.onSubmit()
            }}
          />
        </div>
        <div className="form-group mb20">
          <TextInput
            label={'Lastname'}
            value={surgeon.lastname}
            placeholder={'Lastname'}
            errorMessage={getError(errors, 'lastname')}
            containerClass={`mb30`}
            onValueChange={event => {
              this.props.surgeonUpdate({
                prop: 'lastname',
                value: event.target.value,
              })
            }}
            onKeyPress={event => {
              if (event.key == 'Enter') this.onSubmit()
            }}
          />
        </div>
        <div className="form-group mb20">
          <div className="form-group mb20">
            <label>Upload surgeon photo (Optional)</label>
            <ErrorText error={getError(errors, 'avatar')} />
            <ImagePicker aspectRatio={3 / 3} 
              getImage={(image) => {
                this.props.surgeonUpdate({
                  prop: 'avatar',
                  value: image
                })
               }}
              previewType="rounded-circle"
            />
            
          </div>
        </div>
        <div className="form-group mb20">
          <TextInput
            label={'Title (Optional)'}
            value={surgeon.title}
            placeholder={'Title'}
            errorMessage={getError(errors, 'title')}
            containerClass={`mb30`}
            onValueChange={event => {
              this.props.surgeonUpdate({
                prop: 'title',
                value: event.target.value,
              })
            }}
            onKeyPress={event => {
              if (event.key == 'Enter') this.onSubmit()
            }}
          />
        </div>
        <div className="form-group mb20">
          <TextInput
            label={'Mobile number (Optional)'}
            value={surgeon.mobile}
            placeholder={'Mobile'}
            errorMessage={getError(errors, 'mobile')}
            containerClass={`mb30`}
            onValueChange={event => {
              this.props.surgeonUpdate({
                prop: 'mobile',
                value: event.target.value,
              })
            }}
            onKeyPress={event => {
              if (event.key == 'Enter') this.onSubmit()
            }}
          />
        </div>

        <div className="form-group mb20">
          <label>Handedness</label>

          <ErrorText error={getError(errors, 'dominant_hand')} />
          <div
            className="btn-group btn-group-toggle w-100"
            data-toggle="buttons"
          >
            <label
              className={`btn btn-outline-dark ${
                this.props.surgeon.handness === 'left' ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'handness',
                  value: 'left',
                })
              }}
            >
              <input type="radio" autocomplete="off" value="left" /> Left
            </label>
            <label
              className={`btn btn-outline-dark ${
                this.props.surgeon.handness === 'right' ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'handness',
                  value: 'right',
                })
              }}
            >
              <input type="radio" autocomplete="off" value="right" /> Right
            </label>
          </div>
        </div>

        <div className="form-group mb20">
          <label className="w-100">Glove </label>
          <ErrorText error={getError(errors, 'inner_gloves')} />
          {this.renderGlove()}
        </div>

        <div className="form-group mb20">
          <label className="w-100">Add an out glove (Optional)</label>
          <ErrorText error={getError(errors, 'outer_gloves')} />
          {this.renderOuterGlove()}
        </div>
        <div className="form-group mb20">
          <label className="">Music Preference</label>
          <div
            className="btn-group btn-group-toggle w-100"
            data-toggle="buttons"
          >
            <label
              className={`btn btn-outline-dark ${
                this.props.surgeon.music === 1 ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'music',
                  value: 1,
                })
              }}
            >
              <input type="radio" autocomplete="off" value="left" /> On
            </label>
            <label
              className={`btn btn-outline-dark ${
                this.props.surgeon.music === 0 ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'music',
                  value: 0,
                })
              }}
            >
              <input type="radio" autocomplete="off" value="right" /> Off
            </label>
          </div>
        </div>
        <div className="form-group mb-0">
          <label className="">Room Environment</label>
          <div
            className="btn-group btn-group-toggle w-100"
            data-toggle="buttons"
          >
            <label
              className={`btn btn-outline-dark ${
                this.props.surgeon.environment == 'normal' ? 'active' : null
              }`}
              onClick={() => {
                this.props.surgeonUpdate({
                  prop: 'environment',
                  value: 'normal',
                })
              }}
            >
              <input type="radio" autocomplete="off" /> Normal
            </label>
            <label
              className={`btn btn-outline-dark ${
                this.props.surgeon.environment == 'silent' ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'environment',
                  value: 'silent',
                })
              }}
            >
              <input type="radio" autocomplete="off" /> Silent
            </label>
          </div>
        </div>
      </div>
    )
  }

  selectGlove(item) {
    const { hand } = this.state
    this.props.surgeonUpdate({
      prop: hand,
      value: item,
    })
    $('#gloves-modal').modal('hide')
  }

  selectOneClickGlove(item) {
    const { hand } = this.state
    this.setState({
      selected: item,
    })
  }

  saveOneClickGlove() {
    const { hand, selected } = this.state
    if(selected){
      this.props.surgeonUpdate({
        prop: hand,
        value: selected,
      })
      this.setState({
        selected: null,
      })
      $('#gloves-modal').modal('hide')
    }else{
      this.setState({
        glove_error: true
      })
    }
  }

  render() {
    const { gloveData } = this.state

    const search = _.debounce(() => {
      this.getGloves(this.state.keyword)
    }, 500)

    const gloves = gloveData.map(data => {
      let is_selected = ''
      let checked = ''
      if (this.state.selected) {
        if (this.state.selected.id == data.id) {
          is_selected = '_selected'
          checked = 'checked'
        }
      } else {
        if (this.state.hand) {
          console.log(data.id)
          if (this.props.surgeon[this.state.hand] != null) {
            if (this.props.surgeon[this.state.hand].id == data.id) {
              is_selected = '_selected'
              checked = 'checked'
              console.log(is_selected, this.props.surgeon[this.state.hand].id)
            }
          }
        }
      }

      return (
        <tr
          className={`c-pointer ${is_selected}`}
          onClick={this.selectOneClickGlove.bind(this, data)}
          onDoubleClick={this.selectGlove.bind(this, data)}
          key={data.id}
        >
          <td className="text-center   mw70 pr-0">
            <div
              className="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: 0, paddingLeft: 25 }}
            >
              <input
                type="radio"
                type="radio"
                name="radiobtn"
                checked={checked}
                id={data.id}
                onChange={() => {
                  // this.procedures(category.id);
                  $(`#${data.id}`)
                    .closest('table')
                    .find('tr')
                    .removeClass('_active_fixed')
                  $(`#${data.id}`)
                    .closest('tr')
                    .addClass('_active_fixed')
                }}
                className="custom-control-input  c-pointer"
              />
              <label className="custom-control-label  c-pointer" for={data.id}>
                &nbsp;
              </label>
            </div>
          </td>
          <td className="mw150">
            <label className="font-weight-normal c-pointer" for={data.id}>{`${
              data.catalog_number
            }`}</label>
          </td>
          <td
            className="mw300"
            data-container="body"
            data-trigger="click hover"
            data-toggle="popover"
            data-placement="auto"
            data-html="true"
            data-content='
                <div className="row">
                    <div className="col-md-4 text-center pr-0">
                        <img src="img.png" className="bg-dark w-100">
                    </div>
                    <div className="col">
                        <h6 className="mb15">Title here</h6>
                        <p>Show description of use here. Description not necessary as we want to impart pure use and context of the item instead.
                            Similar to media element, if this text goes over a set limit, make the content box scrollable</p>
                    </div>
                    <div className="col-md-12 mt15">
                        <p className="font-weight-bold mb10">Tags</p>
                        <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">Pediatrics</span>
                        <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">Pediatrics</span>
                        <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">Pediatrics</span>
                        <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">Pediatrics</span>

                    </div>
                </div>'
          >
            <label className="font-weight-normal c-pointer" for={data.id}>{`${
              data.name
            }`}</label>
          </td>
          <td className="mw100">
            <label className="font-weight-normal c-pointer" for={data.id}>{`${
              data.size
            }`}</label>
          </td>
        </tr>
      )
    })

    // const getGloves = _.debounce((term) => {this.getGloves(term)}, 300)

    return (
      <div className="row">
        <div className="col-md-12 d-none d-md-block">
          <h1 className="h1bold page-title">
            {this.props.isEditMode ? 'Edit Surgeon' : 'Add new Surgeon'}
          </h1>
          <h4 className="h2med page-sub-title">Personal Information</h4>
        </div>
        <div className="col-lg-6 col-md-8">{this.renderContent()}</div>

        <div
          className="modal fade"
          id="gloves-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="gloves-modalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body border-0 py-0">
                <label
                  className="h2bold font-weight-bold m0 w-100"
                  id="gloves-modalLabel"
                >
                  Add glove
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </label>
                <div className="row">
                  <div className="col-md-8 py20">
                    <div className="form-group mb0">
                      <label>Search</label>
                      <input
                        type="text"
                        name="search_glove"
                        id="search_glove"
                        className="form-control"
                        value={this.state.keyword}
                        onChange={event => {
                          this.setState({keyword: event.target.value}, () => {search()})
                        }}
                      />
                      {this.state.glove_error ? <p>Select glove first!</p> : ''} 
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="w-100 of-auto">

                      <table className="table table-striped border min-table-width-590 table-fixed">
                        <thead className="bg-dark text-white">
                          <tr>
                            <th className="w70" />
                            <th className="font-weight-bold w150">
                              &nbsp;Catalogue #
                            </th>
                            <th className="font-weight-bold w300">Name</th>
                            <th className="font-weight-bold w100">Size</th>
                          </tr>
                        </thead>
                        <tbody style={{ maxHeight: 244, overflow: 'auto' }}>
                          {gloves}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <label className="">&nbsp;</label>
                  <div
                    className="btn btn-primary mr30 mt30"
                    onClick={this.saveOneClickGlove.bind(this)}
                  >
                    Save
                  </div>
                  <div
                    className="btn btn-outline-primary font-weight-normal mw125  mt30"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ surgeon }) => {
  return { surgeon }
}

export default connect(
  mapStateToProps,
  { surgeonUpdate }
)(SurgeonForm)
