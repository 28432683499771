import React, { Component } from 'react'

export class InlineLoader extends Component {
  render() {
    return (
      <div class="row">
        <div class="col-md-12 text-center pb30">
          <div className="d-flex justify-content-center mb30">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InlineLoader
