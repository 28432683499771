import { POSITIONING_UPDATE, GET_POSITIONS, GET_POSITION, GET_BODY_AREAS, GET_POSITION_DETAILS, POSITIONING_RESET} from './types'
import axios from 'axios';

export const positioningUpdate = ({ prop, value }) => {
  return {
    type: POSITIONING_UPDATE,
    payload: { prop, value },
  }
}

export const positioningReset = () => {
  return {
    type: POSITIONING_RESET
  }
}

export const getPositions = () => {
  return(dispatch) => {

    let url = `/api/admin/all-positions`
    axios.get(url).then((res) => {
    
      dispatch({
        type: GET_POSITIONS,
        positions: res.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const getPosition = (id) => {
  return(dispatch) => {
    dispatch({
      type: GET_POSITION,
      position: {}
    })
    
    let url = `/api/admin/positioning/${id}`

    axios.get(url).then((res) => {
      dispatch({
        type: GET_POSITION,
        position: res.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const getBodyAreas = () => {
  return(dispatch) => {
    let url = '/api/admin/positioning-equipment-areas'

    axios.get(url).then((res) => {
      console.log(res)
      let row = {
        id: '',
        name: 'Please select'
      }
      res.data.unshift(row)
      dispatch({
        type: GET_BODY_AREAS,
        body_areas: res.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
} 

export const savePositioningEquipment = (id, items, position, qty, draft=0) => {
  return(dispatch) => {
    console.log('pos', position)
    let data = new FormData()
    data.append('positioning', id)
    data.append('draft', draft)

    items.map((item, index) => {
      data.append('item_ids[]', item.id)
      data.append('areas[]', position[index])
      data.append('qty[]', qty[index])
    })

    let url = `/api/admin/positioning-equipments`

    axios.post(url, data).then((res) => {
      console.log(res)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const getPositioningDetails = (id, draft=0) => {
  return(dispatch) => {
    let url = `/api/admin/positioning-equipments/${id}?draft=${draft}`
    // dispatch({
    //   type: GET_POSITION_DETAILS,
    //   position_details: [],
    //   position_master: []
    // })
    axios.get(url).then((res) => {
      console.log('tes', res)
      dispatch({
        type: GET_POSITION_DETAILS,
        position_details: Object.values(res.data.details).splice(0),
        position_master: res.data.items.splice(0)
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const getPositioningDetailsProcedure = (id, draft=0) => {
  return(dispatch) => {
    let url = `/api/admin/stage-positioning-equipments/${id}?draft=${draft}`
    // dispatch({
    //   type: GET_POSITION_DETAILS,
    //   position_details: [],
    //   position_master: []
    // })
    axios.get(url).then((res) => {
      console.log('tes', res)
      dispatch({
        type: GET_POSITION_DETAILS,
        position_details: Object.values(res.data.details).splice(0),
        position_master: res.data.items.splice(0)
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const setPositioningEquipmentDetails = (details) => {
  return(dispatch) => {
    if(details.details){
      dispatch({
        type: GET_POSITION_DETAILS,
        position_details: Object.values(details.details).splice(0),
        position_master: details.items.splice(0)
      })
    }else{
      dispatch({
        type: GET_POSITION_DETAILS,
        position_details: [],
        position_master: details.items.splice(0)
      })
    }
    
  }
}

export const getPositioningDetailsAdmin = (id, draft=0) => {
  return(dispatch) => {
    let url = `/api/admin/admin/stage-positioning-equipments/${id}?draft=${draft}`
    // dispatch({
    //   type: GET_POSITION_DETAILS,
    //   position_details: [],
    //   position_master: []
    // })
    axios.get(url).then((res) => {
      console.log('tes', res)
      dispatch({
        type: GET_POSITION_DETAILS,
        position_details: Object.values(res.data.details).splice(0),
        position_master: res.data.items.splice(0)
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

