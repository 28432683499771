import React from 'react';
import {connect} from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {closeProduct} from '../actions/roomSetupAction'
import Grid from '@material-ui/core/Grid';
import ImageBox from './productpagecomponents/ImageBox'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import TopPart from './productpagecomponents/TopPart'
import UsedAndDesc from './productpagecomponents/UsedAndDesc'
import VideoCaption from './productpagecomponents/VideoCaption'
function Transition(props) {
    return <Slide direction="up" {...props} />;
  }


class ProductPage extends React.Component{
 

    handleClose = () => {
        this.props.closeProduct();
      };

      getOpenStatus = (open) =>{
      
        if(open !== undefined){
            return open
        }else{
          return false
        }
      };

    render(){
      
       const {posts} = this.props;
        const {items} = this.props
       
if(!items){
  return null;
}
const forImageBox = {
  name: items.name,
  catalog_number: items.catalog_number,
  size: items.size,
  company: items.company,
  category: items.category.name,
  icon: items.icon
}

const forTop ={
  name: items.name,
  author: items.author.name
}

const forUsedandDesc ={
  usedfor: items.used_for,
  desc: items.description
}
        return(
            <div>
        <Dialog
          fullScreen
          open={this.getOpenStatus(posts)}
          TransitionComponent={Transition}
        >
              <Grid
                container
                direction="row"
                justify="space-between"
                spacing={32}
                style ={{padding : '10px'}}
              >
              
              <Grid /* 1st Panel */
                item
                sm={2}    
              >
                  <Grid item>
                    <ImageBox theName={forImageBox}  />
                    </Grid>
                  <Grid style={{paddingTop:"10%"}} item> {/* TAGS PART */}
                      <Paper style={{padding : "10px"}}>
                       
                        <Typography variant="h6" gutterBottom>
                        <b>Tags</b>
                        </Typography>
                        {
                         items.tags.map(item =>{
                           return(
                            <Chip key={item.name} label={item.name} style={{margin : "5px"}}/>
                           )
                         })
                         }
                      
                      </Paper>
                    </Grid>
                </Grid>{/* end of 1st panel */}
                <Grid /* 2nd Panel */
                  item
                  sm
                >
                    
                    <Grid item sm={12}>
                      <TopPart theProps={forTop} />
                    </Grid>
                    <Grid item >
                      <UsedAndDesc theProps={forUsedandDesc}/>
                    </Grid>
                    <Grid item style={{marginTop:"2%"}}>
                       <VideoCaption />
                    </Grid>
                </Grid>
              </Grid> 
        </Dialog>
      </div>
        )
    }
}

const mapStateToProps = (state) =>{ //the state will have a property called "users" according sa decalred reducers index

const info = state.posts.showInfo

    if(!info)
    {
    return {
        posts: state.posts.productOpen
        }
    }
    else{
            return {
                posts: state.posts.productOpen,
                items: state.posts[1].find(item => item.id === info)
            }

        }
}

export default connect(mapStateToProps,{closeProduct})(ProductPage)