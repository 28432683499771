import React, { Component } from 'react'
import axios from 'axios'
import { getError, getArrayError, dataURItoBlob, isBase64 } from '../../utilities'
import { TextInput } from '../../components/commons/forms'
import { Image } from '../../components/commons/elements/inputs'
import { Confirmation } from '../../components/Confirmation'
import $ from 'jquery'
import _ from 'lodash'
import WholeBodyloader from '../../components/loader/WholeBodyloader'

export class SettingIconLibrary extends Component {
  constructor(props) {
    super(props)

    this.state = {
			icon: {},
			icons: [],
			errors: [],
			loading: 0,
			image: '',
			name: '',
			keyword: '',
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
	}
	
	componentDidMount = () => {
		this.getIcons()
	}

	saveIcon = () => {
		this.setState({
			loading: 1
		})

		let url = '/api/admin/icon-library'
		let data = new FormData()
		data.append('name', this.state.name)
		data.append('icon', dataURItoBlob(this.state.image))

		axios.post(url, data).then((res) => {
			this.setState({
				loading: 0
			})
			this.getIcons()
			$('#addIcon').modal('hide')
		}).catch((error) => {
			console.log(error)
			console.log(error.response)
			this.setState({
				loading: 0,
				errors: error.response.data.errors
			})
		})
	}

	updateIcon = () => {
		const { icon } = this.state 
		this.setState({
			loading: 1
		})

		let data = new FormData()
		data.append('name', this.state.name)
		if(isBase64(this.state.image) > 0){
			data.append('icon', dataURItoBlob(this.state.image))
		}
		data.append('_method', 'PATCH')

		let url = `/api/admin/icon-library/${icon.id}`

		axios.post(url, data).then((res) => {
			$('#editIcon').modal('hide')
			this.getIcons()
		}).catch((error) => {
			console.log(error)
			console.log(error.response)
			this.setState({
				loading: 0,
				errors: error.response.data.errors
			})
		})
	}


	deleteIcon = () => {
		const { icon } = this.state
		this.setState({
			loading: 1
		})

		let data = new FormData() 
		data.append('_method', 'DELETE') 
		let url = `/api/admin/icon-library/${icon.id}` 
		axios.post(url, data).then((res) => {
			$('.modal').modal('hide')
			this.getIcons()
		}).catch((error) => { 
			this.setState({
				loading: 0,
				errors: error.response.data.errors
			})
		})
	}


	getIcons  = () => {
		let url = `/api/admin/icon-library?search=${this.state.keyword}`

		axios.get(url).then((res) => {
			this.setState({
				icons: res.data.data,
				loading: 0
			})
		}).catch((error) => {
			console.log(error)
			console.log(error.response)
			this.setState({
				loading: false
			})
		})
	}

  addIcon() {
    return(
		  <div className="modal fade" id="addIcon" tabIndex={-1} role="dialog" aria-labelledby="addIconTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30">
              <input type="file" ref="file" className="d-none" onChange={(event) => {this.setState({image: this.refs.file.files[0]})}}/>
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">Add new icon</h5>
              <Image 
                label={`Icon`}
                media_data={ {
                    source: this.state.image
                }}
                size={[120, 120]}
                wrapperStyle="mb20 c-pointer"
                previewStyle="d-flex justify-content-center align-items-center bg-light of-hidden m-0 w150 h150"
                onMediaChange={ (event) => {this.setState({image: event.data})
                }}
							/>
							<p className="text-danger">{getError(this.state.errors, 'icon')}</p>
              <div className="form-group w-50">
                <TextInput
                  label={'Name of Icon'}
                  value={this.state.name}
                  placeholder={'Name'}
                  errorMessage={getError(this.state.errors, 'name')}
                  containerClass={`mb30`}
                  onValueChange={(event) => {this.setState({name: event.target.value})}}
                />
              </div>
            </div>
            <div className="modal-body pb30 px30">
              <div className="btn btn-dark mr30 px30" onClick={() => {this.saveIcon()}}>Save</div>
              <div className="btn btn-light border-dark px30" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>
		)
	}
	
	editIcon() {
    return(
		  <div className="modal fade" id="editIcon" tabIndex={-1} role="dialog" aria-labelledby="editIconTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30">
              <input type="file" ref="file" className="d-none" onChange={(event) => {this.setState({image: this.refs.file.files[0]})}}/>
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">Edit new icon</h5>
              <Image 
                label={`Icon`}
                media_data={ {
                    source: this.state.image
                }}
                size={[120, 120]}
                wrapperStyle="mb20 c-pointer"
                previewStyle="d-flex justify-content-center align-items-center bg-light of-hidden m-0 w150 h150"
                onMediaChange={ (event) => {this.setState({image: event.data})
                }}
              />
              <div className="form-group w-50">
                <TextInput
                  label={'Name of Icon'}
                  value={this.state.name}
                  placeholder={'Name'}
                  errorMessage={getError(this.state.errors, 'name')}
                  containerClass={`mb30`}
                  onValueChange={(event) => {this.setState({name: event.target.value})}}
                />
              </div>
            </div>
            <div className="modal-body pb30 px30">
              <div className="btn btn-dark mr30 px30" onClick={() => {this.updateIcon()}}>Save</div>
							<div className="btn btn-light border-dark px30" data-dismiss="modal">Cancel</div>
              <div className="btn btn-link border-0 px30" onClick={()=>{
								$("#editIcon").modal('hide')
								$("#deleteModal").modal('show')
							}}>Delete Icon</div>
            </div>
          </div>
        </div>
      </div>
		)
  }

  render() {
		const { icons } = this.state
		let iconlist = icons.map((icon) => {
			return(
				<div className="icon-item w120 h120 mr30 mb30 p5">
					<img src={icon.icon} class=" c-pointer w-100" data-toggle='modal' title={icon.name}
						data-target='#editIcon'
						onClick={() => {
							this.setState({
								icon: icon,
								name: icon.name,
								image: icon.icon,
								icon_id: icon.id
							})
						}} />
				</div>
			)
		})

		const search = _.debounce(() => {
			this.getIcons()
		}, 500)

    return (
			<div className="container">
				<WholeBodyloader 
					progress={this.state.loading}
				/>
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">Icon Library</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="row mb30">
              <div class="col-md-4">
                <div class="form-group mb-0">
                  <label>Search</label>
                  <input
                    type="text"
                    class="form-control"
										placeholder="Start typing name, catalogue #..."
										onChange={(event) => {
											this.setState({
												keyword: event.target.value
											}, search())
										}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 d-flex flex-wrap justify-content-starts">
            <div
              class="float-left d-flex justify-content-center align-items-center fs20 flex-column text-center mr30 mb30 w120 h120 c-pointer"
							style={{ border: '2px dashed #979797' }}
							data-toggle="modal"
							data-target="#addIcon"
							onClick={() => {
								this.setState({
									image: '',
									name: ''
								})
							}}
            >
              <i class="fa fa-plus" />
              Add new icon
            </div>
            {iconlist}
          </div>
				</div>
				{this.addIcon()}
				{this.editIcon()}

				<Confirmation
					idModal="deleteModal"
					title="Delete Icon?"
					message="Deleting icon will remove it from the database and will have to be added back manually."
					confirm={() => {
						this.deleteIcon()
					}}
					cancel={()=>{
						$("#editIcon").modal('show')
						$("#deleteModal").modal('hide')
					}}
					confirmText="Confirm"
					cancelText="Cancel"
				/>
      </div>
    )
  }
}

export default SettingIconLibrary
