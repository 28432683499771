import {
  GET_SURGICAL_CATEGORIES
} from './types'
import axios from 'axios';

export const getSurgicalCategories = () => {
  console.log('getting surgical categories')
  return(dispatch) => {
    dispatch({
      type: GET_SURGICAL_CATEGORIES,
      categories: [],
      loading: true
    })

    let url = `/api/admin/surgical-trees`
    axios.get(url).then((res) => {
      dispatch({
        type: GET_SURGICAL_CATEGORIES,
        categories: res.data.data,
        loading: false
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const saveCategoriesOrder = (categories) => {
  return(dispatch) => {
    console.log('sad', categories)
    let data = new FormData()

    categories.map((category) => {
      data.append('item_id[]', category.id)
    })
    // data.append('method', '_PATCH')

    let url = '/api/admin/surgical-sort'
    axios.post(url, data).then((res) => {
     
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  } 
}