import React, { Component } from 'react'
import BigPictureModal from '../modals/BigPictureModal'
import $ from 'jquery'
import Vimeo from '@u-wave/react-vimeo'

export class StandardPreview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_media: [],
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  renderMedia = () => {
    const { content } = this.props
    if (content.contents.gallery.type == 'video')
      return (
        <Vimeo
          video={content.contents.gallery.source}
          className="v-player w-100 align-items-center h-100 bg-141517"
          onLoaded={() => {}}
          reponsive={true}
        />
      )

    return (
      <div className="image-div">
        <img
          src={content.contents.gallery.source}
          className="bg-dark c-pointer"
          onClick={() => {
            this.setState(
              {
                selected_media: {
                  title: content.title,
                  source: content.contents.gallery.source,
                  type: 'image',
                },
              },
              () => {
                $(`#bigPictureModal-positioning-${this.state.unique}`).modal(
                  'show'
                )
              }
            )
          }}
        />
      </div>
    )
  }

  render() {
    const { content } = this.props
    return (
      <div className="card p-0 rounded-0 border-0 shadow-sm mt30">
        <BigPictureModal
          idModal={`bigPictureModal-positioning-${this.state.unique}`}
          data={this.state.selected_media}
        />
        <div className="card-body pt-0 px15 pb-0">
          <div className="row">
            <div className="col-md-6 pt20">
              <p className="font-weight-bold mb15">
                {content.title ? content.title : 'No Specified.'}
              </p>
              <div
                className="of-auto h220 pr10"
                dangerouslySetInnerHTML={{
                  __html: content.contents.caption,
                }}
              />
            </div>
            <div className="col-md-6 p-0">{this.renderMedia()}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default StandardPreview
