import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router'
import _ from 'lodash'
import $ from 'jquery'
import { Picker } from '../commons/forms'
import axios from 'axios'
import { getArrayError } from '../../utilities'
import ViewItemModal from '../../components/modals/ViewItemModal'

export class ReviewItemsPositioning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      category: '',
      categories: [],
      inventory: '',
      selected_items: [],
      positions: [],
      qty: [],
      search: '',
      errors: [],
      selectedItem:null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.change != nextProps.change) {
      const qty = []
      const positions = nextProps.selected_items.map((data, index) => {

        qty.push(data.qty ? data.qty:1)

        return data.body_area ? data.body_area : ''
      })
      this.setState({
        selected_items: nextProps.selected_items,
        qty,
        positions,
      })
    }
  } 
  sortBy(key) {
    let arrayCopy = [...this.props.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setSelected(arrayCopy)
    this.setState({ isReverse: !this.state.isReverse })
  }

  validate = (items, position, qty) => {
    let data = new FormData()

    items.map((item, index) => {
      data.append('item_ids[]', item.id)
      data.append('areas[]', position[index])
      data.append('qty[]', qty[index])
    })

    let url = '/api/positioning-equipments-validator'
    axios.post(url, data).then((res) => {
      this.props.assign(this.state.selected_items, this.state.positions, this.state.qty)
      this.setState({
        errors: []
      })
      $('.modal').modal('hide')
    }).catch((error) => {
      console.log('a', error)
      console.log(error.response)
      this.setState({
        errors: error.response.data.errors
      })
    })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  duplicateItem = ( index) => {
    let { selected_items,positions,qty } = this.state

    let item = _.cloneDeep(selected_items[index])
    

    selected_items.splice(index + 1, 0, item)
    positions.splice(index + 1, 0, '')
    qty.splice(index + 1, 0, 1)
    this.setState({ selected_items,positions,qty })
  }

  isEqual = (item) => {
    let { search } = this.state

  }

  render() {
    let { selected_items } = this.state

    console.log('state', this.state)

    let item_list = selected_items.map((item, index) => {
      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })
      return (
        <tr key={`item-${index}`}>
          <td className="w150 px12">{item.catalog_number}</td>
          <td className="w250 font-weight-bold c-pointer text-dark fs-16 px12"
            onClick={() => {

              this.setState({
                selectedItem: item,
                unique:
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15)
              }, () => {
                $(`#item-modal-view`).modal('show');

              })
            }}>
            <span
             data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            >
            {item.name}
            </span>
          </td>
          <td
            className="w300 px12"
            style={{
              paddingLeft: '5px !important',
              paddingRight: '5px !important',
            }}
          >
            <Picker
              containerClass={`float-left m-0 w-75`}
              onChange={event => {
                const { positions } = this.state
                positions[index] = event.target.value
                this.setState({
                  positions: positions.splice(0),
                })
              }}
              value={this.state.positions[index]}
              data={this.props.body_areas}
              errorMessage={getArrayError(this.state.errors, 'areas', index)}
            />
            
            <span
              class="action-circle-btn float-left ml5 mt3 c-pointer"
              onClick={() => {
                this.duplicateItem(index)
              }}
            >
              +
            </span>
          </td>
          <td
            className="w100 text-center px12"
            style={{
              paddingLeft: '5px !important',
              paddingRight: '5px !important',
            }}
          >
            <input
              type="text"
              name="search"
              className="form-control"
              placeholder=""
              value={this.state.qty[index]}
              onChange={event => {
                const { qty} = this.state
                qty[index] = event.target.value
                this.setState({ qty:qty.splice(0) })
              }}
            />
          </td>

          <td className="w100 text-center px12">
            <a
              href="#"
              className="text-dark mt15"
              onClick={event => {
                event.preventDefault()
                this.setState({
                  positions: this.state.positions.filter( (d,i) => {
                    return i != index
                  }),
                  selected_items: this.state.selected_items.filter( (d,i) => {
                    return i != index
                  }),
                  qty: this.state.qty.filter( (d,i) => {
                    return i != index
                  }),
                })
              }}
            >
            Remove
            </a>
          </td>
        </tr>
      )
    })

    return (
      <div>
      <div
        className="modal"
        id="reviewItemsModalCenter"
        role="dialog"
        aria-labelledby="reviewItemsModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl px30"
          role="document"
        >
          <div className="modal-content animated fadeIn">
            <div className="modal-body pt30 px30 pb15">
              <h5 className="mb20 mx-auto font-weight-bold">Review items </h5>
              <p className="mb20">
                Please review and confirm assigned items. <br />
                Items will be separated by PRN and General OR sections.
              </p>
              
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped table-fixed border">
                    <thead className="bg-dark text-white text-left">
                      <tr>
                        <th
                          class="w150 px12"
                        >
                          Catalogue # 
                        </th>
                        <th
                          class="w250 px12"
                        >
                          Name # 
                        </th>
                        <th
                          class="w300 px12"
                        >
                          Assign body category 
                        </th>
                        <th class="w100 px12">Qty</th>
                        <th class="w100 px12" />
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        float: 'left',
                        width: '100%',
                        overflow: 'auto',
                        maxHeight: '420px',
                        height: '420px',
                      }}
                    >
                      {item_list}
                    </tbody>
                  </table>
                </div>
              </div>
            </div> 
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                onClick={() => {
                 this.validate(this.state.selected_items, this.state.positions, this.state.qty)
                    

                }}
              >
                Confirm assigned items
              </div>

              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left"
                onClick={() => {
                 $('#reviewItemsModalCenter').modal('hide')
                 $('#assignItemsModalCenter').modal('show')
                }}
              >
                Back
              </div>
            </div>
          </div>
        </div>
      </div>
        <ViewItemModal
          idModal={`item-modal-view`}
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal').modal('hide')
          }}
        />    
      </div>
    )
  }
}

export default ReviewItemsPositioning
