import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { applyMiddleware } from 'redux'
import storage from 'redux-persist/lib/storage'
import createCompressEncryptor from 'redux-persist-transform-compress-encrypt'

let data = {}

  data.devTools = process.env.NODE_ENV === 'production'
  ? applyMiddleware(thunk)
  : composeWithDevTools(applyMiddleware(thunk))

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  

  data.persistConfig = {
    key: 'staging-admin-wshW4WaHDRQ5XYCw8l97tfHc4yMKmEIL6BAYbob1',
    storage,
    whitelist: ['spcialty', 'surgeon', 'item', 'quicknotes', 'auth'],
  }
  data.vimeoKey = `a414e807eff23826daf0f6b294dee56f`
} else {

  const transformer = createCompressEncryptor({
    secretKey: 'QY42WOmuheVmKecZTZIHLYtIZagsle6kHruT1ikQEhngcAeDqqitwP9RkPnGUHI6N9VaJEpyEbxvpz1HsAYO1wiQp3aKC3lJWTO22',
    whitelist:['auth'],
    onError: function(error) {
    }
  })

  data.persistConfig = {
    key: 'EPUgj1kGh4FV4qHrjhRCbf0Y5fqCE8QIn6tYVfgYBBRGtDcKjebtkNMSXrURyEkuWCoS35k9tyscL7xXMEIyHcPQ1A2',
    storage,
    whitelist: ['spcialty', 'surgeon', 'item', 'quicknotes', 'auth'],
    transforms: [transformer],
  }
  data.vimeoKey = `a414e807eff23826daf0f6b294dee56f`

}

export const ENVIROMENT = data
