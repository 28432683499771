import React, { Component } from 'react'
import BigPictureModal from '../../../modals/BigPictureModal'
import $ from 'jquery'
import Vimeo from '@u-wave/react-vimeo'

export default class Standard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_media: [],
    }
  }

  renderMedia = () => {
    const { data, classStyle } = this.props
    if (data.type == 'video')
      return (
        <Vimeo
          video={data.source}
          className="v-player w-100 align-items-center h-100 bg-141517"
          onLoaded={() => {}}
          reponsive={true}
        />
      )
    return (
      <img
        src={data.source}
        className="bg-dark w-100 h300"
        onClick={() => {
          this.setState(
            {
              selected_media: {
                title: data.title,
                type: 'image',
                source: data.source,
              },
            },
            () => {
              $('#bigPictureModal-standard').modal('show')
            }
          )
        }}
      />
    )
  }

  render() {
    const { data, classStyle } = this.props
    return (
      <div
        className={`card p-0 shadow-sm border-0 mt0 w-100 float-left ${classStyle}`}
      >
        <BigPictureModal
          idModal={'bigPictureModal-standard'}
          data={this.state.selected_media}
        />
        <div className="card-body pt-0 px15 pb-0">
          <div className="row">
            <div className="col-lg-6 pt20 order-2 order-lg-1">
              <p className="font-weight-bold mb15">
                {data.title ? data.title : 'No Specified.'}
              </p>
              <div
                className="of-auto h220 pr10"
                dangerouslySetInnerHTML={{
                  __html:
                    data.caption || data.caption != 'null'
                      ? data.caption
                      : 'No specified',
                }}
              />
            </div>
            <div className="col-lg-6 p-0 order-1 order-lg-2">
              {this.renderMedia()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
