import { 
  CREATE_ITEM, 
  SEARCH_ITEM,
  GET_ITEMS,
  SET_ITEM_MEDICAL_INVENTORY,
  GET_MEDICAL_INVENTORIES,
  REGISTER_CATEGORY,
  ITEM_ERROR,
  GET_INVENTORY_CATEGORIES
} from "../actions/types"


const INITIAL_STATE = {
  items: [],
  errors: [],
  loading: false,
  medical_inventories: [],
  medical_inventory_id: null,
  medical_inventory_name: null,
  inventory_categories:[],
  more: true
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case GET_ITEMS:
      return {...state, loading: action.loading, items: action.items, more: action.more}
    case SET_ITEM_MEDICAL_INVENTORY:
      return {...state, medical_inventory_id: action.medical_inventory_id, medical_inventory_name: action.medical_inventory_name}
    case CREATE_ITEM:
      return {...state}
    case GET_MEDICAL_INVENTORIES:
      return {...state, medical_inventories: action.medical_inventories, loading: action.loading}
    case SEARCH_ITEM:
      return {...state, items: action.items}
    case REGISTER_CATEGORY:
      return {...state, loading: action.loading}
    case ITEM_ERROR:
      return {...state, errors: action.errors}
    case GET_INVENTORY_CATEGORIES:
      return {...state, inventory_categories: action.inventory_categories,  more: action.more}
    default:
      return {...state}
  }
}