//AUTHENCATIONS

export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const LOGIN_USER = 'login_user';
export const LOGIN_UPDATE = 'login_update';



export const FETCH_SPECIALTIES_DATA = 'FETCH_SPECIALTIES_DATA'
export const FETCH_SPECIALTIES_DATA_COMPLETE = 'FETCH_SPECIALTIES_DATA_COMPLETE'
export const SET_SELECTED_SPECIALTY = 'SET_SELECTED_SPECIALTY'

export const SURGEON_UPDATE = 'SURGEON_UPDATE'
export const SURGEON_CREATE = 'SURGEON_CREATE'
export const SURGEON_CREATE_SUCCESS = 'SURGEON_CREATE_SUCCESS'
export const SURGEON_CREATE_FAIL = 'SURGEON_CREATE_FAIL'

export const SET_ISNEXT = 'SET_ISNEXT'

export const CLEAR_SUREGON_DATA = 'CLEAR_SUREGON_DATA'

export const GET_SPECIALTY_CATEGORIES = 'GET_SPECIALTY_CATEGORIES'

export const CREATE_SURGEON_SPECIALTY_SUCCESS = 'CREATE_SURGEON_SPECIALTY_SUCCESS'




//items
export const GET_ITEMS = 'GET_ITEMS'
export const GET_ITEM = 'GET_ITEM'
export const SET_ITEM_MEDICAL_INVENTORY = 'SET_ITEM_MEDICAL_INVENTORY'
export const GET_INVENTORY_CATEGORIES = 'GET_INVENTORY_CATEGORIES'
export const GET_MEDICAL_INVENTORIES = 'GET_MEDICAL_INVENTORIES'
export const CREATE_ITEM = 'CREATE_ITEM'
export const SEARCH_ITEM = 'SEARCH_ITEM'
export const ITEM_ERROR = 'ITEM_ERROR'


export const REGISTER_CATEGORY = 'REGISTER_CATEGORY'


//surgeon
export const GET_SURGEON = 'GET_SURGEON'
export const GET_SURGEONS = 'GET_SURGEONS'


//quiicknotes
export const GET_QUICKNOTES_CATEGORIES = 'GET_QUICKNOTES_CATEGORIES'
export const GET_SURGEON_QUICKNOTES = 'GET_SURGEON_QUICKNOTES'
export const GET_QUICKNOTES_ITEM_LIST = 'GET_QUICKNOTES_ITEM_LIST'
export const SET_QUICKNOTES_ID = 'SET_QUICKNOTES_ID'
export const SAVE_QUICKNOTES_ITEM_LIST = 'SAVE_QUICKNOTES_ITEM_LIST'
export const UPDATE_QUICKNOTES_LIST = 'UPDATE_QUICKNOTES_LIST'
export const FETCH_SURGEON = 'FETCH_SURGEON'
export const RESET_QUICKNOTES_ERRORS = 'RESET_QUICKNOTES_ERRORS'


//surgical tree
export const GET_SURGICAL_CATEGORIES = 'GET_SURGICAL_CATEGORIES'
export const SET_SURGICAL_CATEGORIES = 'SET_SURGICAL_CATEGORIES'

//MASTER PAGE 


//POSITIONING
export const POSITIONING_RESET = 'POSITIONING_RESET'
export const GET_POSITIONS = 'GET_POSITIONS'
export const GET_POSITION = 'GET_POSITION'
export const GET_BODY_AREAS = 'GET_BODY_AREAS'
export const POSITIONING_UPDATE = 'POSITIONING_UPDATE'
export const GET_POSITIONING_EQUIPMENTS = 'GET_POSITIONING_EQUIPMENTS'
export const GET_POSITION_DETAILS = 'GET_POSITION_DETAILS'
//specialty
export const RESET_SPECIALTY_ERRORS = 'RESET_SPECIALTY_ERRORS'

//users
export const GET_USERS = 'GET_USERS'
export const REGISTER_USER = 'REGISTER_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const SET_SUCCESS = 'SET_SUCCESS'
export const SET_USERS = 'SET_USERS'
export const RESET_ERRORS = 'RESET_ERRORS'
export const REGISTER_GLOBAL_USER = 'REGISTER_GLOBAL_USER'


