import React, { Component } from 'react'
import { getSurgeon } from '../../actions/SurgeonActions'
import {
  getQuicknotesCategories,
  updateQuicknotesList,
} from '../../actions/QuicknotesActions'
import { connect } from 'react-redux'
import SurgeonInfo from '../../components/SurgeonInfo'
import SurgeonSpecialties from '../../components/SurgeonSpecialties'
import QuicknotesCategories from '../../components/QuicknotesCategories'
import QuicknotesItemList from '../../components/QuicknotesItemList'
import QuicknotesText from '../../components/QuicknotesText'
import QuicknotesMedia from '../../components/QuicknotesMedia'
import QuicknotesStandard from '../../components/QuicknotesStandard'
import TextEditor from '../../components/TextEditor'
import MediaEditor from '../../components/MediaEditor'
import StandardEditor from '../../components/StandardEditor'
import LoadFromModal from '../../components/modals/LoadFromModal'
import img from '../../assets/images/img.png'
import {
  getSurgeonQuicknotes,
  saveQuicknotesItemList,
} from '../../actions/QuicknotesActions'
import { dataURItoBlob } from '../../utilities/helpers'
import axios from 'axios'
import _ from 'lodash'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Confirmation from '../../components/Confirmation'
import $ from 'jquery'

export class SurgeonQuicknotes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      quicknotes: '',
      edit: false,
      qnlist: [],
      change: false,
      preview: false,
      loadFrom: false,
      loading: true,
    }

    const SortableItem = SortableElement(({ quicknotes, index2 }) => {
      const index = index2
      switch (quicknotes.type) {
        case 'text':
          return (
            <li>
              <TextEditor
                data={{
                  title: quicknotes.title,
                  content: quicknotes.contents[0].content,
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                textContentChange={event => {
                  this.textContentChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        case 'media':
          return (
            <li>
              <MediaEditor
                data={{
                  title: quicknotes.title,
                  medias: quicknotes.contents,
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onContentTitleChange={(event, i) => {
                  this.onContentTitleChange(event, i, index)
                }}
                onContentCaptionChange={(event, i) => {
                  this.onContentCaptionChange(event, i, index)
                }}
                onContentMediaChange={(event, i) => {
                  this.onContentMediaChange(event, i, index)
                }}
                onContentThumbnailChange={(event, i) => {
                  this.onContentThumbnailChange(event, i, index)
                }}
                addNewMedia={event => this.addNewMedia(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                onDelete={event => this.deleteElement(event, index)}
              />
            </li>
          )
          break
        case 'standard':
          return (
            <li>
              <StandardEditor
                data={{
                  title: quicknotes.title,
                  text: quicknotes.contents[0].elements[0].content,
                  media: {
                    thumbnail:
                      quicknotes.contents[1].elements[0].gallery.thumbnail,
                    source: quicknotes.contents[1].elements[0].gallery.source,
                  },
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onStandardTextChange={event => {
                  this.standardTextChange(event, index)
                }}
                onStandardThumbnailChange={thumbnail => {
                  this.standardThumbnailChange(thumbnail, index)
                }}
                onMediaChange={event => {
                  this.mediaStandardChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        default:
          break
      }
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              quicknotes={value}
            />
          ))}
        </ul>
      )
    })
  }

  componentDidMount = () => {
    this.props.getSurgeon(this.props.params.id)

    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 3000);
    // this.props.getSurgeonQuicknotes(this.props.quicknotes_id)
    // this.props.getQuicknotesCategories()
  }

  componentDidUpdate = () => {
    console.log('settingname')

    this.props.categories.map(category => {
      if (category.id == this.props.quicknotes_id) {
        if (this.state.quicknotes != category.name) {
          this.setState({
            quicknotes: category.name,
          })
        }
      }
    })
  }

  componentWillReceiveProps = nextProps => {
    if (
      _.difference(nextProps.quicknotes_list, this.props.quicknotes_list)
        .length > 0
    ) {
      console.log(nextProps)
      this.setState({
        qnlist: nextProps.quicknotes_list,
      })
    }
    if (nextProps.surgeon.id != null) {
      if (nextProps.surgeon.id != this.props.surgeon.id) {
        this.props.getSurgeonQuicknotes(this.props.quicknotes_id)
        this.props.getQuicknotesCategories()
        console.log('setting state')
        this.setState({
          qnlist: [],
        })
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ qnlist }) => ({
      qnlist: arrayMove(qnlist, oldIndex, newIndex),
    }))
  }

  
  newSectionMenu = () => {
    return (
      <div className="row mt30">
        <div className="col-12">
          <label
            className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 cursor-pointer py30"
            style={{ border: '2px dashed rgb(132, 134, 136)' }}
          >
            {/* <img src="img.png" class="h50" id="">  */}
            <div className="row w-100">
              <div className="col-md-12 text-center">
                <h4 className="m-0">Add a new section:</h4>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newTextSection()
                  }}
                >
                  <u>Text only</u>
                </div>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newMediaSection()
                  }}
                >
                  <u>Pictures and videos</u>
                </div>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newStandardSection()
                  }}
                >
                  <u>Text and Media</u>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    )
  }

  newTextSection = () => {
    const { qnlist } = this.state
    let textsection = {
      title: null,
      type: 'text',
      contents: [
        {
          content: null,
        },
      ],
    }
    qnlist.push(textsection)
    this.setState({
      qnlist,
    })
  }

  deleteElement = (event, index) => {
    const { qnlist } = this.state
    event.preventDefault()
    qnlist.splice(index, 1)
    this.setState({ qnlist: qnlist.splice(0) })
  }

  duplicateElement = (event, index) => {
    const { qnlist } = this.state
    event.preventDefault()
    qnlist.push(qnlist[index])
    this.setState({ qnlist: qnlist.splice(0) })
  }

  newMediaSection = () => {
    const { qnlist } = this.state
    let mediasection = {
      title: null,
      type: 'media',
      contents: [
        {
          title: null,
          caption: null,
          gallery: {},
        },
      ],
    }
    qnlist.push(mediasection)
    this.setState({
      qnlist,
    })
  }

  newStandardSection = () => {
    const { qnlist } = this.state
    let standardsection = {
      title: null,
      type: 'standard',
      contents: [
        {
          type: 'text',
          elements: [
            {
              content: null,
              standard: 1,
            },
          ],
        },
        {
          type: 'media',
          elements: [
            {
              title: null,
              caption: null,
              gallery: [],
            },
          ],
        },
      ],
    }

    qnlist.push(standardsection)
    this.setState({
      qnlist,
    })
  }

  titleChange = (event, index) => {
    console.log('title changing')
    const { qnlist } = this.state
    qnlist[index].title = event.target.value

    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  textContentChange = (event, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[0].content = event.editor.getData()

    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  standardTextChange = (event, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[0].elements[0].content = event.editor.getData()

    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onUpdateQuicknotes = draft => {
    const { qnlist } = this.state
    const { surgeon_quicknotes_id, item_list } = this.props

    this.props.saveQuicknotesItemList(item_list, draft)

    const data = new FormData()
    data.append('surgeon_quick_note_id', surgeon_quicknotes_id)
    qnlist.map((quicknotes, index) => {
      data.append(`elements[${index}][title]`, quicknotes.title)
      data.append(`elements[${index}][type]`, quicknotes.type)
      data.append('draft', draft)
      switch (quicknotes.type) {
        case 'text':
          data.append(
            `elements[${index}][values]`,
            quicknotes.contents[0].content
          )
          break
        case 'standard':
          data.append(`elements[${index}][standard-type][]`, 'text')
          data.append(
            `elements[${index}][text-value]`,
            quicknotes.contents[0].elements[0].content
          )
          data.append(`elements[${index}][standard-type][]`, 'media')
          if (
            quicknotes.contents[1].elements[0].gallery.source.indexOf(
              'base64'
            ) >= 0
          ) {
            data.append(`elements[${index}][media-id]`, 0)
            data.append(`elements[${index}][media-updated]`, 1)
            data.append(
              `elements[${index}][media-thumbnail]`,
              dataURItoBlob(
                quicknotes.contents[1].elements[0].gallery.thumbnail
              )
            )
            data.append(
              `elements[${index}][media-value]`,
              dataURItoBlob(quicknotes.contents[1].elements[0].gallery.source)
            )
            data.append(
              `elements[${index}][media-type]`,
              quicknotes.contents[1].elements[0].gallery.type
            )
          } else {
            data.append(
              `elements[${index}]][media-id]`,
              quicknotes.contents[1].elements[0].gallery.id
            )
            data.append(`elements[${index}]][media-updated]`, 0)
            data.append(
              `elements[${index}]][media-thumbnail]`,
              dataURItoBlob(img)
            )
            data.append(`elements[${index}]][media-value]`, dataURItoBlob(img))
            data.append(
              `elements[${index}][media-type]`,
              quicknotes.contents[1].elements[0].gallery.type
            )
          }
          break
        case 'media':
          quicknotes.contents.map(content => {
            data.append(
              `elements[${index}][media-text][]`,
              content.gallery.caption
            )
            data.append(
              `elements[${index}][media-title][]`,
              content.gallery.title
            )
            data.append(
              `elements[${index}][media-type][]`,
              content.gallery.type
            )
            
            data.append(
              `elements[${index}][media-thumbnail][]`,
              dataURItoBlob(content.gallery.thumbnail)
            )

            if (content.gallery.source.indexOf('base64') >= 0) {
              data.append(`elements[${index}][media-id][]`, 0)
              data.append(`elements[${index}][media-updated][]`, 1)
              data.append(
                `elements[${index}][media-value][]`,
                dataURItoBlob(content.gallery.source)
              )
            } else {
              data.append(`elements[${index}][media-id][]`, content.gallery.id)
              data.append(`elements[${index}][media-updated][]`, 0)
              data.append(
                `elements[${index}][media-value][]`,
                dataURItoBlob(img)
              )
            }
          })

          break
        default:
          break
      }
    })

    axios
      .post(`api/quicknotes-element`, data)
      .then(result => {
        if (draft > 0) return
        this.setState(
          {
            edit: false,
            preview: false,
            loadFrom: false,
          },
          () => {
            this.props.updateQuicknotesList(result.data)
          }
        )
      })
      .catch(errors => {
        console.log(errors)
      })
  }

  mediaStandardChange = (event, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[1].elements[0].gallery.source = event.data
    qnlist[index].contents[1].elements[0].gallery.type = event.type
    if (event.type == 'image')
      qnlist[index].contents[1].elements[0].gallery.thumbnail = event.data

    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  standardThumbnailChange = (thumbnail, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[1].elements[0].gallery.thumbnail = thumbnail
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  addNewMedia = (event, index) => {
    console.log('add media', event, index)
    event.preventDefault()
    const { qnlist } = this.state
    qnlist[index].contents.push({
      gallery: {
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      },
    })
    this.setState(
      {
        qnlist: qnlist.splice(0),
      },
      () => {
        console.log(this.state.qnlist)
      }
    )
  }

  onContentTitleChange = (event, item_index, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[item_index].gallery.title = event.target.value
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onContentCaptionChange = (event, item_index, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[item_index].gallery.caption = event.editor.getData()
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onContentMediaChange = (event, item_index, index) => {
    console.log(event, item_index, index)
    const { qnlist } = this.state
    qnlist[index].contents[item_index].gallery.source = event.data
    qnlist[index].contents[item_index].gallery.type = event.type
    if (event.type == 'image')
      qnlist[index].contents[item_index].gallery.thumbnail = event.data
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onContentThumbnailChange = (event, item_index, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[item_index].gallery.thumbnail = event
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  render() {
    console.log(this.props)
    console.log(this.state)
    
    const { surgeon, quicknotes_list } = this.props
    /* if(this.state.loading){
      return null;
    } */
    
    let quicknotesitems = quicknotes_list.map(quicknotes => {
      if (quicknotes.type == 'text') {
        return <QuicknotesText quicknotes={quicknotes} />
      } else if (quicknotes.type == 'media') {
        return <QuicknotesMedia quicknotes={quicknotes} />
      } else if (quicknotes.type == 'standard') {
        return <QuicknotesStandard quicknotes={quicknotes} />
      }
    })
    const { qnlist } = this.state
    if (quicknotes_list.length > 0 && qnlist.length == 0) {
      this.setState({
        qnlist: quicknotes_list,
      })
    }

    let quicknotesedit = (
      <this.SortableList
        items={qnlist}
        onSortEnd={this.onSortEnd}
        distance={1}
      />
    )

    /*  let quicknotesedit = qnlist.map((quicknotes, index) => {
      if (quicknotes.type == 'text') {
        return (
          <TextEditor
            data={{
              title: quicknotes.title,
              content: quicknotes.contents[0].content,
            }}
            onTitleChange={event => {
              this.titleChange(event, index)
            }}
            textContentChange={event => {
              this.textContentChange(event, index)
            }}
          />
        )
      } else if (quicknotes.type == 'media') {
        console.log(quicknotes.contents)
        return (
          <MediaEditor
            data={{
              title: quicknotes.title,
              medias: quicknotes.contents,
            }}
            onTitleChange={event => {
              this.titleChange(event, index)
            }}
            onContentTitleChange={(event, i) => {
              this.onContentTitleChange(event, i, index)
            }}
            onContentCaptionChange={(event, i) => {
              this.onContentCaptionChange(event, i, index)
            }}
            onContentMediaChange={(event, i) => {
              this.onContentMediaChange(event, i, index)
            }}
            onContentThumbnailChange={(event, i) => {
              this.onContentThumbnailChange(event, i, index)
            }}
            addNewMedia={event => this.addNewMedia(event, index)}
          />
        )
      } else if (quicknotes.type == 'standard') {
        return (
          <StandardEditor
            data={{
              title: quicknotes.title,
              text: quicknotes.contents[0].elements[0].content,
              media: {
                thumbnail: quicknotes.contents[1].elements[0].gallery.thumbnail,
                source: quicknotes.contents[1].elements[0].gallery.source,
              },
            }}
            onTitleChange={event => {
              this.titleChange(event, index)
            }}
            onStandardTextChange={event => {
              this.standardTextChange(event, index)
            }}
            onStandardThumbnailChange={thumbnail => {
              this.standardThumbnailChange(thumbnail, index)
            }}
            onMediaChange={event => {
              this.mediaStandardChange(event, index)
            }}
          />
        )
      }
    }) */

    return (
      <div className="container pt-0 mb30 pb30">
        <Confirmation
          title="Update ELements"
          message="This updates the elements."
          confirmText="Yes"
          cancelText="Cancel"
          confirm={this.onUpdateQuicknotes.bind(this, 0)}
          idModal="update-confirmation"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />

        <Confirmation
          title="Save to draft"
          message="This Save to draft."
          confirmText="Yes"
          cancelText="Cancel"
          confirm={this.onUpdateQuicknotes.bind(this, 1)}
          idModal="save-to-draft"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />
        <div className="row">
          <div className="col-md-3">
            {surgeon.id ? <SurgeonInfo /> : null}

            {surgeon.id ? <SurgeonSpecialties /> : null}
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 mb20">
                <h1 className="font-weight-bold m-0">
                  {surgeon.firstname} {surgeon.lastname} quicknotes
                </h1>
              </div>
              <QuicknotesCategories
                edit={this.state.edit}
                preview={this.state.preview}
              />
              <div className="col-md-12 mt20 profile-card">
                <h4 className="mb20 d-flex  align-items-end">
                  {this.state.quicknotes}{' '}
                  <div
                    href="#"
                    className="btn btn-link ml-auto p-0 align-items-end text-dark"
                    onClick={() => {
                      if (!this.state.preview) {
                        if (this.props.draft_count > 0)
                          this.props.getSurgeonQuicknotes(
                            this.props.quicknotes_id,
                            1
                          )
                        else
                          this.props.getSurgeonQuicknotes(
                            this.props.quicknotes_id,
                            0
                          )
                      }

                      this.setState({
                        edit: !this.state.edit,
                        loadFrom: false,
                        qnlist: [],
                      })
                    }}
                  >
                    {this.state.edit || this.state.preview
                      ? null
                      : 'Edit notes'}
                  </div>
                </h4>
                {this.state.preview ? (
                  <div className="bd-callout bd-callout-warning sticky-top">
                    <p className="m-0">
                      <b>Preview mode</b> - If you like what you see, you can{' '}
                      <u
                        className="font-weight-bold c-pointer"
                        data-target="#update-confirmation"
                        data-toggle="modal"
                      >
                        Update Notes
                      </u>{' '}
                      or make edits by{' '}
                      <u
                        className="font-weight-bold c-pointer"
                        onClick={() => {
                          this.setState({
                            edit: true,
                            preview: false,
                          })
                        }}
                      >
                        Closing preview
                      </u>
                    </p>
                  </div>
                ) : null}

                {this.state.edit ? (
                  <div className="row mb20">
                    <div className="col-md-12 text-right animated fadeIn">
                      <ul class="list-unstyled d-inline-flex align-items-center main-card w-100 justify-content-end toolbar-options">
                        <li class="border-right border-dark h-75 d-flex align-items-center">
                          <a
                            href="#"
                            class="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                            onClick={() => {
                              this.setState(
                                {
                                  edit: !this.state.edit,
                                  qnlist: [],
                                  preview: false,
                                },
                                () =>
                                  this.props.getSurgeonQuicknotes(
                                    this.props.quicknotes_id
                                  )
                              )
                            }}
                          >
                            Back
                          </a>
                        </li>
                        <li class="border-right border-dark h-75 d-flex align-items-center">
                          <a
                            href="#"
                            class="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                            data-toggle="modal"
                            data-target="#loadFromModalCenter"
                          >
                            Copy from...
                          </a>
                        </li>
                        <li class="border-right border-dark h-75 d-flex align-items-center">
                          <a
                            href="#"
                            class="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                            data-target="#save-to-draft"
                            data-toggle="modal"
                          >
                            Save
                          </a>
                        </li>
                        <li class="border-right border-dark h-75 d-flex align-items-center">
                          <a
                            href="#"
                            class="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                            onClick={() => {
                              this.setState(
                                {
                                  preview: true,
                                  edit: false,
                                },
                                () => {
                                  this.props.updateQuicknotesList(
                                    this.state.qnlist
                                  )
                                }
                              )
                            }}
                          >
                            Preview
                          </a>
                        </li>
                        <li class="mx15 ">
                          <div
                            class="btn btn-primary m-0 btn-sm"
                            data-target="#update-confirmation"
                            data-toggle="modal"
                          >
                            Update
                          </div>
                        </li>
                      </ul>

                      {this.state.loadFrom ? (
                        <div className="bd-callout bd-callout-positive">
                          <span className="error-state-icon positive p-0">
                            <i className="mdi mdi-check-circle" /> Copy from success
                          </span>
                        </div>
                      ) : null}
                      {/* <div className="btn btn-outline-primary ml-0 mr30 animated fadeIn" onClick={() => {
                        this.setState({ edit: !this.state.edit, qnlist: [] })
                      }}>
                        Cancel
                      </div>
                      <div className="btn btn-outline-primary ml-0 mr30 animated fadeIn">
                        Load from...
                      </div>
                      <div className="btn btn-outline-primary ml-0 mr30 animated fadeIn">
                        Save
                      </div>
                      <div className="btn btn-outline-primary ml-0 mr30 animated fadeIn">
                        Preview
                      </div>
                      <div
                        className="btn btn-primary ml-0 animated fadeIn"
                        onClick={this.onUpdateQuicknotes.bind(this)}
                      >
                        Update
                      </div> */}
                    </div>
                  </div>
                ) : null}
                <div className="accordion" id="itemListAccordion">
                  <QuicknotesItemList edit={this.state.edit} />
                  {this.state.edit ? quicknotesedit : quicknotesitems}
                  {this.state.edit ? this.newSectionMenu() : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadFromModal
          key={this.props.quicknotes_id}
          loadFrom={() => {
            this.setState({
              loadFrom: true,
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    surgeon: state.surgeon.surgeon,
    quicknotes_id: state.quicknotes.quicknotes_id,
    surgeon_quicknotes_id: state.quicknotes.surgeon_quicknotes_id,
    quicknotes_list: state.quicknotes.quicknotes_list,
    item_list: state.quicknotes.item_list,
    categories: state.quicknotes.categories,
    draft_count: state.quicknotes.draft_count,
  }
}

export default connect(
  mapStateToProps,
  {
    getSurgeon,
    getQuicknotesCategories,
    getSurgeonQuicknotes,
    updateQuicknotesList,
    saveQuicknotesItemList,
  }
)(SurgeonQuicknotes)
