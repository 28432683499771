import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getSpecailtiesData,
  getSpecialtyCategories,
  addSpecialty,
  resetSpecialtyErrors,
  addSpecialtyCategory,
  updateSpecialtyCategory,
  deleteSpecialtyCategory,
  addCategoryProcedure,
  updateSpecialty,
  deleteSpecialty,
} from '../../actions/SpecialtyActions'
import { Image } from '../../components/commons/elements/inputs/'
import { TextInput } from '../../components/commons/forms'
import { getError, getArrayError, dataURItoBlob } from '../../utilities'
import arrayMove from 'array-move'
import Confirmation from '../../components/Confirmation'
import Inbodyloader from '../../components/loader/Inbodyloader'
import { Link } from 'react-router'
import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'

export class SettingSpecialties extends Component {
  constructor(props) {
    super(props)

    this.state = {
      procedures: [],
      specialty: {},
      category: {},
      procedure: {},
      image: '',
      name: '',
      errors: [],
      specialty_edit: false,
      selectedSpecialty: [],
      category_edit: false,
      selectedCategory: [],
      procedure_edit: false,
      selectedProcedure: [],
      loadingState: 0,
    }
  }

  componentDidMount = () => {
    this.setState({
      procedures: [],
      specialty: {},
      category: {},
      procedure: {},
      image: '',
      name: '',
      errors: [],
      specialty_edit: false,
      selectedSpecialty: [],
      category_edit: false,
      selectedCategory: [],
      procedure_edit: false,
      selectedProcedure: [],
      loadingState: 0,
    })
  }

  getCategories = id => {
    this.props.getSpecialtyCategories(id)
  }

  saveProcedure = (id, icon, name) => {
    let { procedures } = this.state

    let data = new FormData()
    data.append('category_id', id)
    data.append('icon[]', icon)
    data.append('name[]', name)

    let url = '/api/admin/specialty-procedure'

    axios
      .post(url, data)
      .then(res => {
        $('#addProcedureModal').modal('hide')
        procedures.push(res.data[0])
        this.setState({
          procedures: procedures.splice(0),
        })
        this.props.getSpecialtyCategories(this.state.specialty.id)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors,
        })
      })
  }

  updateProcedure = (id, icon, name) => {
    let { procedures } = this.state

    let data = new FormData()
    data.append('icon', icon)
    data.append('name', name)
    data.append('_method', 'PATCH')

    let url = `/api/admin/specialty-procedure/${id}`

    axios
      .post(url, data)
      .then(res => {
        $('#addProcedureModal').modal('hide')

        let index = _.findIndex(procedures, e => {
          return e.id == id
        })

        procedures[index].icon = res.data.data.icon
        procedures[index].name = res.data.data.name

        this.setState({
          procedures: procedures.splice(0),
        })

        this.props.getSpecialtyCategories(this.state.specialty.id)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors,
        })
      })
  }

  deleteProcedure = id => {
    let { procedures } = this.state

    let url = `/api/admin/specialty-procedure/${id}`

    axios
      .post(url, { _method: 'DELETE' })
      .then(res => {
        $('#addProcedureModal').modal('hide')

        procedures = _.filter(procedures, e => {
          return e.id != id
        })

        this.setState({
          procedures: procedures.splice(0),
        })
        this.props.getSpecialtyCategories(this.state.specialty.id)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors,
        })
      })
  }

  specialtyModal = () => {
    let { image, name, specialty } = this.state
    const { errors, loading } = this.props

    if (specialty.id && !image) {
      image = specialty.icon
    }

    if (specialty.id && name == null) {
      name = specialty.name
    }

    return (
      <div
        className="modal fade"
        id="addSpecialtyModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addSpecialtyModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <Inbodyloader progress={loading} style={{ borderRadius: 5 }} />
            <div className="modal-body pt30 px30">
              <input
                type="file"
                ref="file"
                className="d-none"
                onChange={event => {
                  this.setState({ image: this.refs.file.files[0] })
                }}
              />
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">
                {specialty.id ? 'Edit specialty' : 'Add new specialty'}
              </h5>
              <Image
                label={`Icon`}
                media_data={{
                  source: image,
                }}
                size={[120, 120]}
                wrapperStyle="mb20 c-pointer"
                previewStyle="d-flex justify-content-center align-items-center bg-light of-hidden m-0 w150 h150"
                onMediaChange={event => {
                  this.setState({ image: event.data })
                }}
              />
              <div className="form-group w-50">
                <TextInput
                  label={'Name of specialty'}
                  value={name}
                  placeholder={'Specialty'}
                  errorMessage={getArrayError(errors, 'name', 0)}
                  containerClass={`mb30`}
                  onValueChange={event => {
                    this.setState({ name: event.target.value })
                  }}
                />
              </div>
            </div>
            <div className="modal-body pb30 px30">
              {specialty.id ? (
                <div
                  className="btn btn-dark mr30 px30"
                  onClick={() => {
                    let icon =
                      image == specialty.icon ? null : dataURItoBlob(image)
                    this.props.updateSpecialty(specialty.id, icon, name)
                  }}
                >
                  Save
                </div>
              ) : (
                <div
                  className="btn btn-dark mr30 px30"
                  onClick={() =>
                    this.props.addSpecialty(dataURItoBlob(image), name)
                  }
                >
                  Save
                </div>
              )}
              <div
                className="btn btn-light border-dark px30"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  categoryModal = () => {
    let { image, name, specialty, category } = this.state
    const { errors } = this.props

    if (category.icon && !image) {
      image = category.icon
    }

    if (category.id && name == null) {
      name = category.name
    }

    return (
      <div
        className="modal fade"
        id="addCategoryModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addCategoryModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <Inbodyloader
              progress={this.state.loadingState}
              style={{ borderRadius: 5 }}
            />
            <div className="modal-body pt30 px30">
              <input
                type="file"
                ref="file"
                className="d-none"
                onChange={event => {
                  this.setState({ image: this.refs.file.files[0] })
                }}
              />
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">
                {category.id
                  ? `Edit specialty[${specialty.name}]`
                  : `Add new specialty[${specialty.name}]`}
              </h5>
              <Image
                label={`Icon`}
                media_data={{
                  source: image,
                }}
                size={[120, 120]}
                wrapperStyle="mb20 c-pointer"
                previewStyle="d-flex justify-content-center align-items-center bg-light of-hidden m-0 w150 h150"
                onMediaChange={event => {
                  this.setState({ image: event.data })
                }}
              />
              <div className="form-group w-50">
                <TextInput
                  label={'Name of category'}
                  value={name}
                  placeholder={'Category'}
                  errorMessage={getError(errors, 'name')}
                  containerClass={`mb30`}
                  onValueChange={event => {
                    this.setState({ name: event.target.value })
                  }}
                />
              </div>
            </div>
            <div className="modal-body pb30 px30">
              {category.id ? (
                <div
                  className="btn btn-dark mr30 px30"
                  onClick={() => {
                    let icon =
                      image == category.icon ? null : dataURItoBlob(image)
                    this.props.updateSpecialtyCategory(
                      specialty.id,
                      category.id,
                      icon,
                      name
                    )
                  }}
                >
                  Save
                </div>
              ) : (
                <div
                  className="btn btn-dark mr30 px30"
                  onClick={() =>
                    this.props.addSpecialtyCategory(
                      specialty.id,
                      dataURItoBlob(image),
                      name
                    )
                  }
                >
                  Save
                </div>
              )}
              <div
                className="btn btn-light border-dark px30"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  procedureModal = () => {
    let { image, name, category, procedure } = this.state
    const { errors } = this.state

    if (procedure.icon && !image) {
      image = procedure.icon
    }

    if (procedure.name && name == null) {
      name = procedure.name
    }

    return (
      <div
        className="modal fade"
        id="addProcedureModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addProcedureModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <Inbodyloader
              progress={this.state.loadingState}
              style={{ borderRadius: 5 }}
            />
            <div className="modal-body pt30 px30">
              <input
                type="file"
                ref="file"
                className="d-none"
                onChange={event => {
                  this.setState({ image: this.refs.file.files[0] })
                }}
              />
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">
                {procedure.id
                  ? `Edit category[${category.name}]`
                  : `Add new category[${category.name}]`}
              </h5>
              <Image
                label={`Icon`}
                media_data={{
                  source: image,
                }}
                size={[120, 120]}
                wrapperStyle="mb20 c-pointer"
                previewStyle="d-flex justify-content-center align-items-center bg-light of-hidden m-0 w150 h150"
                onMediaChange={event => {
                  this.setState({ image: event.data })
                }}
              />
              <div className="form-group w-50">
                <TextInput
                  label={'Name of procedure'}
                  value={name}
                  placeholder={'Procedure'}
                  errorMessage={getError(errors, 'name')}
                  containerClass={`mb30`}
                  onValueChange={event => {
                    this.setState({ name: event.target.value })
                  }}
                />
              </div>
            </div>
            <div className="modal-body pb30 px30">
              {procedure.id ? (
                <div
                  className="btn btn-dark mr30 px30"
                  onClick={() => {
                    let icon =
                      image == procedure.icon ? null : dataURItoBlob(image)
                    this.updateProcedure(procedure.id, icon, name)
                  }}
                >
                  Save
                </div>
              ) : (
                <div
                  className="btn btn-dark mr30 px30"
                  onClick={() =>
                    this.saveProcedure(category.id, dataURItoBlob(image), name)
                  }
                >
                  Save
                </div>
              )}
              <div
                className="btn btn-light border-dark px30"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  checkCategory = category => {
    if (this.state.category) {
      if (this.state.category.id == category.id) {
        return true
      }
    }

    return false
  }

  setLoadingState = val => {
    this.setState({
      loadingState: val,
    })
  }

  render() {
    const { specialties, categories, user } = this.props
    const { procedures, specialty, category, procedure } = this.state

    let specialty_list = specialties.map((specialty, index) => {
      return (
        <tr
          className="c-pointer"
          onClick={() => {
            $(`#specialty_row_${specialty.id}`)
              .find('label')
              .click()
          }}
          key={index}
          id={`specialty_row_${specialty.id}`}
        >
          <td className="text-left w50 pr-0">
            <div
              className="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: '-35px', paddingLeft: '25px' }}
            >
              <input
                type="radio"
                name="radiobtn1"
                id={`specialty-${specialty.id}`}
                className="custom-control-input c-pointer"
                onClick={() => {
                  this.setState(
                    { specialty: specialty, procedures: [], category: [] },
                    () => {
                      this.getCategories(specialty.id)
                    }
                  )
                }}
              />
              <label
                className="custom-control-label  c-pointer"
                htmlFor={`specialty-${specialty.id}`}
              >
                &nbsp;
              </label>
            </div>
          </td>
          <td
            className="px15 d-flex align-items-center"
            style={{ width: 'calc(100% - 50px)' }}
          >
            {specialty.name}

            {user.type.id == 1 ? (
              <span className="ml-auto">
                <a
                  href="#"
                  className="mr15"
                  data-toggle="modal"
                  data-target="#addSpecialtyModal"
                  onClick={() => {
                    this.setState({
                      specialty: specialty,
                      image: '',
                      name: null,
                    })
                  }}
                >
                  Edit
                </a>
                <a
                  href="#"
                  className=""
                  data-toggle="modal"
                  data-target="#deleteSpecialtyConfirmation"
                  onClick={() => {
                    this.setState({
                      specialty: specialty,
                    })
                  }}
                >
                  Delete
                </a>
              </span>
            ) : (
              ''
            )}
          </td>
        </tr>
      )
    })

    let category_list = categories.map((category, index) => {
      return (
        <tr
          className="c-pointer"
          onClick={() => {
            $(`#category_row_${category.id}`)
              .find('label')
              .click()
          }}
          key={index}
          id={`category_row_${category.id}`}
        >
          <td className="text-left w50 pr-0">
            <div
              className="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: '-35px', paddingLeft: '25px' }}
            >
              <input
                type="radio"
                name="radiobtn2"
                id={`category-${category.id}`}
                checked={this.checkCategory(category)}
                className="custom-control-input c-pointer"
                onClick={() => {
                  this.setState({
                    procedures: category.procedures,
                    category: category,
                  })
                }}
              />
              <label
                className="custom-control-label  c-pointer"
                for={`category-${category.id}`}
              >
                &nbsp;
              </label>
            </div>
          </td>
          <td
            className="px15 d-flex align-items-center"
            style={{ width: 'calc(100% - 50px)' }}
          >
            {category.name}
            {user.type.id == 1 ? (
              <span className="ml-auto">
                <a
                  href="#"
                  className="mr15"
                  data-toggle="modal"
                  data-target="#addCategoryModal"
                  onClick={() => {
                    this.setState({
                      category: category,
                      image: '',
                      name: null,
                    })
                  }}
                >
                  Edit
                </a>
                <a
                  href="#"
                  className=""
                  data-toggle="modal"
                  data-target="#deleteCategoryConfirmation"
                >
                  Delete
                </a>
              </span>
            ) : (
              ''
            )}
          </td>
        </tr>
      )
    })

    let procedure_list = procedures.map((procedure, index) => {
      return (
        <tr
          className="c-pointer"
          onClick={() => {
            $(`#procedure_row_${procedure.id}`)
              .find('label')
              .click()
          }}
          key={index}
          id={`procedure_row_${procedure.id}`}
        >
          <td className="text-left w50 pr-0">
            <div
              className="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: '-35px', paddingLeft: '25px' }}
            >
              <input
                type="radio"
                name="radiobtn3"
                id={`procedure-${procedure.id}`}
                className="custom-control-input c-pointer"
              />
              <label
                className="custom-control-label  c-pointer"
                for={`procedure-${procedure.id}`}
              >
                &nbsp;
              </label>
            </div>
          </td>
          <td
            className="px15 d-flex align-items-center"
            style={{ width: 'calc(100% - 50px)' }}
          >
            {procedure.name}
            <span className="ml-auto">
              <a
                href="#"
                className="mr15"
                data-toggle="modal"
                data-target="#addProcedureModal"
                onClick={() => {
                  this.setState({
                    procedure: procedure,
                    image: '',
                    errors: [],
                    name: null,
                  })
                }}
              >
                Edit
              </a>
              <a
                href="#"
                className=""
                data-toggle="modal"
                data-target="#deleteProcedureConfirmation"
                onClick={() => {
                  this.setState({
                    procedure: procedure,
                  })
                }}
              >
                Delete
              </a>
            </span>
          </td>
        </tr>
      )
    })

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">Specialties</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <table className="table table-striped table-fixed">
              <thead>
                <tr>
                  <th className="w50" />
                  <th>1. Specialty</th>
                </tr>
              </thead>
              <tbody style={{ height: '320px' }}>
                {specialty_list}

                {user.type.id == 1 ? (
                  <tr>
                    <td />
                    <td>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#addSpecialtyModal"
                        onClick={() => {
                          this.setState(
                            { specialty: {}, image: '', name: '' },
                            () => {
                              this.props.resetSpecialtyErrors()
                            }
                          )
                        }}
                      >
                        Register new specialty +
                      </a>
                    </td>
                  </tr>
                ) : (
                  ''
                )}
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <table className="table table-striped table-fixed">
              <thead>
                <tr>
                  <th className="w50" />
                  <th>2. Surgical Areas</th>
                </tr>
              </thead>
              <tbody style={{ height: '320px' }}>
                {category_list}
                {this.state.specialty.name && user.type.id == 1 ? (
                  
                  <tr>
                    <td />
                    <td>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#addCategoryModal"
                        onClick={() => {
                          this.setState({ image: '', name: '' }, () => {
                            this.props.resetSpecialtyErrors()
                          })
                        }}
                      >
                        Register new category +
                      </a>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <table className="table table-striped table-fixed">
              <thead>
                <tr>
                  <th className="w50" />
                  <th>3. Procedure</th>
                </tr>
              </thead>
              <tbody style={{ height: '320px' }}>
                {procedure_list}
                {this.state.category.name ? (
                  <tr>
                    <td />
                    <td>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#addProcedureModal"
                        onClick={() => {
                          this.setState(
                            { procedure: {}, errors: [], image: '', name: '' },
                            () => {
                              this.props.resetSpecialtyErrors()
                            }
                          )
                        }}
                      >
                        Register new procedure +
                      </a>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
        {this.specialtyModal()}
        {this.categoryModal()}
        {this.procedureModal()}
        <Confirmation
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          idModal="deleteSpecialtyConfirmation"
          confirm={() => {
            this.props.deleteSpecialty(specialty.id)
          }}
          cancel={() => {
            $('confirmModal').modal('hide')
          }}
        />
        <Confirmation
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          idModal="deleteCategoryConfirmation"
          confirm={() => {
            this.props.deleteSpecialtyCategory(specialty.id, category.id)
          }}
          cancel={() => {
            $('confirmModal').modal('hide')
          }}
        />
        <Confirmation
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          idModal="deleteProcedureConfirmation"
          confirm={() => {
            this.deleteProcedure(procedure.id)
          }}
          cancel={() => {
            $('confirmModal').modal('hide')
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    specialties: state.specialty.items,
    categories: state.specialty.categories,
    errors: state.specialty.errors,
    loading: state.specialty.loading,
    user: state.auth.user,
  }
}

export default connect(
  mapStateToProps,
  {
    getSpecailtiesData,
    getSpecialtyCategories,
    addSpecialty,
    updateSpecialty,
    deleteSpecialty,
    resetSpecialtyErrors,
    addSpecialtyCategory,
    updateSpecialtyCategory,
    deleteSpecialtyCategory,

    addCategoryProcedure,
  }
)(SettingSpecialties)
