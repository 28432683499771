import React, { Component } from 'react'
import $ from 'jquery'
import ValidationMessage from './ValidationMessage';
import sanitizeHtml from 'sanitize-html'
import ContentEditable from 'react-contenteditable';

export default class TextBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      html: ''
    }
  }

  componentDidMount() {
    
  }
  clean(html) {
    return sanitizeHtml(html, {
      allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span', 'i', 'b', 'strong', 'u', 'ul', 'li', 'ol', 'table', 'thead', 'tbody', 'tfoot', 'td', 'th', 'tr'],
      allowedAttributes: {
        'a': ['href'], 'p': ['class'], 'span': ['style']
      }
    })
  }
  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      this.setState({
        html: ''
      })
      this.setState({
        html: this.clean(nextProps.value)
      })
    }
  }

  render() {
    const { label, className, value, errorMessage, onChange, onBlur, rows } = this.props
    const { html } = this.state
    return (
      <div className={`form-group ${className ? className : ''} ${errorMessage ? 'is-invalid' : ''}`} id={`text-box-${this.state.unique}`}>
        {
          label ? <label>{label}</label> : null
        }
        <ContentEditable
          innerRef={this.contentEditable}
          html={html}
          disabled={false}
          // handle innerHTML change 
          onChange={onChange}
          onBlur={onBlur}
          className="contenteditable"
        />
        {/* <textarea className="form-control" onChange={onChange} onBlur={onBlur} rows={rows} defaultValue={value} value={value}></textarea> */}
        {
          errorMessage ?
            <ValidationMessage type="invalid" message={errorMessage} />
            : null
        }
      </div>
    )
  }
}
