import React from 'react';

import {connect} from 'react-redux'
import Dialog from '@material-ui/core/Dialog';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {closeTable} from '../actions/roomSetupAction'
import EquipTable from './EquipTable';
function Transition(props) {
    return <Slide direction="up" {...props} />;
  }


class FullDialogue extends React.Component{


  handleClose = () => {
    this.props.closeTable();
  }

  getOpenStatus = (open) =>{    
    if(open !== undefined){
      return open
    }else{
    return false
    }
  }

  render(){
      
    const open = this.props.posts.isOpen;
    
      return(
        <div>
              {/* <Dialog
                    open={this.getOpenStatus(open)}
                    TransitionComponent={Transition}
                  >
              
                  <Toolbar>
                  <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit">
                      Add Equipment
                    </Typography>
                  
                  </Toolbar>
              
              </Dialog> */}
        
        <div className="modal animated fadeInDown faster" id="assignItemsRoomSetupModalCenter" tabIndex={-1} role="dialog" aria-labelledby="assignItemsRoomSetupModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl px30" role="document">
            <div className="modal-content">
              <div className="modal-body pt30 px30 pb15">
                <h5 className="m-0 mx-auto font-weight-bold pb20">Assigning items 
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </h5>
                <div className="row">
                  <div className="col-md-12"> 
                    <EquipTable getEquip={this.props.getEquip} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

const mapStateToProps = (state) =>{ //the state will have a property called "users" according sa decalred reducers index

return {
        posts: state.posts
    }
}

export default connect(mapStateToProps, {closeTable})(FullDialogue)