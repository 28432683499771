import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import $ from 'jquery'
import AssignItemsDefault from './modals/AssignItemsDefault'
import { TextEditor } from './TextEditor'
import { Medias } from './positioning'
import ViewItemModal from './modals/ViewItemModal'

export class ItemList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: -1,
      item: {},
      change: false
    }
  }

  setSelectedItems = () => {
    const { stage_details } = this.props
  }

  setNewState = (state) => {
    this.setState(state)
  }

  render() {
    const { edit, selected_items } = this.props

    const item_list = selected_items.map((item, index) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return (
        <tr>
          <td className="w150 px12">{item.catalog_number}</td>
          <td className="w280 font-weight-bold c-pointer text-dark fs-16 px12"
            data-container="body"
            data-trigger="hover"
            data-toggle="popover"
            data-placement="left"
            data-html="true"
            data-content={`
                          <div class="row">
                          <div class="col-md-4 text-center pr-0">
                          <img src=${item.icon} class="bg-dark w-100">
                          </div>
                          <div class="col">
                          <h6 class="mb15">${item.name}</h6>
                          <p>${item.used_for}</p>
                          </div>
                          <div class="col-md-12 mt15">
                          <p class=" mb10">Tags</p>
                          ${tags}
                          </div>
                          </div>`}
            onClick={() => {
              this.setState(
                {
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                },
                () => {
                  $(`#viewItemConsumableLayout`).modal('show')
                }
              )
            }}>
              {item.name} 
          </td>
          <td className=" w170 px12"> <p className="m-0 p-0 float-left w-100 fs14">{item.size} </p> </td>
          <td className=" text-center w100">{item.qty}</td>

        </tr>
      )
    })

    return (
      <div className="card shadow-sm border-0 rounded-0 mt30">
        <div className="card-header bg-white border-0 rounded-0">
          <h6 class="h3bold mb0 d-flex align-items-center justify-content-between">
            Room equipment
            {edit ? 
              <div class="btn btn-outline-primary float-right" 
                data-toggle="modal" 
                onClick={() => {this.setState({change: !this.state.change})}}
                data-target="#assignItemsDefault">
                  Assign items
                </div> 
            : ''}
          </h6>
        </div>
        <div className="card-body pt0">
          <div className="w-100 of-auto">
            <table className="table normal-table table-striped table-hover border table-hover-bold">
              <thead>
                <tr>
                  <th className="w120">Catalogue #</th>
                  <th className="w300">Name</th>
                  <th className="w180">Size</th>
                  <th className="text-center">Qty</th>
                </tr>
              </thead>
              <tbody>
                {item_list}
              </tbody>
            </table>
          </div>
        </div>

        <ViewItemModal
          idModal="viewItemConsumableLayout"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemConsumableLayout').modal('hide')
          }}
        />  
        
        <AssignItemsDefault selected_items={selected_items} setNewState={(state) => { this.props.setNewState(state) }} change={this.state.change} />
      </div>
    )
  }
}

export default ItemList
