export const dataURItoBlob = dataURI => {
  

  if (dataURI == '') return ''
  
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])
  let mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]
  let ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const result = new Blob([ia], { type: mimeString })
  console.log('viddddd', result, [ia])
  return result
}

export const isBase64 = dataURI => {
  return dataURI.indexOf('base64')
}
