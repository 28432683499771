import React, { Component } from 'react'
import { AssignItems } from '../modals/AssignItems'
import { connect } from 'react-redux'
import { getItemsPerCategory } from '../../actions/ItemAction'
import { Standard, Text, Media } from '../commons/elements/display'
import TextEditor from '../../components/TextEditor'
import MediaEditor from '../../components/MediaEditor'
import StandardEditor from '../../components/StandardEditor'
import { dataURItoBlob } from '../../utilities/helpers'
import axios from 'axios'
import _ from 'lodash'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Confirmation from '../../components/Confirmation'
import $ from 'jquery'
import img from '../../assets/images/img.png'
import WholeBodyloader from '../loader/WholeBodyloader'

export class StandardLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      change: false,
      standardData: [],
      editMode: false,
      preview: false,
      errors: [],
      progress: 0,
    }

    const SortableItem = SortableElement(({ media, index2 }) => {
      const index = index2
      switch (media.type) {
        case 'text':
          return (
            <li>
              <TextEditor
                data={{
                  title: media.title,
                  content: media.contents.caption,
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                textContentChange={event => {
                  this.textContentChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        case 'media':
          return (
            <li>
              <MediaEditor
                data={{
                  title: media.title,
                  medias: media.contents,
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onContentTitleChange={(event, i) => {
                  this.onContentTitleChange(event, i, index)
                }}
                onContentCaptionChange={(event, i) => {
                  this.onContentCaptionChange(event, i, index)
                }}
                onContentMediaChange={(event, i) => {
                  this.onContentMediaChange(event, i, index)
                }}
                onContentThumbnailChange={(event, i) => {
                  this.onContentThumbnailChange(event, i, index)
                }}
                onContentSortChange={(contents, i) => {
                  this.onContentSortChange(contents, index)
                }}
                addNewMedia={event => this.addNewMedia(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                onDelete={event => this.deleteElement(event, index)}
                onDeleteMediaElement={this.deleteMediaElement}
              />
            </li>
          )
          break
        case 'standard':
          return (
            <li>
              <StandardEditor
                data={{
                  title: media.title,
                  text: media.contents.caption,
                  media: {
                    thumbnail: media.contents.gallery
                      ? media.contents.gallery.thumbnail
                      : '',
                    source: media.contents.gallery
                      ? media.contents.gallery.source
                      : '',
                  },
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onStandardTextChange={event => {
                  this.standardTextChange(event, index)
                }}
                onStandardThumbnailChange={thumbnail => {
                  this.standardThumbnailChange(thumbnail, index)
                }}
                onMediaChange={event => {
                  this.mediaStandardChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                progressMedia={() => {}}
                progressComplete={() => {}}
              />
            </li>
          )
          break
        default:
          break
      }
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              media={value}
            />
          ))}
        </ul>
      )
    })
  }

  componentDidMount = () => {
    this.setState({
      standardData: this.props.stage_details,
    })
  }

  componentWillReceiveProps = nextProps => {
    // if (nextProps.stage_details != this.props.stage_details) {
    //   this.setState({
    //     standardData: nextProps.stage_details,
    //   })
    // }

    if (this.props.stage_master != nextProps.stage_master) {
      if (!nextProps.stage_master.draft) {
        this.setState({
          editMode: false,
          standardData: nextProps.stage_details,
        })
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ standardData }) => ({
      errors: [],
      standardData: arrayMove(standardData, oldIndex, newIndex),
    }))
  }

  newSectionMenu = () => {
    return (
      <div className="row mt30">
        <div className="col-12">
          <label
            className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 cursor-pointer py30"
            style={{ border: '2px dashed rgb(132, 134, 136)' }}
          >
            {/* <img src="img.png" class="h50" id="">  */}
            <div className="row w-100">
              <div className="col-md-12 text-center">
                <h4 className="m-0">Add a new section:</h4>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newTextSection()
                  }}
                >
                  <u>Text only</u>
                </div>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newMediaSection()
                  }}
                >
                  <u>Pictures and videos</u>
                </div>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newStandardSection()
                  }}
                >
                  <u>Text and Media</u>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    )
  }

  newTextSection = () => {
    const { standardData } = this.state
    let textsection = {
      title: null,
      type: 'text',
      contents: {
        caption: '',
      },
    }
    standardData.push(textsection)
    this.setState({
      standardData,
    })
  }

  deleteMediaElement = (event, index, subIndex) => {
    event.preventDefault()
    const { standardData } = this.state

    standardData[index].contents.splice(subIndex, 1)

    this.setState({ standardData: standardData.splice(0) })
  }

  onContentSortChange = (contents, index) => {
    const { standardData } = this.state
    standardData[index].contents = contents

    this.setState({
      errors: [],
      standardData: standardData.splice(0),
    })
  }

  deleteElement = (event, index) => {
    const { standardData } = this.state
    event.preventDefault()
    standardData.splice(index, 1)
    this.setState({
      errors: [],
      standardData: standardData.splice(0),
    })
  }

  duplicateElement = (event, index) => {
    const { standardData } = this.state
    event.preventDefault()
    standardData.push(standardData[index])
    this.setState({ standardData: standardData.splice(0) })
  }

  newMediaSection = () => {
    const { standardData } = this.state
    let mediasection = {
      title: null,
      type: 'media',
      contents: [
        {
          title: null,
          caption: null,
          gallery: {},
        },
      ],
    }
    standardData.push(mediasection)
    this.setState({
      standardData,
    })
  }

  newStandardSection = () => {
    const { standardData } = this.state
    let standardsection = {
      title: null,
      type: 'standard',
      contents: {
        caption: '',
        gallery: {
          source: null,
          thumbnail: null,
        },
      },
    }

    standardData.push(standardsection)
    this.setState({
      standardData,
    })
  }

  titleChange = (event, index) => {
    console.log('title changing')
    const { standardData } = this.state
    standardData[index].title = event.target.value

    this.setState({
      standardData: standardData.splice(0),
    })
  }

  textContentChange = (event, index) => {
    const { standardData } = this.state
    standardData[index].contents.caption = event.editor.getData()

    this.setState({
      standardData: standardData.splice(0),
    })
  }

  standardTextChange = (event, index) => {
    const { standardData } = this.state
    standardData[index].contents.caption = event.editor.getData()

    this.setState({
      standardData: standardData.splice(0),
    })
  }

  onSaveData = draft => {
    const { standardData } = this.state
    const { item_list } = this.state

    console.log('list', item_list)

    const data = new FormData()
    data.append('draft', draft)
    data.append('_method', 'PATCH')

    standardData.map((standard, index) => {
      data.append(
        `elements[${index}][title]`,
        standard.title ? standard.title : ''
      )
      data.append(`elements[${index}][type]`, standard.type)
      switch (standard.type) {
        case 'text':
          data.append(`elements[${index}][values]`, standard.contents.caption)
          break
        case 'standard':
          data.append(
            `elements[${index}][media-title]`,
            standard.contents.title
          )
          data.append(
            `elements[${index}][media-text]`,
            standard.contents.caption
          )
          data.append(`elements[${index}][media-id]`, 0)
          data.append(`elements[${index}][media-updated]`, 1)
          data.append(
            `elements[${index}][media-original]`,
            standard.contents.gallery.originSource
          )
          data.append(
            `elements[${index}][media-value]`,
            standard.contents.gallery.source
          )
          data.append(
            `elements[${index}][media-type]`,
            standard.contents.gallery.type
          )

          break
        case 'media':
          standard.contents.map((content, i) => {
            data.append(
              `elements[${index}][media-text][]`,
              content.gallery.caption ? content.gallery.caption : ''
            )
            data.append(
              `elements[${index}][media-title][]`,
              content.gallery.title ? content.gallery.title : ''
            )
            data.append(
              `elements[${index}][media-type][]`,
              content.gallery.type
            )
            data.append(`elements[${index}][media-id][]`, 0)
            data.append(`elements[${index}][media-updated][]`, 1)
            data.append(
              `elements[${index}][media-value][${i}]`,
              content.gallery.source
            )
            data.append(
              `elements[${index}][media-original][${i}]`,
              content.gallery.originSource
            )
          })

          break
        default:
          break
      }
    })

    const { stage_master } = this.props
    let url = `api/admin/stage-default-element/${stage_master.id}`

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        this.setState({
          progress: percentCompleted >= 95 ? 95 : percentCompleted,
        })
      },
    }

    axios
      .post(url, data, config)
      .then(result => {
        if (!draft) {
          this.setState(
            {
              errors: [],
              editMode: false,
              preview: false,
              loadFrom: false,
              draft_success: false,
              progress: 0,
            },
            () => {
              if (this.props.stage_master.stage_type == 'custom') {
                this.props.getProcedureDetail(0, 'custom')
              } else {
                this.props.getProcedureDetail(0)
              }
            }
          )
        } else {
          this.setState({
            errors: [],
            progress: 0,
            draft_success: true,
          })
        }
      })
      .catch(errors => {
        console.log(errors)
        console.log(errors.response)
        this.setState(
          {
            progress: 0,
            errors: errors.response.data.errors,
          },
          () => {
            let targetOffset = $('.is-invalid').offset().top
            $('html, body').animate({ scrollTop: targetOffset }, 500)
          }
        )
      })
  }

  mediaStandardChange = (event, index) => {
    const { standardData } = this.state
    standardData[index].contents.gallery.source = event.data
    standardData[index].contents.gallery.type = event.type
    if (event.type == 'image')
      standardData[index].contents.gallery.thumbnail = event.data

    this.setState({
      standardData: standardData.splice(0),
    })
  }

  standardThumbnailChange = (thumbnail, index) => {
    const { standardData } = this.state
    standardData[index].contents.gallery.thumbnail = thumbnail
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  addNewMedia = (event, index) => {
    console.log('add media', event, index)
    event.preventDefault()
    const { standardData } = this.state
    standardData[index].contents.push({
      gallery: {
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      },
    })
    this.setState(
      {
        standardData: standardData.splice(0),
      },
      () => {
        console.log(this.state.standardData)
      }
    )
  }

  onContentTitleChange = (event, item_index, index) => {
    const { standardData } = this.state
    standardData[index].contents[item_index].gallery.title = event.target.value
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  onContentCaptionChange = (event, item_index, index) => {
    const { standardData } = this.state
    standardData[index].contents[
      item_index
    ].gallery.caption = event.editor.getData()
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  onContentMediaChange = (event, item_index, index) => {
    console.log(event, item_index, index)
    const { standardData } = this.state
    standardData[index].contents[item_index].gallery.source = event.data
    standardData[index].contents[item_index].gallery.type = event.type
    if (event.type == 'image')
      standardData[index].contents[item_index].gallery.thumbnail = event.data
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  onContentThumbnailChange = (event, item_index, index) => {
    const { standardData } = this.state
    standardData[index].contents[item_index].gallery.thumbnail = event
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  renderEditMode = () => {
    const { preview, standardData } = this.state

    if (preview) {
      return this.renderView()
    } else {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="row mb20">
              <div className="col-md-12 text-right mt20">
                <ul className="list-unstyled d-inline-flex align-items-center h-100 main-card w-100 justify-content-end shadow-sm">
                  <li className="border-right border-dark h-75 d-flex align-items-center">
                    <a
                      href="#"
                      className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                      onClick={() => {
                        this.props.getProcedureDetail()
                        this.setState({
                          editMode: false,
                        })
                      }}
                    >
                      Cancel
                    </a>
                  </li>
                  <li className="border-right border-dark h-75 d-flex align-items-center">
                    <a
                      href="#"
                      className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                      onClick={() => {
                        this.onSaveData(1)
                      }}
                    >
                      Save
                    </a>
                  </li>
                  <li className="border-right border-dark h-75 d-flex align-items-center">
                    <a
                      href="#"
                      className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                      onClick={() => {
                        this.setState({
                          preview: true,
                        })
                      }}
                    >
                      Preview
                    </a>
                  </li>
                  <li className="mx15 ">
                    <div
                      className="btn btn-primary m-0 btn-sm"
                      onClick={() => {
                        this.onSaveData(0)
                      }}
                    >
                      Update
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="accordion" id="itemListAccordion">
              {
                <this.SortableList
                  items={standardData}
                  onSortEnd={this.onSortEnd}
                  distance={1}
                  useDragHandle
                />
              }
              {this.newSectionMenu()}
            </div>
          </div>
        </div>
      )
    }
  }

  renderView = () => {
    const { standardData } = this.state

    const viewData = standardData.map(item => {
      switch (item.type) {
        case 'text':
          return (
            <Text
              data={{
                title: item.title,
                caption: item.contents.caption,
              }}
              classStyle="mt30"
            />
          )
        case 'media':
          return (
            <Media data={item.contents} title={item.title} styleClass="mt30" />
          )
        case 'standard':
          let media = item.contents
          return (
            <Standard
              data={{
                title: item.title,
                caption: media.caption,
                source: media.gallery ? media.gallery.source : '',
                type:media.gallery ? media.gallery.type : ''
              }}
              classStyle="mt30"
            />
          )
        default:
          return
      }
    })

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="accordion" id="itemListAccordion">
            {this.state.preview ? (
              <div className="bd-callout bd-callout-warning sticky-top">
                <p className="m-0">
                  <b>Preview mode</b> - If you like what you see, you can{' '}
                  <u
                    className="font-weight-bold c-pointer"
                    onClick={() => {
                      this.onSaveData(0)
                    }}
                  >
                    Update Notes
                  </u>{' '}
                  or make edits by{' '}
                  <u
                    className="font-weight-bold c-pointer"
                    onClick={() => {
                      this.setState({
                        edit: true,
                        preview: false,
                      })
                    }}
                  >
                    Closing preview
                  </u>
                </p>
              </div>
            ) : (
              ''
            )}
            {viewData}
          </div>
        </div>
      </div>
    )
  }

  updateData = (draft = 0) => {
    const { stage_master } = this.props
    axios
      .get(`api/admin/stage-default-element/${stage_master.id}?draft=${draft}`)
      .then(result => {
        this.setState({
          standardData: result.data.data,
          editMode: draft,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  render() {
    const { stage } = this.props

    return (
      <div className="col-md-9 mt20 profile-card mx-auto">
        <h4 className="mb0 fs28 font-weight-bold d-flex align-items-center">
          {stage.name}
          {this.state.editMode ? null : (
            <a
              href="javascript:;"
              onClick={() => {
                this.props.getProcedureDetail(1)
                this.setState({
                  editMode: true,
                })
              }}
              class="btn btn-link ml-auto p-0 text-dark fs14"
            >
              {this.props.title} Edit
            </a>
          )}
        </h4>
        {this.state.editMode ? this.renderEditMode() : this.renderView()}
        <WholeBodyloader
          message="Saving! please wait..."
          progress={this.state.progress}
          type="spinner"
        />
      </div>
    )
  }
}

export default connect(
  null,
  { getItemsPerCategory }
)(StandardLayout)
