import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import Vimeo from '@u-wave/react-vimeo'


export class MediaPreview extends Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.galleryRef = this.galleryRef.bind(this)
    this.thumbRef = this.thumbRef.bind(this)
  }

  componentWillUpdate(nextProps, nextState) {
    if (!this.state.editMode) {
      if (nextState.gallerySwiper && nextState.thumbnailSwiper) {
        const { gallerySwiper, thumbnailSwiper } = nextState

        gallerySwiper.controller.control = thumbnailSwiper
        thumbnailSwiper.controller.control = gallerySwiper
      }
    }
  }

  galleryRef(ref) {
    if (ref) this.setState({ gallerySwiper: ref.swiper })
  }

  thumbRef(ref) {
    if (ref) this.setState({ thumbnailSwiper: ref.swiper })
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  renderVideo(gallery) {
    if (gallery.disk == 'local') {
      return (
        <video className="bg-dark w-100 align-items-center h-100" controls>
          <source src={gallery.source} type="video/mp4" />
          <source src={gallery.source} type="video/webM" />
          <source src={gallery.source} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )
    }

    const source = gallery.source.split('/').pop()

    return <Vimeo video={source} className="v-player w-100 align-items-center h-100 bg-141517" 
      onLoaded={()=>{
        
      }}
      reponsive={true}
    />
  }

  mediaitem = media => {
    return (
      <div className="d-flex pr10 pb20" key={media.gallery.id}>
        <div className="col-md-8 of-hidden text-center pl-0 d-flex align-items-start">
          {media.gallery.type == 'video' ? (
           this.renderVideo(media.gallery)
          ) : (
            <img
              src={media.gallery.source}
              className="bg-dark w-100 align-items-center h-100"
            />
          )}
        </div>
        <div className="col h300 of-auto pl-0">
          <p className="font-weight-bold mb15">
            {media.gallery.title ? media.gallery.title : 'No specified.'}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: media.gallery.caption
                ? media.gallery.caption
                : 'No specified.',
            }}
          />
        </div>
      </div>
    )
  }

  mediathumbs = media => {
    return (
      <div className="col-md-3 px-0" key={media.gallery.id}>
        <img src={media.gallery.thumbnail} className="bg-dark w-100 h130" />
      </div>
    )
  }

  render() {
    const { content } = this.props

    const gallerySwiperParams = {
      spaceBetween: 20,
    }

    const thumbnailSwiperParams = {
      spaceBetween: 20,
      centeredSlides: true,
      slidesPerView: 4,
      touchRatio: 0.2,
      freeMode: true,
      slideToClickedSlide: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }
    let gallery = content.contents.map(media => {
      return this.mediaitem(media)
    })

    let thumbs = content.contents.map(media => {
      return this.mediathumbs(media)
    })

    return (
      <div className="row">
        <div className="col-md-12 pt20 mb15">
          <div className="bg-white p15 h-100 shadow-sm float-left w-100">
            <h5 className="mb20 d-flex align-items-center">
              {this.props.content.title}
            </h5>
            <Swiper
              {...gallerySwiperParams}
              ref={this.galleryRef}
              shouldSwiperUpdate
            >
              {gallery}
            </Swiper>
            <div className="w-100 pt15 thumbnail-swiper">
              <Swiper
                {...thumbnailSwiperParams}
                ref={this.thumbRef}
                shouldSwiperUpdate
              >
                {thumbs}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MediaPreview
