
import {
    SURGEON_UPDATE,
    SURGEON_CREATE,
    SURGEON_CREATE_SUCCESS,
    SURGEON_CREATE_FAIL,
    SET_ISNEXT,
    CREATE_SURGEON_SPECIALTY_SUCCESS,
    CLEAR_SUREGON_DATA,
    GET_SURGEON,
    GET_SURGEONS
    } from './types';
  
import axios from 'axios';
import _ from 'lodash'

export const setIsNext = (status) => {
    return(dispatch) => {
        dispatch({
            type: SET_ISNEXT,
            status: status
        })
    }
}

export const surgeonUpdate = ({ prop, value }) => {
    return {
        type: SURGEON_UPDATE,
        payload: { prop, value }
    };
}

export const clearSurgeonData = () => {
    return(dispatch) => {
        dispatch({
            type: CLEAR_SUREGON_DATA
        })
    }
}

export const getSurgeon = (id) => {
    return(dispatch) => {
        
        console.log('getting surgeon')
        let url = `/api/surgeon/${id}`

        axios.get(url).then((res) => {
            console.log(res)
            dispatch({
                type: GET_SURGEON,
                surgeon: res.data.data
            })
        }).catch((error) => {
            console.log(error)
            console.log(error.response)
        })
    }
}

export const getSurgeons = (id, page, search = '') => {
    return( dispatch) => { 
        let url = `api/surgeon?s_id=${id}&page=${page}`
        if(search.length > 1){
            url = `${url}&search=${search}`
        }
        axios.get(url).then((res) => {
            console.log(res)
            dispatch({
                type: GET_SURGEONS,
                surgeons: res.data.data
            })
        }).catch((error) => {
            console.log(error)
            console.log(error.response)
        })
    }
}

export const createSurgeonSpecialty = (categories, procedures) => {
    return(dispatch, getState) => {
        const { specialty } = getState().specialty
        let all_categories = getState().specialty.categories
        const { surgeon } = getState().surgeon
        let exist = []
        
    


        

        let temp_categories = []
        let temp_procedures = []
        categories.map((category, index) => {
            all_categories.map((c) => {
                if(category == c.id){
                    temp_categories.push(category)
                    temp_procedures.push(procedures[index])
                }
            })
        })

        categories = temp_categories
        procedures = temp_procedures

        console.log('test')
        console.log(categories)
        console.log(procedures)

        // surgeon.specialties.map((specialty) => {
        //     specialty.categories.map((category, index) => {
        //         let dif = procedures[index].filter((procedure) => category.surgeonProcedures.filter((sprocedures) => procedure === sprocedures.procedure.id).length == 0)
        //         console.log('ifdif')
        //         console.log(dif)
        //         if(dif.length){
        //             exist.push(category.category.id)
        //         }
        //     })
        // })

        // console.log('exist')
        // console.log(exist)

        // let diffs = _.differenceWith(categories, exist, _.isEqual);
        // console.log('dif')
        // console.log(diffs)

        // let proc = []
        // diffs.map((dif) => {
        //     let index = categories.indexOf(dif)
        //     proc.push(procedures[index])
        // })

        // categories = diffs
        // procedures = proc

        // console.log('process')
        // console.log(categories)
        // console.log(procedures)


        let data = new FormData()
        data.append('specialty_id', specialty)
        data.append('surgeon_id', surgeon.id)
        
        let url = `api/surgeon-save-specialties`
        axios.post(url, data).then((res) => {
            data = new FormData()
            categories.map((category) => {
                let index = categories.indexOf(category)
                    if(procedures[index].length > 0){
                        data.append('category[]', category)
                        procedures[index].map((procedure) => {
                        data.append(`procedures_${category}[]`, procedure)	
                    })
                }
            })


            console.log('data')
            console.log(data)
            url = `/api/surgeon-save-category/${surgeon.id}/${specialty}`

			axios.post(url, data).then((res) => {
				console.log(res)
				dispatch({
					type: CREATE_SURGEON_SPECIALTY_SUCCESS,
					surgeon: res.data.surgeon
				})
			}).catch((error) => {
				console.log(error)
				console.log(error.response)
            })
        }).catch((error) => {
            console.log(error)
            console.log(error.response)
        })
    }
}

export const createSurgeon = () => {
    return (dispatch, getState) => {
        let {
        firstname,
        lastname,
        title,
        music,
        mobile,
        handness,
        glove_inner,
        glove_outer,
        environment,
        avatar, surgeon } = getState().surgeon

        let data = new FormData()
		data.append('firstname', firstname)
		data.append('lastname', lastname)
        data.append('contact', mobile)
        data.append('dominant_hand', handness)
        data.append('music', music)
        data.append('title', title)
        
        /* console.log(glove_outer.id) */

        if(glove_inner.id != undefined){
            glove_inner = glove_inner.id
        }else{
            glove_inner = '' //changed from = 0 to = ''
        }
        data.append('inner_gloves', glove_inner)
         
        if(glove_outer){

        if(glove_outer.id != null ){
            glove_outer = glove_outer.id
        }else{
            glove_outer = '' //changed from = 0 to = '' 
        }
        }
        else{
            glove_outer = '' //changed from = 0 to = ''
            
        }
        data.append('outer_gloves', glove_outer)
        if(environment == 'normal'){
            data.append('enviroment_on', 1)

        }

        let url = ''
        if(surgeon.id){
            url = `/api/surgeon/${surgeon.id}`
            data.append('_method', 'PATCH')
        }else{
            url = '/api/surgeon'
        }
        
        if(avatar){
            if (avatar.indexOf('base64') >= 0) {
            data.append('avatar', dataURItoBlob(avatar))
            }
        }

        let config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
        }
        dispatch({
            type: SURGEON_CREATE 
        });
    
    axios.post(url, data, config).then(function (result) {
            // createSurgeonSuccess(dispatch,result);
            dispatch({
                type: SURGEON_CREATE_SUCCESS,
                payload: result.data.data
              });
        })
        .catch(function (error) {
        console.log('Error');
        console.log(error);
        console.log(error.response);
        createSurgeonFail(dispatch,error.response.data);
        });
    }}

const createSurgeonSuccess = (dispatch,result) =>{
    dispatch({
        type: SURGEON_CREATE_SUCCESS,
        payload: result.data.data
      });
}

const createSurgeonFail = (dispatch, error) => {
    dispatch({
        type: SURGEON_CREATE_FAIL,
        payload: error
      });
}

const dataURItoBlob =  (dataURI) => {
  if(dataURI == '')
      return ''
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], {type:mimeString});
}