import React, { Component } from 'react'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import Confirmation from '../Confirmation'
import ItemCard from '../ItemCard'
import ViewItemModal from '../modals/ViewItemModal'
import TData from '../commons/table/TData'
class Items extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading: false,
      page: 1,
      loading: 1,
      dataLength: 1,
      isEditMode: false,
      medical_categories: [],
      medical_inventory_name: '',
      selectedCategory: 'all',
      searchKeyword: '',
      inventory: 1,
      cardView: false,
      selectedItem: null,
      sizes: [{ catalogue: '', size: '', show: true }],
      successAppear: false,
      isCreate: false,
      successMessage: '',
      isReverse: false,
      errors: [],
      unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
      selected_category_id: null,
    }
  }

  componentDidMount() {
    console.log('itemssssss', this.props)
    if (this.props.item) {
      const { item } = this.props
      this.setState(
        {
          data: this.state.data.filter(i => i.id != item.id),
        },
        () => {
          const { data } = this.state

          data.unshift(item)
          this.setState({
            data: data.splice(0),
            isCreate: false,
            successAppear: true,
            successMessage: 'Items have been successfully updated',
          })
          setTimeout(() => {
            this.setState(
              {
                successAppear: false,
              },
              () => {
                this.props.removeState()
              }
            )
          }, 5000)
        }
      )
    }
    this.makeRemoteRequest()
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }


  sortBy(key) {
    let arrayCopy = [...this.state.data]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ data: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }
  
  renderView() {
    console.log('-======================TABLE RENDER====')
    if (this.state.cardView) return this.renderCard() 
    return this.renderTable()
  }


  renderCard() {
    const cards = this.state.data.map(item => {
      return <ItemCard key={`item_card_${item.id}`}  itemData={item}  onClick={() => {
        
        this.setState({
          selectedItem: item,
          unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15)
        }, () => {
        $("#viewItemModal").modal('show'); 

        })
      }}/>
    })
    return <div className="d-flex flex-wrap my20">{cards}</div>
  }
  componentDidUpdate() { 
    $('[data-toggle="popover"]').popover() 
    $('.popover').popover('hide')
  }

  editItemSuccess(item) {}

  makeRemoteRequest = () => {
    this.setState({ loading: true })
    const { page, selectedCategory, searchKeyword, inventory } = this.state
    
    let url = `api/admin/items?medical_inventory_id=2&medical_category_id=${selectedCategory}&query=${searchKeyword}&paginate=15&page=${page}` 
    axios
      .get(url)
      .then(res => {
        this.setState({
          data:
            page === 1 ? res.data.data : [...this.state.data, ...res.data.data],
          loading: false,
          hasMore: res.data.data.length > 0,
          dataLength: res.data.data.length,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false, hasMore: false })
      })

    axios
      .get(`api/admin/medical-categories-by-inventory/2`)
      .then(result => {
        console.log(result)
        this.setState({
          medical_categories: result.data.data,
        })
      })
      .catch(error => {
        console.log(error.response)
      }) 
  }


  categoryChange(event) {
    console.log()
    event.preventDefault()
    this.setState(
      {
        page: 1,
        loading: true,
        data: [],
        selectedCategory: event.target.value,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div className="row">
          <div className="col-md-12 text-center pb30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )

    return (
      <div className="row">
        <div className="col-md-12 text-center mt30">
          <h3 className="mb-15">Nothing else to see...</h3>
          <p className="">
            Missing something? Add new items via <a href="#" onClick={()=>{
              this.setState({
                isEditMode: true
              })
            }}>Edit database</a>
          </p>
        </div>
      </div>
    )
  }

  changeView() {
    this.setState({
      cardView: !this.state.cardView,
    })
  }


  renderTable() {
    const rows = this.state.data.map(data => {
      //console.log('item', data)
      return (
        <tr key={data.id}>
          <td>{data.catalog_number}</td>
          
          <TData className="has-action" item={data} popover onClick={()=>{
            
            this.setState({
                  selectedItem: data,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                }, () => {
                  $('#viewItemModal').modal('show')
                })

          }}>{data.name}</TData>  
          <td>{data.company}</td>
          <td>{data.category ? data.category.name : '-'}</td>
          <td>{data.size ? data.size : '-'}</td>
          {this.state.isEditMode ? (
            <td className="w115 px-0 text-center">
              {data.manageable ? (
                <Link
                  to={{ pathname: '/update-item', state: data }}
                  className="text-dark font-weight-bold"
                >
                  <u>Edit content</u>
                </Link>
              ) : (
                '-'
              )}
            </td>
          ) : (
            ''
          )}

          {this.state.isEditMode ? (
            <td className="w95 px-0 text-center">
              <a
                href="#"
                className="text-dark"
                data-backdrop="false"
                data-toggle="modal"
                data-target="#sizesModalCenter"
                onClick={() =>
                  this.setState({
                    selectedItem: data,
                    sizes: [
                      {
                        catalogue: '',
                        size: '',
                        show: true,
                      },
                    ],
                  })
                }
              >
                <u>Add sizes</u>
              </a>
            </td>
          ) : (
            ''
          )}

          {this.state.isEditMode ? (
            <td className="w70 px-0 text-center">
              {data.manageable ? (
                <a
                  href="#"
                  className="text-dark"
                  data-target="#confirmationModal"
                  data-toggle="modal"
                  onClick={() => this.setState({ selectedItem: data })}
                  data-backdrop="false"
                >
                  <u>Delete</u>
                </a>
              ) : (
                '-'
              )}
            </td>
          ) : (
            ''
          )}
        </tr>
      )
    })
    return (
      <table className="table table-striped table-hover table-hover-bold ">
        <thead>
          <tr>
            <th onClick={() => this.sortBy('catalog_number')} className="w120 c-pointer">
              <i className="fa fa-sort  mr5" />
              Catalogue #
            </th>
            <th className="w300 c-pointer" onClick={() => this.sortBy('name')}>
              <i className="fa fa-sort mr5 " />
              Name
            </th>
            <th onClick={() => this.sortBy('company')} className="c-pointer">
              <i className="fa fa-sort mr5 " />
              Company
            </th>
            <th onClick={() => this.sortBy('category')} className="c-pointer">
              <i className="fa fa-sort mr5 " />
              Category
            </th>
            <th onClick={() => this.sortBy('size')} className="c-pointer">
              <i className="fa fa-sort mr5 " />
              Size
            </th>
            {this.state.isEditMode ? <th colSpan="3">Edits</th> : ''}
          </tr>
        </thead>

        <tbody style={{ minHeight: 250 }}>
          {this.state.successAppear ? (
            <tr className="_selected">
              <th colspan="8" className="text-center">
                {this.state.successMessage}
                <i className="fa fa-check" />
              </th>
            </tr>
          ) : (
            ''
          )}
          {rows}
        </tbody>
      </table>
    )
  }

  
  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map(data => {
        return (
          <span
            key={`tag_${data.id}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }

  onInventoryChange = event => {
    this.setState(
      {
        inventory: event.target.value,
        page: 1,
        data: [],
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  getArrayError(field, index) {
    const { errors } = this.state

    if (errors[`${field}.${index}`]) {
      return errors[`${field}.${index}`][0]
    }
    return ''
  }

  addMoreSizes() {
    const { sizes } = this.state
    sizes.push({ catalogue: '', size: '', show: true })
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  addSizesSubmit() {
    const { selectedItem, sizes } = this.state

    const form = new FormData()
    sizes.map(data => {
      if (data.show) {
        form.append('catalog_number[]', data.catalogue)
        form.append('size[]', data.size)
      }
    })
    axios
      .post(`api/admin/save-duplicate-supply/${selectedItem.id}`, form)
      .then(result => {
        if (result.data.success) {
          $('.modal').modal('hide')
          this.setState({
            successAppear: true,
            successMessage: 'Items have been successfully duplicated!',
            show: true,
            data: [...result.data.data, ...this.state.data],
            sizes: [
              {
                catalogue: '',
                size: '',
                show: true,
              },
            ],
          })

          setTimeout(() => {
            this.setState({
              successAppear: false,
            })
          }, 5000)
        } else {
          console.log(result.data.errors)
          this.setState(
            {
              errors: result.data.errors,
            },
            () => {
              console.log('errors', this.state.errors)
            }
          )
        }
      })
      .catch(error => {})
  }

  sizeRemove(index) {
    const { sizes } = this.state
    //sizes[index].show = false
    sizes.splice(index, 1)
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  deleteItem() {
    const { selectedItem } = this.state
    axios
      .post(`api/admin/items/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          data: this.state.data.filter(i => i.id != selectedItem.id),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully deleted!',
        })
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  onChangeCategory(event){
    this.setState({
      selected_category_id: event.target.value 
    });
  }


  deleteItem() {
    const { selectedItem } = this.state
    axios
      .post(`api/admin/items/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          data: this.state.data.filter(i => i.id != selectedItem.id),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully deleted!',
        })
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  searchItem() {
    this.setState(
      {
        searchKeyword: this.searchKeyword.value,
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }
  render() {
    const search = _.debounce(keyword => {
      this.searchItem(keyword)
    }, 500)
    const categories = this.state.medical_categories.map(item => {
      return (
        <option value={item.id} key={`item_${item.id}`}>
          {item.name}
        </option>
      )
    }) 
    const filter = this.state.sizes.filter(data => {
      return data.show
    })

    const sizes = filter.map((data, index) => {
      return (
        <tr key={`size_item_${index}`} className="border-0">
          <td className="pl-0 w-50 py-0 border-0">
            <div className="form-group mb-0">
              <label className="mb-0">Catalogue</label>
              <div className="invalid-feedback fs-14 d-block mt-0 mb12">
                {this.getArrayError('catalog_number', index)}
              </div>
              <input
                type="type"
                name="text"
                className={
                  this.getArrayError('catalog_number', index) == ''
                    ? 'form-control'
                    : 'form-control is-invalid'
                }
                value={data.catalogue}
                onChange={event => {
                  const { sizes } = this.state
                  sizes[index].catalogue = event.target.value
                  this.setState({
                    sizes: sizes.splice(0),
                  })
                }}
              />
            </div>
          </td>
          <td className=" py-0 border-0">
            <div className="form-group mb-0">
              <label className="mb-0">Size</label>
              <div className="invalid-feedback fs-14 d-block mt-0 mb12">
                {this.getArrayError('size', index)}
              </div>

              <input
                type="type"
                name="text"
                className={
                  this.getArrayError('size', index) == ''
                    ? 'form-control'
                    : 'form-control is-invalid'
                }
                value={data.size}
                onChange={event => {
                  const { sizes } = this.state
                  sizes[index].size = event.target.value
                  this.setState({
                    sizes: sizes.splice(0),
                  })
                }}
              />
            </div>
          </td>
          <td className="border-0">
            <div className="invalid-feedback d-block fs-14 mt-0 mb10">&nbsp;</div>
            {index != 0 ? (
              <i
                className="fa fa-times-circle fa-2x mt10"
                onClick={this.sizeRemove.bind(this, index)}
              />
            ) : (
              ''
            )}
          </td>
        </tr>
      )
    })
    return (
      <div
        className={`tab-pane ${
          this.props.activeTab ? 'active' : ''
          }`}
        id="items"
        role="tabpanel"
        aria-labelledby="items-tab">
        <div
        className="modal fade"
        id="sizesModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="sizesModalCenterTitle"
        aria-hidden="true"
      >
      
        <div className="modal-dialog modal-dialog-centered" role="document">
        {this.state.selectedItem ? 
          <div className="modal-content">
         
            <div className="modal-body pt30 px30 pb20">
              
              <h5 className="m-0 mx-auto font-weight-bold pb20">
                Add sizes for "{this.state.selectedItem.name}"
              </h5>
              {/* <p className="m-0">
                Adding additional sizes for{' '}
                <b>{this.state.selectedItem.name}</b>
              </p> */}
              <p className="m-0">
                <b>Catalogue </b>
                {this.state.selectedItem.catalog_number}
                <b className="ml15">Size </b>
                {this.state.selectedItem.size}
              </p>
            </div>
            
            <div className="px30 pt20">
              <div className="row">
                <div className="col-md-12">
                  <table className="table fixed-table m-0">
                    <tbody
                      style={{
                        float: 'left',
                        width: '100%',
                        overflow: 'auto',
                        maxHeight: '264px',
                      }}
                    >
                      {sizes}
                      <tr className="c-pointer">
                        <td
                          colSpan="3"
                          className="pl-0 pr10 c-pointer border-0 pb-0 pt20"
                        >
                          <label
                            className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 c-pointer h50 py30"
                            style={{
                              border: '2px dashed rgb(132, 134, 136)',
                            }}
                            onClick={this.addMoreSizes.bind(this)}
                          >
                            Add another size +
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                onClick={this.addSizesSubmit.bind(this)}
              >
                Add
              </div>
              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
          : null}
        </div>
      </div>
        <Confirmation
          title="Delete Item"
          message="Deleting item will remove it from the database and will have to be added back manually."
          confirmText="Delete item"
          cancelText="Cancel"
          confirm={this.deleteItem.bind(this)}
          cancel={() => {
            $('confirmModal').modal('hide')
          }}
        />
        <div className=" animated fadeIn"
      >

        <div className="row mt30">
          <div className="col-md-4">
            <div className="form-group m-0">
              <h6 className="mb10">Search</h6>    
              <input
                type="text"
                name="search"
                id="search"
                className="form-control"
                placeholder="Start typing name, catalogue #…"
                ref={el => (this.searchKeyword = el)}
                onChange={search}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group m-0">
              <h6 className="mb10">Filter instruments by category</h6>
              <select
                className="form-control"
                onChange={this.categoryChange.bind(this)}
              >
                <option value="all">All</option>  
                {categories}
              </select>
            </div>
          </div>

          {this.state.isEditMode ? (
            <div className="col ml-auto text-right">
              <h6 className="mb10">&nbsp;</h6>
              <a
                href="#"
                className="btn btn-outline-dark px30 mr20"
                onClick={event => {
                  event.preventDefault()
                  this.props.setIsCreate(true)
                }}
              >
                Register new item
              </a>

              <a
                href="#"
                className="btn btn-dark ml10 px30"
                onClick={() => {
                  this.setState({
                    isEditMode: false
                  })
                  this.props.setIsCreate(false)
                  }}
              >
                Update
              </a>
            </div>
          ) : (
              <div className="col ml-auto text-right">
                <h6 className="mb10">&nbsp;</h6>
                <a
                  href="#"
                  className="btn btn-outline-dark px30 mr30"
                  onClick={()=>{this.setState({isEditMode:true})}}
                >
                  Edit database
              </a>
                <div className="btn p-0">
                  {this.state.cardView ? (
                    <i
                      className="fa fa-list fa-2x"
                      onClick={this.changeView.bind(this)}
                    />
                  ) : (
                      <i
                        className="fa fa-th-large fa-2x"
                        onClick={this.changeView.bind(this)}
                      />
                    )}
                </div>
              </div>
            )}

        </div>
        <div className="row mt20 mb30">
          <div className="col-md-12">

            <InfiniteScroll
              className=""
              dataLength={this.state.data.length} //This is important field to render the next data
              next={this.loadMoreData.bind(this)}
              hasMore={true}
            >
              {this.renderView()}
            </InfiniteScroll>
          </div> 
          <div className="col-md-12">
            {this.renderLoading()}
          </div> 
        </div>
      </div>
      <ViewItemModal
        key={this.state.unique}
        item={this.state.selectedItem}
        cancel={() => {
          $('viewItemModal').modal('hide')
        }}
      />               

      </div>
    )
  }
}

export default Items
