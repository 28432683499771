import React, { Component } from 'react'

export class TextPreview extends Component {
  render() {
    const { content } = this.props
    return (
      <div className="card p-0 shadow-sm border-0 mt30"> 
        <div className="card-header bg-white p15 border-0" id="note-headeing-1">
          <h5 className="mb-0 d-flex align-items-center">
            {this.props.content.title}
          </h5>
        </div>
        <div className="card-body pt-0 mt5 px15">
          <div
            dangerouslySetInnerHTML={{
              __html: content.contents.caption
            }}
          />
        </div>
      </div>
    )
  }
}

export default TextPreview
