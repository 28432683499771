import React, { Component } from 'react'
import AssignItemsProcedureInstrument from '../modals/AssignItemsProcedureInstrument'
import AssignTraysProcedureInstrument from '../modals/AssignTraysProcedureInstrument'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router'
import $ from 'jquery'
import axios from 'axios'
import { TextInput } from '../commons/forms'
import { getError } from '../../utilities'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import ViewItemModal from '../modals/ViewItemModal';
import Confirmation from '../Confirmation'
import InlineNotification from '../commons/notifier/InlineNotification'
import CollapsePanel from '../commons/panel/CollapsePanel'
import Notifier from '../commons/notifier/Notifier';

const SortableItem = SortableElement(({ category, assignNewState, actions }) => (
  <li className="mb10 font-weight-bold d-flex align-items-center align-items-stretch flex-column flex-md-row">
    <div className="font-weight-bold d-flex align-items-center align-items-stretch flex-fill max-w300 mr15 order-2 order-md-1">
      <div className="w50 bg-dark p10 text-center rounded-left c-pointer d-flex justify-content-center align-items-center">
        <i className="fa fa-arrows fs18 text-white" />
      </div>
      <div className="main-card rounded flex-fill max-w250 d-flex align-items-center p10">
        <span className="pl10 work-break-all">{category.name}</span>
      </div>
    </div>

    {actions ? <a href="#"
      className="mr30 m-xsx0 d-flex align-items-center order-1 order-md-2 justify-content-end max-w300 m-xsy5"
      data-toggle="modal"
      data-target="#confirmationDeleteSectionModal"
      onClick={(e) => {
        e.preventDefault()
        assignNewState({
          section: category
        })
      }}
    >
      Remove
    </a> : ''}
    
  </li>
))

const SortableList = SortableContainer(({ categories, assignNewState, actions = 1}) => {
  return (
    <ul className="list-unstyled mb-0">
      {categories.map((category, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            category={category}
            assignNewState={assignNewState}
            actions={actions}
          />
        )
      })}
    </ul>
  )
})

export class InstrumentsLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      selected_trays: [],
      tray: {},
      section: {},
      section_name: '',
      errors: [],
      addSection: false,
      detail_stage: [],
      custom_sections: [],
      custom_trays: [],
      edit: false,
      change: false,
      selectedItem: null,
      draft_success: false,
      showNotification: false,
      notificationMessage: ''
    }
    this.toggleShow = this.toggleShow.bind(this)

  }

  toggleShow(val, message = '') {
    this.setState({
      showNotification: val,
      notificationMessage: message
    })
  }

  componentDidMount = () => {
    const { trays } = this.props
    if (trays) {
      this.setState({
        selected_trays: trays,
      })
    }
    this.scrollToTop()
    
    setTimeout(() => {
      $('[data-toggle="popover"]').popover()
      $('.popover').popover('hide')
    }, 200);
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }
  
  assignNewState = state => {
    this.setState(state)
  }

  onSortEndTray = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ selected_trays }) => ({
        selected_trays: arrayMove(selected_trays, oldIndex, newIndex),
      }),
      () => {
        // this.saveSorting()
      }
    )
  }

  onSortEndSection = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ custom_sections }) => ({
        custom_sections: arrayMove(custom_sections, oldIndex, newIndex),
      }),
      () => {
        // this.saveSorting()
      }
    )
  }

  componentWillReceiveProps = nextProps => {
    if(this.props.stage_master != nextProps.stage_master){
      if(!nextProps.stage_master.draft){
        this.setState({
          edit: false,
          item_list: nextProps.item_list
        })
      }
    }

    if (!this.state.selected_flag) {
      this.generateSelected(nextProps.stage_details)
    }
    
    if (nextProps.stage_details != this.props.stage_details) {
      this.setState({
        detail_stage: nextProps.stage_details.filter(item => {
          return item.default_value == 1
        }),
        custom_sections: nextProps.stage_details.filter(item => {
          return item.default_value == 0 && item.tray_id == 0
        }),
        selected_trays: nextProps.trays ? nextProps.trays : [],
      })
    }
  }

  addSection = () => {
    const { stage_master } = this.props

    const data = {
      name: this.state.section_name,
      stage_id: stage_master.id,
    }
    axios
      .post(`api/admin/stage-instrument`, data)
      .then(result => {
        this.setState({
          addSection: false,
          section_name: ''
        })
        this.props.getProcedureDetail(1)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.reponse.data.errors
        })
      })
  }

  editSection = () => {
    const { stage_master } = this.props

    const data = {
      name: this.state.section_name,
    }

    let url = `api/admin/stage-instrument/${this.state.section.id}`
    axios
      .post(url, data)
      .then(result => {
        this.setState({
          create_category: false
        })
        this.props.getProcedureDetail(1)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.reponse.data.errors
        })
      })
  }

  deleteSection = () => {
    const { section } = this.state

    const data = {
      _method: 'DELETE',
    }

    let url = `api/admin/stage-instrument/${this.state.section.id}`
    axios.post(url, data).then((res) => {
      this.props.getProcedureDetail(1)
    }).catch((err) => {
      console.log(err)
      console.log(err.reponse)
    })
  }
  
  generateSelected = stage_details => {
    let temp = []
    stage_details.map(detail => {
      if (detail.tray_id == 0) {
        if (detail.items) {
          detail.items.map(item => {
            item.item_category = detail.id
            temp.push(item)
          })
        }
      }
    })

    this.setState({
      selected_items: temp,
    })
  }

  setNewstate = state => {
    this.setState(state)
  }

  subtractQty = (item, index) => {
    let { selected_items } = this.state

    if (item.qty > 1) {
      item.qty -= 1
    }

    selected_items[index] = item
    this.setState({
      selected_items: selected_items,
    })
  }

  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.setState({selected_items: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  sortByTray(key) {
    let arrayCopy = [...this.state.selected_trays]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareByTray(key))
    } else {
      arrayCopy.sort(this.compareByTray(key)).reverse()
    }

    this.setState({selected_trays: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareByTray(key) {
    if (key == 'specialty') {
      return function (a, b) {
        if (a.specialty.name < b.specialty.name) return -1
        if (a.specialty.name > b.specialty.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  addQty = (item, index) => {
    let { selected_items } = this.state

    item.qty += 1

    selected_items[index] = item
    this.setState({
      selected_items: selected_items,
    })
  }

  updateInstrument = () => {
    const { stage_master } = this.props
    let data = new FormData()
    data.append('_method', 'PATCH')

    let url = `/api/admin/stage-instrument-update/${stage_master.id}`

    axios.post(url, data).then((res) => {
      this.props.getProcedureDetail(0)
      this.setState({
        edit: false,
        draft_success: false,
        showNotification: true,
        notificationMessage: 'Instruments saved!'
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.reponse)
    })
  }

  editContent = () => {
    let itemlist = this.state.selected_items.map((item, index) => {
      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })
      let section = this.state.detail_stage.find((e) => {
        return e.id == item.item_category
      })

      if(!section){
        section = this.state.custom_sections.find((e) => {
          return e.id == item.item_category
        })
      }
      return (
        <tr>
          <td className="w150 px10">{item.catalog_number}</td>
          <td
            className="w250 font-weight-bold c-pointer text-dark fs-16 px10"
            data-container="body"
            data-trigger="hover"
            data-toggle="popover"
            data-placement="right"
            data-html="true"
            data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            onClick={() => {
              this.setState(
                {
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                },
                () => {
                  $(`#${this.state.unique}`).modal('show')
                }
              )
            }}
          >
            {item.name}
          </td>
          <td className="text-left w150 px10">
            <p className="m-0 p-0 float-left w-100 fs14">{section ? section.name : '-'}</p>
          </td>
          <td className=" text-center w80 px10">
            <p className="m-0 p-0 float-left w-100 fs14">{item.size} </p>
          </td>
          <td className=" text-center w80 d-flex px10">
            <p className="m-0 p-0 float-left w-100 fs14">{item.qty} </p>
          </td>
        </tr>
      )
    })

    let traylist = this.state.selected_trays.map(tray => {
      return (
        <tr>
          <td>{tray.name}</td>
          <td>{tray.specialty ? tray.specialty.name : ''}</td>
        </tr>
      )
    })

    let sectionlist = this.state.detail_stage.map(category => {
      if (category.items && category.default_value == 1) {
        return (
          <li className="mb10 font-weight-bold d-flex align-items-center">
            <div className="main-card rounded w300 d-flex align-items-center mr15 p10">
              <span className="pl10">{category.name}</span>
            </div>
          </li>
        )
      }
    })

    return (
      <div className="row mt40">
        <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center">
          <h3 className="mb20 h2med">Editing - Instruments</h3>
        </div>
        <div className="col-md-12 mx-auto">
          <ul className="list-unstyled d-inline-flex align-items-center main-card w-100 justify-content-end toolbar-options flex-wrap">
            <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center">
              <a
                href="#"
                className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 m-xsx10 "
                onClick={() => {
                  this.setState({ edit: false, draft_success: false }, () => { this.props.getProcedureDetail()})
                }}
              >
                Cancel
              </a>
            </li>
            <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center border-md-0">
              <a
                href="#"
                className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 m-xsx10 "
                onClick={(e) => {
                  e.preventDefault()
                  this.setState({
                    draft_success: true,
                    showNotification: true,
                    notificationMessage: 'Saved to draft!'
                  })
                }}
              >
                Save
              </a>
            </li>
            <li className="d-flex align-items-center flex-fill my0 px15 border-xs-0 text-center justify-content-md-start justify-content-lg-center justify-content-xl-center justify-content-center">
              <div
                className="btn btn-primary btn-sm px30 p-xsx10 "
                onClick={(e) => {
                  e.preventDefault()
                  this.updateInstrument()
                }}
              >
                Update
              </div>
            </li>
          </ul>
        </div>

        {/* {this.state.draft_success ? <InlineNotification message={'Saved to drafts!'} /> : null} */}

        <div className="col-md-12 mx-auto mb30">

          <Notifier show={this.state.showNotification && this.state.draft_success && this.state.edit} message={this.state.notificationMessage} type="success" styleClass="mb30 mt10"
            toggleShow={this.toggleShow} />
            
          <div className="card">
            <div className="card-body pb-0">
              <h5 className="mb15 font-weight-bold fs20">Jump to a section</h5>
            </div>
            <div className="card-body pt-0 pb15">
              <ul className="list-unstyled">
                {sectionlist}

                <SortableList
                  categories={this.state.custom_sections}
                  onSortEnd={this.onSortEndSection}
                  pressDelay={150}
                  assignNewState={this.assignNewState}
                />

                <SortableList
                  categories={[
                    ...this.state.custom_trays,
                    ...this.state.selected_trays,
                  ]}
                  actions={0}
                  onSortEnd={this.onSortEndTray}
                  pressDelay={150}
                  assignNewState={this.assignNewState}
                />
                {this.state.addSection ? (
                  <li>
                    <div className="form-group mt20">
                      <div className="d-flex flex-column flex-md-row">
                        <TextInput
                          label={'New section name'}
                          value={this.state.section_name}
                          placeholder={'Name'}
                          errorMessage={getError(this.state.errors, 'name')}
                          containerClass={`mb0 max-w300 `}
                          onValueChange={event => {
                            this.setState({ section_name: event.target.value })
                          }}
                        />
                        <div className="d-flex align-items-end flex-column flex-md-row max-w300">
                          {this.state.section.id ?
                            <div className="btn btn-dark mx15 m-xst20 max-w300 m-xsx0"
                              onClick={() => {
                                this.editSection()
                              }}
                            >Save</div>
                            :
                            <div className="btn btn-dark mx15 m-xst20 max-w300 m-xsx0"
                              onClick={() => {
                                this.addSection()
                              }}
                            >Save</div>
                          }
                          <div className="btn btn-outline-dark m-xst20" onClick={() => { this.setState({ create_category: false, errors: [] }) }}>Cancel</div>
                        </div>
                      </div>
                    </div>
                  </li>
                
                ) : (

                  <li className="media d-flex mb10 align-items-center">
                    <div
                      className="p20 rounded text-center w300 bodybold"
                      style={{ border: '2px dashed #979797' }}
                      onClick={() => {
                        this.setState({ addSection: !this.state.addSection, section: {}, section_name: '' })
                      }}
                    >
                      Add new section +
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-12 mx-auto mb30">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="mb20 fs20 sticky-top bg-white d-flex align-items-md-center justify-content-start justify-content-md-between flex-column flex-md-row">
                <span>
                  Item list
                </span>
                <div
                  className="btn btn-outline-primary ml-auto m-xst20"
                  data-toggle="modal"
                  data-target="#assignItemsModalCenter"
                  onClick={() => {
                    this.setState({ change: !this.state.change })
                  }}
                >
                  Assign items
                </div>
              </h5>
              <div className="w-100 of-auto">
                <table className="m-0 table table-hover table-striped min-table-width-775 table-fixed">
                  <thead>
                    <tr>
                      <th className="w150 px10" onClick={() => {
                        this.sortBy('catalog_number')
                      }}>Catalogue #
                        <i className="fa fa-sort ml5"></i>
                      </th>
                      <th className="w250 px10" onClick={() => {
                        this.sortBy('name')
                      }}>Name
                        <i className="fa fa-sort ml5"></i>
                      </th>
                      <th className="w150 px10" onClick={() => {
                        this.sortBy('item_category')
                      }}>Section
                        <i className="fa fa-sort ml5"></i>
                      </th>
                      <th className="w80 px10 text-center" onClick={() => {
                        this.sortBy('size')
                      }}>Size
                        <i className="fa fa-sort ml5"></i>
                      </th>
                      <th className="w80 px10 text-center" onClick={() => {
                        this.sortBy('qty')
                      }}>Qty
                        <i className="fa fa-sort ml5"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{maxHeight: 400}}>{itemlist}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mx-auto mb30">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="mb20 fs20 sticky-top bg-white d-flex align-items-md-center justify-content-start justify-content-md-between flex-column flex-md-row">
                <span>
                  Trays
                </span>
                <div
                  className="btn btn-outline-primary ml-auto m-xst20"
                  data-toggle="modal"
                  data-target="#assignTraysModal"
                >
                  Assign trays
                </div>
              </h5>
              <div className="w-100 of-auto">
                <table className="m-0 table table-hover table-striped min-table-width-500">
                  <thead>
                    <tr>
                      <th onClick={() => {
                        this.sortByTray('name')
                      }}>Tray name <i className="fa fa-sort ml5"></i></th>
                      <th className="w300" onClick={() => {
                        this.sortByTray('specialty')
                      }}>Specialty <i className="fa fa-sort ml5"></i></th>
                    </tr>
                  </thead>
                  <tbody>{traylist}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  sectionContent = (itemlist) => {
    return(
      <div className="w-100 of-auto">
        <table className="m-0 table table-hover table-striped min-table-width-640">
          <thead>
            <th className="w150">Catalogue #
            </th>
            <th className="w280">Name
            </th>
            <th className="w100 text-center">Size
            </th>
            <th className="w100 text-center">Qty
            </th>
          </thead>
          <tbody>
            {itemlist}
          </tbody>
        </table>
      </div>
    )
  }

  mainContent = () => {
    const { stage_details } = this.props
    console.log('p1p1p1', stage_details)
    let headerlist = stage_details.map(detail => {
      if (detail.items ? detail.items.length : false) {
        return (
          <li className="list-inline-item pr20 fs16 mb15 mx-0">
            <a href={`#${detail.name}`}>{detail.name}</a>
          </li>
        )
      }
    })

    let detaillist = stage_details.map((detail, index) => {
      if (detail.items ? detail.items.length : false) {
        let itemlist = detail.items.map(item => {
          let tags = item.tags.map(tag => {
            return ReactDOMServer.renderToStaticMarkup(
              <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
                {tag.name}
              </span>
            )
          })
          return (
            <tr>
              <td className="w150">{`${item.catalog_number}`}</td>
              <td
                className="w280 font-weight-bold c-pointer text-dark fs-16 px12"
                data-container="body"
                data-trigger="hover"
                data-toggle="popover"
                data-placement="right"
                data-html="true"
                data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
                onClick={() => {
                  this.setState(
                    {
                      selectedItem: item,
                      unique:
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15),
                    },
                    () => {
                      $(`#viewItemInstrumentsLayout`).modal('show')
                    }
                  )
                }}>
                {`${item.name}`}
              </td>
              <td className="w100 text-center">{`${item.size}`}</td>
              <td className="w100 text-center">{`${item.qty}`}</td>
            </tr>
          )
        })

        return (
          <div className="w-100 float-left"> 
            {detail.tray_id ? 
              <div className="col-md-12 mx-auto mb30" id={detail.name}>
                <CollapsePanel
                  idPanel={`tray-panel-${detail.id}`}
                  className={'mb30'}
                  title={detail.name}
                  content={this.sectionContent(itemlist)}
                />
              </div>
              :
              <div className="col-md-12 mx-auto mb30" id={detail.name}>
                <div className="card rounded-0">
                  <div className="card-body">
                    <h5 className="mb20 fs20 sticky-top bg-white">{detail.name}</h5>
                    <div className="w-100 of-auto">
                      <table className="m-0 table table-hover table-striped min-table-width-640">
                        <thead>
                          <th className="w150">Catalogue #
                            <i className="fa fa-sort ml5"></i>
                          </th>
                          <th className="w280">Name
                            <i className="fa fa-sort ml5"></i>
                          </th>
                          <th className="w100 text-center">Size
                            <i className="fa fa-sort ml5"></i>
                          </th>
                          <th className="w100 text-center">Qty
                            <i className="fa fa-sort ml5"></i>
                          </th>
                        </thead>
                        <tbody>{itemlist}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
         
          // <div className="col-md-12 mx-auto mb30" id={detail.name}>
          //   <div className="card shadow-sm">


          //     <div className="card-body">
          //       <h5 className="mb20 fs20 sticky-top bg-white">{detail.name}</h5>
               
          //     </div>

          //   </div>
          // </div>
        )
      }
    })

    return (
      <div className="row mt40">
        <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center">
          <h3 className="mb20 h2med">Instruments</h3>
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              this.setState({
                edit: true,
              })
              this.props.getProcedureDetail(1)
            }}
          >
            {this.props.title} Edit
            
          </a>
        </div>
        <div className="col-md-12 mx-auto mb30">
          <Notifier show={this.state.showNotification && !this.state.draft_success} message={this.state.notificationMessage} type="success" styleClass="mb30 mt10"
            toggleShow={this.toggleShow} />

          <div className="card rounded-0">
            <div className="card-body pb-0">
              <h5 className="mb15 font-weight-bold fs20">Jump to a section</h5>
            </div>
            <div className="card-body pt-0 pb15">
              <ul className="list-inline mb-0">{headerlist}</ul>
            </div>
          </div>
        </div>
        
        {detaillist}
      </div>
    )
  }

  scrollToTop = () => {
    $('.scroll-to-top').fadeOut(0)
    $(window).scroll(function () {
      if ($(this).scrollTop() > 350) {
        $('.scroll-to-top').fadeIn()
      } else {
        $('.scroll-to-top').fadeOut()
      }
    })
 
    $(document).on('click', '.scroll-to-top', function () {
      $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 0)
      return false
    })
    setTimeout(() => {
      $('[data-toggle="popover"]').popover()
      $('.popover').popover('hide')
    }, 200);
  }

  render() {
    return (
      <div className="col-lg-9 mx-auto">

        <div className="scroll-to-top c-pointer">
          <i className="fa fa-chevron-up fs20" />
        </div>
        {this.state.edit ? this.editContent() : this.mainContent()}
        <AssignItemsProcedureInstrument
          assignNewState={this.assignNewState}
          selected_items={this.state.selected_items}
          change={this.state.change}
          item_categories={this.props.stage_details}
          stage_master={this.props.stage_master}
        />
        <AssignTraysProcedureInstrument
          assignNewState={this.assignNewState}
          selected_trays={this.state.selected_trays}
          change={this.state.change}
          item_categories={this.props.stage_details}
          stage_master={this.props.stage_master}
          getProcedureDetail={this.props.getProcedureDetail}
        />

        <Confirmation
          idModal="confirmationDeleteSectionModal"
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.deleteSection()
          }}
          cancel={() => {
            $('#confirmationModal').modal('hide')
          }}
        />
        <ViewItemModal
          idModal="viewItemInstrumentsLayout"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemInstrumentsLayout').modal('hide')
          }}
        />    
      </div>
    )
  }
}

export default InstrumentsLayout
