import React, { Component } from 'react'
import { Image } from '../commons/elements/inputs'
import { connect } from 'react-redux'
import { positioningUpdate } from '../../actions'
import { getError } from '../../utilities'


class Images extends Component {
  
  onMediaChange = (event, prop) => {
  
    const value = event.data

    this.props.positioningUpdate({ prop, value })
  }

  error = (error) => {
    if(error.length){
      return(
        <span className="error-state-icon error">
          <i className="mdi mdi-information mdi-rotate-180" />
          {error}
        </span>
      )
    }
  }
  
  render() {
    const { position_image,
      pressure_map_image} = this.props
    return (
      <div className="row mb30">

        {this.error(getError(this.props.errors, 'positioning_image'))}
        <Image 
            label={`Positioning image`}
            media_data={ {
                source:position_image
            }}
            aspectRatio={3 / 2}
            onMediaChange={ (event) => {
              this.onMediaChange(event,'position_image')
            }}
        />

        {this.error(getError(this.props.errors, 'pressure_map_image'))}
        <Image 
            label={`Pressure map`}
            media_data={ {
                source:pressure_map_image
            }}
            aspectRatio={3 / 2}
            onMediaChange={ (event) => {
              this.onMediaChange(event,'pressure_map_image')
            }}

        />
      </div>
    )
  }
}


const mapStateToProps = ({ positioning }) => {
  const {  position_image,pressure_map_image } = positioning
  return { position_image,pressure_map_image }
}

export default connect(
  mapStateToProps,
  { positioningUpdate }
)(Images)
