import React, { Component } from 'react'
import {
  CardSection,
  PositionAreas,
} from '../../components/positioning_equipments'
import $ from 'jquery'
import { connect } from 'react-redux'
import {
  getPositions,
  getPositioningDetailsProcedure,
  setPositioningEquipmentDetails
} from '../../actions/PostioningActions'
import { Media, MediaPreview } from '../../components/commons/elements/display'
import PositioningEquipmentEditLayout from './PositioningEquipmentEditLayout'
import { positioningUpdate } from '../../actions'



class PositioningEquipmentLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      position: '',
      selected_item: null,
      edit: false,
      set: true,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount() {
    this.getPositioning()
    $('.scroll-to-top').fadeOut(0)
    $('.surgeon-card').click(function() {
      window.location.href = 'C:/corh/corh_home/surgeon_profile.html'
    })
    $('td').click(function() {
      var prop = $(this)
        .find('input')
        .prop('checked')
      $(this)
        .find('input')
        .prop('checked', prop == true ? false : true)
    })

    $(window).scroll(function() {
      if ($(this).scrollTop() > 350) {
        $('.scroll-to-top').fadeIn()
      } else {
        $('.scroll-to-top').fadeOut()
      }
    })
    //Click event to scroll to top
    $(document).on('click', '.scroll-to-top', function () {
      $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 0)
      return false
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.stage_master != nextProps.stage_master){
      if(!nextProps.stage_master.draft){
        this.setState({
          edit: false
        })
      }
    }
  }

  setNewState = (state) => {
    this.setState(state)
  }

  positioningContent = () => {
    const {stage_details} = this.props
    const { position } = this.state
    if (stage_details && stage_details.details) {
      const arrayDetails = Object.values(stage_details.details).splice(0)
      let area_list = arrayDetails.map(detail => {
        return (
          <CardSection
            data={{
              name: detail[0].position.name,
              code: detail[0].position.code,
              tableData: detail,
            }}
            onViewMedia={ (event,item) =>{
              event.preventDefault()
              this.setState({
                selected_item: item, 
                unique:
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15)}, 
                () =>{
                $('#viewMedia').modal('show')
              })
            }}
          />
        )
      })
      return (
        <div className="row">
          <div className="col-md-12">
            <h4 className="h3bold mb0 d-flex align-items-end flex-column flex-md-row">
              <span className="mr-auto">
                Position {this.props.stage_details.position.name}
              </span>
              <a
                className="btn btn-link ml-auto p-0 align-items-end text-dark m-xst20"
                onClick={(e) => {
                  e.preventDefault()
                  this.props.getPositioningDetailsProcedure(this.props.stage_details.master.id, 1)
                  this.props.getProcedureDetail(1)
                  this.setState({
                    edit: true
                  })
                }}
              >
            {this.props.title} Edit
               
              </a>
            </h4>
          </div>
          <div className="col-md-12 profile-card pt-0">
          
            <div className="accordion" id="itemListAccordion">
              <PositionAreas details={ Object.values(this.props.stage_details.details).splice(0)} />
              {area_list}
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  viewPositionMedia = () => {
    const { selected_item } = this.state
    if (selected_item){
      return (
        <MediaPreview
          idModal='viewMedia'

          key={this.state.unique}
          title={`Viewing the media of [${selected_item.item.name}]`}
          data={selected_item}
        />
      )
    }else{
      return null;
    }
  }
  getPositioning = () => {
    const {stage_details} = this.props
    if(stage_details && stage_details.position){
      Object.keys(stage_details.position).forEach(e => {
        this.props.positioningUpdate({
          prop: e,
          value: stage_details.position[e],
        })
      })
    }
   
  }

  view = () => {
    const { positions } = this.props

    return (
      <div className="row mt40">
      {this.viewPositionMedia()}
          <div className="col-md-12">
            <h1 className="h2med mb20">
              Positioning equipment page
            </h1>
          </div> 
          <div className="col-md-12">
            <div className="scroll-to-top c-pointer">
              <i className="fa fa-chevron-up fs20" />
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* <Preview widthStyle="col-md-12" /> */}
              </div>
            </div>
            {this.positioningContent()}
          </div> 
      </div>
    )
  }

  edit = () => {
    return(
      <PositioningEquipmentEditLayout 
        setNewState={this.setNewState} 
        position={this.props.stage_details.position} 
        position_id={this.props.stage_details.master.id}  
        getProcedureDetail={this.props.getProcedureDetail}
        stage_details={this.props.stage_details}
        stage_master={this.props.stage_master}
        />
    )
  }

  render() {
    return (
      <div className="col-lg-9 mx-auto">
      {this.state.edit ? this.edit() : this.view()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    positions: state.positioning.positions,
    position_details: state.positioning.position_details,
    position_master: state.positioning.position_master,
  }
}

export default connect(
  mapStateToProps,
  { getPositions, getPositioningDetailsProcedure, setPositioningEquipmentDetails,positioningUpdate }
)(PositioningEquipmentLayout)
