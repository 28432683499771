import React, { Component } from 'react'
import $ from 'jquery'
import ViewItemModal from '../modals/ViewItemModal'
import Notifies from './Notifies'
class Notification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedItem: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
    $('#viewItemModal').modal('hide')
  }

  render() {
    return (
      <div className="col-md-8">
        <ViewItemModal
          item={this.state.selectedItem}
          key={this.state.unique}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />
        <h1 className="font-weight-bold mb20">The Lounge</h1>
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item mr30">
                <a
                  className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs20 active"
                  id="supplies-tab"
                  data-toggle="tab"
                  href="#supplies"
                  role="tab"
                  aria-controls="supplies"
                  aria-selected="true"
                  style={{ textDecoration: 'none' }}
                >
                  Activity logs
                </a>
              </li>
              <li className="nav-item mr30">
                <a
                  className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs20"
                  id="newly_added-tab"
                  data-toggle="tab"
                  href="#newly_added"
                  role="tab"
                  aria-controls="newly_added"
                  aria-selected="false"
                  style={{ textDecoration: 'none' }}
                >
                  Newly added
                </a>
              </li>
              <li className="nav-item mr30">
                <a
                  className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs20"
                  id="surgeon_updates-tab"
                  data-toggle="tab"
                  href="#surgeon_updates"
                  role="tab"
                  aria-controls="surgeon_updates"
                  aria-selected="false"
                  style={{ textDecoration: 'none' }}
                >
                  Surgeon updates
                </a>
              </li>
              {/*  <li className="nav-item mr30">
                <a
                  className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs20"
                  id="announcements-tab"
                  data-toggle="tab"
                  href="#announcements"
                  role="tab"
                  aria-controls="announcements"
                  aria-selected="true"
                  style={{ textDecoration: 'none' }}
                >
                  Announcements
                </a>
              </li> */}
            </ul>
            <div className="tab-content pt30">
              <div
                className="tab-pane animated fadeIn active"
                id="supplies"
                role="tabpanel"
                aria-labelledby="supplies-tab"
              >
                <div className="row">
                  <Notifies
                    data_uri="api/admin/my-notifications"
                    key={'activity-logs'}
                    onItemClick={item => {
                      this.setState(
                        {
                          selectedItem: item,
                          unique:
                            Math.random()
                              .toString(36)
                              .substring(2, 15) +
                            Math.random()
                              .toString(36)
                              .substring(2, 15),
                        },
                        () => {
                          console.log(item)
                          $('.pover').popover('hide')
                          $('#viewItemModal').modal('show')
                        }
                      )
                    }}
                  />
                </div>
              </div>
              <div
                className="tab-pane animated fadeIn"
                id="newly_added"
                role="tabpanel"
                aria-labelledby="newly_added-tab"
              >
                <div className="row">
                <Notifies
                    key={'newly-added-key'}
                    data_uri="api/admin/newly-added"
                    onItemClick={item => {
                      this.setState(
                        {
                          selectedItem: item,
                          unique:
                            Math.random()
                              .toString(36)
                              .substring(2, 15) +
                            Math.random()
                              .toString(36)
                              .substring(2, 15),
                        },
                        () => {
                          console.log(item)
                          $('.pover').popover('hide')
                          $('#viewItemModal').modal('show')
                        }
                      )
                    }}
                  />
                </div>
              </div>
              <div
                className="tab-pane animated fadeIn"
                id="surgeon_updates"
                role="tabpanel"
                aria-labelledby="surgeon_updates-tab"
              >
                <div className="row">
                <Notifies
                    key={'surgeon-updates-key'}
                    data_uri="api/admin/surgeon-updates"
                    onItemClick={item => {
                      this.setState(
                        {
                          selectedItem: item,
                          unique:
                            Math.random()
                              .toString(36)
                              .substring(2, 15) +
                            Math.random()
                              .toString(36)
                              .substring(2, 15),
                        },
                        () => {
                          console.log(item)
                          $('.pover').popover('hide')
                          $('#viewItemModal').modal('show')
                        }
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Notification
