import React, { Component } from 'react'
import { getMedicalInventories, getItemsPerCategory, setItems, getItems } from '../../actions/ItemAction'
import { getBodyAreas } from  '../../actions/PostioningActions'
import { updateQuicknotesItemList, incrementItemQty } from '../../actions/QuicknotesActions'
import ReactDOMServer from 'react-dom/server'
import ReviewItemsModal from './ReviewItemsModal'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import _ from 'lodash'
import $ from 'jquery'


export class AssignItemsModal extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      categories: [],
      selected_items: [],
      selected_items_review: [],
      inventory: null,
      category: null,
      // items: [],
      change: false,
      counters:{
        supplies: 0,
        instruments: 0,
        equipments: 0,
      }
    }
  }
  
  componentDidMount = () => {
    this.props.setItems([])
    
    setTimeout(() => {
      this.setSelected()
    }, 800)

    this.props.getMedicalInventories() 

    this.props.getItemsPerCategory('all', this.state.selected_items)

    this.props.getBodyAreas()
  } 

  componentWillReceiveProps = () => { 
    if(this.state.selected_items.length > 0){

      this.setState({
        selected_items: []
      })
  
      setTimeout(() => {
        this.setSelected()
      }, 800)
  
      this.props.getMedicalInventories()
    }
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      items:nextProps.items
    })
  }

  

  // com = () => {
  //   console.log(this.props.data)
  //   this.setSelected()
  // }

  setSelected = () => {
    let { selected_items } = this.state
    let { item_list } = this.props

    selected_items = []
    item_list.map((list, index) => {
      list.details.map((item) => {
        item.item.prn = null
        if(index == 0){
          item.item.prn = list.id
        }
        item.item.qty = item.qty
        selected_items.push(item.item)
      })
    })
    let a=0,b=0,c=0
    selected_items.map((data) => {
      if (data.category.medical_inventory_id == 1)
        a++;
      if (data.category.medical_inventory_id == 2)
        b++;
      
      if (data.category.medical_inventory_id == 3)
        c++;
    })
    this.setState({
      selected_items: [],
      counters: {
        supplies: a,
        instruments: b,
        equipments: c,
      }
    }, () => {
      this.setState({
        selected_items: selected_items.splice(0)
      })
    })
   
  }

  checkItem = (id) => {
    let { selected_items } = this.state
    const { items } = this.props

    let item = items.find((e) => e.id === id)
    if(item){
      if(!item.qty){
        item.qty = 1
      }
    }else{
      item = selected_items.find((e) => e.id === id)
    }
    
    if(selected_items.find((e) => e.id === id)){
      this.setState({
        selected_items: selected_items.filter(x => x.id !== id),
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? -1:0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? -1:0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? -1:0),
        },
      })
    }else{
      this.setState({
        selected_items: [...selected_items, item],
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? 1 : 0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? 1 : 0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? 1 : 0),
        },
      })
    }
  }

  checkItemExistence = (id) => {
    let { selected_items } = this.state

    let exist = false
    if(selected_items.filter((e) => e.id === id).length > 0){
      exist = true
    }else{
      exist = false
    }

    return exist
  }

  isChecked = (id) => {
    let { selected_items } = this.state
    const { items } = this.props

    let item_local = selected_items.find((e) => e.id === id)
    let item_store = items.find((e) => e.id === id)

    let checked = false
    if(item_local){
      if(item_local.prn){
        checked = true
      }
    }else{
      if(item_store){
        if(item_store.prn){
          checked = true
        }
      }
      
    }

    return checked
  }

  setPRN = (id) => { 
    let { selected_items } = this.state
    let { item_list, items } = this.props
    // // console.log(this.props.item_list)
    let prn_id = item_list[0].id

    let item_local = selected_items.find( x => x.id === id)

    selected_items.map((selected) => {
      items.map((item, index) => {
        if(selected.id == item.id){
          items[index] = selected
        }
      })
    })

    let item_store = items.find((e) => e.id === id)

    if(item_store){
      if(item_store.prn){ 
        item_store.prn = null
      }else{ 
        item_store.prn = prn_id
      }
       
      items[items.findIndex((e) => e.id === id)] = item_store

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_store
      this.setState({
        selected_items: selected_items
      })

    }else{
      if(item_local.prn){ 
        item_local.prn = null
      }else{ 
        item_local.prn = prn_id
      }

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_local
      this.setState({
        selected_items: selected_items
      })
    }
      

    
    this.setState({
      change: true
    })

    this.props.setItems(items)
  }

  changeQty = (id, type) => {
    let { selected_items } = this.state
    let { items } = this.props

    let item_local = selected_items.find((e) => e.id === id)

    selected_items.map((selected) => {
      items.map((item, index) => {
        if(selected.id == item.id){
          items[index] = selected
        }
      })
    })

    let item_store = items.find((e) => e.id === id)

    if(item_store){
      if(type == 'increment'){
        if(!item_store.qty){
          item_store.qty = 1
        }
        item_store.qty = item_store.qty + 1
      }else if(type == 'decrement'){
        if(!item_store.qty){
          item_store.qty = 1
        }
        if(item_store.qty > 1){
          item_store.qty = item_store.qty - 1
        }
      }
      items[items.findIndex((e) => e.id === id)] = item_store

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_store

    }else{
      if(type == 'increment'){
        if(!item_local.qty){
          item_local.qty = 1
        }
        item_local.qty = item_local.qty + 1
      }else if(type == 'decrement'){
        if(!item_local.qty){
          item_local.qty = 1
        }
        if(item_local.qty > 1){
          item_local.qty = item_local.qty - 1
        }
      }

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_local
      
    }

    this.setState({
      selected_items: selected_items,
      change: true

    })


    this.props.setItems(items)
  }

  getItemsPerCategory = (event) => {
    const { selected_items } = this.state
    this.props.getItemsPerCategory(event.target.value, selected_items)
  }

  filterByInventory = (event) => {
    let { selected_items } = this.state
    let { medical_inventories } = this.props
    this.setState({
      categories: []
    })
    
    if(event.target.value == "all"){
      this.setState({
        selected_items_review: selected_items,
        categories: []
      })
    }else{
      selected_items = _.filter(selected_items, (item) => {
        return item.category.medical_inventory_id == event.target.value
      })


      this.setState({
        selected_items_review: selected_items,
        categories: medical_inventories[event.target.value - 1].categories
      })
    }
  } 

  filterByCategory = (event) => {
    let { selected_items_review } = this.state

    selected_items_review = _.filter(selected_items_review, (item) => {
      return item.medical_category_id == event.target.value
    })
    
    this.setState({
      selected_items_review: selected_items_review,
    })

  }

  checkQty = (selected_items, item) => {
    let i = _.find(selected_items, (e) => {
      return e.id === item.id
    })
    if(i){
      return i.qty
    }else{
      if(item.qty)
        return item.qty
      return 1
    }
  }

  filterSelected = (event) => {
    let { selected_items_review, selected_items } = this.state

    if(event.target.value != ''){
      selected_items_review = []
    }else{
      selected_items_review = selected_items
    } 


    let query = event.target.value
    selected_items.map((item) => {
      if(item.name.includes(query) || item.catalog_number.includes(query) || item.company.includes(query)){
        selected_items_review.push(item)
      }
    })

    this.setState({
      selected_items_review: selected_items_review
    })

  }

  render() {
    const { medical_inventories, items } = this.props
    const { categories, counters, selected_items, selected_items_review } = this.state

    let inventories = medical_inventories.map((inventory, index) => {
      return(
        <option value={inventory.id}>{inventory.name}</option>
      )
    })
    if(categories.length == 0){
      if (medical_inventories[0]){
        this.setState({
          categories: medical_inventories[0].categories
        });
      }
    }
    let categorylist = categories.map((category) => {
      return(
        <option value={category.id}>{category.name}</option>
      )
    })

    let itemlist = items.map((item) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr>
          <td className="col-md-2" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>{item.catalog_number}</td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="col-md-3 px-1">
            <Link href={`/item/${item.id}`} target="_blank" className="text-dark fs-16" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}>
              {item.name}
            </Link>
          </td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.company} </p> </td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.category ? item.category.name : 'No Category'}</p> </td>
          <td className="px-0 text-center w60" style={{paddingLeft: '5px !important', paddingRight: '5px !important'}}>{item.size}</td> 
          <td className="w120 px-1" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>
            <div className="w-100 w300 d-flex align-items-center justify-content-center">
              <div className="btn btn-secondary mr0 btn-sm bg-secondary" onClick={() => { this.changeQty(item.id, 'decrement') }}><i className="fa fa-minus" /></div>
              <input type="text" name className="form-control text-center p-0 mx10 bg-white" value={this.checkQty(selected_items, item)} />
              <div className="btn btn-secondary btn-sm bg-secondary" onClick={() => { this.changeQty(item.id, 'increment') }}><i className="fa fa-plus" /></div>
            </div>
          </td>
          <td class="text-left w60 pr-0">
            <div class="custom-control custom-checkbox"
              style={{ marginRight: -35, paddingLeft: 25 }}>
              <input type="checkbox" class="custom-control-input c-pointer" id={`item-${item.id}`}
                checked={this.isChecked(item.id)}
                onChange={() => { this.setPRN(item.id) }} />                <label class="custom-control-label c-pointer" for={`item-${item.id}`}>&nbsp;</label>
            </div>
          </td>
          <td className="w80 text-center p-0">
            <a href="javascript:;" className={`h64 float-left justify-content-center align-items-center d-flex w-100 ${this.checkItemExistence(item.id) ? 'text-danger' : null}`} id={`assign-item-${item.id}`} onClick={() => { $(`#assign-item-${item.id}`).toggleClass('text-danger');this.checkItem(item.id)}}>{this.checkItemExistence(item.id) ? 'Remove' : 'Assign'}</a>
          </td>
        </tr>
      )
    })

    let selected_list = selected_items_review.map((item) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr>
          <td className="col-md-2" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>{item.catalog_number}</td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="col-md-3 px-1">
            <Link href={`/item/${item.id}`} target="_blank" className="text-dark fs-16" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}>
              {item.name}
            </Link>
          </td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.company} </p> </td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.category.name}</p> </td>
          <td className="px-0 text-center w60" style={{paddingLeft: '5px !important', paddingRight: '5px !important'}}>{item.size}</td> 
          <td className="w120 px-1" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>
            <div className="w-100 w300 d-flex align-items-center justify-content-center">
              <div className="btn btn-secondary mr0 btn-sm bg-secondary" onClick={() => { this.changeQty(item.id, 'decrement') }}><i className="fa fa-minus" /></div>
              <input type="text" name className="form-control text-center p-0 mx10 bg-white" value={item.qty ? item.qty : 1} />
              <div className="btn btn-secondary btn-sm bg-secondary" onClick={() => { this.changeQty(item.id, 'increment') }}><i className="fa fa-plus" /></div>
            </div>
          </td>
          <td class="text-left w60 pr-0">
            <div class="custom-control custom-checkbox"
              style={{ marginRight: -35, paddingLeft: 25 }}>
              <input type="checkbox" class="custom-control-input c-pointer" id={`item-${item.id}`}
                checked={this.isChecked(item.id)}
                onChange={() => { this.setPRN(item.id) }} />                <label class="custom-control-label c-pointer" for={`item-${item.id}`}>&nbsp;</label>
            </div>
          </td>
          <td className="w80 text-center p-0">
            <a href="javascript:;" className={`h64 float-left justify-content-center align-items-center d-flex w-100 ${this.checkItemExistence(item.id) ? 'text-danger' : null}`} id={`assign-item-${item.id}`} onClick={() => { $(`#assign-item-${item.id}`).toggleClass('text-danger');this.checkItem(item.id)}}>{this.checkItemExistence(item.id) ? 'Remove' : 'Assign'}</a>
          </td>
        </tr>
      )
    })

    return (
      <div>
      <div className="modal animated fadeInDown faster" id="assignItemsModalCenter" tabIndex={-1} role="dialog" aria-labelledby="assignItemsModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl px30" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30 pb15">
              <h5 className="m-0 mx-auto font-weight-bold">Assigning items 
                
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </h5>

            </div>
            <div className="modal-body px30 pt-0 pb30">

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item mr30">
                  <a class="nav-link active border-0 font-weight-bold bg-transparent px-0 py5 fs16" id="supplies-tab" data-toggle="tab" href="#supplies" role="tab" aria-controls="supplies" aria-selected="true" style={{ textDecoration: 'none' }} onClick={(event) => { this.setState({ categories: medical_inventories[0].categories }, () => {this.props.getItems(medical_inventories[0].id, 0, '')}) }}>Supplies 
                    <span className="badge badge-secondary badge-pill counter-pill ml15">{counters.supplies}</span>
                  </a>
                </li>
                <li class="nav-item mr30">
                  <a class="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs16" id="instruments-tab" data-toggle="tab" href="#instruments" role="tab" aria-controls="instruments" aria-selected="true" style={{ textDecoration: 'none' }} onClick={(event) => { this.setState({ categories: medical_inventories[1].categories }, () => {this.props.getItems(medical_inventories[1].id, 0, '')}) }}>Instruments
                    <span className="badge badge-secondary badge-pill counter-pill ml15">{counters.instruments}</span>
                  </a>
                </li>
                <li class="nav-item mr30">
                  <a class="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs16" id="equipments-tab" data-toggle="tab" href="#equipments" role="tab" aria-controls="equipments" aria-selected="true" style={{ textDecoration: 'none' }} onClick={(event) => { this.setState({ categories: medical_inventories[2].categories }, () => {this.props.getItems(medical_inventories[2].id, 0, '')}) }}>Equipments
                    <span className="badge badge-secondary badge-pill counter-pill ml15">{counters.equipments}</span>
                  </a>
                </li>
              </ul>
              <div class="tab-content">

                <div class="tab-pane animated fadeIn active" id="supplies" role="tabpanel" aria-labelledby="supplies-tab">

                  <div className="row py20">
                    <div className="col-md-4 form-group m-0">
                      <label className="mb10">Search</label>
                      <input type="text" name="search" className="form-control" 
                        placeholder="Start typing name, catalogue #..." 
                        onChange={(event) => {this.props.getItems(1, 0, event.target.value)}}
                      />  
                    </div> 
                    <div className="col-md-4 form-group m-0">
                      <label className="mb10">Filter by category</label>
                      <select className="form-control" onChange={(event) => { this.getItemsPerCategory(event) }}>
                        <option value="all" selected>All</option>
                        {categorylist}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-striped table-fixed border table-hover-bold">
                        <thead className="bg-dark text-white text-left">
                          <tr>
                            <th className="col-md-2 pr-1 pl15">Catalogue #</th>
                            <th className="col-md-3 px-1" >Name</th>
                            <th className="w125 px-1" >Company</th>
                            <th className="w125 px-1" >Category</th>
                            <th className="w60 px-0 text-center" >Size</th>
                            <th className="w120 text-center px-0" >Qty</th>
                            <th className="w60  text-center px-0" >PRN</th>
                            <th className="w80 px-0" />
                          </tr>
                        </thead>
                        <tbody style={{ float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '344px' }}>
                          {itemlist}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>

                <div class="tab-pane animated fadeIn" id="instruments" role="tabpanel" aria-labelledby="instruments-tab">

                  <div className="row py20">
                    <div className="col-md-3 form-group m-0">
                      <label className="mb10">Search</label>
                      <input type="text" name="search" className="form-control" 
                        placeholder="Start typing name, catalogue #..." 
                        onChange={(event) => {this.props.getItems(2, 0, event.target.value)}}
                      />  
                    </div>
                    <div className="col-md-3 form-group m-0">
                      <label className="mb10">Filter by category</label>
                      <select className="form-control" onChange={(event) => { this.getItemsPerCategory(event) }}>
                        <option value="" selected disabled hidden>Categories</option>
                        {categorylist}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-striped table-fixed border table-hover-bold">
                        <thead className="bg-dark text-white text-left">
                          <tr>
                            <th className="col-md-2 pr-1 pl15">Catalogue #</th>
                            <th className="col-md-3 px-1" >Name</th>
                            <th className="w125 px-1" >Company</th>
                            <th className="w125 px-1" >Category</th>
                            <th className="w60 px-0 text-center" >Size</th>
                            <th className="w120 text-center px-0" >Qty</th>
                            <th className="w60  text-center px-0" >PRN</th>
                            <th className="w80 px-0" />
                          </tr>
                        </thead>
                        <tbody style={{ float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '344px' }}>
                          {itemlist}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>

                <div class="tab-pane animated fadeIn" id="equipments" role="tabpanel" aria-labelledby="equipments-tab">

                  <div className="row py20">
                    <div className="col-md-3 form-group m-0">
                      <label className="mb10">Search</label>
                      <input type="text" name="search" className="form-control" 
                        placeholder="Start typing name, catalogue #..." 
                        onChange={(event) => {this.props.getItems(3, 0, event.target.value)}}
                      />  
                    </div>
                    <div className="col-md-3 form-group m-0">
                      <label className="mb10">Filter by category</label>
                      <select className="form-control" onChange={(event) => { this.getItemsPerCategory(event) }}>
                        <option value="" selected disabled hidden>Categories</option>
                        {categorylist}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-striped table-fixed border table-hover-bold">
                        <thead className="bg-dark text-white text-left">
                          <tr>
                            <th className="col-md-2 pr-1 pl15">Catalogue #</th>
                            <th className="col-md-3 px-1" >Name</th>
                            <th className="w125 px-1" >Company</th>
                            <th className="w125 px-1" >Category</th>
                            <th className="w60 px-0 text-center" >Size</th>
                            <th className="w120 text-center px-0" >Qty</th>
                            <th className="w60  text-center px-0" >PRN</th>
                            <th className="w80 px-0" />
                          </tr>
                        </thead>
                        <tbody style={{ float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '344px' }}>
                          {itemlist}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start">
                <div className="btn btn-dark mr30 mt-0 px30 mb-0 float-left" data-dismiss="modal" onClick={() => {
                this.setState({selected_items_review: this.state.selected_items, categories: []})
                //$('#assignItemsModalCenter').modal('hide'); 
                  $("#reviewItemsModalCenter").modal('show');
              }}>Review Items</div>
              <div className="btn btn-light border-dark px30 mt-0 mb-0 float-left" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>

      {/* Review Items modal */}
      <div className="modal" id="reviewItemsModalCenter" role="dialog" aria-labelledby="reviewItemsModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog  modal-xl px30" role="document">
          <div className="modal-content animated fadeIn">
            <div className="modal-body pt30 px30 pb15">
              <h5 className="mb20 mx-auto font-weight-bold">Review items </h5>
              <p className="mb20">Please review and confirm assigned items. <br />Items will be separated by PRN and General OR sections.</p>
              <div className="row pb20">   
                <div className="col-md-4 form-group m-0">
                  <label className="mb10">Search</label>
                  <input type="text" name="search" className="form-control" 
                    placeholder="Start typing name, catalogue #..." 
                    onChange={(event) => {this.filterSelected(event)}}
                  />  
                </div>
                <div className="col-md-4 form-group m-0">
                  <label className="mb10">Filter by Inventory</label>
                  <select className="form-control" onChange={(event) => { this.filterByInventory(event) }}>
                    <option value="all" selected>All</option>
                    {inventories}
                  </select>
                </div>
                <div className="col-md-4 form-group m-0">
                  <label className="mb10">Filter by category</label>
                  <select className="form-control" onChange={(event) => { this.filterByCategory(event) }}>
                    <option value="" selected disabled hidden>Categories</option>
                    {categorylist}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped table-fixed border">
                    <thead className="bg-dark text-white text-left">
                      <tr>  
                          <th className="col-md-2 pr-1 pl15">Catalogue #</th>
                          <th className="col-md-3 px-1" >Name</th>
                          <th className="w125 px-1" >Company</th>
                          <th className="w125 px-1" >Category</th>
                          <th className="w60 px-0 text-center" >Size</th>
                          <th className="w120 text-center px-0" >Qty</th>
                          <th className="w60  text-center px-0" >PRN</th>
                          <th className="w80 px-0" />
                      </tr>
                    </thead> 
                    <tbody style={{float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '388px'}}> 
                      {selected_list}
                    </tbody>
                  </table>
                </div>
              </div> 
            </div>
            <div className="modal-body px30 pt-0 pb30">
            </div>
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start"> 
              <div className="btn btn-dark mr30 mt-0 px30 mb-0 float-left" data-dismiss="modal" onClick={() => {
                this.props.updateQuicknotesItemList(this.state.selected_items)
                $('#assignItemsModalCenter').modal('hide')
              }}>Confirm assigned items</div>
                <div className="btn btn-light border-dark px30 mt-0 mb-0 float-left" data-dismiss="modal" onClick={() => { $('#assignItemsModalCenter').removeClass('animated fadeInDown faster').modal('show')}}>Back</div>
              <div className="btn btn-link px30 mt-0 mb-0 float-left" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>

      
      </div>


    )
  }
}



const mapStateToProps = (state) => {
  return({
    medical_inventories: state.item.medical_inventories,
    items: state.item.items,
    item_list: state.quicknotes.item_list,
    quicknotes_id: state.quicknotes.quicknotes_id
  })
}

export default connect(mapStateToProps, {getMedicalInventories, getItemsPerCategory, setItems, updateQuicknotesItemList, getItems, getBodyAreas})(AssignItemsModal)
