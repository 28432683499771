import React, { Component } from 'react'
import { Media } from '../../components/commons/elements/display'
import { connect } from 'react-redux'
import $ from 'jquery'
import { ViewInjuryModal } from '../../components/commons/elements/display/ViewInjuryModal';
import BigPictureModal from '../../components/modals/BigPictureModal';


class Preview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      selected_media: []
    }
  }

  previewInjuryModal() {
    const { positioning } = this.props
    if (!positioning) return null
    return (
      <ViewInjuryModal
        idModal="viewInjuryModal"
        key={this.state.unique}
        title={positioning.media_title}
        data={positioning.pressure_maps}
      />
    )
  }

  previewMedia() {
    return (
      <BigPictureModal
        idModal={`preview-${this.state.unique}`}
        data={this.state.selected_media}
      />
    )
  }

  render() {
    const { positioning, widthStyle } = this.props
    if (!positioning) return null
    return (
      <div className="row">
        {this.previewInjuryModal()}
        {this.previewMedia()}
        <div className={`pt20 mx-auto ${widthStyle ? widthStyle : 'col-md-9'}`}>
          <h4 className="h3bold mb0">
            {`${positioning.name}`}
          </h4>
        </div>
        <div className={`mx-auto profile-card pt-0 ${widthStyle ? widthStyle : 'col-md-9'}`}>
          <div className="accordion" id="itemListAccordion">
            <div className="card p-0 shadow-sm border-0 mt20">
              <div
                className="card-header bg-white p15 border-0"
                id="note-headeing-1"
              >
                <h5 className="h3med mb0">
                  Posititioning references
              </h5>
              </div>
              <div className="card-body pt-0 mt5 px15">
                <div className="row">
                  <div className="col-lg-6">
                    <label>Image</label>
                    <div className="image-div border">
                      <img
                        src={positioning.position_image}
                        className="w-100 h250 bg-dark  c-pointer"
                        onClick={() => {
                          this.setState({
                            selected_media: {
                              title: positioning.name,
                              type: 'image',
                              source: positioning.position_image,
                            }
                          }, () => {
                            $(`#preview-${this.state.unique}`).modal('show')
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 m-xst20 m-mdt20" >
                    <label>Pressure map</label>
                    <div className="image-div border">
                      <img
                        src={positioning.pressure_map_image}
                        className="w-100 bg-dark "
                        onClick={() => {
                          this.setState({
                            selected_media: {
                              title: positioning.name,
                              type: 'image',
                              source: positioning.pressure_map_image,
                            }
                          }, () => {
                            $(`#preview-${this.state.unique}`).modal('show')
                          })

                        }}
                      />
                    </div>
                    <label className="w-100 text-center pt20 m-0">
                      <a
                        href="javascript:;"
                        className="h4bold mx-auto"
                        onClick={() => {
                          this.setState({
                            unique:
                              Math.random()
                                .toString(36)
                                .substring(2, 15) +
                              Math.random()
                                .toString(36)
                                .substring(2, 15),
                          }, () => {

                            $('#viewInjuryModal').modal('show')
                          })
                        }}
                      >
                        View injury prevention information
                      </a>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/*     <div className="card p-0 shadow-sm border-0 mt30">
              <Media title={positioning.media_title} data={positioning.media} />
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ positioning }) => {
  return { positioning }
}
export default connect(
  mapStateToProps,
  {}
)(Preview)
