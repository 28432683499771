import React, { Component } from 'react'

class ItemCard extends Component {
  render() {
    const { itemData} = this.props
    return (
      <div className="col-md-3 mb30">
        <div className="card h-100">
          <div className="card-img-top h150 of-hidden d-flex align-items-center">
            <div className="image-div">
              <img src={itemData.icon} className=" bg-dark w-100" alt="..." />
            </div>
          </div>
          <div className="card-body text-center py15">
            <h5 className="card-title mb15 h3bold"><b>{itemData.name}</b></h5>
            <p className="card-text mb5 h5regular text-left d-flex"><span className="mr10 h5med">Catalog:</span><span >{itemData.catalog_number}</span></p>
            <p className="card-text mb5 h5regular text-left d-flex"><span className="mr10 h5med">Size:</span><span >{itemData.size}</span></p>
            <p className="card-text mb5 h5regular text-left d-flex"><span className="mr10 h5med">Company:</span><span >{itemData.company}</span></p>
            <p className="card-text mb5 h5regular text-left d-flex"><span className="mr10 h5med">Category:</span><span >{itemData.category.name}</span></p>
            {/* <h5 className="card-title fs20">{itemData.catalog_number}</h5>
            <h5 className="card-title mb15 fs20">{itemData.size}</h5>
            <p className="card-text">{itemData.company}</p>
            <h5 className="card-title mb15">{itemData.category.name}</h5> */}
          </div>
        </div>
      </div>
    )
  }
}

export default ItemCard
