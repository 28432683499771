import React, { Component } from 'react'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'

class RegisterProcedure extends Component { 

    constructor(props) {
        super(props)

        this.state = {
            specialties: [],
            categories: [],
            procedures: [],
            page: 1,
            loading: false,
            procedure: '',
            procedure_name: ''
        }
    }

    componentDidMount() {

        this.getSpecialties()
    }


    getSpecialties() {
        this.setState({ loading: 1 })
        let url = `/api/specialty`
        axios
            .get(url)
            .then(res => {
                let specialties = res.data.data
                //console.log('specialties', specialties)
                this.setState({
                    specialties: specialties,
                })
                this.setState({ loading: 0 })
            })
            .catch(error => {
                //console.log(error)
                //console.log(error.response)
            })
    }

    //get areas of specialty (specialty_id)
    getAreas(id) {
        this.setState({ loading: 1, categories: [], procedures: [] })
        let url = `/api/specialty-categories-by-specialty/${id}`
        axios
            .get(url)
            .then(res => {
                let categories = res.data.data
                //console.log('categories', categories)
                this.setState({
                    categories: categories,
                })
                this.setState({ loading: 0 })
            })
            .catch(error => {
                //console.log(error)
                //console.log(error.response)
            })
    }

    //get procedure of area (area_id)
    getProcedure(id) {
        this.setState({ loading: 1, })
        let url = `/api/specialty-procedures-by-category/${id}`
        axios
            .get(url)
            .then(res => {
                let procedures = res.data.data
                //console.log('procedures', procedures)
                this.setState({
                    procedures: procedures,
                })
                this.setState({ loading: 0 })
            })
            .catch(error => {
                //console.log(error)
                //console.log(error.response)
            })
    }

    
    render() {
        const specialties = this.state.specialties.map((specialty, index) => {
            return (
                <option
                    key={`specialty-${this.state.unique}-${index}`}
                    value={specialty.id}
                >
                    {specialty.name}
                </option>
            )
        })
        
        const categories = this.state.categories.map((category, index) => {
            return (
                <option
                    key={`category-${this.state.unique}-${index}`}
                    value={category.id}
                >
                    {category.name}
                </option>
            )
        })
 
        const procedures = this.state.procedures.map((procedure, index) => {
            return (
                <option
                    key={`procedure-${this.state.unique}-${index}`}
                    value={procedure.id}
                >
                    {procedure.name}
                </option>
            )
        })
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="font-weight-bold mb30">Register a procedure</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group mb-0">
                            <label>1. Select Specialty</label>
                            <select className="form-control"
                                onChange={event => {
                                    this.getAreas(event.target.value)
                                }}
                            >
                                <option>Select Specialty</option>
                                {specialties}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group mb-0">
                            <label>2. Select Category</label>
                            <select className="form-control"
                                onChange={event => {
                                    this.getProcedure(event.target.value)
                                }}
                            >
                                <option>Select Category</option>
                                {categories}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group mb-0">
                            <label>3. Select procedure</label>
                            <select className="form-control"
                                onChange={event=>{

                                    let procedure = _.find(this.state.procedures, (e) => { return e.id == event.target.value })
                                    this.setState({
                                        procedure_name: procedure.name,
                                    })
                                }}
                            >
                                <option>Select Procedure</option>
                                {procedures}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row my20">
                    <div className="col-md-12">
                        <h5>{this.state.procedure_name ? this.state.procedure_name: '[Name of procedure]'}</h5>  
                    </div>
                </div>

            </div>
        )
    }
}


export default RegisterProcedure