import React, { Component } from 'react'

export class SettingUserPermissions extends Component {
    
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="font-weight-bold mb30">User Permissions</h1>
                    </div>
                </div>
                <div className="row">
                
                </div>
            </div>
        )
    }
}

export default SettingUserPermissions
