let uri = ''

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  uri = `http://staging-api.corhsystem.com/`
} else {
  uri = `http://staging-api.corhsystem.com/`
}


export const BASE_URL = uri
