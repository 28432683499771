import React, { Component } from 'react'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import ImageCropper from './ImageCropper'
import $ from 'jquery'

class Image extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      file_raw: null,
      croppedFile: null,
      show: false,
      filename: '',
      type: 'image',
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    this.refs.file.click()
  }
  onFileChange = () => {
    if (this.refs.file) {
      $(`#imageCropperStandard${this.state.unique}`).modal({backdrop: 'static', keyboard: false, show: true})
      var reader = new FileReader()
      var url = reader.readAsDataURL(this.refs.file.files[0])
      reader.onloadend = () => {
        this.setState({
          file_raw: reader.result,
          type: 'image'
          // image: reader.result
        })
      }
    }
  }

  
  
  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    reader.onload = () => {
      this.props.onMediaChange({ data: reader.result, type: 'image' })
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        image: reader.result,
      })
    }
  }

  renderCropper() {
    const { file } = this.state
    const { media_data, previewStyle, size, textLabel } = this.props

    if (!file) {
      if (media_data.source) {
        return (
          <label
            className={previewStyle ? previewStyle : "d-flex justify-content-center align-items-center bg-light of-hidden m-0 h250 preview-media-section c-pointer"}
            style={{ border: '2px dashed rgb(132, 134, 136)' }}
          >
            <input
              type="file"
              name="image"
              accept="image/*"
              className="w300 d-none"
              style={{ zIndex: -1, position: 'absolute', left: 0}}
              onChange={this.onFileChange}
              ref="file"
              required
            />
           <img src={media_data.source} class="w-100"/>
          </label>
        )
      }

      return (
        <label
          className={previewStyle ? previewStyle : "d-flex justify-content-center align-items-center bg-light of-hidden m-0 h250 c-pointer"}
          style={{ border: '2px dashed rgb(132, 134, 136)' }}
        >
          <input
            type="file"
            name="image"
            accept="image/*"
            className="w300 d-none"
            style={{ zIndex: -1, position: 'absolute', left: 0 }}
            onChange={this.onFileChange}
            ref="file"
            required
          />
          {/* <img src="img.png" class="w-100" id="" v-if="map.type == ''">  */}
          {textLabel ? textLabel:'Add image'}
        </label>
      )
    } else {
      if (this.state.show) {
        return (
          <Cropper
            size={size ? size : [80, 45]}
            file={this.state.file}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog className="modal-dialog-centered" />}
            onChange={this.croppeFile}
            circle={false}
            locale='en-US'
            options={{
              aspectRatio: 7 / 4,
            }}
          />
        )
      }
    }
  }

  render() {
    const { wrapperStyle, label } = this.props
    return (
      <div className={`${wrapperStyle ? wrapperStyle : 'col-md-6'}`} key={this.state.unique}>
        <label>{label}</label>
        <label className="w-100 text-center pt0 m-0 h-100">
          {this.renderCropper()}
        </label>

        <ImageCropper id={`imageCropperStandard${this.state.unique}`} image={this.state.file_raw} 
          aspectRatio={this.props.aspectRatio ? this.props.aspectRatio : 3 / 3}
          width={720}
          height={480}
          getImage={(image) => { 
            this.props.onMediaChange({ data: image, type: 'image' })
            this.setState({
              show: false,
              file: null,
              croppedFile: null,
              image: image,
            })
          }} 
        />

      </div>
    )
  }
}

export default Image
