import React, { Component } from 'react'

import Vimeo from '@u-wave/react-vimeo'
import error_img from '../../assets/images/error_img.png'
import $ from 'jquery'

export class BigPictureModal extends Component {
	/*  PARAMETERS 
		idModal
		title,
		type,
		source,
	*/

	constructor(props) {
		super(props)

		this.state = {

		}
	}

	componentDidMount() {
		const { idModal } = this.props
		$(`#${idModal ? idModal : 'bigPictureModal'}`).on('shown.bs.modal', function (e) {
			$(".modal-backdrop").last().addClass('opacity-9 z-index-2k');
		})
		$(`#${idModal ? idModal : 'bigPictureModal'}`).on('hide.bs.modal', function (e) {
			$(".modal-backdrop").last().removeClass('opacity-9 z-index-2k');
		})
	}


	renderVideo(gallery, widthOfModal) {
		let vimeoError = false;

		if (gallery.disk == 'local') {
			return (
				<video className="bg-dark w-100 align-items-center h-100" controls>
					<source src={gallery.source} type="video/mp4" />
					<source src={gallery.source} type="video/webM" />
					<source src={gallery.source} type="video/ogg" />
					Your browser does not support the video tag.
				</video>
			)
		}

		const source = gallery.source.split('/').pop()
		if (vimeoError) {
			return <img className="bg-light w-100 h-100" src={error_img} />
		} else {
			return <Vimeo video={source} height={568} className="bg-primaryblack w-100 align-items-center h-100 d-flex justify-content-center" onError={() => {
				vimeoError = false;
				this.setState({
					errorInVimeo: true
				})
			}} />
		}
	}

	renderImage(data) {
		return (
			<div>
				<p>{data.caption}</p>
				<img src={data.source} className="w-100 image-ni mh50 bg-dark" />
			</div>
		)
	}

	renderMedia(data) {
		if (data.type == 'image') {
			return (
				<img src={data.source} className='w-100 image-ni mh50 bg-dark' />
			)
		} else {
			let widthOfModal = ($(`#${this.props.idModal ? this.props.idModal : 'bigPictureModal'}`).clientWidth) - 50
			if (data.source) {
				return this.renderVideo(data, widthOfModal)
			}
		}
	}

	render() {
		const { idModal, data } = this.props
		return (
			<div className="modal fade media-item-view-modal" id={`${idModal ? idModal : 'bigPictureModal'}`}>
				<div className="modal-dialog modal-dialog-centered modal-lg border-0 p0">
					<div className="modal-content border-0 p0">
						<div className="modal-header border-0 p0 d-flex justify-content-between align-items-center">
							<h4 className="modal-title pb0 mb10">{data.title ? data.title : '-'}</h4>
							<a href="javascript:;" className="text-white" data-dismiss="modal">Close ×</a>
						</div>
						<div className="modal-body border-0 p0">

							{this.renderMedia(data)}
						</div>
					</div>
				</div>
			</div>

		)
	}
}

export default BigPictureModal
