import React, { Component } from 'react'
import CKEditor from 'ckeditor4-react'
import { Cropper } from 'rc-cropping'
import VideoThumbnail from 'react-video-thumbnail'
import Dialog from 'rc-dialog'
import img from '../assets/images/img.png'
import { TextInput } from './commons/forms'
import { getElementError } from '../utilities'
import { SortableHandle } from 'react-sortable-hoc'
import ImageCropper from './commons/elements/inputs/ImageCropper'
import $ from 'jquery'
import CircleLoading from './loader/CircleLoading'
import { uploadImage } from '../utilities/upload-image'
import { uploadVideo } from '../utilities/upload-video'
import axios from 'axios'

const DragHandle = SortableHandle(() => {
  return (
    <a
      href="#"
      className="text-dark font-weight-bold d-flex align-items-center justify-content-end m-0 px15 bg-dark move-icon mh50 mr-auto"
    >
      <i className="fa fa-arrows text-white fa-2x" />
    </a>
  )
})

export class StandardEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: null,
      file_raw: '',
      croppedFile: null,
      show: false,
      filename: '',
      selected: null,
      image: img,
      type: 'image',
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      loading: false,
      progress: 0,
      source: ''

    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.setState({
      source: source
    })
  }


  handleClick = e => {
    this.refs.file.click()
  }

  onFileChange = () => {
    this.setState({
      loading: true,
      progress: 0,
    })

    if (this.refs.file.files[0].type.split('/')[0] == 'image') {
      $(`#imageCropperStandard${this.state.unique}`).modal('show')
      var reader = new FileReader()
      var url = reader.readAsDataURL(this.refs.file.files[0])
      reader.onloadend = () => {
        this.setState({
          file_raw: reader.result,
          type: 'image',
          // image: reader.result
        })
      }
    } else {
      if (!this.refs.file.files[0]) {
        this.setState({
          show: false,
          file: null,
          croppedFile: null,
          isNext: false,
          page: 1,
        })
        return
      }

      const fileData = this.refs.file.files[0]

      this.props.progressMedia()
      uploadVideo(
        fileData,
        progress => {
          this.setState({
            progress: Math.round((progress.loaded * 100) / progress.total),
          })
        },
        this.state.source.token
      )
        .then(result => {
          console.log(' this.props', result)
          if (typeof this.props.thumbnailChange === 'function') {
            this.props.thumbnailChange(result.thumbnail)
          } else {
            this.props.onStandardThumbnailChange(result.thumbnail)
          }

          this.props.onMediaChange({
            data: result.source,
            type: 'video',
          })
          this.setState(
            {
              loading: false,
              progress: 0,
            },
            () => {
              this.props.progressComplete()
            }
          )
        })
        .catch(error => {
          console.log(error)
        })

      return
    }
  }

  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    reader.onload = () => {
      this.props.onMediaChange({ data: reader.result, type: 'image' })
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        image: reader.result,
      })
    }
  }

  renderCropper() {
    const { file,loading,progress } = this.state
    const { data, validation } = this.props
    let error = getElementError(
      validation.errors,
      validation.prefix,
      validation.index,
      'media-value'
    )
    if (loading) return <CircleLoading progress={progress} />
    
    if (!file) {
      if (data.media.source) {
        return (
          <label
            className={`col-lg-4 d-flex justify-content-center align-items-center bg-light of-hidden m-0 preview-media-section c-pointer  ${
              error ? 'is-invalid' : ''
            }`}
            style={
              error
                ? { border: '2px dashed red' }
                : { border: '2px dashed rgb(132, 134, 136)' }
            }
          >
            <span className="change_media_label">
              Change {this.state.type == 'image' ? 'image' : 'video'}
            </span>
            <input
              type="file"
              name="image"
              accept="video/*,image/*"
              className="w300"
              style={{ zIndex: -1, position: 'absolute' }}
              onChange={this.onFileChange}
              ref="file"
              required
            />
            {this.state.type == 'image' ? (
              <img src={data.media.thumbnail} class="w-100" />
            ) : (
              <VideoThumbnail
                videoUrl={data.media.source}
                thumbnailHandler={thumbnail =>
                  this.props.thumbnailChange(thumbnail)
                }
              />
            )}
          </label>
        )
      }

      return (
        <label
          className={`col-lg-4  d-flex justify-content-center align-items-center bg-light of-hidden m-0 text-center mh150  ${
            error ? 'is-invalid' : ''
          }`}
          style={
            error
              ? { border: '2px dashed red' }
              : { border: '2px dashed rgb(132, 134, 136)' }
          }
        >
          <input
            type="file"
            name="image"
            accept="video/*,image/*"
            className="w300"
            style={{ zIndex: -1, position: 'absolute' }}
            onChange={this.onFileChange}
            ref="file"
            required
          />
          {/* <img src="img.png" class="w-100" id="" v-if="map.type == ''">  */}
          Add image or video +
        </label>
      )
    } else {
      if (this.state.show) {
        return (
          <Cropper
            size={[80, 45]}
            file={this.state.file}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog className="modal-dialog-centered" />}
            onChange={this.croppeFile}
            circle={false}
            options={{
              aspectRatio: 7 / 4,
            }}
          />
        )
      }
    }
  }

  render() {
    const { data, validation } = this.props
    return (
      <div className="card p-0 mt30 rounded-0 border-0 shadow-none">
        <div className="card-header py-0 px-0 rounded-0 border-0 shadow-none d-flex bg-secondary pl-0 flex-wrap flex-sm-nowrap justify-content-end justify-content-md-start">
          <DragHandle />
          <div className="d-flex mh50 order-3 order-xs-1 order-sm-1 oder-md-1 flex-fill align-items-center">
            <div className="font-weight-bold ml-auto pl15 d-flex align-items-center justify-content-end text-dark d-xs-block d-none mw50">
              Title
            </div>
            <TextInput
              value={this.props.data.title ? this.props.data.title : ''}
              placeholder={'e.g. Images'}
              errorMessage={getElementError(
                validation.errors,
                validation.prefix,
                validation.index,
                validation.field
              )}
              containerClass={`mb0 w-100 non-rounded-input mx10 d-flex align-items-center`}
              onValueChange={event => {
                this.props.onTitleChange(event)
              }}
            />
          </div>
          <a
            href="#"
            className="font-weight-bold ml-auto pl20 d-flex align-items-center justify-content-end text-dark p-xsx15 m-xsx0 justify-content-center  order-1 order-xs-1"
            onClick={this.props.onDuplicate}
          >
            <u>Duplicate</u>
          </a>
          <a
            href="#"
            className="font-weight-bold ml-auto pl30 pr15 d-flex align-items-center justify-content-end text-dark p-xsx15 m-xsx0 justify-content-center  order-2 order-xs-2"
            onClick={this.props.onDelete}
          >
            <u>Delete</u>
          </a>
        </div>
        <div className="card-body rounded-0 py0 border-0 shadow-none">
          <div className="row d-flex">
            <div className="col px-0 h-100">
              <CKEditor
                data={data.text}
                onChange={this.props.onStandardTextChange}
                config={{
                  allowedContent: {
                    $1: {
                      attributes: false,
                      styles: false,
                      classes: false
                    }
                  },
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', '-'],
                    [
                      'NumberedList',
                      'BulletedList',
                      '-',
                      // 'Outdent',
                      // 'Indent',
                      // '-',
                      'JustifyLeft',
                      'JustifyCenter',
                      'JustifyRight',
                      // 'JustifyBlock',
                      '-',
                    ],
                    [
                      //'Table', 'HorizontalRule',
                      'Link',
                      'Smiley',
                    ],
                    ['Format'],
                    ['TextColor', 'BGColor'],
                  ],
                }}
              />
            </div>
            {this.renderCropper()}
          </div>
        </div>

        <ImageCropper
          key={this.state.unique}
          id={`imageCropperStandard${this.state.unique}`}
          image={this.state.file_raw}
          aspectRatio={3 / 2}
          width={720}
          height={480}
          getImage={image => {
            this.props.progressMedia()
            uploadImage(image, progress => {
              this.setState({
                progress: Math.round((progress.loaded * 100) / progress.total),
              })
            },
            this.state.source.token
            ).then(result => {
              this.props.onMediaChange({ data: result.data, type: 'image' })

              this.props.thumbnailChange(image)
              this.setState(
                {
                  loading: false,
                  progress: 0,
                },
                () => {
                  this.props.progressComplete()
                }
              )
            })
            this.setState({
              show: false,
              file: null,
              croppedFile: null,
              isNext: false,
              page: 1,
              image: image,
            })
          }}
        />
      </div>
    )
  }
}

export default StandardEditor
