import React, { Component } from "react";
import SurgeonCard from "../../components/surgeon_card";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import _ from 'lodash'
import axios from "axios";
import { Link } from "react-router";
import { setIsNext } from "../../actions/SurgeonActions";

import { setSpecialty } from "../../actions";

class Surgeon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      items: [],
      loading: false,
      hasMore: false,
      searchKeyword: '',
      dataLength: 1,
      meta:{}
    };
  }

  componentDidMount() {
    console.log(this.props)
    this.makeRemoteRequest();
    this.setSelectedSpecialty();
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.makeRemoteRequest();
      }
    );
  }

  refresh() {
    this.setState(
      {
        page: 1,
        loading: true,
        items: []
      },
      () => {
        this.setSelectedSpecialty();
        this.makeRemoteRequest();
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.id === this.props.params.id) {
      return;
    } else {
      this.refresh();
    }
  }

  makeRemoteRequest = () => {
    this.setState({ loading: true });
    const { page, searchKeyword } = this.state;
    const { params } = this.props;

    if (params.id == undefined) {
      params.id = 'all'
    }
    axios
      .get(`api/surgeon?s_id=${params.id}&page=${page}&search=${searchKeyword}`)
      .then(res => {
        console.log(res);
        this.setState({
          items:
            page === 1
              ? res.data.data
              : [...this.state.items, ...res.data.data],
          loading: false,
          hasMore: res.data.meta.last_page > res.data.meta.current_page,
          dataLength: res.data.meta.total,
        }, () => {
          console.log(this.state.hasMore)
        });
      })
      .catch(error => {
        console.log(error);
        console.log(error.response);
        this.setState({ loading: false, hasMore: false });
      });
  };

  renderLoading() {
    if (this.state.loading)
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );

    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <h2 className="h2med mb15">Nothing else to see...</h2>
          <p className="m-0 text-center">
            Missing Someone?{" "}
            <Link to="/surgeon-create" onClick={this.props.setIsNext(false)} className="float-left float-md-none w-xs-100">
              Add new Surgeon here.
            </Link>
          </p>
        </div>
      </div>
    );
  }

  setSelectedSpecialty() {
    const { specialty, params } = this.props;
   

    const spec = specialty.items.find(data => {
      return data.id == params.id;
    });
    
    if(specialty.items.length > 0){ 

      this.props.setSpecialty(spec);
    } 
  }

  searchItem() {
    this.setState(
      {
        searchKeyword: this.searchKeyword.value,
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }
  renderSearchBar() {
    const search = _.debounce(keyword => {
      this.searchItem(keyword)
    }, 500)
    const { params } = this.props;
    if (params.id == 'all') {
      return (
        <div className="row">
          <div className="col-md-7 d-flex mb20">
            <input type="text" className="form-control" placeholder="Start typing surgeon first/last name or specialty"
              ref={el => (this.searchKeyword = el)}
              onChange={search} />
          </div>
        </div>
      )
    }else{
      return null
    }
  }
  render() {
    const cards = this.state.items.map(data => {
      return <SurgeonCard key={data.id} surgeonData={data} />;
    });
    const specialty = this.props.location.state ? this.props.location.state.specialty : null
    console.log('specialty')

    console.log(specialty)
    return (
      <div className="container animated fadeIn">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold title">{specialty ? specialty.name : 'Surgeon list'}</h1>
            {this.renderSearchBar()}
            <p className="bodyregular">
              Missing Someone?{" "}
              <a className="px-1 float-left float-md-none w-xs-100 p-xsx0 m-xsb20" href="/surgeon-create" >
                Add new Surgeon here.
              </a>
            </p>
          </div> 
        </div>
        <div className="row">
          <div className="col-md-12">
            <InfiniteScroll
              className="row"
              dataLength={this.state.items.length} //This is important field to render the next data
              next={this.loadMoreData.bind(this)}
              hasMore={true}
            >
              {cards}
            </InfiniteScroll>
          </div>
        </div>
        {this.renderLoading()}
      </div>
    );
  }
}

const mapStateToProps = ({ specialty }) => {
  return { specialty };
};

export default connect(
  mapStateToProps,
  { setSpecialty, setIsNext }
)(Surgeon);
