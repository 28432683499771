import React, { Component } from 'react';
import $ from 'jquery'

class Footer extends Component {

    componentDidMount() {
        $(document).on('hidden.bs.modal', '.modal', function () {
            if ($('.modal.show').length) {
                $('body').addClass('modal-open');
            }
        });
        $(document).on('shown.bs.modal', '.modal', function () { 
            if ($('.modal.show').length > 1) { 
            }
        });

        function UpdateTableHeaders() {
            $(".persist-area").each(function () {

                var el = $(this),
                    offset = el.offset(),
                    scrollTop = $(window).scrollTop(),
                    floatingHeader = $(".floatingHeader", this)

                if ((scrollTop > offset.top) && (scrollTop < offset.top + el.height())) {
                    floatingHeader.css({
                        "visibility": "visible"
                    });
                } else {
                    floatingHeader.css({
                        "visibility": "hidden"
                    });
                };
            });
        }

        window.initPersistHeader = function initPersistHeader(id){
            // DOM Ready      
            $(function () {

                var clonedHeaderRow;

                $(id).each(function () {
                    clonedHeaderRow = $(".persist-header", this);
                    clonedHeaderRow
                        .before(clonedHeaderRow.clone())
                        .css("width", clonedHeaderRow.width())
                        .addClass("floatingHeader");

                });

                $(window)
                    .scroll(UpdateTableHeaders)
                    .trigger("scroll");

            });
        }

        window.initScrollToTop = function initScrollToTop(){
            
            $('.scroll-to-top').fadeOut(0)
            $('.surgeon-card').click(function() {
            window.location.href = 'C:/corh/corh_home/surgeon_profile.html'
            })
            $('td').click(function() {
            var prop = $(this)
                .find('input')
                .prop('checked')
            $(this)
                .find('input')
                .prop('checked', prop == true ? false : true)
            })

            $(window).scroll(function() {
            // console.log('wwww');
            if ($(this).scrollTop() > 350) {
                $('.scroll-to-top').fadeIn()
            } else {
                $('.scroll-to-top').fadeOut()
            }
            })
            //Click event to scroll to top
            $('.scroll-to-top').click(function() {
            $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 100)
            return false
            })  
        }
    }
    render() {
        
        return (
            <footer className="navbar navbar-expand-lg footer mt-auto py-3 bg-dark">
                <div className="container">  
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav mr-auto"> 
                            <a className="nav-item nav-link text-white mr20" href="#">Legal</a>
                            <a className="nav-item nav-link text-white mx20" href="#">Our Privacy policy</a> 
                            <a className="nav-item nav-link text-white mx20" href="#">Cookie policy</a>
                        </div>
                        <div className="navbar-nav"> 
                            <a className="nav-item nav-link text-white ml-auto" href="#">Powered by CORH</a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}




export default Footer;