import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import Vimeo from '@u-wave/react-vimeo'
import $ from 'jquery'
import BigPictureModal from '../../../modals/BigPictureModal';
import InsideLoader from '../../../loader/InsideLoader';

export class Media extends Component {
  constructor(props) {
    super(props)

    this.state = {

      galleryRef: null,
      thumbRef: null,
      media_data: {},
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      loaded_videos: [],
      loading: false
    }
    this.galleryRef = this.galleryRef.bind(this)
    this.thumbRef = this.thumbRef.bind(this)
  }

  componentDidUpdate() {

  }



  componentWillUpdate(nextProps, nextState) {
    if (nextState.gallerySwiper && nextState.thumbnailSwiper) {
      const { gallerySwiper, thumbnailSwiper } = nextState

      gallerySwiper.controller.control = thumbnailSwiper
      thumbnailSwiper.controller.control = gallerySwiper
    }

    if (this.props.data) {
      if (this.props.data.length) {
        setTimeout(() => {
          if ($(`.media-${this.state.unique} .image-div`)[0]) {
            $(`.media-${this.state.unique} .media-description-main`).height($(`.media-${this.state.unique} .image-div`)[0].offsetHeight);
          }
        }, 1000);
      }
    }
  }

  galleryRef(ref) {
    if (ref) this.setState({ gallerySwiper: ref.swiper })
  }

  thumbRef(ref) {
    if (ref) this.setState({ thumbnailSwiper: ref.swiper })
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  renderVideo(gallery, index) {
    let vimeoError = false;

    if (gallery.disk == 'local') {
      return (
        <video className="bg-dark w-100 align-items-center h-100" controls>
          <source src={gallery.source} type="video/mp4" />
          <source src={gallery.source} type="video/webM" />
          <source src={gallery.source} type="video/ogg" />
          Your browser does not support the video tag.
				</video>
      )
    }

    console.log(this.state)

    console.log('loaded', this.state.loaded_videos, index, this.state.loaded_videos.indexOf(index))

    const source = gallery.source
    return (
      <div className="w-100 h-100">
        {gallery.status != 'complete' ?
          <InsideLoader customStyle={{ right: 20, width: ' !important' }} message='Media Loading...' />
          :
          null}
        <Vimeo
          video={source} className="bg-primaryblack w-100 align-items-center h-100"
          onLoaded={() => {
            let videos = this.state.loaded_videos.push(index)
            console.log('loaded', true)
            this.setState({
              loaded_videos: videos.splice(0)
            })
          }}
          reponsive={true}
        />
      </div>
    )
  }

  mediaitem = (media, index) => {
    const { no_bigpiture, swiperClass } = this.props
    if (media.gallery) {
      return (
        <div className={`d-flex pb20 align-items-stretch flex-column ${swiperClass}`} key={media.gallery.id} data-toggle='modal' data-target={`#${this.props.index}media-big-picture`} onClick={() => {
          
          this.setState({
            media_data: media.gallery,
          }, () => {
            if (no_bigpiture) {
              this.props.setSelectedMedia(this.state.media_data)
            } else {
              $(`#media-big-picture-${this.state.unique}`).modal('show')
            }
          })
        }}>
          <div className="row mx0">
            <div className="col-lg-8 col-md-12 of-hidden text-center d-flex align-items-center pl-xl-0 pl-lg-0">
              {media.gallery.type == 'video' ? (
                this.renderVideo(media.gallery, index)
              ) : (
                  <div className="image-div">
                    <img
                      src={media.gallery.source}
                      className="bg-dark w-100 align-items-center h-100"
                    />
                  </div>
                )}
            </div>
            <div className="col-lg-4 col-md-12 of-auto pl-lg-0 pl-xl-0 of-auto">
              <p className="font-weight-bold mb15 m-xst20 m-mdt20">
                {media.gallery.title ? media.gallery.title : '  -'}
              </p>
              <div className="media-description of-auto pr10"
                dangerouslySetInnerHTML={{
                  __html: media.gallery.caption
                }}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`d-flex pb20 align-items-stretch flex-column ${swiperClass}`} key={media.id} data-toggle='modal' data-target={`#${this.props.index}media-big-picture`}
          onClick={() => {
            
            this.setState({
              media_data: media,
            }, () => {
              if (no_bigpiture) {
                this.props.setSelectedMedia(this.state.media_data)
              } else {
                $(`#media-big-picture-${this.state.unique}`).modal('show')
              }
            })
          }}
        >
          <div className="row mx0">
            <div className="col-lg-8 col-md-12 of-hidden text-center d-flex align-items-center pl-xl-0 pl-lg-0">
              {media.type == 'video' ? (
                this.renderVideo(media)
              ) : (
                  <div className="image-div">
                    <img
                      src={media.source}
                      className="bg-dark w-100 align-items-center h-100"
                    />
                  </div>
                )}
            </div>
            <div className="col-lg-4 col-md-12 of-auto pl-lg-0 pl-xl-0 of-auto">
              <p className="font-weight-bold mb15 m-xst20 m-mdt20">
                {media.title ? media.title : '  -'}
              </p>
              <div className="media-description"
                dangerouslySetInnerHTML={{
                  __html: media.caption
                    ?
                    // media.caption.length > 300 ? media.caption.substr(0, 300) + '...' :
                    media.caption : '-',
                }}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  mediathumbs = media => {
    if (media.gallery) {
      return (
        <div className="col-md-3 px-0" key={media.gallery.id}>
          <img src={media.gallery.thumbnail} className="bg-dark w-100 h130" />
        </div>
      )
    } else {
      return (
        <div className="col-md-3 px-0" key={media.id}>
          <img src={media.thumbnail} className="bg-dark w-100 h130" />
        </div>
      )
    }
  }

  mediapreview = item => {
    const { styleClass, containerStyle, title, data, innerProps } = this.props
    const gallerySwiperParams = {
      spaceBetween: 20,
      freeMode: false,
      navigation: {
        nextEl: '.swiper-button-next.main-swipebtn',
        prevEl: '.swiper-button-prev.main-swipebtn',
      }
    }

    const thumbnailSwiperParams = {
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 4,
      touchRatio: 0.2,
      freeMode: false,
      slideToClickedSlide: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }
    let gallery = item.map((media, index) => {
      if (innerProps) {
        return this.mediaitem(media[innerProps], index)
      }
      return this.mediaitem(media, index)
    })

    let thumbs = item.map((media, index) => {
      if (innerProps) {
        return this.mediathumbs(media[innerProps], index)
      }
      return this.mediathumbs(media)
    })

    return (
      <div className={`h-100 float-left w-100 ${containerStyle}`}>
        <Swiper
          {...gallerySwiperParams}
          ref={this.galleryRef}
          shouldSwiperUpdate
        >
          {gallery}
        </Swiper>
        <div className="w-100 pt0 thumbnail-swiper">
          <Swiper
            {...thumbnailSwiperParams}
            ref={this.thumbRef}
            shouldSwiperUpdate
          >
            {thumbs}
          </Swiper>
        </div>
      </div>
    )
  }

  render() {
    const { styleClass, title, data, no_bigpiture } = this.props
    if (!data.length) {
      return null
    }
    return (
      <div className={`media-${this.state.unique} p15 card border-0 rounded-0 ${styleClass}`}>
        {
          this.props.title ?
            <h5 className="mb15 d-flex align-items-center font-weight-bold">
              {this.props.title}
            </h5>
            : null
        }
        {data.length > 0 ? this.mediapreview(data) : null}
        {
          no_bigpiture ? null :
            <BigPictureModal idModal={`media-big-picture-${this.state.unique}`} data={this.state.media_data} />
        }
      </div>
    )
  }
}

export default Media
