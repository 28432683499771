

const INITIAL_STATE = {
    room_setup_positions: [],
    items: [],
    save_info: false,
    clear_room: false,
    edit_mode: null
}

export default (state = INITIAL_STATE, action) =>{
    
    switch(action.type)
    {
        case 'GET_ITEMS':
            return {...state, items:action.payload}
        case 'FETCH_POSITIONS':
            console.log('action.payload', action.payload)
            return { ...state, room_setup_positions: action.payload}
        
        case 'SET_POST_DATA':
            return { ...state, room_setup_positions: action.room_setup_positions}
        
        case 'OPEN_TABLE':
            return { ...state, isOpen: true }
        case 'SAVE_INFO':
            return { ...state, save_info: true }
            
        case 'CLEAR_ROOM':
            return { ...state, clear_room: action.clear_room }

        case 'SET_EDIT_MODE':
            return { ...state, edit_mode: action.edit_mode }
        
        case 'CLOSE_TABLE':
            return {...state, isOpen : false}
        case 'OPEN_MODAL':
            return {...state, modalOpen : true, showInfo : action.payload}
        case 'CLOSE_MODAL':
            return {...state, modalOpen : false}
        case 'OPEN_PRODUCT':
            return {...state, productOpen : true, modalOpen : false}
        case 'CLOSE_PRODUCT':
            return { ...state, productOpen: false }
        case 'ADD_POSITION':
            return { ...state, save_info: action.save_info }
        case 'ADD_POSITION_STAGE':
            return { ...state, save_info: action.save_info }
        default:
            return state;
    }
     
}
