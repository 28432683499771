import React, { Component } from 'react';
import MedicalInventoryCard from '../../components/medical_inventory_card';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux'
import axios from 'axios'
import { Link } from 'react-router'

import { setSpecialty } from '../../actions'

class MedicalInventories extends Component {
     
    render() { 
        return (
            <div className="container animated fadeIn"> 
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1 class="font-weight-bold mb30 title">Medical Inventory</h1>
                        <h3 class="sub-title">Our global database for your knowledge</h3>
                    </div>
                </div> 
                <MedicalInventoryCard/> 
            </div>
        );
    }
}
export default MedicalInventories;
