import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TextInput } from '../../components/commons/forms'
import { getError, dataURItoBlob, isBase64 } from '../../utilities'
import ItemList from '../ItemList'
import { getItemsPerCategory } from '../../actions/ItemAction'
import axios from 'axios'
import { StandardElementor } from '../StandardElementor'
import img from '../../assets/images/img.png'
import BigPictureModal from '../modals/BigPictureModal'
import $ from 'jquery'
import ImagePicker from '../commons/elements/inputs/ImagePicker'
import WholeBodyloader from '../loader/WholeBodyloader'

export class RoomSetupLayout extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      edit: false,
      preview: false,
      errors: [],
      selected_items: [],
      setup_name: '',
      image: '',
      standardData: [],
      selected_media: [],
      progress: 0
    }
  }

  componentDidMount = () => {
    this.props.getItemsPerCategory('all', this.props.stage_details.items, 3, '')
    this.setSelectedItems(this.props)
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.stage_details != nextProps.stage_details){
      this.setSelectedItems(nextProps)
    }
  }

  setSelectedItems = (props) => {
    console.log('peqr', props)
    this.setState({
      setup_name: props.stage_details.name ? props.stage_details.name : '',
      image: props.stage_details.path ? props.stage_details.path : '',
      selected_items: props.stage_details.items ? props.stage_details.items : [],
      standardData: props.stage_details.elements ? props.stage_details.elements : []
    })
  }

  updateRoomSetup = (draft=0) => {
    const { setup_name, image, selected_items, standardData} = this.state
    const { stage_master } = this.props

    let data = new FormData()
    data.append('name', setup_name)
    if(isBase64(image) > 0){
      data.append('floor_plan', dataURItoBlob(image))
    }
    data.append('draft', draft)
    data.append('_method', 'PATCH')
    selected_items.map((item) => {
      data.append('item_ids[]', item.id)
      data.append('qty[]', item.qty)
    })

    standardData.map((standardData, index) => {
      data.append(`elements[${index}][title]`, standardData.title ? standardData.title : '')
      data.append(`elements[${index}][type]`, standardData.type)
      switch (standardData.type) {
        case 'text':
          data.append(`elements[${index}][values]`, standardData.contents.caption)
          break
        case 'standard':
            data.append(
              `elements[${index}][media-title]`,
              standardData.contents.title
            )
          data.append(
            `elements[${index}][media-text]`,
            standardData.contents.caption
          )
          data.append(`elements[${index}][media-id]`, 0)
          data.append(`elements[${index}][media-updated]`, 1)
          data.append(
            `elements[${index}][media-original]`,
           standardData.contents.gallery.originSource
          )
          data.append(
            `elements[${index}][media-value]`,
           standardData.contents.gallery.source
          )
          data.append(
            `elements[${index}][media-type]`,
            standardData.contents.gallery.type
          )
          break
        case 'media':
          standardData.contents.map((content, i) => {
            data.append(
              `elements[${index}][media-text][${i}]`,
              content.gallery.caption
            )
            data.append(
              `elements[${index}][media-title][${i}]`,
              content.gallery.title
            )
            
            data.append(
              `elements[${index}][media-title][${i}]`,
              content.gallery.title ? content.gallery.title : ''
            )
            data.append(
              `elements[${index}][media-type][${i}]`,
              content.gallery.type
            )

            data.append(`elements[${index}][media-id][${i}]`, 0)
            data.append(`elements[${index}][media-updated][${i}]`, 1)
            data.append(
              `elements[${index}][media-value][${i}]`,
              content.gallery.source
            )
            data.append(
              `elements[${index}][media-original][${i}]`,
              content.gallery.originSource
            )
            
            
           
          })

          break
        default:
          break
      }
    })

    let url = `/api/admin/room/${stage_master.id}`
    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log('percentCompleted:' + percentCompleted);
        this.setState({
          progress: percentCompleted >= 95 ? 95 : percentCompleted,
        })
      }
    }
    
    axios.post(url, data, config).then((res) => {
      if(!draft){
        this.props.getProcedureDetail()
        this.setState({
          edit: false,
          preview: false,
          progress: 0,
          errors: []
        })
      }else{
        this.props.getProcedureDetail(1)
        this.setState({
          errors: [],
          progress: 0
        })
      }
    }).catch((err) => {
      console.log(err)
      this.setState({
        progress: 0,
        errors: err.response.data.errors
      }, () => {
        let targetOffset = $('.is-invalid').offset().top
        $('html, body').animate({scrollTop: targetOffset}, 500)
      })
    })
  }

  setNewState = (state) => {
    this.setState(state)
  }

  editActions = () => {
    const { preview } = this.state
    if(!preview){
      return(
        <div className="col-md-12">
          <ul className="list-unstyled d-inline-flex align-items-center main-card w-100 justify-content-end toolbar-options flex-wrap">
            <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center">
              <a
                href="#"
                className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 m-xsx10"
                onClick={e => {
                  e.preventDefault()
                  this.props.getProcedureDetail(0)
                  this.setState({
                    edit: false,
                  })
                }}
              >
                Cancel
              </a>
            </li>
            <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center">
              <a
                href="#"
                className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 m-xsx10"
                onClick={(e) => {
                  e.preventDefault()
                  this.updateRoomSetup(1)
                }}
              >
                Save
              </a>
            </li>
            <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center border-md-0">
              <a
                href="#"
                className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 m-xsx10"
                onClick={() => {
                  this.setState({
                    preview: true,
                    edit: false
                  })
                }}
              >
                Preview
              </a>
            </li>
            <li className="d-flex align-items-center flex-fill my10 px15 border-xs-0 text-center justify-content-md-start justify-content-lg-center justify-content-xl-center justify-content-center">
              <div
                className="btn btn-primary btn-sm px30 p-xsx10 "
                onClick={() => {
                  this.updateRoomSetup()
                }}
              >
                Update
              </div>
            </li>
          </ul>
        </div>
      )
    }else{
      return(
        <div className="col-md-12">
          <div className="bd-callout bd-callout-warning sticky-top">
            <p className="m-0">
              <b>Preview mode</b> - If you like what you see, you can{' '}
              <u
                className="font-weight-bold c-pointer"
                onClick={() => {
                  this.updateRoomSetup()
                }}
              >
                Update positioning
            </u>{' '}
              or make edits by{' '}
              <u
                className="font-weight-bold c-pointer"
                onClick={() => {
                  this.setState({
                    preview: false,
                    edit: true
                  })
                }}
              >
                Closing preview
            </u>
            </p>
          </div>
        </div>
      )
    }
  }

  edit = () => {
    const { preview } = this.state
    if(!preview){
      return(
        <div className="row mt40">
          <div className="col-lg-12">
            <h2 className="h2med mb20 d-flex align-items-end">
              <span>Equipment / Room setup</span>
            </h2>
          </div>
          {this.editActions()}
          <div className="col-lg-12">
            <div className="card rounded-0 border-0">
              <div className="card-header bg-header rounded-0 border-0">
                <div className="row">
                  <div className="col-lg-6">
                    <TextInput
                      label={null}
                      value={this.state.setup_name}
                      placeholder={'Name'}
                      errorMessage={getError(this.state.errors, 'name')}
                      containerClass={`mb0 w-100`}
                      onValueChange={event => {
                        this.setState({ setup_name: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body p0 d-flex align-items-stretch">
                <div className="w-100" style={{position: 'relative', paddingTop:'66.67%'}}>
                  <ImagePicker aspectRatio={3 / 2} 
                    imageButton={true}
                    imagePreview={this.state.image}
                    getImage={(image) => {this.setState({image: image})}} 
                  />
                </div>
              </div>
            </div>
          </div> 
        </div>
      )
    }else{
      return(
        <div className="row mt40">
          <div className="col-lg-12">
            <h2 className="h2med mb20 d-flex align-items-end">
              <span>Equipment / Room setup</span>
            </h2>
          </div>
          {this.editActions()}
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="h3bold mb0">{this.state.setup_name}</h4>
              </div>
              <div className="card-body p0 d-flex align-items-stretch">
                <div className="image-div w-100">
                  <img src={this.state.image} alt="IMAGE NI" className="bg-dark w-100 " />
                </div>
              </div>
            </div>
          </div> 
        </div>
      )
    }
  }
  
  view = () => {
    const { stage_details } = this.props
    return(
      <div className="row mt40">
        <div className="col-lg-12">
          <h2 className="h2med mb20 d-flex align-items-between flex-column flex-md-row">
            <span>Equipment / Room setup</span>
            <a href="javascript:;" className="btn btn-link pr0 ml-auto m-xst20 text-xs-underline" 
              onClick={() => {
                this.props.getProcedureDetail(1)
                this.setState({edit: true, errors: []})
              }}
            >
              
            {this.props.title} Edit

            </a>
          </h2>
        </div>
        <div className="col-lg-12">
          <div className="card shadow-sm border-0 rounded-0">
            <div className="card-header bg-white border-0 rounded-0">
              <h4 className="h3bold mb0">{stage_details.name ? stage_details.name:'[Add a floor plan]'}</h4>
            </div>
            <div className="card-body p0 d-flex align-items-stretch">
              <div className="image-div w-100">
                {
                  stage_details.path ?
                    <img src={stage_details.path ? stage_details.path : img} alt="" className="bg-dark c-pointer" 
                      onClick={()=>{
                        this.setState({
                          selected_media:{
                            title: stage_details.name ? stage_details.name:'-',
                            type: 'image',
                            source: stage_details.path ? stage_details.path : img,
                          }
                        },()=>{
                            $("#bigPictureModal1").modal('show')
                        })
                      }}
                    />
                    :
                    <label
                      className={"d-flex justify-content-center align-items-center bg-light of-hidden m-0 h-100 font-weight-normal"}
                      style={{ border: '2px dashed rgb(132, 134, 136)', width: '100%', position: 'absolute', top:0, left:0 }}
                    >
                      <i>'Click edit notes to upload a floor plan for this room setup'</i>
                    </label>
                }
              </div>
              
            </div>
          </div>
        </div> 
      </div>
    )
  }

  render() {
    console.log(this.state, this.props)
    const { edit, preview, selected_items, standardData } = this.state
    return (
      <div className="col-lg-9 mx-auto">
        <BigPictureModal idModal={'bigPictureModal1'} data={this.state.selected_media} />
        {edit || preview ? this.edit() : this.view()}
        <ItemList edit={edit} setNewState={this.setNewState} selected_items={selected_items ? selected_items : []} />
        <StandardElementor edit={edit} setNewState={this.setNewState} standardData={standardData ? standardData : []} errors={this.state.errors}/>
        <WholeBodyloader message="Saving! please wait..." progress={this.state.progress} type="spinner" />
      </div>
    )
  }
  
}

export default connect(null, {getItemsPerCategory})(RoomSetupLayout)
