import React, { Component } from 'react'
import axios from 'axios'
import TextInput from '../../components/commons/forms/TextInput'
import { Link } from 'react-router'
import Notification from '../../components/Notification';
import $ from 'jquery'
import { BASE_URL } from '../../config/constant';

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      email: '',
      loading: false,
    }
  }

  componentWillMount() {
    axios.defaults.baseURL = BASE_URL
  }

  

  onSubmit = () => {
    axios.post(`api/create`, {
        email:this.state.email
    }).then( result => {
        $('#notificationModal').modal('show')
       
    }).catch( error => {
        if(error.response.status == 422){
           this.setState({
               error:error.response.data.errors['email'][0]
           })
        }
        console.log(error.response)
    })
    
  }
  render() {
    return (
      <main role="main" className="flex-shrink-0 d-flex">
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="card shadow">
                <div className="card-body p30 text-center">
                  <div className="font-weight-bold d-flex justify-content-center mb30">
                    <h1 className="bg-secondary px-2">CORH</h1>
                  </div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p className="mx-auto mb30">
                        Welcome to CORH. Not yet a member? Sign up and start
                        your{' '}
                        <Link
                          to={{
                            pathname: `/register`,
                          }}
                        >
                          <u>Free trial</u>
                        </Link>{' '}
                        today!
                      </p>
                    </div>
                  </div> 
                 

                  <TextInput
                    label={'Email Address'}
                    value={this.state.email}
                    errorMessage={this.state.error}
                    containerClass={`mb30 text-left`}
                    onValueChange={event => {
                      this.setState({
                          email:event.target.value
                      })
                    }}
                    onKeyPress={ (event) => {
                        if(event.key == 'Enter')
                        this.onSubmit()
                    }}

                  />
                  <div
                    className="btn btn-block btn-primary mb30"
                    onClick={this.onSubmit.bind(this)}
                    
                  >
                    Reset Password
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <Notification 
              title="Password Reset"
              message="We have e-mailed your password reset link!"
              confirmText="OK"
              confirm={ () => {
                this.props.router.push({
                    location: '/login'
                })
              }}
          />
        </div>
      </main>
    )
  }
}



export default ResetPassword
