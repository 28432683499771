import React, { Component } from 'react'
import CKEditor from 'ckeditor4-react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import $ from  'jquery'
import { TextInput } from './commons/forms'
import { getElementError } from '../utilities'
import { SortableHandle } from 'react-sortable-hoc'

const DragHandle = SortableHandle(() => {
  return(
    <a href="#" className="text-dark font-weight-bold d-flex align-items-center justify-content-end m-0 px15 bg-dark move-icon mh50 mr-auto">
      <i className="fa fa-arrows text-white fa-2x" />
    </a>
  )
})

export class TextEditor extends Component {

  componentDidUpdate = () => { 
    console.log('popver set')
    $( '[data-toggle="popover"]').popover()
  }
  

  headerAction = () => {
    const { validation } = this.props
    return(
      <div className="card-header py-0 px-0 rounded-0 border-0 shadow-none d-flex bg-secondary pl-0 flex-wrap flex-sm-nowrap justify-content-end justify-content-md-start">
          <DragHandle />
          <div className="d-flex mh50 order-3 order-xs-1 order-sm-1 oder-md-1 flex-fill align-items-center ">
            <div className="font-weight-bold ml-auto pl10 d-flex align-items-center justify-content-end text-dark d-xs-block d-none mw50">
              Title
            </div>
            <TextInput
              value={this.props.data.title}
              placeholder={'e.g. Images'}
              errorMessage={getElementError(validation.errors, validation.prefix, validation.index, validation.field)}
              containerClass={`w-100 mb0 non-rounded-input mx10 d-flex align-items-center`}
              onValueChange={event => {
                this.props.onTitleChange(event)
              }}
            />
          </div>
          <a
            href="#"
            className="font-weight-bold ml-auto pl20 d-flex align-items-center justify-content-end text-dark p-xsx15 m-xsx0 justify-content-center  order-1 order-xs-1"
            onClick={this.props.onDuplicate}
          >
            <u>Duplicate</u>
          </a>
          <a
            href="#"
            className="font-weight-bold ml-auto pl30 pr15 d-flex align-items-center justify-content-end text-dark p-xsx15 m-xsx0 justify-content-center  order-2 order-xs-2"
            onClick={this.props.onDelete}
          >
            <u>Delete</u>
          </a>
        </div>
    )
  }

  render() {
    const { data, item_list} = this.props

    console.log(this.props)
    let items = []
    item_list.map((list) => {
      list.details.map((item) => {
        items.push(item.item)
      })
    })

    items = items.splice(0)

    

    const dataFeed = (opts, callback) => {
      var matchProperty = 'name',
      data = items.filter(function(item) {
        return item[matchProperty].toLowerCase().indexOf(opts.query.toLowerCase()) !== -1
      })

    
      // data = data.sort(function(a, b) {
      //   return a[matchProperty].localeCompare(b[matchProperty], undefined, {
      //     sensitivity: 'accent',
      //   })
      // })


      callback(data)
    }
    
    return (
      <div className="card p-0 rounded-0 border-0 shadow-sm mt30">

        {this.props.onTitleChange ? this.headerAction() : null}

        <div className="card-body p-0 rounded-0 border-0 shadow-none">
          <div className="row">
            <div className="col d-flex">
              <div className="col p-0 h-100">
                <CKEditor
                  data={data.content}
                  onChange={this.props.textContentChange}
                  config={{
                    allowedContent: {
                      $1: {
                        attributes: false,
                        styles: false,
                        classes: false
                      }
                    },
                    extraPlugins:
                      'mentions',
                      toolbar: [
                        ['Bold', 'Italic', 'Underline', '-'],
                        [
                          'NumberedList',
                          'BulletedList',
                          '-',
                          // 'Outdent',
                          // 'Indent',
                          // '-',
                          'JustifyLeft',
                          'JustifyCenter',
                          'JustifyRight',
                          // 'JustifyBlock',
                          '-',
                        ],
                        [//'Table', 'HorizontalRule', 
                          'Link',
                          'Smiley'
                        ],
                        ['Format'],
                        ['TextColor', 'BGColor'],
                      ],
                    
                    mentions: [
                      {
                        feed: dataFeed,
                        itemTemplate:
                          '<li data-id="{id}">' +
                          '<strong class="username">{name}</strong>' +
                          '<span class="fullname"> ({catalog_number})</span>' +
                          '</li>',
                        outputTemplate:
                         `<a href="#" className="text-dark font-weight-normal" data-container="body" 
                            data-trigger="click hover" data-toggle="popover" data-placement="auto" 
                            data-html="true" 
                            data-content="<div class='row'>
                            <div class='col-md-4 text-center pr-0'>
                            <img src={icon} class='bg-dark w-100'>
                            </div>
                            <div class='col'>
                            <h6 class='mb15'>{catalog_number}</h6>
                            <p>{used_for}</p>
                            </div>
                            <div class='col-md-12 mt15'>
                            <p class='font-weight-bold mb10'>Tags</p>
                            </div>
                            </div>">
                          {name}
                          </a>`,
                        minChars: 1,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    item_list: state.quicknotes.item_list
  })
}

export default connect(mapStateToProps)(TextEditor)
