import React, { Component } from 'react'
import MediaBuilder from './MediaBuilder'
import { TextInput } from './commons/forms'
import { getElementError } from '../utilities'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'

const DragHandle = SortableHandle(() => {
  return (
    <a
      href="#"
      className="text-dark font-weight-bold d-flex align-items-center justify-content-end m-0 px15 bg-dark move-icon mh50 mr-auto"
    >
      <i className="fa fa-arrows text-white fa-2x" />
    </a>
  )
})

const SortableItem = SortableElement(
  ({
    item,
    i,
    setNewState,
    validation,
    onDeleteMediaElement,
    onContentTitleChange,
    onContentCaptionChange,
    onMediaChange,
    thumbnailChange,
  }) => (
    <li className="media d-flex mb10 align-items-center">
      <MediaBuilder
        key={`media_key_${i}`}
        media_data={item.gallery ? item.gallery : item}
        onTitleChange={event => onContentTitleChange(event, i)}
        onCaptionChange={event => onContentCaptionChange(event, i)}
        validation={{ ...validation, field: 'media-title' }}
        index={i}
        onMediaChange={event => onMediaChange(event, i)}
        onDeleteMediaElement={onDeleteMediaElement}
        thumbnailChange={thumbnail => thumbnailChange(thumbnail, i)}
        progressMedia={() => {
         
        }}
        progressComplete={() => {
         
        }}
      />
    </li>
  )
)

const SortableList = SortableContainer(
  ({
    items,
    setNewState,
    validation,
    onDeleteMediaElement,
    onContentTitleChange,
    onContentCaptionChange,
    onMediaChange,
    thumbnailChange,
  }) => {
    return (
      <ul className="list-unstyled mb30 pb20">
        {items.map((item, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              i={index}
              index={index}
              item={item}
              setNewState={setNewState}
              validation={validation}
              onContentTitleChange={onContentTitleChange}
              onContentCaptionChange={onContentCaptionChange}
              onMediaChange={onMediaChange}
              onDeleteMediaElement={onDeleteMediaElement}
              thumbnailChange={thumbnailChange}
            />
          )
        })}
      </ul>
    )
  }
)

export class MediaEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onContentSortChange(
      arrayMove(this.props.data.medias, oldIndex, newIndex)
    )
  }

  headerActions = () => {
    const { validation } = this.props

    return (
      <div className="card-header py-0 px-0 rounded-0 border-0 shadow-none d-flex bg-secondary pl-0 flex-wrap flex-sm-nowrap justify-content-end justify-content-md-start">
        <DragHandle />
        <div className="d-flex mh50 order-3 order-xs-1 order-sm-1 oder-md-1 flex-fill align-items-center">
          <div className="font-weight-bold ml-auto pl10 d-flex align-items-center justify-content-end text-dark mw50">
            Title
          </div>
          <TextInput
            value={this.props.data.title}
            placeholder={'e.g. Images'}
            errorMessage={getElementError(
              validation.errors,
              validation.prefix,
              validation.index,
              validation.field
            )}
            containerClass={`mb0 w-100 non-rounded-input mx10 d-flex align-items-center`}
            onValueChange={event => {
              this.props.onTitleChange(event)
            }}
          />
        </div>
        <a
          href="#"
          className="font-weight-bold ml-auto pl20 pr15 d-flex align-items-center justify-content-end text-dark p-xsx15 m-xsx0 justify-content-center  order-1 order-xs-1"
          onClick={this.props.onDuplicate}
        >
          <u>Duplicate</u>
        </a>
        <a
          href="#"
          className="font-weight-bold ml-auto pl30 pr15 d-flex align-items-center justify-content-end text-dark p-xsx15 m-xsx0 justify-content-center  order-2 order-xs-2"
          onClick={this.props.onDelete}
        >
          <u>Delete</u>
        </a>
      </div>
    )
  }

  render() {
    const { data, validation } = this.props

    return (
      <div className="card p-0 rounded-0 border-0 shadow-sm mt30">
        {this.props.onTitleChange ? this.headerActions() : null}
        <div className="card-body px15 py0 rounded-0 border-0 shadow-none">
          <SortableList
            items={data.medias}
            setNewState={state => {
              this.setState(state)
            }}
            validation={this.props.validation}
            onContentTitleChange={this.props.onContentTitleChange}
            onContentCaptionChange={this.props.onContentCaptionChange}
            onMediaChange={this.props.onContentMediaChange}
            onDeleteMediaElement={this.props.onDeleteMediaElement}
            thumbnailChange={this.props.onContentThumbnailChange}
            pressDelay={100}
            onSortEnd={this.onSortEnd}
            useDragHandle
          />
          <div className="row mb20">
            <div className="col-12">
              <label
                className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 cursor-pointer h50 py30 mh150"
                style={{ border: '2px dashed rgb(132, 134, 136)' }}
                onClick={this.props.addNewMedia}
              >
                {/* <img src="img.png" class="h50" id="">  */}
                Add more image or video +
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MediaEditor
