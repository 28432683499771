import React, { Component } from 'react'
import '../assets/scss/App.scss'
import '../assets/css/app.css'
import Notification from '../components/lounge/Notification'
class Welcome extends Component {
  
    render() {
			return (
				<div className="container">
				
					<div className="row">
						<Notification />
					</div>
				</div>
      );
    }
}

export default Welcome