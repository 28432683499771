import React, { Component } from 'react'
import { Images, PressureMap, Medias } from '../../components/positioning'
import { TextInput } from '../../components/commons/forms'
import { connect } from 'react-redux'
import { positioningUpdate, positioningReset } from '../../actions'
import { getError, dataURItoBlob, getArrayError } from '../../utilities'
import axios from 'axios'
import { Link } from 'react-router'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import Preview from './Preview'
import Confirmation from '../../components/Confirmation'
import $ from 'jquery'

class CreatePositioning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      id: null,
      progress: 0,
      preview: false,
      errors: [],
      progressMedia: [],

    }
  }

  componentDidMount = () => {
    const { router } = this.props
    this.setState({
      progress: 1,
    })

    if (router) {
      if (router.params.id) {
        let url = `api/admin/edit/${router.params.id}/positioning`

        let config = {
          onUploadProgress: progressEvent => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            this.setState({
              progress: percentCompleted >= 95 ? 95 : percentCompleted,
            })
          },
        }

        axios.get(url, config).then(result => {
          this.setState(
            {
              progress: 0,
            },
            () => {
              Object.keys(result.data.data).forEach(e => {
                this.props.positioningUpdate({
                  prop: e,
                  value: result.data.data[e],
                })
              })
            }
          )
        })
      } else {
        this.setState({
          progress: 0,
        })
       
      }
    }
  }

  componentWillUnmount = () => {
    this.props.positioningReset()
  }

  moveToAchive = () => {
    const { master_id,master } = this.props.positioning
    console.log(this.props.positioning)
    this.setState({
      progress: 5,
    })
    if(master.deleted_at){
      axios
      .post(`api/admin/positioning/${master_id}`, { _method: 'PATCH' })
      .then(result => {
        console.log('RESTORING...  ')
       
        this.setState({
          progress: 0,
        }, () =>{
          this.props.router.push({
            pathname: '/positioning',
          })
        })
      })
      .catch(error => {
        console.log(error.response)
      })
    }else{
      axios
      .post(`api/admin/positioning/${master_id}`, { _method: 'DELETE' })
      .then(result => {
        this.setState({
          progress: 0,
        }, () =>{
          this.props.router.push({
            pathname: '/positioning',
          })
        })
       
      })
      .catch(error => {
        console.log(error.response)
      })
    }
   
  }

  submitPosition = (event, draft) => {
    event.preventDefault()
    const { positioning } = this.props
    const { router } = this.props
    let uri = `api/admin/save-surgical-tree-positioning`
    this.setState({
      progress: 5,
    })

    const data = new FormData()
    if (router) {
      if (router.params.id) {
        uri = `api/admin/save-surgical-tree-positioning/${router.params.id}`
        data.append('_method', 'PATCH')
      }
    }

    if (this.state.id) {
      uri = `api/admin/save-surgical-tree-positioning/${this.state.id}`
      data.append('_method', 'PATCH')
    }

    data.append('name', positioning.name ? positioning.name : '')
    data.append('draft', draft)

    let positioning_image_source = positioning.position_image
      ? positioning.position_image
      : ''

    if (positioning_image_source.indexOf('base64') >= 0) {
      data.append('positioning_image_update', 1)
    }

    data.append('positioning_image', dataURItoBlob(positioning_image_source))

    let positioning_pressure_map_source = positioning.pressure_map_image
      ? positioning.pressure_map_image
      : ''

    if (positioning_pressure_map_source.indexOf('base64') >= 0) {
      data.append('pressure_map_image_update', 1)
    }

    data.append(
      'pressure_map_image',
      dataURItoBlob(positioning_pressure_map_source)
    )

    positioning.pressure_maps.map((item, index) => {
      if(item.show){
        data.append(`map_title[${index}]`, item.title)
        data.append(`map_text[${index}]`, item.caption)
  
        let source = item.source ? item.source : ''
        
        data.append(`map_media[${index}]`, source)
        data.append(`map_original[${index}]`, item.originSource)
        data.append(`map_type[${index}]`, item.type)
        data.append(`map_updated[${index}]`, 1)
      }
     
    })

    /* positioning.media.map((item, index) => {
      if (item.show && item.source) {
        data.append(`gallery_title[${index}]`, item.title)
        data.append(`gallery_text[${index}]`, item.caption)
        if (item.source.indexOf('base64') >= 0) {
          data.append(`gallery_media[${index}]`, dataURItoBlob(item.source))
          data.append(`gallery_type[${index}]`, item.type)
          data.append(
            `gallery_thumbnail[${index}]`,
            dataURItoBlob(item.thumbnail)
          )
          data.append(`gallery_updated[${index}]`, 1)
        } else {
          data.append(`gallery_updated[${index}]`, 0)
          data.append(`gallery_id[${index}]`, item.id)
        }
      }
    }) */

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        console.log('percentCompleted:' + percentCompleted)
        this.setState({
          progress: percentCompleted >= 95 ? 95 : percentCompleted,
        })
      },
    }

    axios
      .post(uri, data, config)
      .then(result => {
        this.setState(
          {
            progress: 0,
          },
          () => {
            if (!draft) {
              this.props.router.push({
                pathname: '/positioning',
                state: { master_id: result.data.id },
              })
            }
          }
        )
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState(
          {
            errors: error.response.data.errors,
            progress: 0,
          },
          () => {
            let targetOffset = $('.is-invalid').offset().top
            $('html, body').animate({ scrollTop: targetOffset }, 500)
          }
        )
      })
  }

  renderView = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">
              {this.props.positioning.id ? `Edit` : `Add new`} - Positioning
              page
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 mx-auto">
            <div className="row">
              <div className="col-md-12 mb14">
                {this.props.positioning.master &&
                this.props.positioning.master.deleted_at ? (
                  <ul className="list-unstyled d-inline-flex align-items-center w-100 justify-content-end toolbar-options">
                    <li className="border-right border-dark h-75 d-flex align-items-center">
                      <Link
                        to="/positioning"
                        className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                      >
                        Cancel
                      </Link>
                    </li>
                    <li
                      className="border-right border-dark h-75 d-flex align-items-center"
                      onClick={event => {}}
                    >
                      <a
                        href="#"
                        className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                        data-toggle="modal"
                        data-target="#confirmationModal"
                      >
                        Unarchive
                      </a>
                    </li>
                  </ul>
                ) : (
                  <ul className="list-unstyled d-inline-flex align-items-center w-100 justify-content-end toolbar-options">
                    <li className="border-right border-dark h-75 d-flex align-items-center">
                      <Link
                        to="/positioning"
                        className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                      >
                        Cancel
                      </Link>
                    </li>

                    {this.props.positioning.id ? (
                      <li
                        className="border-right border-dark h-75 d-flex align-items-center"
                        onClick={event => {}}
                      >
                        <a
                          href="#"
                          className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                          data-toggle="modal"
                          data-target="#confirmationModal"
                        >
                          Archive
                        </a>
                      </li>
                    ) : (
                      ''
                    )}

                    <li
                      className="border-right border-dark h-75 d-flex align-items-center"
                      onClick={event => {
                        this.submitPosition(event, 1)
                      }}
                    >
                      <a
                        href="#"
                        className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                      >
                        Save
                      </a>
                    </li>
                    <li
                      className="border-right border-dark h-75 d-flex align-items-center"
                      onClick={() => {
                        this.setState({
                          preview: true,
                        })
                      }}
                    >
                      <a
                        href="#"
                        className="mx15 text-dark font-weight-bold d-flex align-items-center h-100"
                      >
                        Preview
                      </a>
                    </li>
                    <li
                      className="mx15 "
                      onClick={event => {
                        this.submitPosition(event, 0)
                      }}
                    >
                      <div className="btn btn-primary m-0 btn-sm">Update</div>
                    </li>
                  </ul>
                )}
              </div>
              <div className="col-md-5">
                <TextInput
                  label={'Name of position'}
                  value={this.props.positioning.name}
                  errorMessage={getError(this.state.errors, 'name')}
                  containerClass={`mb30 text-left`}
                  onChange={event => {
                    this.props.positioningUpdate({
                      prop: 'name',
                      value: event.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 profile-card pt-0">
                <Images errors={this.state.errors} />
                <PressureMap
                  setNewState={states => {
                    this.setState(states)
                  }}
                  errors={this.state.errors}
                />

                {/*  <Medias /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPreview = () => {
    return (
      <div>
        <div className="col-md-9 mx-auto px0">
          <div className="bd-callout bd-callout-warning sticky-top">
            <p className="m-0">
              <b>Preview mode</b> - If you like what you see, you can{' '}
              <u
                className="font-weight-bold c-pointer"
                onClick={event => {
                  this.submitPosition(event, 0)
                }}
              >
                Update Positioning
              </u>{' '}
              or make edits by{' '}
              <u
                className="font-weight-bold c-pointer"
                onClick={() => {
                  this.setState({
                    preview: false,
                  })
                }}
              >
                Closing preview
              </u>
            </p>
          </div>
        </div>
        <Preview />
      </div>
    )
  }

  render() {
    const { preview } = this.state
    console.log('pres', this.props, this.state)
    return (
      <div className="container">
        <WholeBodyloader progress={this.state.progress} />
        {preview ? this.renderPreview() : this.renderView()}

        <Confirmation
          title="Move Archive"
          message="Moving to archive might be no longer selectable in procedure creator"
          confirmText="OK"
          confirm={this.moveToAchive.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ positioning }) => {
  return { positioning }
}

export default connect(
  mapStateToProps,
  { positioningUpdate, positioningReset }
)(CreatePositioning)
