import {
  GET_USERS, REGISTER_USER, SET_SUCCESS, UPDATE_USER, SET_USERS, RESET_ERRORS, REGISTER_GLOBAL_USER
} from './types'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'

export const getUsers = (page, type='users') => {
  return(dispatch, getState) => {
    let { users } = getState().users
    dispatch({
      type: GET_USERS,
      users: users,
      loading: true,
      errors: []
    })

    let url = ''
    if(type == 'users'){
      url = `/api/admin/users?query=undefined&paginate=15&page=${page}&page_from=global`

    }else{
      url = `/api/admin/global-users?query=undefined&paginate=15&page=${page}&page_from=global`
    }

    axios.get(url).then((res) => {
      dispatch({
        type: GET_USERS,
        users: page === 1 ? res.data.data : [...users, ...res.data.data],
        errors: [],
        loading: false 
      })
      
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: GET_USERS,
        users: users,
        errors: error.response.data.errors,
        loading: false 
      })
    })
  }
}

export const setUsers = (users) => {
  return(dispatch) => {
    dispatch({
      type: SET_USERS,
      users: users
    })
  }
}
export const resetErrors = (users) => {
  return (dispatch) => {
    dispatch({
      type: RESET_ERRORS,
      erros: []
    })
  }
}


export const registerUser = (firstname, lastname, email, username, password, password_confirmation, type) => {
  return (dispatch, getState) => {
    let { users } = getState().users
    dispatch({
      type: GET_USERS,
      users: users,
      loading: true,
      errors: []
    })
    dispatch({
      type: REGISTER_USER,
      success: false,
      errors: [],
    })

    let data = new FormData()
    data.append('name', `${firstname} ${lastname}`)
    data.append('firstname', firstname)
    data.append('lastname', lastname)
    data.append('username', username)
    data.append('email', email)
    data.append('password', password)
    data.append('password_confirmation', password_confirmation)
    data.append('user_type_id', type)

    let url = '/api/users'

    axios.post(url, data).then((res) => {
      dispatch({
        type: REGISTER_USER,
        success: true,
        errors: []
      })

      users.unshift(res.data.data)
      dispatch({
        type: GET_USERS,
        users: users.splice(0),
        loading: false,
        errors: []
      })
      $('#addModal').modal('hide')
      setTimeout(() => {
        dispatch({
          type: SET_SUCCESS,
          success: false
        })
      }, 2000)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: REGISTER_USER,
        success: false,
        errors: error.response.data.errors
      })
    })
  }
}
export const registerGlobalUser = (firstname, lastname, email, username, password, password_confirmation, type) => {
  return (dispatch, getState) => {
    let { users } = getState().users
    dispatch({
      type: GET_USERS,
      users: users,
      loading: true,
      errors: []
    })
    dispatch({
      type: REGISTER_GLOBAL_USER,
      success: false,
      errors: [],
    })

    let data = new FormData()
    data.append('name', `${firstname} ${lastname}`)
    data.append('firstname', firstname)
    data.append('lastname', lastname)
    data.append('username', username)
    data.append('email', email)
    data.append('password', password)
    data.append('password_confirmation', password_confirmation)
    data.append('user_type_id', type)

    let url = '/api/store-globaluser'

    axios.post(url, data).then((res) => {
      dispatch({
        type: REGISTER_USER,
        success: true,
        errors: []
      })

      users.unshift(res.data.data)
      dispatch({
        type: GET_USERS,
        users: users.splice(0),
        loading: false,
        errors: []
      })
      $('#addModal').modal('hide')
      setTimeout(() => {
        dispatch({
          type: SET_SUCCESS,
          success: false
        })
      }, 2000)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: REGISTER_USER,
        success: false,
        errors: error.response.data.errors
      })
    })
  }
}

export const updateUser = (id, firstname, lastname, email, username, type) => {
  return(dispatch, getState) => {
    let { users } = getState().users
    dispatch({
      type: UPDATE_USER,
      success: false,
      errors: []
    })

    let data = new FormData()
    data.append('name', `${firstname} ${lastname}`)
    data.append('firstname', firstname)
    data.append('lastname', lastname)
    data.append('username',username)
    data.append('email', email)
    data.append('user_type_id', type)
    data.append('_method', 'PATCH')
    
    let url = `/api/users/${id}`

    axios.post(url, data).then((res) => {
      dispatch({
        type: UPDATE_USER,
        success: true,
        errors: []
      })
      let index = _.findIndex(users, {id: res.data.data.id})
      users[index] = res.data.data

      console.log('new', users)
      dispatch({
        type: GET_USERS,
        users: users,
        loading: false,
        errors: []
      })
     
      $('#editModal').modal('hide')
      setTimeout(() => {
        dispatch({
          type: SET_SUCCESS,
          success: false
        })
      }, 2000)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: UPDATE_USER,
        success: false,
        errors: error.response.data.errors
      })
    })
  }
} 
