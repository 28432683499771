import React, { Component } from 'react'
import tippy from 'tippy.js'
import img from '../../../../src/assets/images/img.png'
import ReactDOMServer from 'react-dom/server'
import { Pills } from '../misc/Pills';  
import $ from 'jquery'
export class TData extends Component { 

  constructor(props) {
    super(props) 
    this.state = {
      tippyInstance: [],
      unique: this.makeid(10)//Math.random(10).toString(26).substring(2, 7) + Math.random(10).toString(26).substring(2, 7),
    }

  }

  popOverItem() {
    const { item } = this.props
    let imgContent = '', usedForContent = '', tagsContent = '';
    if (item.icon) {
      imgContent = `<div class="row item-img ">
                            <div class="col-md-12">
                                <div class="ar32">
                                <img src="${item.icon}" class="bg-dark" />
                                </div>
                            </div>
                        </div>`;
    }
    if (item.used_for) {
      usedForContent = `<div class="row">
                                <div class="col-12">
                                    <h3 class="title">Used for</h3>
                                    <div class="description">
                                    <p>
                                        ${item.used_for}
                                    </p> 
                                    </div>
                                </div>
                                </div>`;
    }
    if (item.tags) {
      /* let tags = item.tags.map(tag => {
        return (<Pills className="tags-pill">{tag.name}</Pills>)
      }) */

      let tags = ReactDOMServer.renderToStaticMarkup(
        item.tags.map(tag => { 
          if(tag.name.length){ 
            return (<Pills className="tags-pill">{tag.name}</Pills>)
          }
          return null;
        })
      )
      tagsContent = `<div class="row"> 
                                <div class="col-12"> 
                                  <h4 class="sub-title">Tags</h4>
                                </div>
                                <div class="col-12 tags"> 
                                    ${tags}
                                </div>
                            </div>`;
    }

    return `<div class="popover-item-card" style="width: 300px">
              ${imgContent}
              ${usedForContent}
              ${tagsContent}
            </div>`;
  }
  
  componentDidMount() { 
     
  }
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
 
  createTippy() {
    if (this.props.item) {  
      if (this.state.tippyInstance.length == 0) {  
        this.setState({
          tippyInstance: tippy(`#${this.state.unique}`, {
            trigger: 'mouseenter focus',
            placement: 'right',
            arrow: true,
            boundary: "window",
            duration: [100, 100],
            content: this.popOverItem(this.props.item),
          })
        }) 
      }
    }
  }

  previewItem(){  
    $(`#preview-modal-${this.state.unique}`).modal('show')
  }

  render() {
    const { className, children, onClick, previewItem } = this.props
    return (
      <td className={className} onClick={onClick} id={this.state.unique} onMouseOver={()=>{
        this.createTippy()
      }}>
 
        {children}
      </td>
    )
  }
}

export default TData
