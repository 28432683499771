import React, { Component } from 'react'
import Cropper from 'react-cropper'
import $ from 'jquery'
import { Button } from '../../forms';

export class ImagePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      image: '',
      zoom_value: 0,
      id: this.makeid(12),
      key: this.makeid(10),
      previewHeight: 100,
      previewWidth: 100,
    }
  }
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  componentDidMount() {
    $("#imageCropper").on('shown.bs.modal', (e) => {
      this.setState({
        key: this.makeid(10),
        previewHeight: this.props.aspectRatio == 1 ? $(".preview-image")[0].offsetWidth : $(".preview-image")[0].offsetWidth * .66666666666666666666666666666667,
      })
    })
  }


  _crop = () => {
    this.props.getImage(this.refs.cropper.getCroppedCanvas().toDataURL())
    $('#imageCropper').modal('hide')
  }

  _zoom = (value) => {
    this.refs.cropper.zoomTo(value)

    this.setState({
      zoom_value: value
    })
  }

  cropper = () => {
    console.log('123', this.state.zoom_value)
    return (
      <div className='modal modal-lg' id='imageCropper' tabIndex={-1} role='dialog' aria-labelledby='imageCropperLabel' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header pb0 border-0'>
              <h5 className='modal-title m0 p0 border-0' id='imageCropperLabel'>Crop image</h5>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body border-0'>
              <div className='row'>
                <div className='col-lg-8'>
                  <Cropper
                    ref='cropper'
                    key={this.state.key}
                    src={this.state.image}
                    style={{ height: 400, width: '100%' }}
                    aspectRatio={this.props.aspectRatio}
                    setCropBoxData={{ width: this.props.width, height: this.props.height }}
                    strict={true}
                    dragCrop={true}
                    zoom={1}
                    zoomOnWheel={false}
                    zoomOnTouch={false}
                    ready={() => {
                      const containerData = this.refs.cropper.getContainerData();
                      // Zoom to 0 from the center of the container.
                      if (containerData.width) {
                        this.refs.cropper.zoomTo(0.5, {
                          x: containerData.width / 2,
                          y: containerData.height / 2,
                        });
                      }

                    }}
                    highlight={false}
                    cropBoxMovable={false}
                    cropBoxResizable={false}
                    guides={true}
                    preview='.preview-image'
                    dragMode='move'
                    viewMode={0}
                  />

                  <div className="col-md-7 mx-auto d-flex pt10">
                    <i className="mdi mdi-magnify-plus-outline fs20"></i>
                    <input type="range" className="custom-range mx15" min="0.1" max="3" step="0.01" value={this.state.zoom_value} id="zoom" onChange={(event) => { this._zoom(event.target.value) }} />
                    <i className="mdi mdi-magnify-minus-outline fs20"></i>
                  </div>

                </div>
                <div className='col-lg-4 text-center pl0'>
                  <b className="py10 float-left w-100">Preview</b>
                  <div className={`preview-image mx-auto  ${this.props.previewType}`} style={{ width: '100%', height: this.state.previewHeight, overflow: 'hidden' }} />

                  <div className='row mt20'>
                    <div className="col-md-12 text-center d-flex justify-content-center">

                      <a href="javascript:;" className="py3 px10 border border-dark rounded fs25 mx10" onClick={() => { this.refs.cropper.rotate(-90) }}>
                        <span class="mdi mdi-rotate-left" title="Rotate to the left" ></span>
                      </a>
                      <a href="javascript:;" className="py3 px10 border border-dark rounded fs25 mx10" onClick={() => { this.refs.cropper.rotate(90) }}>
                        <span class="mdi mdi-rotate-right" title="Rotate the right"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className='modal-footer d-flex flex-column flex-sm-row border-0 pt30'>
              <Button secondary datadismiss='modal'>
                Close
              </Button>
              <Button primary onClick={() => { this._crop() }}>
                Crop photo
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getButton = () => {
    const { image } = this.state
    const { imageButton, imagePreview } = this.props
    if (imageButton) {
      return (
        <label className="w-100 h-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 c-pointer text-center  preview-media-section p0"
          style={false ? { border: '2px dashed red' } : { border: '2px dashed rgb(132, 134, 136)' }} onClick={() => { this.refs.file.click() }}>
          {imagePreview ? <img src={imagePreview} class="h-100" /> : <p>Upload a floor plan image.</p>}
        </label>
      )
    } else {
      if (image) {
        return (
          <Button onClick={() => { this.refs.file.click() }} secondary>
            Change photo
        </Button>
        )
      }

      return (
        <Button onClick={() => { this.refs.file.click() }} primary>
          Upload photo
        </Button>
      )

    }
  }

  render() {

    return (
      <div style={this.props.imageButton ? { top: 0, position: 'absolute', width: '100%', height: '100%' } : []}>
        <div className={`d-inline-flex aling-items-center ${this.props.imageButton ? ' w-100 h-100' : ''}`}>
          {this.getButton()}
          <input
            ref="file"
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            className="d-none"
            onClick={(event) => {
              event.target.value = null
            }}
            onChange={(event) => {
              if (event.target.files[0]) {
                this.setState({
                  zoom_value: 1,
                  image: URL.createObjectURL(event.target.files[0])
                }, () => {

                  console.log('ready')
                  $('#imageCropper').modal({ backdrop: 'static', keyboard: false, show: true })
                })
              }
            }}
          />

        </div>
        {this.cropper()}
      </div>

    )
  }
}

export default ImagePicker
