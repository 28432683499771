import React, { useState, useEffect} from 'react';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/src/styles/css/swiper.css'
import '../../roomSetupStyle/gallery.css'
import { Navigation, Controller } from "swiper/dist/js/swiper.esm";

    function ProdGallery(data){
    
      
     
        const [gallerySwiper, updateGallerySwiper] = useState(null);
        const [thumbnailSwiper, updateThumbnailSwiper] = useState(null);
      
        useEffect(() => {
          if (gallerySwiper !== null && thumbnailSwiper !== null) {
            gallerySwiper.controller.control = thumbnailSwiper;
            thumbnailSwiper.controller.control = gallerySwiper;
          }
        }, [gallerySwiper, thumbnailSwiper]);
      
        const thumbnailSwiperParams = {
          modules: [Controller],
          paceBetween: 10,
          centeredSlides: true,
          slidesPerView: 'auto',
          touchRatio: 0.2,
          slideToClickedSlide: true,
          getSwiper: updateGallerySwiper,
          containerClass: 'swiper-container thumbs-container'
        };
      
        const params = {
          modules: [Navigation, Controller],
          spaceBetween: 10,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          getSwiper: updateThumbnailSwiper,
          containerClass: 'swiper-container gallery-top'
        };
        return (
          <div>
            <Swiper {...params} >
              <div><img src="http://lorempixel.com/1200/1200/nature/1"/></div>
              <div><img src="http://lorempixel.com/1200/1200/nature/2"/></div>
              <div><img src="http://lorempixel.com/1200/1200/nature/3"/></div>
              <div><img src="http://lorempixel.com/1200/1200/nature/4"/></div>
            </Swiper>
            <Swiper {...thumbnailSwiperParams}>
              <div><img src="http://lorempixel.com/1200/1200/nature/1"/></div>
              <div><img src="http://lorempixel.com/1200/1200/nature/2"/></div>
              <div><img src="http://lorempixel.com/1200/1200/nature/3"/></div>
              <div><img src="http://lorempixel.com/1200/1200/nature/4"/></div>
            </Swiper>
          </div>
        );
      }
    
      
export default ProdGallery;