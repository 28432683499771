

import {
  GET_QUICKNOTES_CATEGORIES, GET_SURGEON_QUICKNOTES, GET_QUICKNOTES_ITEM_LIST, SET_QUICKNOTES_ID,UPDATE_QUICKNOTES_LIST, RESET_QUICKNOTES_ERRORS
} from './types'
import axios from 'axios';
import _ from 'lodash'
import $ from 'jquery'


export const updateQuicknotesList = (data) => {

  console.log('update',data)
  return(dispatch) => {
    dispatch({
      type: UPDATE_QUICKNOTES_LIST,
      quicknotes_list: data
    })
  }
}

export const getQuicknotesCategories = () => {
  return(dispatch) => {
    let url = '/api/admin/quicknotes'
    axios.get(url).then((res) => {
      dispatch({
        type: GET_QUICKNOTES_CATEGORIES,
        categories: res.data.data,
        errors: []
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

const localGetQuicknotesCategories = (dispatch) => {
  let url = '/api/admin/quicknotes'
  axios.get(url).then((res) => {
    console.log(res)
    dispatch({
      type: GET_QUICKNOTES_CATEGORIES,
      categories: res.data.data,
      errors: []
    })
    $('#addNewQuicknotesCategory').modal('hide')
    $('#updateQuicknotesCategory').modal('hide')
  }).catch((error) => {
    console.log(error)
    console.log(error.response)
  })
}

export const saveQuicknotesCategory = (icon, category) => {
  return(dispatch, getState) => {
    const { categories } = getState().quicknotes

    let data = new FormData()
    data.append('icon[]', icon)
    data.append('name[]', category)
    
    let url = '/api/admin/quicknotes'

    let config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
    }
    axios.post(url, data, config).then((res) => {
      console.log(res)
      localGetQuicknotesCategories(dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: GET_QUICKNOTES_CATEGORIES,
        categories: categories,
        errors: error.response.data.errors
      })
    })
  }
}

export const updateQuicknotesCategory = (id, icon, category) => {
  return(dispatch, getState) => {
    const { categories } = getState().quicknotes

    let data = new FormData()
    data.append('icon', icon)
    data.append('name', category)
    data.append('_method', 'PATCH') 

    let url = `/api/admin/quicknotes/${id}`
    axios.post(url, data).then((res) => {
      console.log(res)
      localGetQuicknotesCategories(dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: GET_QUICKNOTES_CATEGORIES,
        categories: categories,
        errors: error.response.data.errors
      })
    })
  
  }
}

export const deleteQuicknotesCategory = (id) => {
  return(dispatch) => {
    let url = `/api/admin/quicknotes/${id}`
    axios.post(url, {'_method': 'DELETE'}).then((res) => {
      console.log(res)
      localGetQuicknotesCategories(dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const saveQuicknotesCategoryOrder = (categories) => {
  return(dispatch) => {
    console.log('categories', categories)
    let data = new FormData()

    categories.map((category) => {
      data.append('item_id[]', category.id)
    })
    
    let url = '/api/admin/quicknotes-sort'

    axios.post(url, data).then((res) => {
      localGetQuicknotesCategories(dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const resetQuicknotesErrors = () => {
  return(dispatch) => {
    dispatch({
      type: RESET_QUICKNOTES_ERRORS
    })
  }
}

//id = quicknotes_id
export const getSurgeonQuicknotes = (id, draft = 0) => {
  return(dispatch, getState) => {
    dispatch({
      type: GET_SURGEON_QUICKNOTES,
      quicknotes_list: [],
      quicknotes_id: [],
      draft_count:0,
      loading: true
    })

    const { surgeon } = getState().surgeon

    let url = `/api/admin/quicknotes-element?surgeon_id=${surgeon.id}&quicknotes_id=${id}&draft=${draft}`
    
    axios.get(url).then((res) => {
      console.log(res)
      dispatch({
        type: GET_SURGEON_QUICKNOTES,
        quicknotes_id: id,
        quicknotes_list: res.data.quicknotes_list,
        surgeon_quicknotes_id: res.data.surgeon_quicknotes_id,
        draft_count:res.data.draft_count,
        loading: true
      })

      url = `/api/admin/quicknotes-item-categories?surgeon_quick_note_id=${res.data.surgeon_quicknotes_id}&draft=${draft}`
      
      axios.get(url).then((res) => {
        dispatch({
          type: GET_QUICKNOTES_ITEM_LIST,
          item_list: res.data.data,
          loading: false
        })
      }).catch((error) => {
        console.log(error)
        console.log(error.response)
      })

    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

//id = quicknotes_id
export const setQuicknotesId = (id) => {
  return(dispatch) => {
    dispatch({
      type: SET_QUICKNOTES_ID,
      quicknotes_id: id
    })
    
  }
}

export const incrementItemQty = (id, item_id) => {
  return(dispatch, getState) => {
    const { item_list } = getState().quicknotes
    let details = item_list.find((item) => item.id === id)
    let item_index = item_list.findIndex((item) => item.id === id)
    let index = details.details.findIndex((item) => item.item.id === item_id)

    item_list[item_index].details[index].qty++
    // debounce(item_list[item_index].details[index])
    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      item_list: item_list.splice(0),
      loading: false
    })
  }
}

export const decrementItemQty = (id, item_id) => {
  return(dispatch, getState) => {
    const { item_list } = getState().quicknotes
    let details = item_list.find((item) => item.id === id)
    let item_index = item_list.findIndex((item) => item.id === id)
    let index = details.details.findIndex((item) => item.item.id === item_id)

    if(item_list[item_index].details[index].qty > 1){
      item_list[item_index].details[index].qty--
    }
    // debounce(item_list[item_index].details[index])

    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      item_list: item_list.splice(0),
      loading: false
    })
  }
}

export const removeItem = (id, item_id) => {
  return(dispatch, getState) => {
    let { item_list } = getState().quicknotes
    let details = item_list.find((item) => item.id === id)
    let item_index = item_list.findIndex((item) => item.id === id)

    _.remove(details.details, (e) => {
      return e.item.id === item_id
    })

    item_list[item_index].details = details.details
    
    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      item_list: item_list.splice(0),
      loading: false
    })
  }
}

export const loadSurgeonQuicknotes = (id) => {
  return(dispatch, getState) => {
    let { quicknotes_id, surgeon_quicknotes_id, item_list } = getState().quicknotes

    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      item_list: [],
      loading: false
    })

    let url = `/api/admin/quicknotes-element?surgeon_id=${id}&quicknotes_id=${quicknotes_id}&draft=0`

    axios.get(url).then((res) => {
      dispatch({
        type: UPDATE_QUICKNOTES_LIST,
        quicknotes_list: res.data.quicknotes_list
      })

      url = `/api/admin/quicknotes-item-categories?surgeon_quick_note_id=${res.data.surgeon_quicknotes_id}&draft=0`
      


      axios.get(url).then((res) => {
        console.log('result', res)
        let temp = res.data.data

        //set item prn
        temp.map((e, index) => {
          e.details.map((item) => {
            if(index == 0){
              item.prn = item_list[0].id
            }
          })
        })

        console.log('temp', temp)

        temp.map((list, index) => {
          item_list[index].details = list.details
        })

        dispatch({
          type: GET_QUICKNOTES_ITEM_LIST,
          item_list: item_list,
          loading: false
        })
      }).catch((error) => {
        console.log(error)
        console.log(error.response)
      })

    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const updateQuicknotesItemList = (items) => {
  return(dispatch, getState) => {
    let { item_list } = getState().quicknotes

    let prn_items = []
    let or_items = []
    items.map((item) => {
      console.log(item)
      let e = {
        qty: item.qty,
        item: item
      }
      if(item.prn){
        prn_items.push(e)
      }else{
        or_items.push(e)
      }
    })

    item_list[0].details = prn_items
    item_list[1].details = or_items

    console.log(item_list)

    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      loading: false,
      item_list: item_list.splice(0)
    })
  }
}

export const saveQuicknotesItemList = (new_list,draft = 0) => {
  return(dispatch, getState) => {
    const { item_list, surgeon_quicknotes_id } = getState().quicknotes

    console.log(new_list)

    let data = new FormData()
    data.append('surgeon_quick_note_id', surgeon_quicknotes_id)
    data.append('draft', draft)
    new_list.map((list) => {
      list.details.map((item) => {
        data.append('item_id[]', item.item.id)
        data.append('qty[]', item.qty)
        if(!item.item.prn){
          data.append('category_id[]', item_list[1].id)
        }else{
          data.append('category_id[]', item.item.prn)
        }
      })
    })

    let url = '/api/admin/quicknotes-item-list'

    axios.post(url, data).then((res) => {
      console.log(res)
      dispatch({
        type: GET_QUICKNOTES_ITEM_LIST,
        loading: false,
        item_list: res.data.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })

  }
}