import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import ViewItemModal from './modals/ViewItemModal'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server' 


class EquipTable extends React.Component {

  constructor(props) {
    super(props)
  
    this.state = {
      searchKeyword: '',
      page: 1,
      loading: false,
      data: [],
      selectedItem: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
    }
  }
  

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }
getEquip = (item) =>{
  this.props.getEquip(item);
}


  searchItem() {
    this.setState(
      {
        searchKeyword: this.searchKeyword.value,
        page: 1,
        loading: true,
        data: [],
      },
      () => { 
      }
    )
  }
render(){
  const { posts } = this.props
  const search = _.debounce(keyword => {
    this.searchItem(keyword)
  }, 500)
  
  const rows = posts.map((row, index) => {
    let tags = row.tags.map((tag) => {
      return ReactDOMServer.renderToStaticMarkup(
        <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
      )
    })
    return (
      <tr key={`ai-tr-${row.id}`}>
        <td className=" w150 px10">{row.catalog_number}</td>
        <th className=" w300 c-pointer px10 float-left" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${row.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${row.name}</h6>
                              <p>${row.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`} onClick={() => {

            this.setState({
              selectedItem: row,
              unique:
                Math.random()
                  .toString(36)
                  .substring(2, 15) +
                Math.random()
                  .toString(36)
                  .substring(2, 15)
            }, () => {
              $("#viewItemModal").modal('show');

            })
          }}>{row.name}</th>
        <td className=" w150 px10">{row.company}</td>
        <td className=" w150 px10">{row.category ? row.category.name : ''}</td>
        <td className=" w100 px10">{row.size}</td>
        <td className="text-center w100 px10"><div className="btn btn-link" onClick={() => this.getEquip(row)}>Add</div></td>
      </tr>
    )
  })
  return (
    <div className="">

      <div className="row">

        <div className="col-md-4">
          <div className="form-group mb20">
            <h6 className="mb10">Search</h6>
            <input
              type="text"
              name="search"
              id="search"
              className="form-control"
              placeholder="Start typing name, catalogue #…"
              ref={el => (this.searchKeyword = el)}
              onChange={search}
            />
          </div>
        </div>
        <div className="col-md-12">
          <table className="table table-striped table-fixed border">
          <thead>
            <tr>
              <th className="w150 px10">Catalog #</th>
              <th className="w300 px10">Name</th>
              <th className="w150 px10">Company</th>
              <th className="w150 px10">Category</th>
              <th className="w100 px10">Size</th>
              <th className="w100 px10"></th>
            </tr>
            </thead>
            <tbody style={{ float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '344px' }}>
              {rows}
          </tbody>
        </table>
        </div>
        <ViewItemModal
          idModal="viewItemModal"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal').modal('hide')
          }}
        />
      </div>
    </div>
  
  );
 }
}



const mapStateToProps = (state) =>{ //the state will have a property called "users" according sa decalred reducers indexks
return {
        posts: state.posts.items
    }
}

export default connect(mapStateToProps)(EquipTable);