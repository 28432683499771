import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  getUsers,
  registerGlobalUser,
  updateUser,
  setUsers,
  resetErrors,
} from '../../actions/UsersActions'
import { getError, getArrayError, dataURItoBlob } from '../../utilities'
import { connect } from 'react-redux'
import { TextInput, Picker } from '../../components/commons/forms'
import axios from 'axios'
import moment from 'moment'
import Confirmation from '../../components/Confirmation'
import $ from 'jquery'

export class GlobalUsers extends Component {
  constructor(props) {
    super(props)

    let randomPassword = Math.random()
      .toString(36)
      .substring(7)

    this.state = {
      page: 1,
      id: null,
      firstname: '',
      lastname: '',
      email: '',
      username: '',
      password: randomPassword,
      password_confirmation: randomPassword,
      type: '',
      types: [
        { id: '', name: 'Select type' },
        { id: 1, name: 'Super Admin' },
        { id: 2, name: 'Admin' },
        { id: 3, name: 'Subcscriber' },
        { id: 4, name: 'Content Writer' },
      ],
      successAppear: false,
      successMessage: '',
      isReverse: false,
      selectedItem:null,
      users:[]
    }
  }

  componentDidMount = () => {
    this.getUsers()
  }

  getUsers = () => {
    const { page } = this.state
    this.setState({
      loading: true,
    })

    let url = `/api/admin/global-users?page=${page}&paginate=15`

    axios
      .get(url)
      .then(res => {
        console.log(res)

        this.setState({
          users:
            page === 1
              ? res.data.data
              : [...this.state.users, ...res.data.data],
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          loading: false,
        })
      })
  }

  compareBy(key) {
    if (key == 'type') {
      return function(a, b) {
        if (a.type.name < b.type.name) return -1
        if (a.type.name > b.type.name) return 1
        return 0
      }
    }

    return function(a, b) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1
      return 0
    }
  }

  sortBy(key) {
    let arrayCopy = this.state.users
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ users: arrayCopy, isReverse: !this.state.isReverse })
  }

  renderLoading = () => {
    const { loading } = this.props
    if (loading) {
      return (
        <div className="row">
          <div className="col-md-12 text-center pb30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-md-12 text-center mt30">
          <h3 className="mb-15">Nothing else to see...</h3>
        </div>
      </div>
    )
  }

  resetPasswordUser = () =>{
    const { users, selectedItem } = this.state

    axios
      .post(`api/admin/users/reset/${selectedItem.id}`, {
        _method: 'PATCH',
      })
      .then(res => {
        $('#notificationModal').modal('show')
      })
  }

  

  registerModal = () => {
    const {
      firstname,
      lastname,
      email,
      username,
      password,
      password_confirmation,
      type,
      types,
    } = this.state
    const { errors } = this.props

    return (
      <div
        className="modal fade"
        id="addModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="cancelModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-0">
            <div className="modal-body pt30 px30">
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">
                Register user
              </h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb30">
                    <TextInput
                      label={'First name'}
                      value={firstname}
                      placeholder={'First name'}
                      errorMessage={getError(errors, 'firstname', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ firstname: event.target.value })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Last name'}
                      value={lastname}
                      placeholder={'Last name'}
                      errorMessage={getError(errors, 'lastname', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ lastname: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Email'}
                      value={email}
                      placeholder={'Email'}
                      errorMessage={getError(errors, 'email', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ email: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Username'}
                      value={username}
                      placeholder={'Username'}
                      errorMessage={getError(errors, 'username', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ username: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-none">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Password'}
                      value={password}
                      placeholder={'Password'}
                      inputType={'password'}
                      errorMessage={getError(errors, 'password', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ password: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-none">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Password Confirmation'}
                      value={password_confirmation}
                      placeholder={'Password Confirmation'}
                      inputType={'password'}
                      errorMessage={getError(
                        errors,
                        'password_confirmation',
                        0
                      )}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({
                          password_confirmation: event.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <Picker
                      label={'User type'}
                      data={types}
                      value={type}
                      errorMessage={getError(errors, 'user_type_id', 0)}
                      containerClass={`mb30 fs16`}
                      onChange={event => {
                        this.setState({ type: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body py0 rounded-0">
              <div
                className="btn btn-dark px30 mr30"
                onClick={() => {
                  this.props.registerGlobalUser(
                    firstname,
                    lastname,
                    email,
                    username,
                    password,
                    password_confirmation,
                    type
                  )
                }}
              >
                Send invitation
              </div>
              <div className="btn btn-outline-dark px30" data-dismiss="modal">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  editModal = () => {
    const {
      id,
      firstname,
      lastname,
      email,
      username,
      password,
      password_confirmation,
      type,
      types,
    } = this.state
    const { errors } = this.props

    return (
      <div
        className="modal fade"
        id="editModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="cancelModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-0">
            <div className="modal-body pt30 px30">
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">
                Edit user
              </h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb30">
                    <TextInput
                      label={'First name'}
                      value={firstname}
                      placeholder={'First name'}
                      errorMessage={getError(errors, 'firstname', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ firstname: event.target.value })
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Last name'}
                      value={lastname}
                      placeholder={'Last name'}
                      errorMessage={getError(errors, 'lastname', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ lastname: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Email'}
                      value={email}
                      placeholder={'Email'}
                      errorMessage={getError(errors, 'email', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ email: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Username'}
                      value={username}
                      placeholder={'Username'}
                      errorMessage={getError(errors, 'username', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ username: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <Picker
                      label={'User type'}
                      data={types}
                      value={type}
                      errorMessage={getError(errors, 'type', 0)}
                      containerClass={`mb30 fs16`}
                      onChange={event => {
                        this.setState({ type: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <label>User status</label>
                    <div
                      className="btn-group btn-group-toggle w-100"
                      data-toggle="buttons"
                    >
                      <label className="btn btn-outline-dark">
                        <input
                          type="radio"
                          name="handedness"
                          id="room_environment"
                          autoComplete="off"
                          defaultValue="left"
                        />{' '}
                        Active
                      </label>
                      <label className="btn btn-outline-dark">
                        <input
                          type="radio"
                          name="handedness"
                          id="room_environment"
                          autoComplete="off"
                          defaultValue="right"
                        />{' '}
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body py0 rounded-0">
              <div
                className="btn btn-dark px30 mr30"
                onClick={() => {
                  this.props.updateUser(
                    id,
                    firstname,
                    lastname,
                    email,
                    username,
                    type
                  )
                }}
              >
                Send invitation
              </div>
              <div className="btn btn-outline-dark px30" data-dismiss="modal">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  notifySuccess() {
    return (
      <tr>
        <td className="td-notifier" colSpan={6}>
          <span className="error-state-icon positive text-center w-100 d-flex justify-content-center font-weight-normal">
            <i className="mdi mdi-check-circle" />
            {this.state.successMessage}
          </span>
        </td>
      </tr>
    )
  }
  notification = () => {
    const { users } = this.props
    console.log(users)
    return (
      <tr>
        <td className="td-notifier" colSpan={6}>
          <span className="error-state-icon positive text-center w-100 d-flex justify-content-center font-weight-normal">
            <i className="mdi mdi-check-circle" /> Saving success
          </span>
        </td>
      </tr>
    )
  }

  deleteItem() {
    const { id } = this.state
    axios
      .post(`api/admin/users/block/${id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          isCreate: false,
          successAppear: true,
          successMessage: 'User successfully deleted!',
          show: true,
        })
        this.props.getUsers(this.state.page, 'globalusers')
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  bannedUser = () => {
    const { users, selectedItem } = this.state

    axios
      .post(`api/admin/users/block/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(res => {
        let data = users.find(x => x.id == selectedItem.id)
        data.isDeleted = true
        this.setState({
          users: users.splice(0),
        })
      })
  }

  restoreUser = () => {
    const { users, selectedItem } = this.state

    axios
      .post(`api/admin/users/restore/${selectedItem.id}`, {
        _method: 'PATCH',
      })
      .then(res => {
        let data = users.find(x => x.id == selectedItem.id)
        data.isDeleted = false
        this.setState({
          users: users.splice(0),
        })
      })
  }
  render() {
    const { success } = this.props
    const {users, page } = this.state

    let user_list = users.map(user => {
      return (
        <tr>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.type.name}</td>
          <td>{moment(user.created_at.date).format('MM/DD/YYYY')}</td>
          <td>{user.status ? 'Active' : 'Invitation sent'}</td>
          <td>
            <a
              href="#"
              data-toggle="modal"
              data-target="#editModal"
              onClick={() => {
                this.setState({
                  id: user.id,
                  firstname: user.firstname,
                  lastname: user.lastname,
                  email: user.email,
                  username: user.username,
                  type: user.type.id,
                })
                this.props.resetErrors()
              }}
            >
              Edit
            </a>
            {user.isDeleted ? (
              <a
                className="mx-2"
                href="javascript:;"
                data-target="#restoreModal"
                data-toggle="modal"
                onClick={() => this.setState({ selectedItem: user })}
              >
                Restore
              </a>
            ) : (
              <a
              className="mx-2"
                href="javascript:;"
                data-target="#bannedModal"
                data-toggle="modal"
                onClick={() => this.setState({ selectedItem: user })}
              >
                Banned
              </a>
            )}

            <a
              className="mx-2"
              href="javascript:;"
              data-target="#resetPasswordModal"
              data-toggle="modal"
              onClick={() => this.setState({ selectedItem: user })}
            >
              Password Reset
            </a>
          </td>
        </tr>
      )
    })
    return (
      <div className="container">
        <div className="row">
          <Confirmation
            idModal="deleteModal"
            title="Delete User"
            message="Deleting user will remove it from the database and will have to be added back manually."
            confirmText="Delete user"
            cancelText="Cancel"
            confirm={this.deleteItem.bind(this)}
            cancel={() => {
              $('#deleteModal').modal('hide')
            }}
          />
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30 d-flex align-items-end">
              Global OR Users
              <a
                href="#"
                className="ml-auto fs16 pb5"
                data-toggle="modal"
                data-target="#addModal"
                onClick={() => {
                  this.setState({
                    firstname: '',
                    lastname: '',
                    email: '',
                    username: '',
                    password: '',
                    password_confirmation: '',
                    type: '',
                  })
                }}
              >
                Register users
              </a>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <InfiniteScroll
              className=""
              dataLength={users.length} //This is important field to render the next data
              next={() => {
                this.setState({ page: page + 1 }, () => {
                  this.getUsers()
                })
              }}
              hasMore={true}
            >
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th onClick={() => this.sortBy('name')}>
                      Full name <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('email')}>
                      Email <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('type')}>
                      User type <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('date')}>
                      Start date <i className="fa fa-sort ml5" />
                    </th>
                    <th>Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {success ? this.notification() : null}
                  {this.state.successAppear ? this.notifySuccess() : null}
                  {user_list}
                </tbody>
              </table>
            </InfiniteScroll>
            {this.renderLoading()}
          </div>
        </div>
        {this.registerModal()}
        {this.editModal()}
        <Confirmation
          title="Password Reset"
          message="Password Reset will reset user password."
          confirmText="Password Reset"
          cancelText="Cancel"
          confirm={this.resetPasswordUser.bind(this)}
          idModal={'resetPasswordModal'}
          cancel={() => {
            $('resetPasswordModal').modal('hide')
          }}
        />
         <Confirmation
          title="Restore User"
          message="Restore user will restore the access of the user."
          confirmText="Restore user"
          cancelText="Cancel"
          confirm={this.restoreUser.bind(this)}
          idModal={'restoreModal'}
          cancel={() => {
            $('restoreModal').modal('hide')
          }}
        />
        <Confirmation
          title="Banned User"
          message="Banned user will remove the access of the user."
          confirmText="Banned user"
          cancelText="Cancel"
          confirm={this.bannedUser.bind(this)}
          idModal={'bannedModal'}
          cancel={() => {
            $('bannedModal').modal('hide')
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    loading: state.users.loading,
    errors: state.users.errors,
    success: state.users.success,
  }
}

export default connect(
  mapStateToProps,
  { getUsers, registerGlobalUser, updateUser, setUsers, resetErrors }
)(GlobalUsers)
