import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getItemsPerCategory } from '../../actions/ItemAction'
import {
  savePositioningEquipment,
  getPositioningDetails,
} from '../../actions/PostioningActions'
import ReactDOMServer from 'react-dom/server'
import { Images, PressureMap, Medias } from '../../components/positioning'
import { Link } from 'react-router'
import AssignItemsPositioning from '../../components/modals/AssignItemsPositioning'
import { getError, getArrayError, dataURItoBlob, isBase64 } from '../../utilities'
import { TextInput } from '../../components/commons/forms'
import { getPosition } from '../../actions/PostioningActions'
import { positioningUpdate } from '../../actions'
import $ from 'jquery'
import {
  CardSection,
  PositionAreas,
} from '../../components/positioning_equipments'
import axios from 'axios'
import { TextEditor } from '../../components/TextEditor';
import _ from 'lodash'
import ViewItemModal from '../../components/modals/ViewItemModal'
import { timingSafeEqual } from 'crypto';

export class UpdatePositioningEquipment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      change: false,
      qty: [],
      position: [],
      positions: [],
      errors: [],
      medias: [],
      preview: false,
      fresh: true,
      item: {},
      isReverse: false,
      selected_index:'',
      index: null,
      selectedItem: null
    }
  }

  componentDidMount = () => {
    this.props.getPosition(this.props.params.id)
    this.props.getPositioningDetails(this.props.params.id, 1)
  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.position_master != this.props.position_master){
      let pos = []
        let qty = []
        let medias = []
        nextProps.position_master.map(item => {
          pos.push(item.body_area)
          qty.push(item.qty)
          medias.push({ data: item.position_media ? item.position_media :[]})
        })
        this.setState({
          selected_items: nextProps.position_master,
          positions: pos,
          qty: qty,
          medias,
          fresh: false
        })
    }
  }

  mediaElements = (index) => {
    const { medias } = this.state
    const { media } = this.props
    media.length = 0

    if (medias[index].data.length) {
      medias[index].data.map( data => {
        media.push(data)
      })
    } else {
      media.push({
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      })
    }
    
    const value = media.splice(0)

    this.props.positioningUpdate({ prop: 'media', value })
    this.setState({
      selected_index:index
    }, () =>{
      $('#addMediaModal').modal('show')

    })
  }

  getItems2 = (nextProps) => {
        let pos = []
        let qty = []
        let medias = []
        nextProps.position_master.map(item => {
          pos.push(item.body_area)
          qty.push(item.qty)
          medias.push({ data: item.position_media ? item.position_media :[]})
        })
        this.setState({
          selected_items: nextProps.position_master,
          positions: pos,
          qty: qty,
          medias,
          fresh: false
        })
  }

  getItems = () => {
    let pos = []
    let qty = []
    let medias = []
    this.props.position_master.map(item => {
      pos.push(item.body_area)
      qty.push(item.qty)
      medias.push({ data: item.position_media ? item.position_media :[]})
    })
    this.setState({
      selected_items: this.props.position_master,
      positions: pos,
      qty: qty,
      medias,
      fresh: false
    })
  }

  savePositioningEquipment = (id, items, position, qty, draft, redirect=true) => {
    const { medias } = this.state
    let data = new FormData()
    data.append('positioning', id)
    data.append('draft', draft)

    items.map((item, index) => {
      data.append(`item_ids[${index}]`, item.id)
      data.append(`areas[${index}]`, position[index])
      data.append(`qty[${index}]`, qty[index])
      data.append(`notes[${index}]`, item.position_notes ? item.position_notes : '')
      medias[index].data.map((media, i) => {

        if(media.show){
        
          data.append(`media_id[${index}][${i}]`, 0)
          data.append(`medias[${index}][${i}]`,media.source)
          data.append(`origin_source[${index}][${i}]`, media.originSource)
          data.append(`updated[${index}][${i}]`, 'yes')
          data.append(`caption[${index}][${i}]`, media.caption)
          data.append(`title[${index}][${i}]`, media.title)
          data.append(`type[${index}][${i}]`, media.type)
         
        }
      })      
    })

    let url = '/api/admin/positioning-equipments'

    axios
      .post(url, data)
      .then(res => {
        if (redirect) {
          this.props.router.push({
            pathname: '/positioning-equipments',
            state: {
              id: id,
            },
          }, () => {
            
          })
        }
        this.props.getPositioningDetails(this.props.params.id, 1)
        
        
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: Object.values(error.response.data.errors),
        })
      })
  }

  setItems = (items, positions, qty) => {
    const medias = []
    qty.map((q, index) => {
      items[index].qty = q
      items[index].body_area = positions[index]
      items[index].position_media = items[index].position_media ? items[index].position_media.splice(0) : []
      medias.push({ data: items[index].position_media ? items[index].position_media.splice(0) :[]})
    })

    this.setState({
      selected_items: items.splice(0),
      positions: positions,
      qty: qty,
      fresh: false,
      medias
    }, () => {
      // this.savePositioningEquipment(this.props.params.id, this.state.selected_items, this.state.positions, this.state.qty, 1, false)
      // this.props.getPositioningDetails(this.props.params.id, 1)
    })
  }

  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({
      selected_items: arrayCopy,
      isReverse: !this.state.isReverse,
    })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  textContentChange = (event, index) => {
    let item = this.state.item

    item.position_notes = event.editor.getData()

    this.setState({
      item: item
    })
  }

  saveMedia = () => {
    let { medias,selected_index} = this.state
    const { media} = this.props
    medias[selected_index].data = media.splice(0)
    media.length = 0
    const value = media.splice(0)

    this.props.positioningUpdate({ prop: 'media', value })

    

    this.setState({
      medias:medias.splice(0)
    },() => {
      $('#addMediaModal').modal('hide')
    })
    
  }

  saveNotes = (index) => {
    let { item, selected_items } = this.state 

    selected_items[index] = item

    this.setState({
      selected_items: selected_items,
      item: {}
    })
  }
  

  addNotesModal = () => {
    return (
      <div
        className="modal"
        id="addNotesModal"
        role="dialog"
        aria-labelledby="addNotesModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-xl px30" role="document">
          <div className="modal-content animated fadeIn">
            <div className="modal-body pt30 px30 pb15">
              <h5 className="mb20 mx-auto font-weight-bold">{this.state.item.position_notes ? 'Update Notes' : 'Add Notes'}</h5>
              <TextEditor
                limitChar={300}
                notitle={true}
                item_list={[]}
                data={{
                  title: '',
                  content: this.state.item ? this.state.item.position_notes : ''
                }}
                textContentChange={event => {
                  this.textContentChange(event, this.state.index)
                }}
              />
            </div>
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                data-dismiss="modal"
                onClick={() => {
                  this.saveNotes()
                }}
              >
                Save
              </div>
              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  addMediaModal = () => {
    return (
      <div
        className="modal"
        id="addMediaModal"
        role="dialog"
        aria-labelledby="addMediaModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-xl px30" role="document">
          <div className="modal-content animated fadeIn">
            <div className="modal-body pt30 px30 pb15">
              <h5 className="mb20 mx-auto font-weight-bold">Add Media </h5>
              <Medias 
                  
              />
              
            </div>
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                onClick={ () => {
                  this.saveMedia()
                }}
              >
                Save
              </div>
              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  preview = () => {
    this.setState({ preview: true })
    this.savePositioningEquipment(
      this.props.params.id,
      this.state.selected_items,
      this.state.positions,
      this.state.qty,
      1,
      false
    )
    this.props.getPositioningDetails(this.props.params.id, 1)
  }

  positioningContent = () => {
    window.initScrollToTop()
    const { position } = this.state
    const { position_details } = this.props
    let area_list = position_details.map(detail => {
      return (
        <CardSection
          data={{
            name: detail[0].position.name,
            code: detail[0].position.code,
            tableData: detail,
          }}
        />
      )
    })
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
              <div className="bd-callout bd-callout-warning sticky-top">
              <p className="m-0">
                <b>Preview mode</b> - If you like what you see, you can{' '}
                <u
                  className="font-weight-bold c-pointer"
                  onClick={() => {
                    this.savePositioningEquipment(
                      this.props.params.id,
                      this.state.selected_items,
                      this.state.positions,
                      this.state.qty,
                      0
                    )
                  }}
                >
                  Update position equipments
                </u>{' '}
                or make edits by{' '}
                <u
                  className="font-weight-bold c-pointer"
                  onClick={() => {
                    this.setState({
                      preview: false,
                    })
                  }}
                >
                  Closing preview
                </u>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="scroll-to-top c-pointer">
              <i className="fa fa-chevron-up fs20" />
            </div>
            <div className="row">
              <div className="col-md-12 profile-card pt-0">
                <div className="accordion" id="itemListAccordion">
                  <PositionAreas details={this.props.position_details} />
                  {area_list}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { selected_items } = this.state
    const { media, position} = this.props
    let errors = []

    if(this.state.fresh) this.getItems()
    
    let item_list = selected_items.map((item, index) => {
      let body_area = _.find(this.props.body_areas, (e) => {
        return e.id == item.body_area
      })

      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })
      return (
        <tr key={`item-${index}`} className="h44">
          <td className="w130 px10 h44" >
            {item.catalog_number}
          </td>
          <th className="w250 font-weight-bold c-pointer text-dark fs-16" onClick={() => {

              this.setState({
                selectedItem: item,
                unique:
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15)
              }, () => {
                $(`#${this.state.unique}`).modal('show');

              })
            }}>
             <span
             data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            >
            {item.name}
            </span>
          </th>
          <td className="w170 px10 h44"> 
            <p className="m-0 p-0 float-left w-100 fs14">
              {body_area ? body_area.name : ''}
            </p> 
          </td>
          <td className="text-center w70 px10 h44">
          {item.qty}
          </td> 
          <td className="px10 w80 text-center"><a href="#" data-toggle="modal" data-target="#addNotesModal" onClick={() => {this.setState({item: item, index: index})}}> {item.position_notes ? 'Edit' : 'Add'}</a></td>
          <td className="px10 w80 text-center h44">
            <a
              href="#"
              onClick={event => {
                event.preventDefault()
                this.mediaElements(index)
              }}
            >
              {this.state.medias[index].data.length ? 'Edit' : 'Add'}
            </a>
          </td>
          <td className="px10 w80 text-center h44">
            <a
              href="#"
              className="text-dark mt15"
              onClick={event => {
                event.preventDefault()
                this.setState({
                  positions: this.state.positions.filter( (d,i) => {
                    return i != index
                  }),
                  selected_items: this.state.selected_items.filter( (d,i) => {
                    return i != index
                  }),
                  qty: this.state.qty.filter( (d,i) => {
                    return i != index
                  }),
                })
              }}
            >
              Remove
            </a>
          </td>
        </tr>
      )
    })

    if (this.state.preview) {
      return <div className="w-100">{this.positioningContent()}</div>
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">
              Edit - [{position.name}] equipment
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 mx-auto px0">
            <div className="row">
              <div className="col-md-12">
                <ul className="list-unstyled d-inline-flex align-items-center w-100 justify-content-end toolbar-options">
                  <li className="border-right border-dark h-75 d-flex align-items-center">
                    <Link
                      to={{
                        pathname: '/positioning-equipments',
                        state: { id: this.props.params.id },
                      }}
                      className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                    >
                      Cancel
                    </Link>
                  </li>
                  <li className="border-right border-dark h-75 d-flex align-items-center">
                    <a
                      href="#"
                      className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
                      onClick={() => {
                        this.savePositioningEquipment(
                          this.props.params.id,
                          this.state.selected_items,
                          this.state.positions,
                          this.state.qty,
                          1,
                          false
                        )
                      }}
                    >
                      Save
                    </a>
                  </li>
                  <li className="border-right border-dark h-75 d-flex align-items-center">
                    <a
                      href="#"
                      className="mx15 text-dark font-weight-bold d-flex align-items-center h-100"
                      onClick={() => {
                        this.preview()
                      }}
                    >
                      Preview
                    </a>
                  </li>
                  <li className="mx15 ">
                    <a
                      className="btn btn-primary m-0 btn-sm"
                      onClick={() => {
                        this.savePositioningEquipment(
                          this.props.params.id,
                          this.state.selected_items,
                          this.state.positions,
                          this.state.qty,
                          0
                        )
                      }}
                      href="#"
                    >
                      Update
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="col-md-5">
                <div className="form-group mb30">
                  <label>Position</label>
                  <p>{this.props.position.name}</p>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card border-0 rounded-0">
                  <div className="card-body">
                    {this.state.errors.map(error => {
                      return <p className="text-danger">{error}</p>
                    })}
                    <h6 className="d-flex align-items-end mb20">
                      Item list
                      <div
                        className="btn btn-outline-primary ml-auto"
                        data-toggle="modal"
                        data-target="#assignItemsModalCenter"
                        onClick={() => {
                          this.setState(
                            {
                              change: !this.state.change,
                            },
                            () => {
                              this.props.getItemsPerCategory(
                                'all',
                                this.state.selected_items,
                                1
                              )
                            }
                          )
                        }}
                      >
                        Assign items
                      </div>
                    </h6>
                    
                    <div className=" border border-dark mh300">
                      <table
                        className="table table-striped border-0"

                      >
                        <thead>
                          <tr>
                            <th
                              onClick={() => this.sortBy('catalog_number')}
                              className="w130 c-pointer px10"
                            >
                              Catalogue #
                            <i className="fa fa-sort ml5" />
                            </th>
                            <th
                              className="w300 c-pointer px10"
                              onClick={() => this.sortBy('name')}
                            >
                              Name
                            <i className="fa fa-sort ml5" />
                            </th>
                            <th
                              onClick={() => this.sortBy('body_area')}
                              className="c-pointer w170 px10"
                            >
                              Body Category
                            <i className="fa fa-sort ml5 " />
                            </th>
                            <th
                              className="c-pointer px-1 w70 px10 text-center"
                              onClick={() => this.sortBy('qty')}
                            >
                              Qty
                            <i className="fa fa-sort ml5 " />
                            </th>
                            <th className="px10 w80 text-center">Notes</th>
                            <th className="px10 w80 text-center">Media</th>
                            <th className="px10 w80 text-center">Edits</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item_list.length > 0 ? (
                            item_list
                          ) : (
                              <tr>
                                <td colSpan={7} className="text-center">
                                  <i>Complete your list by Assigning items above</i>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.addNotesModal()}
        {this.addMediaModal()}
        <AssignItemsPositioning
          assign={this.setItems}
          selected_items={this.state.selected_items}
          change={this.state.change}
        />


        <ViewItemModal
          idModal={this.state.unique}
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />      
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    position: state.positioning.position,
    position_master: state.positioning.position_master,
    position_details: state.positioning.position_details,
    media: state.positioning.media,
    body_areas: state.positioning.body_areas
  }
}

export default connect(
  mapStateToProps,
  {
    getItemsPerCategory,
    getPosition,
    savePositioningEquipment,
    getPositioningDetails,
    positioningUpdate,
  }
)(UpdatePositioningEquipment)
