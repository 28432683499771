import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import $ from 'jquery'
import { Link } from 'react-router'


export class ReviewItemsModal extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
  
  render() {
    const { selected_items } = this.props
    let itemlist = selected_items.map((item) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr>
          <td className="col-md-2" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>{item.catalog_number}</td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="col-md-3 px-1">
            <Link href={`/item/${item.id}`} target="_blank" className="text-dark fs-16" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}>
              {item.name}
            </Link>
          </td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.company} </p> </td>
          <td style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} className="w125 px-1"> <p className="m-0 p-0 float-left w-100 fs14">{item.category.name}</p> </td>
          <td className="px-0 text-center w60" style={{paddingLeft: '5px !important', paddingRight: '5px !important'}}>{item.size}</td> 
          <td className="w120 px-1" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>
            <div className="w-100 w300 d-flex align-items-center justify-content-center">
              <div className="btn btn-secondary mr0 btn-sm bg-secondary" onClick={() => { this.changeQty(item.id, 'decrement') }}><i className="fa fa-minus" /></div>
              <input type="text" name className="form-control text-center p-0 mx10 bg-white" value={item.qty ? item.qty : 1} />
              <div className="btn btn-secondary btn-sm bg-secondary" onClick={() => { this.changeQty(item.id, 'increment') }}><i className="fa fa-plus" /></div>
            </div>
          </td>
          <td class="text-left w60 pr-0">
            <div class="custom-control custom-checkbox"
              style={{ marginRight: -35, paddingLeft: 25 }}>
              <input type="checkbox" class="custom-control-input c-pointer" id={`item-${item.id}`}
                checked={this.isChecked(item.id)}
                onChange={() => { this.setPRN(item.id) }} />                <label class="custom-control-label c-pointer" for={`item-${item.id}`}>&nbsp;</label>
            </div>
          </td>
          <td className="w80 text-center p-0">
            <a href="javascript:;" className={`h64 float-left justify-content-center align-items-center d-flex w-100 ${this.checkItemExistence(item.id) ? 'text-danger' : null}`} id={`assign-item-${item.id}`} onClick={() => { $(`#assign-item-${item.id}`).toggleClass('text-danger');this.checkItem(item.id)}}>{this.checkItemExistence(item.id) ? 'Remove' : 'Assign'}</a>
          </td>
        </tr>
      )
    })


    return (
      <div className="modal" id="reviewItemsModalCenter" tabIndex={-1} role="dialog" aria-labelledby="reviewItemsModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog  modal-xl px30" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30 pb15">
              <h5 className="mb20 mx-auto font-weight-bold">Review items </h5>
              <p className="mb20">Please review and confirm assigned items. <br />Items will be separated by PRN and General OR sections.</p>
              <div className="row pb20">   
                <div className="col-md-4 form-group m-0">
                  <label className="mb10">Search</label>
                  <input type="text" name="search" className="form-control" placeholder="Start typing name, catalogue #..." />
                </div>
                <div className="col-md-4 form-group m-0">
                  <label className="mb10">Filter by [cat. name]</label>
                  <select className="form-control">
                    <option />
                    <option />
                  </select>
                </div>
                <div className="col-md-4 form-group m-0">
                  <label className="mb10">Filter by category</label>
                  <select className="form-control">
                    <option />
                    <option />
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped table-fixed border">
                    <thead className="bg-dark text-white text-left">
                      <tr>  
                        <th className="col-md-2" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>Catalogue #</th>
                        <th className="col-md-2" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>Name</th> 
                        <th className="col-md-2" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>Company</th> 
                        <th className="col-md-2" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>category</th> 
                        <th className="w50" style={{paddingLeft: '5px !important', paddingRight: '5px !important'}}>Size</th> 
                        <th className="w150 text-center" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}}>Qty</th> 
                        <th className="w50" style={{paddingLeft: '5px !important', paddingRight: '5px !important'}}>PRN</th> 
                        <th className="col-md-1" style={{paddingLeft: '10px !important', paddingRight: '10px !important'}} /> 
                      </tr>
                    </thead> 
                    <tbody style={{float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '388px'}}> 
                      {itemlist}
                    </tbody>
                  </table>
                </div>
              </div> 
            </div>
            <div className="modal-body px30 pt-0 pb30">
            </div>
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start"> 
              <div className="btn btn-dark mr30 mt-0 px30 mb-0 float-left" data-toggle="modal" data-target="#reviewItemsModalCenter">Confirm assigned items</div>
              <div className="btn btn-light border-dark px30 mt-0 mb-0 float-left" data-dismiss="modal">Back</div>
              <div className="btn btn-link px30 mt-0 mb-0 float-left" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default ReviewItemsModal
