import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'
import {
  incrementItemQty,
  decrementItemQty,
  removeItem
} from '../actions/QuicknotesActions'
import AssignItemsModal from './modals/AssignItemsModal'

export class QuicknotesItemList extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      change: 'yes'
    }

    this.modal = React.createRef()
  }
  
  componentDidUpdate = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  onSubmit = item => {
    const data = { qty: item.qty, _method: 'PATCH' }
    axios
      .post(`quicknotes-item-list/${item.id}`, data)
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        console.log(error)
      })
  }


  assignItems = (category) => {
    return ReactDOMServer.renderToStaticMarkup(
      <h6 class="mb10 d-flex align-items-end justify-content-between"> 
        {category.category_name}
        <div class="btn btn-outline-primary float-right"  data-toggle="modal" data-target="#assignItemsModalCenter">Assign items</div>
      </h6>
    )
  }

  render() {
    console.log(this.state)
    const { item_list } = this.props

    const debounce = _.debounce(item => {
      this.onSubmit(item)
    }, 500)

    let categories = item_list.map(category => {
      let items = category.details.map(item => {
        let tags = item.item.tags.map(tag => {
          return ReactDOMServer.renderToStaticMarkup(
            <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
              {tag.name}
            </span>
          )
        })
        return (
          <tr>
            <td>{item.item.catalog_number}</td>
            <td>
              <Link
                href={`/item/${item.item.id}`}
                className="text-dark"
                data-container="body"
                data-trigger="hover"
                data-toggle="popover"
                data-placement="right"
                data-html="true"
                data-content={`
                                  <div class="row">
                                  <div class="col-md-4 text-center pr-0">
                                  <img src=${
                                    item.item.icon
                                  } class="bg-dark w-100">
                                  </div>
                                  <div class="col">
                                  <h6 class="mb15">${
                                    item.item.name
                                  }</h6>
                                  <p>${item.item.used_for}</p>
                                  </div>
                                  <div class="col-md-12 mt15">
                                  <p class="font-weight-bold mb10">Tags</p>
                                  ${tags}
                                  </div>
                                  </div>`}
              >
                {item.item.name}
              </Link>
            </td>
            <td>
              {' '}
              <p className="m-0 p-0 float-left w-100">
                {item.item.company}
              </p>
            </td>
            {this.props.edit ? (
              <td
                className="px-0"
                style={{
                  paddingLeft: '10px !important',
                  paddingRight: '10px !important',
                }}
              >
                <div className="w-100 w300 d-flex align-items-center justify-content-center">
                  <div
                    className="btn btn-secondary mr0 btn-sm m-0 bg-secondary"
                    onClick={() => {
                      this.setState({
                        change: !this.state.change,
                      })
                      this.props.decrementItemQty(
                        category.id,
                        item.item.id
                      )
                    }}
                  >
                    <i className="fa fa-minus" />
                  </div>
                  <input
                    type="text"
                    name
                    className="form-control text-center px-0 bg-white w50 mx10"
                    value={item.qty}
                  />
                  <div
                    className="btn btn-secondary m-0 btn-sm bg-secondary"
                    onClick={() => {
                      this.setState({
                        change: !this.state.change,
                      })
                      this.props.incrementItemQty(
                        category.id,
                        item.item.id
                      )
                    }}
                  >
                    <i className="fa fa-plus" />
                  </div>
                </div>
              </td>
            ) : (
              <td className="text-center">{item.qty}</td>
            )}

            <td className="text-center">{item.item.size}</td>
            {this.props.edit ? (
              <td
                style={{
                  paddingLeft: '10px !important',
                  paddingRight: '10px !important',
                }}
              >
                <a
                  href="#"
                  className="text-dark"
                  onClick={() => {
                    this.props.removeItem(category.id, item.item.id)
                    this.setState({
                      change: !this.state.change,
                    })
                  }}
                >
                  Remove
                </a>
              </td>
            ) : null}
          </tr>
        )
      })
      return (
        <div>
          <div 
            dangerouslySetInnerHTML={{ __html: item_list.indexOf(category) == 0 && this.props.edit ? this.assignItems(category) : `<h6 class="mb10 mt20 d-flex align-items-end justify-content-between"> ${category.category_name}</h6>` }}
          >
            
          </div>
          
          <table className="table normal-table table-striped table-hover border table-hover-bold">
            <thead>
              <tr>
                <th className="w120">Catalogue #</th>
                <th className="w300">Name</th>
                <th className="w180">Company</th>
                <th className="text-center">Qty</th>
                <th className="text-center">Size</th>
                {this.props.edit ? <th /> : null}
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
        </div>
      )
    })

    return (
      <div className="card p-0 shadow-sm border-0">
        <div
          className="card-header bg-white px15 pt15 border-0"
          id="note-headeing-1"
        >
          <h5
            className="mb-0 d-flex align-items-center c-pointer"
            data-toggle="collapse"
            data-target="#notes-1"
            aria-expanded="true"
            aria-controls="notes-1"
          >
            Show item list
            <i className="fa fa-chevron-down float-right ml-auto fs14" />
          </h5>
        </div>
        <div
          id="notes-1"
          className="collapse"
          aria-labelledby="note-headeing-1"
          data-parent="#itemListAccordion"
        >
          <div className="card-body pt-0 mt5 px15">{categories}</div>
        </div>
        <AssignItemsModal key={this.props.quicknotes_id} change={this.state.change}/>
      </div> 

    )
  }
}

const mapStateToProps = state => {
  return {
    item_list: state.quicknotes.item_list,
    quicknotes_id: state.quicknotes.quicknotes_id
  }
}

export default connect(
  mapStateToProps,
  { incrementItemQty, decrementItemQty, removeItem }
)(QuicknotesItemList)
