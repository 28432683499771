import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link} from 'react-router'
import { getSurgeon } from '../actions/SurgeonActions'
import { setQuicknotesId } from '../actions/QuicknotesActions'
import { clearSurgeonData } from '../actions/SurgeonActions'

class SurgeonCard extends Component {
	constructor(props) {
		super(props)
	
		this.state = {
			 
		}
	}
	
	setquicknotes = (surgeonData) => {
		this.props.getSurgeon(surgeonData.id)
		this.props.clearSurgeonData()
	}

	componentDidMount = () => {
		this.props.setQuicknotesId(9)
	}

  render() {
		const { surgeonData } = this.props

    const specialities = surgeonData.specialties.map(data => {
      return (
        <span className="badge badge-secondary badge-pill px15 py5 mr10 mt10 surgeon-badge">{`${
          data.specialty.name
        }`}</span>
      )
		})

    return (
			<div className="col-md-4 surgeon-card">
				<Link to={{ pathname: `/quicknotes/${surgeonData.id}` }} onClick={() => { this.setquicknotes(surgeonData) }} className="no-underline">
					<div className="card">
						<div className="card-body">
							<div className="media">
								<img
									src={`${surgeonData.avatar}`}
									className="align-self-center mr-3 bg-dark rounded-circle surgeon-card-img"
									alt=""
								/>
								<div className="media-body align-self-center">
									<h6 className="m-0 surgeon-name font-weight-bold">{`${surgeonData.firstname} ${
										surgeonData.lastname
									}`}</h6>
									<p className="m-0 surgeon-sub font-weight-normal fs14">{`${
										surgeonData.title != null ? surgeonData.title : ''
									}`}</p>
								</div>
							</div>
							<div className="row py-3">
								<div className="col-md-6">
									<h6 className="m-0 font-weight-bold surgeon-name">Gloves</h6>
									<p className="font-weight-normal fs14">{`${
										surgeonData.inner_glove == undefined
											? ''
											: surgeonData.inner_glove.name
									}`}</p>
									<h6 className="m-0 font-weight-bold surgeon-name">{`${
										surgeonData.outer_glove == undefined ? '' : 'Outer Gloves'
									}`}</h6>
									<p className="font-weight-normal fs14">{`${
										surgeonData.outer_glove == undefined
											? ''
											: surgeonData.outer_glove.name
									}`}</p>
								</div>
								<div className="col-md-6 border-left">
									<h6 className="m-0 font-weight-bold surgeon-name">Prefers</h6>
									<p className="font-weight-normal fs14">{`Music ${surgeonData.music ? 'on' : 'off'}`}</p>
									<p className="m-0 font-weight-normal fs14">
										{surgeonData.enviroment_on ? '' : 'Silent OR'}
									</p>
								</div>
								<div className="col-md-12 pt-3">
									<h6 className="m-0 font-weight-bold surgeon-name">
										Specialties
									</h6>
									{specialities}
								</div>
							</div>
						</div>
					</div> 
				</Link>
			</div>
    )
  }
}

export default connect(null, {getSurgeon, setQuicknotesId, clearSurgeonData})(SurgeonCard)
