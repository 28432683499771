import React, { Component } from 'react'
import AssignItemsProcedure from '../modals/AssignItemsProcedure'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import $ from 'jquery'
import axios from 'axios'
import { TextInput } from '../commons/forms'
import { getError } from '../../utilities'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Confirmation from '../Confirmation'
import ViewItemModal from '../modals/ViewItemModal'
import Notifier from '../commons/notifier/Notifier'
import WholeBodyloader from '../loader/WholeBodyloader'


const SortableItem = SortableElement(({ item, setNewState}) => (
  <li className="mb10 font-weight-bold d-flex align-items-center align-items-stretch flex-column flex-md-row">
    <div className="font-weight-bold d-flex align-items-center align-items-stretch flex-fill max-w300 mr15 order-2 order-md-1">
      <div className="w50 bg-dark p10 text-center rounded-left c-pointer d-flex justify-content-center align-items-center">
        <i className="fa fa-arrows fs18 text-white" />
      </div>
      <div className="main-card rounded flex-fill max-w250 d-flex align-items-center p10">
        <span className="pl10 work-break-all">{item.name}</span>
      </div>
    </div>
    <a href="#"
      className="mr30 m-xsx0 d-flex align-items-center order-1 order-md-2 justify-content-end max-w300 m-xsy5"
      data-toggle="modal"
      data-target="#confirmationDeleteSectionModal"
      onClick={e => {
        e.preventDefault()
        setNewState({
          section: item,
        })
      }}
    >
      Remove
    </a>
  </li>
))

const SortableList = SortableContainer(({ items, setNewState }) => {
  return (
    <ul className="list-unstyled mb-0">
      {items.map((item, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            item={item}
            setNewState={setNewState}
          />
        )
      })}
    </ul>
  )
})

export class ConsumableLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      create_category: false,
      section_name: '',
      section: {},
      errors: [],
      change: false,
      edit: false,
      selected_flag: false,
      detail_stage: [],
      custom_stage: [],
      selectedItem: null,
      draft_success: false,
      showNotification: false,
      notificationMessage: '',
      progress: 0
    }

    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow(val, message = '') {
    this.setState({
      showNotification: val,
      notificationMessage: message
    })
  }

  componentDidMount = () => {
    this.scrollToTop()
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }

  componentWillReceiveProps = nextProps => {
    if(this.props.stage_master != nextProps.stage_master){
      if(!nextProps.stage_master.draft){
        this.setState({
          edit: false,
          item_list: nextProps.item_list
        })
      }
    }

    if(!this.state.selected_flag){
      this.generateSelected(nextProps.stage_details)
    }

    if (nextProps.stage_details != this.props.stage_details) {
      this.setState({
        detail_stage: nextProps.stage_details.filter(item => {
          return item.default_value == 1
        }),
        custom_stage: nextProps.stage_details.filter(item => {
          return item.default_value == 0
        }),
      })
    }
  }

  scrollToTop = () => {
    $('.scroll-to-top').fadeOut(0)
    $(window).scroll(function () {
      if ($(this).scrollTop() > 350) {
        $('.scroll-to-top').fadeIn()
      } else {
        $('.scroll-to-top').fadeOut()
      }
    })
    
    $(document).on('click', '.scroll-to-top', function () {
      $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 0)
      return false
    })

    setTimeout(() => {
      $('[data-toggle="popover"]').popover()
      $('.popover').popover('hide')
    }, 200);
  }
  
  addSection = () => {
    const { stage_master } = this.props
    this.setState({
      errors: []
    })

    const data = {
      name: this.state.section_name,
      stage_id: stage_master.id,
      create_category: !this.state.create_category
    }

    axios.post(`api/admin/stage-consumable`, data).then((result) => {
      this.setState({
        create_category: !this.state.create_category
      })
      this.props.getProcedureDetail(1)
    }).catch(error => {
      console.log(error)
      console.log(error.response)
      this.setState({
        errors: error.response.data.errors
      })
    })
  }
  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.setState({selected_items: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  setNewState = (states) => {
    this.setState(states)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ custom_stage }) => ({
      custom_stage: arrayMove(custom_stage, oldIndex, newIndex),
    }), () => {
      const combind = [...this.state.detail_stage,...this.state.custom_stage]
      const data = new FormData
      console.log('combind', combind)
      combind.map( (item,index) => {
        if(item.items)
        data.append(`item_ids[]`,item.id)
      })
      axios.post('api/admin/stage-consumable-sort',data).then(result => {
        console.log(result)
        this.props.getProcedureDetail(1)
      }).catch( error => {
        console.log(error)
        console.log(error.response)
      })
    })
  }

  deleteSection = () => {
    const { section } = this.state

    let url = `/api/admin/stage-consumable/${section.id}`

    axios.post(url, { _method: 'DELETE' }).then((res) => {
      this.props.getProcedureDetail(1)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }

  editSection = () => {
    const data = {
      name: this.state.section_name,
    }

    let url = `api/admin/stage-consumable/${this.state.section.id}`
    axios.post(url, data).then((res) => {
      this.setState({
        create_category: false
      })
      this.props.getProcedureDetail(1)
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
      this.setState({
        errors: err.reponse.data.errors
      })
    })
  }

  generateSelected = (stage_details) => {
    let temp = []
    stage_details.map(detail => {
      if (detail.items) {
        detail.items.map(item => {
          item.item_category = detail.id
          temp.push(item)
        })
      }
    })

    this.setState({
      selected_items: temp,
    })
  }

  renderIndex() {
    const { stage_details } = this.props

    let headerlist = stage_details.map(detail => {
      if (detail.items ? detail.items.length : false) {
        return (
          <li className="list-inline-item pr20 fs16 mb15 mx-0">
            <a href={`#${detail.name}`}>{detail.name}</a>
          </li>
        )
      }
    })

    let detaillist = stage_details.map(detail => {
      if (detail.items ? detail.items.length : false) {
        let itemlist = detail.items.map(item => {
          let tags = item.tags.map(tag => {
            return ReactDOMServer.renderToStaticMarkup(
              <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
                {tag.name}
              </span>
            )
          })
          return (
            <tr>
              <td className="w150">{`${item.catalog_number}`}</td>
              <td
                className="w280 font-weight-bold c-pointer text-dark fs-16 px12"
                data-container="body"
                data-trigger="hover"
                data-toggle="popover"
                data-placement="right"
                data-html="true"
                data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
                onClick={() => {
                  this.setState(
                    {
                      selectedItem: item,
                      unique:
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15),
                    },
                    () => {
                      $(`#viewItemConsumableLayout`).modal('show')
                    }
                  )
                }}>
                  {`${item.name}`} 
              </td>
              <td className="w100 text-center">{`${item.size}`}</td>
              <td className="w100 text-center">{`${item.qty}`}</td>
            </tr>
          )
        }) 
         

        return (
          <div className="col-md-12 mx-auto mb30" id={`${detail.name}`}>
            <div className="card rounded-0">
              <div className="card-body">
                <h5 className="mb20 fs20 sticky-top bg-white">{detail.name}</h5>
                <div className="w-100 of-auto">
                  <table className="m-0 table table-hover table-striped min-table-width-640">
                    <thead>
                      <tr>
                        <th className="w150">Catalogue #</th>
                        <th className="w280">Name</th>
                        <th className="w100 text-center">Size</th>
                        <th className="w100 text-center">Qty</th>
                      </tr>
                    </thead>
                    <tbody>{
                      itemlist.length > 0 ?
                        itemlist :
                        <tr>
                          <td className="w-100 text-center" colSpan={4}>
                            No item(s) added. Add new items via <a href="javascript:;"
                              onClick={e => {
                                e.preventDefault()
                                this.setState({
                                  edit: true,
                                  draft_success: false
                                })
                                this.props.getProcedureDetail(1)
                              }}>Edit notes</a>.
                      </td>
                        </tr>
                    }</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) 
      }
    })
    return (
      <div className="row mt40">
        <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center">
          <h3 className="mb20 h2med">Consumables</h3>
          <a
            className=""
            href="javascript:;"
            onClick={e => {
              e.preventDefault()
              this.setState({
                edit: true,
              })
              this.props.getProcedureDetail(1)
            }}
          >
            {this.props.title} Edit
          </a>
        </div>
        <div className="col-md-12 mx-auto mb30">

          <Notifier show={this.state.showNotification && !this.state.draft_success} message={this.state.notificationMessage} type="success" styleClass="mb30 mt10"
            toggleShow={this.toggleShow} />
            
          <div className="card rounded-0">
            <div className="card-body pb-0">
              <h5 className="mb15 font-weight-bold fs20">Jump to a section</h5>
            </div>
            <div className="card-body pt-0 pb15">
              <ul className="list-inline mb-0">{headerlist}</ul>
            </div>
          </div>
        </div>

        {detaillist}
      </div>
    )
  }

  editContent() {
    let itemlist = this.state.selected_items.map((item, index) => {
      
      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })

      let section = this.state.detail_stage.find((e) => {
        return e.id == item.item_category
      })

      if(!section){
        section = this.state.custom_stage.find((e) => {
          return e.id == item.item_category
        })
      }

      return (
        <tr>
          <td className="w150 px10">{item.catalog_number}</td>
          <td
            className="font-weight-bold c-pointer text-dark fs-16 w250 px10"
            data-container="body"
            data-trigger="hover"
            data-toggle="popover"
            data-placement="right"
            data-html="true"
            data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            onClick={() => {
              this.setState(
                {
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                },
                () => {
                  $(`#viewItemConsumableLayout`).modal('show')
                }
              )
            }}
          >
            {item.name}
          </td>
          <td className="w150 px10">
            {section ? section.name : '-'}
          </td>
          <td className=" text-center w100 px10">
            <p className="m-0 p-0 float-left w-100 fs14">{item.size} </p>
          </td>
          <td className="text-center w100 d-flex px10">
            <p className="m-0 p-0 float-left w-100 fs14">{item.qty} </p>
          </td>
        </tr>
      )
    })

    let sectionlist = this.state.detail_stage.map(category => {
      if (category.items && category.default_value == 1) {
        return (
          <li className="mb10 font-weight-bold d-flex align-items-center">
            <div className="main-card rounded w300 d-flex align-items-center mr15 p10">
              <span className="pl10">{category.name}</span>
            </div>
          </li>
        )
      }
    })

    const custom_data = this.state.custom_stage

    return (
      <div className="row mt20">
        <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center mt20">
          <h3 className="h2med mb20 font-weight-bold">Editing - Consumables</h3>
        </div>
        <div className="col-md-12 mx-auto">
          <ul className="list-unstyled d-inline-flex align-items-center main-card w-100 justify-content-end toolbar-options flex-wrap">
            <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center">
              <a
                href="#"
                className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 m-xsx10 "
                onClick={e => {
                  e.preventDefault()
                  this.props.getProcedureDetail(0)
                  this.setState({
                    edit: false,
                    draft_success: false
                  })
                }}
              >
                Cancel
              </a>
            </li>
            
            <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center border-md-0">
              <a
                href="#"
                className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 m-xsx10 "
                onClick={(e) => {
                  e.preventDefault()
                  this.setState({
                    draft_success: true,
                    showNotification: true,
                    notificationMessage: 'Saved to draft!'
                  })
                }}
              >
                Save
              </a>
            </li>
           
            <li className="d-flex align-items-center flex-fill my0 px15 border-xs-0 text-center justify-content-md-start justify-content-lg-center justify-content-xl-center justify-content-center">
              <div
                className="btn btn-primary btn-sm px30 p-xsx10 "
                onClick={() => {
                  this.onSubmmit(0)
                }}
              >
                Update
              </div>
            </li>
          </ul>
        </div>

        <div className="col-md-12 mx-auto">
          {/* {this.state.draft_success ? <InlineNotification message={'Saved to drafts!'} /> : null} */}
        </div>
        
        <div className="col-md-12 mx-auto">
          <Notifier show={this.state.showNotification && this.state.draft_success} message={this.state.notificationMessage} type="success" styleClass="mb30 mt10"
            toggleShow={this.toggleShow} />
            
          <div className="card">
            <div className="card-body pb-0">
              <h5 className="mb15 font-weight-bold fs20">Jump to a section</h5>
            </div>
            <div className="card-body pt-0 pb15">
              <ul className="list-unstyled">
                {sectionlist}
                <SortableList
                  items={custom_data}
                  pressDelay={150}
                  onSortEnd={this.onSortEnd}
                  setNewState={this.setNewState}
                />
                {
                  this.state.create_category ? 
                    <li>
                      <div className="form-group mt20">
                        <div className="d-flex flex-column flex-md-row">
                          <TextInput
                            label={'New section name'}
                            value={this.state.section_name}
                            placeholder={'Name'}
                            errorMessage={getError(this.state.errors, 'name')}
                            containerClass={`mb0 max-w300 `}
                            onValueChange={event => {
                              this.setState({ section_name: event.target.value })
                            }}
                          />
                          <div className="d-flex align-items-end flex-column flex-md-row max-w300"> 
                            {this.state.section.id ? 
                              <div className="btn btn-dark mx15 m-xst20 max-w300 m-xsx0"
                                onClick={() => {
                                  this.editSection()
                                }}
                              >Save</div>
                              : 
                              <div className="btn btn-dark mx15 m-xst20 max-w300 m-xsx0"
                                onClick={() => {
                                  this.addSection()
                                }}
                              >Save</div>
                            }
                            <div className="btn btn-outline-dark m-xst20" onClick={() => {this.setState({create_category: false, errors: []})}}>Cancel</div>
                          </div>  
                      </div>
                    </div>
                  </li>
                 : (
                <li className="media d-flex mb10 align-items-center"
                    onClick={() => {
                      this.setState({
                        create_category: true,
                        section_name: '',
                        errors: [],
                      })
                    }}
                  >
                    <div
                      className="p20 rounded text-center w300 bodybold"
                      style={{ border: '2px dashed #979797' }}
                      onClick={() => {
                        this.setState({ addSection: !this.state.addSection, section: {}, section_name: '' })
                      }}
                    >
                      <u>Add new section + </u>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-12 mx-auto mb30 mt30">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="mb20 fs20 sticky-top bg-white d-flex align-items-md-center justify-content-start justify-content-md-between flex-column flex-md-row">
                <span>
                  Item list
                </span>
                <div
                  className="btn btn-outline-primary ml-auto m-xst20"
                  data-toggle="modal"
                  data-target="#assignItemsModalCenter"
                  onClick={() => {
                    this.setState({
                      change: !this.state.change,
                    })
                  }}
                >
                  Assign items
                </div>
              </h5>
              <div className="w-100 of-auto">
                <table className="m-0 table table-hover table-striped min-table-width-755">
                  <thead>
                    <tr>
                      <th className="w150 px10" onClick={() => {
                        this.sortBy('catalog_number')
                      }}>Catalogue #
                    <i className="fa fa-sort ml5"></i>
                      </th>
                      <th className="w250 px10" onClick={() => {
                        this.sortBy('name')
                      }}>Name
                    <i className="fa fa-sort ml5"></i>
                      </th>
                      <th className="w150 px10" onClick={() => {
                        this.sortBy('item_category')
                      }}>Section
                    <i className="fa fa-sort ml5"></i>
                      </th>
                      <th className="w100 px10 text-center" onClick={() => {
                        this.sortBy('size')
                      }}>Size
                    <i className="fa fa-sort ml5"></i>
                      </th>
                      <th className="w100 px10 text-center" onClick={() => {
                        this.sortBy('qty')
                      }}>Qty
                    <i className="fa fa-sort ml5"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemlist}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onSubmmit = (draft = 0) => {
    const { selected_items } = this.state
    const { stage_details, stage_master, surgeon } = this.props
    const data = new FormData()
    data.append(`draft`, draft)
    data.append(`_method`, 'PATCH')
    stage_details.map((stage, index) => {
      if (index > 0) {
        data.append(`consumables[${index}]`, stage.id)
        selected_items.map(item => {
          if (stage.id == item.item_category) {
            console.log('true')
            data.append(`item_ids[${index}][]`, item.id)
            data.append(`qty[${index}][]`, item.qty)
          }
        })
      }
    })

    let url = `api/admin/stage-consumable-add-item/${stage_master.id}`
    let config = {
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.setState({
          progress: percentCompleted >= 95 ? 95 : percentCompleted,
        })
      }
    }

    axios.post(url, data, config).then((result) => {
      this.props.getProcedureDetail(0)
      if(!draft){
        this.setState({
          progress: 0,
          edit: false,
          draft_success: false,
          showNotification: true,
          notificationMessage: 'Consumables updated!'
        })
      }else{
        this.setState({
          progress: 0,
        })
      }
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      this.setState({
        progress: 0
      })
    })
  }
  render() {
    return (
      <div className="col-lg-9 mx-auto">
        <div className="scroll-to-top c-pointer">
          <i className="fa fa-chevron-up fs20" />
        </div>
        {this.state.edit ? this.editContent() : this.renderIndex()}

        <WholeBodyloader message="Saving! please wait..." progress={this.state.progress} type="spinner" />

        <AssignItemsProcedure
          setNewState={this.setNewState}
          selected_items={this.state.selected_items}
          change={this.state.change}
          stage_master={this.props.stage_master}
          item_categories={this.props.stage_details}
        />

        <Confirmation
          idModal="confirmationDeleteSectionModal"
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.deleteSection()
          }}
          cancel={() => {
            $('#confirmationModal').modal('hide')
          }}
        />

        <ViewItemModal
          idModal="viewItemConsumableLayout"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemConsumableLayout').modal('hide')
          }}
        />   

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    surgeon: state.surgeon.surgeon
  })
}

export default connect(mapStateToProps)(ConsumableLayout)
