import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server'


export class SurgeonInfo extends Component {
  
  componentDidUpdate = () => { 
    $( '[data-toggle="popover"]').popover()
  }

  render() {
    const { surgeon } = this.props

    let glovetag = surgeon.inner_glove ? surgeon.inner_glove.tags.map((tag) => {
      return ReactDOMServer.renderToStaticMarkup(
        <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
      )
    }):null

    
    let outerglovetags = surgeon.outer_glove ? surgeon.outer_glove.tags.map((tag) => {
      return ReactDOMServer.renderToStaticMarkup(
        <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
      )
    }) : null
    return (
      <div className="card side-card border-0">
        <div className="card-body border-0 pb-0 pt-0">

          <div className="row profile-img-overlay pt20">
            <div className="col-md-12 pb10 ">
              <div style={{ height: '100px', width: '100px' }} className="card border-0 text-white p-0 mx-auto rounded-circle">
                <img src={surgeon.avatar} className="rounded-circle h-100" />	
                <div className="card-img-overlay bg-transparent rounded-circle d-flex justify-content-start align-items-end p-0 of-hidden">
                  <a href="#" className="btn btn-link text-white card-text text-center w-100 py10 bg-black animated fadeIn faster fs14 font-weight-normal">Edit</a>
                </div>
              </div>
            </div>

            <div className="col-md-12 text-center">
              <p className="m-0 fs20 font-weight-bold">{surgeon.firstname} {surgeon.lastname}</p>

              <div
                dangerouslySetInnerHTML={{
                  __html: surgeon.title
                    ? `<p class="fw-100 m-0">${surgeon.title}</p>`
                    : null,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: surgeon.contact_number
                    ? `<p class="mb20">${surgeon.contact_number}</p>`
                    : null,
                }}
              />  
            </div>
          </div> 
          <div className="row">
            {/* <div className="col-md-12 text-center pb10">
              <img src={surgeon.avatar} style={{height: '100px', width: '100px'}} className="bg-dark rounded-circle" />	
            </div>
            
            <div className="col-md-12 text-center"> 
              <p className="m-0 fs20 font-weight-bold">{surgeon.firstname} {surgeon.lastname}</p>
              
              <p className="m-0 mb10">Surgeon's job title</p>
              <p className="mb20">{surgeon.contact ? surgeon.contact : 'Not specified.'}</p>
            </div>
            <div className="col-md-12">
              <h6 className="mb20 font-weight-bold">{surgeon.handedness ? `${surgeon.handedness.slice(0, 1).toUpperCase()}${surgeon.handedness.slice(1)} handed` : null}</h6>
            </div> */}
            <div className="col-md-12">
              <h6 className=" font-weight-bold mb5">Gloves</h6>
              <p className="mb5">
                {
                  surgeon.inner_glove ? (
                    <Link href={`/item/${surgeon.inner_glove.id}`} className="text-dark font-weight-normal" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="auto" data-html="true" data-content={`
                                              <div class="row">
                                              <div class="col-md-4 text-center pr-0">
                                              <img src=${surgeon.inner_glove.icon} class="bg-dark w-100">
                                              </div>
                                              <div class="col">
                                              <h6 class="mb15">${surgeon.inner_glove.name}</h6>
                                              <p>${surgeon.inner_glove.used_for != null ? surgeon.inner_glove.used_for : "No specified."}</p>
                                              </div>
                                              <div class="col-md-12 mt15">
                                              <p class="font-weight-bold mb10">Tags</p>
                                              ${glovetag}
                                              </div>
                                              </div>`}>
                  {surgeon.inner_glove.name}
                </Link>
                  ):null
                }
               
              </p>
            </div>
            {
                  surgeon.inner_glove ? (
            <div className="col-md-12">
              <h6 className="mb20 font-weight-bold">Size <span className="font-weight-normal ">{surgeon.inner_glove.size}</span></h6>
            </div>):null
            }
            {surgeon.outer_glove ? 
              <div className="col-md-12">
                <h6 className=" font-weight-bold mb5">Outer Gloves</h6>
                <p className="mb5">
                  
                    <Link href={`/item/${surgeon.outer_glove.id}`} className="text-dark font-weight-normal" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="auto" data-html="true" data-content={`
                                                  <div class="row">
                                                  <div class="col-md-4 text-center pr-0">
                                                  <img src=${surgeon.outer_glove.icon} class="bg-dark w-100">
                                                  </div>
                                                  <div class="col">
                                                  <h6 class="mb15">${surgeon.outer_glove.name}</h6>
                                                  <p>${surgeon.outer_glove.used_for}</p>
                                                  </div>
                                                  <div class="col-md-12 mt15">
                                                  <p class="font-weight-bold mb10">Tags</p>
                                                  ${outerglovetags}
                                                  </div>
                                                  </div>`}>
                      {surgeon.outer_glove.name}
                    </Link>
                </p>
              </div>
              
            : null}
            
            {surgeon.outer_glove ? 
              <div className="col-md-12">
                <h6 className="mb20 font-weight-bold">Size <span className="font-weight-normal ">{surgeon.inner_glove.size}</span></h6>
              </div>
            : null}
            <div className="col-md-12 pb20">
              <h6 className="mb5 font-weight-bold">Prefers</h6>
              <p className="mb5">Music <b>{surgeon.music == 1 ? 'ON' : 'OFF'}</b></p>
              {surgeon.environment_in == 1 ? <p className="mb5"><b>Normal</b> OR noise</p> : null}
              
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    surgeon: state.surgeon.surgeon
  })
} 

export default connect(mapStateToProps)(SurgeonInfo)
