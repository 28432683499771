import React, { Component } from 'react'
import CKEditor from 'ckeditor4-react'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import img from '../assets/images/img.png'
import VideoThumbnail from 'react-video-thumbnail'
import { TextInput } from './commons/forms'
import { getSubElementError } from '../utilities'
import { SortableHandle } from 'react-sortable-hoc'
import ImageCropper from './commons/elements/inputs/ImageCropper'
import $ from 'jquery'
import CircleLoading from './loader/CircleLoading'
import { uploadImage } from '../utilities/upload-image'
import { uploadVideo } from '../utilities/upload-video'
import axios from 'axios'

const DragHandle = SortableHandle(() => {
  return (
    <a
      href="#"
      className="text-dark font-weight-bold d-flex align-items-center justify-content-end m-0 px15 bg-dark move-icon mh50 mr-auto"
    >
      <i className="fa fa-arrows text-white fa-2x" />
    </a>
  )
})

class MediaBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source:'',
      file: null,
      file_raw: '',
      croppedFile: null,
      show: false,
      filename: '',
      selected: null,
      image: img,
      type: 'image',
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      loading: false,
      progress: 0,
      xhr: new XMLHttpRequest(),
    }

    this.handleClick = this.handleClick.bind(this)
  }


  componentDidMount = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.setState({
      source
    })
  }

  handleClick(e) {
    this.refs.file.click()
  }

  onFileChange = () => {

    this.setState({
      loading: true,
      progress: 0,
    })
    
    if (this.refs.file.files[0].type.split('/')[0] == 'image') {
      $(`#imageCropper${this.props.validation.index}${this.props.index}`).modal(
        { backdrop: 'static', keyboard: false, show: true }
      )
      var reader = new FileReader()
      var url = reader.readAsDataURL(this.refs.file.files[0])
      reader.onloadend = () => {
        this.setState({
          file_raw: reader.result,
          type: 'image',
        })
      }
    } else {
      if (!this.refs.file.files[0]) {
        this.setState({
          show: false,
          file: null,
          croppedFile: null,
        })
        return
      }

      const fileData = this.refs.file.files[0]

      this.props.progressMedia()
      uploadVideo(
        fileData,
        progress => {
          this.setState({
            progress: Math.round((progress.loaded * 100) / progress.total),
          })
        },
        this.state.source.token
      )
        .then(result => {
          console.log(' this.props', result)
          if (typeof this.props.thumbnailChange === 'function') {
            this.props.thumbnailChange(result.thumbnail)
          } else {
            this.props.onStandardThumbnailChange(result.thumbnail)
          }

          this.props.onMediaChange({
            data: result.source,
            type: 'video',
          })
          this.setState(
            {
              loading: false,
              progress: 0,
            },
            () => {
              this.props.progressComplete()
            }
          )
        })
        .catch(error => {
          console.log(error)
        })

      return

     
    }
  }

  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    reader.onload = () => {
      this.props.onMediaChange({ data: reader.result, type: 'image' })
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
        image: reader.result,
      })
    }
  }

  renderCropper() {
    const { file,loading,progress } = this.state
    const { media_data, validation, index } = this.props
    let error = getSubElementError(
      validation.errors,
      validation.prefix,
      validation.index,
      'media-value',
      index
    )
    console.log(error)
    if (loading) return <CircleLoading progress={progress} />
    if (!file) {
      if (media_data.source) {
        return (
          <label
            className={`col-lg-4 d-flex justify-content-center align-items-center bg-light of-hidden m-0 preview-media-section c-pointer ${
              error ? 'is-invalid' : ''
            }`}
            style={
              error
                ? { border: '2px dashed red' }
                : { border: '2px dashed rgb(132, 134, 136)' }
            }
          >
            <span className="change_media_label">
              Change {this.state.type == 'image' ? 'image' : 'video'}
            </span>
            <input
              type="file"
              name="image"
              accept="video/*,image/*"
              className="w300"
              style={{ zIndex: -1, position: 'absolute' }}
              onChange={this.onFileChange}
              onClick={event => {
                event.target.value = null
              }}
              ref="file"
              required
            />
            {this.state.type == 'image' ? (
              <img src={media_data.thumbnail} class="w-100" />
            ) : (
              <VideoThumbnail
                videoUrl={this.props.media_data.source}
                thumbnailHandler={thumbnail =>
                  this.props.thumbnailChange(thumbnail)
                }
              />
            )}
          </label>
        )
      }

      return (
        <label
          className={`col-lg-4 d-flex justify-content-center align-items-center bg-light of-hidden m-0 preview-media-section c-pointer ${
            error ? 'is-invalid' : ''
          }`}
          style={
            error
              ? { border: '2px dashed red' }
              : { border: '2px dashed rgb(132, 134, 136)' }
          }
        >
          <input
            type="file"
            name="image"
            accept="video/*,image/*"
            className="w300"
            style={{ zIndex: -1, position: 'absolute' }}
            onChange={this.onFileChange}
            onClick={event => {
              event.target.value = null
            }}
            ref="file"
            required
          />
          Add image or video +
        </label>
      )
    }
  }
  render() {
    const { validation, index } = this.props
    console.log(this.state)
    return (
      <div
        className="card p-0 my15 rounded-0 border-0 shadow-none w-100"
        key={`${validation.index} ${index}`}
      >
        <div className="card-header py-0 px-0 rounded-0 border-0 shadow-none d-flex bg-secondary pl-0 flex-wrap flex-sm-nowrap justify-content-end justify-content-md-start">
          <DragHandle />
          <div className="d-flex mh50 order-3 order-xs-1 order-sm-1 oder-md-1 flex-fill align-items-center">
            <div className="font-weight-bold ml-auto pl10 d-flex align-items-center justify-content-end text-dark d-xs-block d-none mw50">
              Title
            </div>
            <TextInput
              value={
                this.props.media_data.title ? this.props.media_data.title : ''
              }
              placeholder={'e.g. Images'}
              errorMessage={getSubElementError(
                validation.errors,
                validation.prefix,
                validation.index,
                validation.field,
                index
              )}
              containerClass={`mb0 w-100 non-rounded-input mx10 d-flex align-items-center`}
              onValueChange={this.props.onTitleChange}
            />
          </div>
          <a
            href="#"
            className="font-weight-bold ml-auto pl30 pr15 d-flex align-items-center justify-content-end text-dark p-xsx15 m-xsx0 justify-content-center  order-2 order-xs-2"
            onClick={event => {
              const { source } = this.state
              source.cancel('Operation canceled by the user.')
              this.props.onDeleteMediaElement(event, validation.index, index)
            }}
          >
            <u>Delete</u>
          </a>
        </div>

        <div className="card-body rounded-0 border-0 py0 shadow-none">
          <div className="row d-flex h-100">
            {this.renderCropper()}
            <div className="col h-100 px0">
              <CKEditor
                data={this.props.media_data.caption}
                onChange={this.props.onCaptionChange}
                config={{
                  allowedContent: {
                    $1: {
                      attributes: false,
                      styles: false,
                      classes: false
                    }
                  },
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', '-'],
                    [
                      'NumberedList',
                      'BulletedList',
                      '-',
                      // 'Outdent',
                      // 'Indent',
                      // '-',
                      'JustifyLeft',
                      'JustifyCenter',
                      'JustifyRight',
                      // 'JustifyBlock',
                      '-',
                    ],
                    [
                      //'Table', 'HorizontalRule',
                      'Link',
                      'Smiley',
                    ],
                    ['Format'],
                    ['TextColor', 'BGColor'],
                  ],
                }}
              />
            </div>
          </div>
        </div>

        <ImageCropper
          key={this.state.unique}
          id={`imageCropper${validation.index}${index}`}
          image={this.state.file_raw}
          aspectRatio={3 / 2}
          width={720}
          height={480}
          getImage={image => {
            this.props.progressMedia()
            uploadImage(image, progress => {
              this.setState({
                progress: Math.round((progress.loaded * 100) / progress.total),
              })
            },
            this.state.source.token
            ).then(result => {
              this.props.onMediaChange({ data: result.data, type: 'image' })

              this.props.thumbnailChange(image)
              this.setState(
                {
                  loading: false,
                  progress: 0,
                },
                () => {
                  this.props.progressComplete()
                }
              )
            })
            this.setState({
              show: false,
              file: null,
              croppedFile: null,
              isNext: false,
              page: 1,
              image: image,
            })
          }}
        />
      </div>
    )
  }
}

export default MediaBuilder
