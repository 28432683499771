import Raven from 'raven-js';

const sentry_key = `9525dbdb9de244e1b483c8968323b45c`
const sentry_app = `1501039`
export const sentry_url = `https://${sentry_key}@sentry.io/${sentry_app}`

export const logException = (ex, context) => {
    Raven.captureException(ex, {
        extra: context
    })

    window && window.console && console.error && console.error(ex)
}