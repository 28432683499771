import React, { Component } from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router'
import Confirmation from '../../components/Confirmation'
import $ from 'jquery'
export class Surgeons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      surgeons: [],
      searchKeyword: '',
      selectedItem: null,
    }
  }

  componentDidMount = () => {
    this.makeRequest()
  }

  makeRequest = () => {
    const { page, searchKeyword } = this.state
    this.setState({
      loading: true,
    })
    axios
      .get(`api/admin/surgeons?page=${page}&keyword=${searchKeyword}`)
      .then(res => {
        console.log(res)
        this.setState({
          surgeons:
            page === 1
              ? res.data.data
              : [...this.state.surgeons, ...res.data.data],
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRequest()
      }
    )
  }

  generateSpecialties = surgeon => {
    return surgeon.specialtyList.map((specialty, index) => {
      return (
        <span
          key={`surgeon-${surgeon.id}-specialty-${index}`}
          class="badge badge-secondary badge-pill mr10"
        >
          {specialty.name}
        </span>
      )
    })
  }
  deleteSurgeon = () => {
    const { surgeons, selectedItem } = this.state

    axios
      .post(`api/admin/surgeons/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(res => {
        let data = surgeons.find(x => x.id == selectedItem.id)
        data.isDeleted = true
        this.setState({
          surgeons: surgeons.splice(0),
        })
      })
  }
  recoverSurgeon = () => {
    const { surgeons, selectedItem } = this.state

    axios
      .post(`api/admin/surgeons-restore/${selectedItem.id}`, {
        _method: 'PATCH',
      })
      .then(res => {
        let data = surgeons.find(x => x.id == selectedItem.id)
        data.isDeleted = false
        this.setState({
          surgeons: surgeons.splice(0),
        })
      })
  }
  render() {
    const surgeonData = this.state.surgeons.map((surgeon, index) => {
      return (
        <tr>
          <td>{`${surgeon.firstname} ${surgeon.lastname}`}</td>
          <td className="tags">{this.generateSpecialties(surgeon)}</td>
          <td className>
            <Link
              to={{ pathname: `/surgeon-update/${surgeon.id}` }}
              className="mr5"
            >
              Edit
            </Link>
            {surgeon.isDeleted ? (
              <a
                href="javascript:;"
                data-target="#restoreModal"
                data-toggle="modal"
                onClick={() => this.setState({ selectedItem: surgeon })}
              >
                Restore
              </a>
            ) : (
              <a
                href="javascript:;"
                data-target="#confirmationModal"
                data-toggle="modal"
                onClick={() => this.setState({ selectedItem: surgeon })}
              >
                Delete
              </a>
            )}
          </td>
        </tr>
      )
    })
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold my30">Surgeons</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <InfiniteScroll
              className=""
              dataLength={this.state.surgeons.length} //This is important field to render the next data
              next={this.loadMoreData.bind(this)}
              hasMore={true}
            >
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>
                      Surgeon name <i className="fa fa-sort ml5" />
                    </th>
                    <th>Specialties</th>
                    <th>Edits</th>
                  </tr>
                </thead>
                <tbody>{surgeonData}</tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>

        <Confirmation
          title="Delete Surgeon"
          message="Deleting surgeon will remove it from the database and will have to be added back manually."
          confirmText="Delete surgeon"
          cancelText="Cancel"
          confirm={this.deleteSurgeon.bind(this)}
          cancel={() => {
            $('confirmModal').modal('hide')
          }}
        />

        <Confirmation
          title="Restore Surgeon"
          message="Restoring surgeon will recovery your deleted surgeon."
          confirmText="Restore surgeon"
          cancelText="Cancel"
          confirm={this.recoverSurgeon.bind(this)}
          idModal={'restoreModal'}
          cancel={() => {
            $('restoreModal').modal('hide')
          }}
        />
      </div>
    )
  }
}

export default Surgeons
