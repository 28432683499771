import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server'
import axios from 'axios'
import _ from 'lodash'
import { connect } from 'react-redux'
import ItemCard from '../../components/ItemCard'
import { Link } from 'react-router'
import Create from './Create'
import Confirmation from '../../components/Confirmation'
import ViewItemModal from '../../components/modals/ViewItemModal'
import { setItemMedicalInventory } from '../../actions/ItemAction'
import Notifier from '../../components/commons/table/Notifier'
import TData from '../../components/commons/table/TData'

export class Items extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading: false,
      page: 1,
      loading: 1,
      dataLength: 1,
      isEditMode: false,
      medical_categories: [],
      medical_inventory_name: '',
      selectedCategory: 'all',
      searchKeyword: '',
      inventory: '',
      cardView: false,
      selectedItem: {},
      sizes: [{ catalogue: '', size: '', show: true }],
      successAppear: false,
      isCreate: false,
      successMessage: '',
      isReverse: false,
      errors: [],
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),

    }
    this.searchData = _.debounce(this.getItemsResult, 600)
    this.compareBy.bind(this)
    this.sortBy.bind(this)
  }

  componentDidMount() {
    this.checkMedicalInventoryId()

    this.makeRemoteRequest()
  }
  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    //$('[data-toggle="popover"]').popover('hide') 
    $('.popover').popover('hide')
  }

  checkMedicalInventoryId = () => {
    let medical_inventory_id = null
    switch (this.props.params.inventory) {
      case 'supplies':
        medical_inventory_id = 1
        break
      case 'instruments':
        medical_inventory_id = 2
        break
      case 'equipments':
        medical_inventory_id = 3
        break
      default:
        medical_inventory_id = 1
        break
    }

    console.log(medical_inventory_id)
    this.props.setItemMedicalInventory(medical_inventory_id)
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  sortBy(key) {
    let arrayCopy = [...this.state.data]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ data: arrayCopy, isReverse: !this.state.isReverse })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isCreate: false,
      selectedItem: {},
    })
    this.checkMedicalInventoryId()
    if (nextProps.params.inventory === this.props.params.inventory) {
      return
    } else {
      this.refresh()
    }
  }

  addSizesSubmit() {
    const { selectedItem, sizes } = this.state

    const form = new FormData()
    sizes.map(data => {
      if (data.show) {
        form.append('catalog_number[]', data.catalogue)
        form.append('size[]', data.size)
      }
    })
    axios
      .post(`api/admin/save-duplicate-supply/${selectedItem.id}`, form)
      .then(result => {
        if (result.data.success) {
          $('.modal').modal('hide')
          this.setState({
            successAppear: true,
            successMessage: 'Items have been successfully duplicated!',
            show: true,
            data: [...result.data.data, ...this.state.data],
            sizes: [
              {
                catalogue: '',
                size: '',
                show: true,
              },
            ],
          })

          setTimeout(() => {
            this.setState({
              successAppear: false,
            })
          }, 5000)
        } else {
          console.log(result.data.errors)
          this.setState(
            {
              errors: result.data.errors,
            },
            () => {
              console.log('errors', this.state.errors)
            }
          )
        }
      })
      .catch(error => { })
  }

  addMoreSizes() {
    const { sizes } = this.state
    sizes.push({ catalogue: '', size: '', show: true })
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  sizeRemove(index) {
    const { sizes } = this.state
    //sizes[index].show = false
    sizes.splice(index, 1)
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  setEditMode(value) {
    console.log(value)
    this.setState({
      isEditMode: value,
      cardView: false,
    })
  }

  makeRemoteRequest = () => {
    this.setState({ loading: true })
    let inventory = ''
    console.log('propsss here', this.props.params.inventory)
    if (this.props.params.inventory == 'supplies') {
      inventory = 1
      this.setState({
        medical_inventory_name: 'Supply',
        inventory
      })
    } else if (this.props.params.inventory == 'instruments') {
      inventory = 2
      this.setState({
        medical_inventory_name: 'Instrument',
        inventory
      })
    } else if (this.props.params.inventory == 'equipments') {
      inventory = 3
      this.setState({
        medical_inventory_name: 'Equipment',
        inventory
      })
    }
    axios
      .get(`api/admin/medical-categories-by-inventory/${inventory}`)
      .then(result => {
        console.log(result)
        this.setState({
          medical_categories: result.data.data,
        }, () => {
          this.getItemsResult()
        })
      })
      .catch(error => {
        console.log(error.response)
      })
    console.log('props')

    console.log(this.props)

   
    this.props.setItemMedicalInventory(inventory, this.props.params.inventory)
  }

  getItemsResult = () => {
    const { page, selectedCategory, searchKeyword,inventory } = this.state

    let url = `api/admin/items?medical_inventory_id=${inventory}&medical_category_id=${selectedCategory}&query=${searchKeyword}&paginate=15&page=${page}`
    axios
      .get(url)
      .then(res => {
        console.log(res)
        this.setState({
          data:
            page === 1 ? res.data.data : [...this.state.data, ...res.data.data],
          loading: false,
          hasMore: res.data.data.length > 0,
          dataLength: res.data.data.length,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false, hasMore: false })
      })
  }

  deleteItem() {
    const { selectedItem } = this.state
    axios
      .post(`api/admin/items/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          data: this.state.data.filter(i => i.id != selectedItem.id),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully deleted!',
          show: true,
        })
        console.log(this.state.successAppear)
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  addItemSucces(item) {
    const { data } = this.state
    data.unshift(item)

    this.setState({
      data: data.splice(0),
      isCreate: false,
      successAppear: true,
      successMessage: 'Items have been registered successfully',
    })

    setTimeout(() => {
      this.setState({
        successAppear: false,
      })
    }, 5000)
  }

  editItemSuccess(item) {
    console.log('edit', item)
    const { selectedItem } = this.state
    console.log('edsuccess')

    this.setState(
      {
        data: this.state.data.filter(i => i.id != selectedItem.id),
      },
      () => {
        const { data } = this.state

        data.unshift(item)
        this.setState({
          data: data.splice(0),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully updated',
        })
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
      }
    )
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }
  refresh() {
    this.setState(
      {
        page: 1,
        loading: true,
        data: [],
        searchKeyword: '',
        selectedCategory: 'all',
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div class="row">
          <div class="col-md-12 text-center py30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )

    return (
      <div class="row">
        <div class="col-md-12 text-center mt30">
          <h3 class="h2med mb-15">Nothing else to see...</h3>
          <p class="bodyregular mb0">
            Missing something? Add new items via <a href="#"
              onClick={this.setEditMode.bind(this, true)}>Edit database</a>
          </p>
        </div>
      </div>
    )
  }

  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map(data => {
        return (
          <span
            key={`tag_${data.id}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }

  categoryChange(event) {
    console.log()
    event.preventDefault()
    this.setState(
      {
        page: 1,
        loading: true,
        data: [],
        selectedCategory: event.target.value,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  searchItem({ target: { value } }) {
    this.setState(
      {
        searchKeyword: value,
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        this.searchData()
      }
    )
  }

  renderTable() {
    const rows = this.state.data.map((data,index) => {
      return (
        <tr key={`item-key-`,index}>
          <td>{data.catalog_number ? data.catalog_number : ''}</td>
          <TData className="has-action w310" item={data} popover onClick={()=>{
            
            this.setState({
                  selectedItem: data,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                }, () => {
                  $('#viewItemModal').modal('show')
                })

          }}>{data.name}</TData> 
          <td>{data.company ? data.company:''}</td>
          <td>{data.category ? data.category.name:''}</td>
          <td>{data.size}</td>
          {this.state.isEditMode ? (

            <td className="w115 px-0 text-center">
                <a
                  href="#"
                  class="text-dark font-weight-bold"
                  onClick={(event) => {
                    event.preventDefault()
                    this.setState({ 
                      selectedItem: data, 
                      isCreate: true
                    })
                  }}
                > Edit content
              </a>
            </td>
          ) : (
              ''
            )}

          {this.state.isEditMode ? (
            <td className="w95 px-0 text-center">
              <a
                href="#"
                class="text-dark"
                data-toggle="modal"
                data-target="#sizesModalCenter"
                onClick={() =>
                  this.setState({
                    selectedItem: data,
                    sizes: [
                      {
                        catalogue: '',
                        size: '',
                        show: true,
                      },
                    ],
                    errors: []
                  })
                }
              > Add sizes
              </a>
            </td>
          ) : (
              ''
            )}

          {this.state.isEditMode ? (
            <td className="w70 px-0 text-center">
              {data.manageable ? (
                <a
                  href="#"
                  class="text-dark"
                  data-target="#confirmationModal"
                  data-toggle="modal"
                  onClick={() => this.setState({ selectedItem: data })}
                >
                  Delete
                </a>
              ) : (
                  '-'
                )}
            </td>
          ) : (
              ''
            )}
        </tr>
      )
    })
    return (
      <table className={`table table-striped table-hover table-hover-bold ${this.state.isEditMode ? 'min-table-width-1100' : 'min-table-width-920'}`}>
        <thead>
          <tr>
            <th onClick={() => this.sortBy('catalog_number')} className="w140">
              <i className="fa fa-sort mr5" />
              Catalogue #
            </th>
            <th className="w310" onClick={() => this.sortBy('name')}>
              <i className="fa fa-sort mr5" />
              Name
            </th>
            <th onClick={() => this.sortBy('company')} className="w120">
              <i className="fa fa-sort mr5" />
              Company
            </th>
            <th onClick={() => this.sortBy('category')} className="w120">
              <i className="fa fa-sort mr5" />
              Category
            </th>
            <th onClick={() => this.sortBy('size')} className="w80">
              <i className="fa fa-sort mr5" />
              Size
            </th>
            {this.state.isEditMode ? <th colSpan="3">Edits</th> : ''}
          </tr>
        </thead>

        <tbody style={{ minHeight: 250 }}>
          {this.state.successAppear ? (
            <Notifier
              status={'positive'}
              message={this.state.successMessage}
              show={this.state.show || this.successAppear}
              cols={8}
            />
          ) : (
              ''
            )}
          {rows}
        </tbody>
      </table>
    )
  }

  renderCard() {
    const cards = this.state.data.map(item => {
      return <ItemCard key={`item_card_${item.id}`} itemData={item} onClick={() => {

        this.setState({
          selectedItem: item,
        })
        $("#viewItemModal").modal('show');
      }} />
    })
    return <div className="d-flex flex-wrap my20">{cards}</div>
  }

  renderView() {
    if (this.state.cardView) return this.renderCard()
    return this.renderTable()
  }

  changeView() {
    this.setState({
      cardView: !this.state.cardView,
    })
  }

  renderIndex() {
    
    const categories = this.state.medical_categories.map(item => {
      return (
        <option value={item.id} key={`item_${item.id}`}>
          {item.name}
        </option>
      )
    })

    return (
      <div className="container animated fadeIn">
        {/* TITLE ROW */}

        <div className="row">
          <div className="col-md-12">
            <h1 className="h1bold page-title">
              {this.props.params.inventory.charAt(0).toUpperCase() +
                this.props.params.inventory.slice(1)}{' '}
              database
            </h1>
            <h4 className="h2med page-sub-title">
              [Define what supplies is and what items are to be found]
            </h4>
          </div>
        </div>
        {/* END TITLE ROW */}
        <div className="row">
          <div className="col-lg-3 col-md-6 mb30 ">
            <div className="form-group m-0 m-xsb20">
              <label>Search</label>
              <input
                type="text"
                name="search"
                id="search"
                className="form-control"
                placeholder="Start typing name, catalogue #…"
                onChange={this.searchItem.bind(this)}
                value={this.state.searchKeyword}
              />
            </div>
          </div>
            <div className="col-lg-4 col-md-6 mb30 ">
            <div className="form-group m-0">
              <label>Filter supplies by</label>
              <select
                className="form-control"
                onChange={this.categoryChange.bind(this)}
              >
                <option value="all">All</option>
                {categories}
              </select>
            </div>
          </div>
          
              

          {this.state.isEditMode ? (
            <div className="col-lg-5 col-md-12 mb30 d-md-flex justify-content-md-end">
              <a
                href="javascript:;"
                className="btn btn-outline-dark px30 mr20 m-xsx0 mt29 m-xst0 m-smt0 m-mdt0"
                onClick={event => {
                  event.preventDefault()
                  this.setState({
                    isCreate: true,
                    selectedItem: null,
                  })
                }}
              >
                Register new item
              </a>

              <a
                href="javascript:;"
                className="btn btn-dark ml10 m-xsl0 px30 mr0 mt29 m-mdt0"
                onClick={this.setEditMode.bind(this, false)} 
              >
                Update
              </a>
            </div>
          ) : (
              <div className="col ml-auto text-right mb30 d-flex justify-content-end justify-content-lg-end">
                <a
                  href="javascript:;"
                  className="btn btn-outline-dark px30 mr30 mt29 m-xst0 m-smt0 m-mdt0"
                  onClick={this.setEditMode.bind(this, true)}
                >
                  Edit database
              </a>
                <div className="p-0 pt6 mt29 m-xst0 m-smt0 m-mdt0">
                  {this.state.cardView ? (
                    <i
                      className="fa fa-list fa-2x"
                      onClick={this.changeView.bind(this)}
                    />
                  ) : (
                      <i
                        className="fa fa-th-large fa-2x"
                        onClick={this.changeView.bind(this)}
                      />
                    )}
                </div>
              </div>
            )}

        </div>

        <div className="row mt0 mb30">
          <div className="col-md-12">
            <InfiniteScroll
              className=""
              dataLength={this.state.data.length} //This is important field to render the next data
              next={this.loadMoreData.bind(this)}
              hasMore={true}
            >
              {this.renderView()}
            </InfiniteScroll>
          </div>
        </div>

        {this.renderLoading()}
      </div>
    )
  }

  getArrayError(field, index) {
    const { errors } = this.state

    if (errors[`${field}.${index}`]) {
      return errors[`${field}.${index}`][0]
    }
    return ''
  }

  render() {
    const filter = this.state.sizes.filter(data => {
      return data.show
    })

    console.log(this.state)

    const sizes = filter.map((data, index) => {
      return (
        <tr key={`size_item_${index}`} className="border-0">
          <td className="pl-0 w-50 py-0 border-0">
            <div className="form-group mb-0">
              <label className="mb-0">Catalogue</label>
              <div class="invalid-feedback fs-14 d-block mt-0 mb12">
                {this.getArrayError('catalog_number', index)}
              </div>
              <input
                type="type"
                name="text"
                className={
                  this.getArrayError('catalog_number', index) == ''
                    ? 'form-control'
                    : 'form-control is-invalid'
                }
                value={data.catalogue}
                onChange={event => {
                  const { sizes } = this.state
                  sizes[index].catalogue = event.target.value
                  this.setState({
                    sizes: sizes.splice(0),
                  })
                }}
              />
            </div>
          </td>
          <td className=" py-0 border-0">
            <div className="form-group mb-0">
              <label className="mb-0">Size</label>
              <div class="invalid-feedback fs-14 d-block mt-0 mb12">
                {this.getArrayError('size', index)}
              </div>

              <input
                type="type"
                name="text"
                className={
                  this.getArrayError('size', index) == ''
                    ? 'form-control'
                    : 'form-control is-invalid'
                }
                value={data.size}
                onChange={event => {
                  const { sizes } = this.state
                  sizes[index].size = event.target.value
                  this.setState({
                    sizes: sizes.splice(0),
                  })
                }}
              />
            </div>
          </td>
          <td className="border-0">
            <div class="invalid-feedback d-block fs-14 mt-0 mb10">&nbsp;</div>
            {index != 0 ? (
              <i
                className="fa fa-times-circle fa-2x mt10"
                onClick={this.sizeRemove.bind(this, index)}
              />
            ) : (
                ''
              )}
          </td>
        </tr>
      )
    })
    if (this.state.isCreate)
      return (
        <Create
          addItemSucces={item => this.addItemSucces(item)}
          editItemSuccess={item => this.editItemSuccess(item)}
          modalCancel={() => {
            this.setState({
              isCreate: false,
            })
          }}
          item={this.state.selectedItem}
          medicalInventoryName={this.state.medical_inventory_name}
        />
      )
    return (
      <div className="col-md-12">
        <Confirmation
          title="Delete Item"
          message="Deleting item will remove it from the database and will have to be added back manually."
          confirmText="Delete item"
          cancelText="Cancel"
          confirm={this.deleteItem.bind(this)}
          cancel={() => {
            $('confirmModal').modal('hide')
          }}
        />

        <ViewItemModal
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />

          <div
            className="modal fade"
            id="sizesModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="sizesModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body pt30 px30 pb20">
                  <h5 className="m-0 mx-auto font-weight-bold pb20">
                    Add sizes for "{this.state.selectedItem ? this.state.selectedItem.name : ''}"
                  </h5>
                  {/* <p className="m-0">
                    Adding additional sizes for{' '}
                    <b>{this.state.selectedItem.name}</b>
                  </p> */}
                  <p className="m-0">
                    <b>Catalogue </b>
                    {this.state.selectedItem ? this.state.selectedItem.catalog_number : ''}
                    <b className="ml15">Size </b>
                    {this.state.selectedItem ? this.state.selectedItem.size : ''}
                  </p>
                </div>
                <div className="px30 pt20">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table fixed-table m-0">
                        <tbody
                          style={{
                            float: 'left',
                            width: '100%',
                            overflow: 'auto',
                            maxHeight: '264px',
                          }}
                        >
                          {sizes}
                          <tr className="c-pointer">
                            <td
                              colSpan="3"
                              className="pl-0 pr10 c-pointer border-0 pb-0 pt20"
                            >
                              <label
                                className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 c-pointer h50 py30"
                                style={{
                                  border: '2px dashed rgb(132, 134, 136)',
                                }}
                                onClick={this.addMoreSizes.bind(this)}
                              >
                                Add another size +
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-body mb-0 px30 pb0 pt-0 border-0 justify-content-start">
                  <div
                    className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                    onClick={this.addSizesSubmit.bind(this)}
                  >
                    Add
                  </div>
                  <div
                    className="btn btn-light border-dark px30 m-xst30 mb-0 float-left"
                    data-dismiss="modal"
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
         
          </div>
     
        {this.renderIndex()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    medical_inventory_id: state.item.medical_inventory_id,
  }
}

export default connect(
  mapStateToProps,
  { setItemMedicalInventory }
)(Items)
