import React from 'react';
import Grid from '@material-ui/core/Grid';
import Vimeo from '@u-wave/react-vimeo'
import Caption from './Caption'
function VideoCaption(data) {
  

  return (

                    <Grid /* Root Grid */
                container
                direction= 'row'
                justify= 'flex-start'
                alignItems= 'stretch'
                spacing={24}
                style={{margin:0, padding:0}}
            >        
        
           
                <Grid item sm>
                    
                
                     <Vimeo height="480" width="640"
                        video="57832329"
                        autoplay
                       
                        />
                    
                </Grid>
                <Grid item sm>                
                      <Caption />
                </Grid>

            </Grid>
 
    
    
  );
}


export default VideoCaption;