import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios'
import _ from 'lodash'
import Confirmation from '../../components/Confirmation'
import $ from 'jquery'
import Notification from '../../components/Notification';

export class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      users: [],
      page: 1,
      loading: false,
      selectedItem: null,
    }
  }

  componentDidMount = () => {
    this.getUsers()
  }

  compareBy(key) {
    if (key == 'type') {
      return function(a, b) {
        if (a.type.name < b.type.name) return -1
        if (a.type.name > b.type.name) return 1
        return 0
      }
    }

    return function(a, b) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1
      return 0
    }
  }

  sortBy(key) {
    let arrayCopy = this.state.users
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ users: arrayCopy, isReverse: !this.state.isReverse })
  }

  getUsers = () => {
    const { page } = this.state
    this.setState({
      loading: true,
    })

    let url = `/api/admin/users/list?page=${page}&paginate=15`

    axios
      .get(url)
      .then(res => {
        console.log(res)

        this.setState({
          users:
            page === 1
              ? res.data.data
              : [...this.state.users, ...res.data.data],
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          loading: false,
        })
      })
  }

  renderLoading = () => {
    const { loading } = this.state
    if (loading) {
      return (
        <div className="row">
          <div className="col-md-12 text-center py30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-md-12 text-center mt30">
          <h3 className="mb-15">Nothing else to see...</h3>
        </div>
      </div>
    )
  }

  bannedUser = () => {
    const { users, selectedItem } = this.state

    axios
      .post(`api/admin/users/block/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(res => {
        let data = users.find(x => x.id == selectedItem.id)
        data.isDeleted = true
        this.setState({
          users: users.splice(0),
        })
      })
  }

  restoreUser = () => {
    const { users, selectedItem } = this.state

    axios
      .post(`api/admin/users/restore/${selectedItem.id}`, {
        _method: 'PATCH',
      })
      .then(res => {
        let data = users.find(x => x.id == selectedItem.id)
        data.isDeleted = false
        this.setState({
          users: users.splice(0),
        })
      })
  }

  resetPasswordUser = () =>{
    const { users, selectedItem } = this.state

    axios
      .post(`api/admin/users/reset/${selectedItem.id}`, {
        _method: 'PATCH',
      })
      .then(res => {
        $('#notificationModal').modal('show')
      })
  }

  render() {
    let { users, page } = this.state

    let user_list = users.map(user => {
      return (
        <tr>
          <td>{user.username}</td>
          <td>{user.email}</td>
          <td>{user.name}</td>
          <td>{user.premium ? user.onTrial ? 'Free Trial':'Premium' : 'Free mode'}</td>
          <td> {user.endsAt} </td>
          <td>
            {user.isDeleted ? (
              <a
                className="mx-2"
                href="javascript:;"
                data-target="#restoreModal"
                data-toggle="modal"
                onClick={() => this.setState({ selectedItem: user })}
              >
                Restore
              </a>
            ) : (
              <a
              className="mx-2"
                href="javascript:;"
                data-target="#bannedModal"
                data-toggle="modal"
                onClick={() => this.setState({ selectedItem: user })}
              >
                Banned
              </a>
            )}
            <a
              className="mx-2"
              href="javascript:;"
              data-target="#resetPasswordModal"
              data-toggle="modal"
              onClick={() => this.setState({ selectedItem: user })}
            >
              Password Reset
            </a>
          </td>
        </tr>
      )
    })

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">Users</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <InfiniteScroll
              className=""
              dataLength={users.length} //This is important field to render the next data
              next={() => {
                this.setState({ page: page + 1 }, () => {
                  this.getUsers()
                })
              }}
              hasMore={true}
            >
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th onClick={() => this.sortBy('username')}>
                      Username <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('email')}>
                      Email Address <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('name')}>
                      Full name <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('name')}>
                      Status
                      <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('name')}>
                      End Date
                      <i className="fa fa-sort ml5" />
                    </th>
                    <th className="text-center">Manage</th>
                  </tr>
                </thead>
                <tbody>{user_list}</tbody>
              </table>

            </InfiniteScroll>
            {this.renderLoading()}
          </div>
        </div>

        <Confirmation
          title="Banned User"
          message="Banned user will remove the access of the user."
          confirmText="Banned user"
          cancelText="Cancel"
          confirm={this.bannedUser.bind(this)}
          idModal={'bannedModal'}
          cancel={() => {
            $('bannedModal').modal('hide')
          }}
        />

        <Confirmation
          title="Restore User"
          message="Restore user will restore the access of the user."
          confirmText="Restore user"
          cancelText="Cancel"
          confirm={this.restoreUser.bind(this)}
          idModal={'restoreModal'}
          cancel={() => {
            $('restoreModal').modal('hide')
          }}
        />

        <Confirmation
          title="Password Reset"
          message="Password Reset will reset user password."
          confirmText="Password Reset"
          cancelText="Cancel"
          confirm={this.resetPasswordUser.bind(this)}
          idModal={'resetPasswordModal'}
          cancel={() => {
            $('resetPasswordModal').modal('hide')
          }}
        />

        <Notification
          title="Password Reset Successfully"
          message="The user password send to their email"
          confirmText="OK"
         />
      </div>
    )
  }
}

export default Users
