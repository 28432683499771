import React, { Component } from 'react'
import _ from 'lodash'
import $ from 'jquery'
import axios from 'axios'
export class LoadFromProcedure extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: 0,
      page: 1,
      searchKeyword: '',
      procedures: [],
    }
  }

  componentDidMount = () => {
    const { procedure_load } = this.props
    if (procedure_load) {
      axios
        .get(`api/admin/load-procedure/${procedure_load}`)
        .then(result => {
          console.log(result)
          this.setState({
            procedures: result.data.data,
          })
        })
        .catch(error => {
          console.log(error)
          console.log(error.response)
        })
    }
  }

  searchProcedure = () => {
    this.setState(
      {
        page: 1,
        searchKeyword: this.searchKeyword.value,
      },
      () => {
        const { procedure_load } = this.props
        if (procedure_load) {
          axios
            .get(`api/admin/load-procedure/${procedure_load}?search=${this.state.searchKeyword}`)
            .then(result => {
              console.log(result)
              this.setState({
                procedures: result.data.data,
              })
            })
            .catch(error => {
              console.log(error)
              console.log(error.response)
            })
        }
      }
    )
  }

  render() {
    const debounce = _.debounce(keyword => {
      this.searchProcedure()
    }, 500)
    const { procedures } = this.state

    let procedure_list = procedures.map((procedure, index) => {
      console.log(procedure)
      return (
        <tr key={`load-key-${index}`}>
          <td
            className="text-center w70 pr-0 c-pointer"
            onClick={() => {
              $(`#${procedure.id}`)
                .closest('td')
                .find('label')
                .click()
              this.setState({
                selected: procedure.id,
              })
            }}
          >
            <div
              class="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: -15, paddingLeft: 0 }}
            >
              <input
                type="radio"
                type="radio"
                name="radiobtn"
                id={procedure.id}
                onChange={() => {
                  this.setState({
                    selected: procedure.id,
                  })
                }}
                class="custom-control-input  c-pointer"
              />
              <label class="custom-control-label  c-pointer" for={procedure.id}>
                &nbsp;
              </label>
            </div>
          </td>
          <td className="pl-0 w500">
            <a href="javascript:;" className="p-0">
              {procedure.name}
            </a>
          </td>
          <td className="w80 px10">
            <a
              href={`/quicknotes/${procedure.id}`}
              target="_blank"
              className="p-0"
            >
              View
            </a>
          </td>
        </tr>
      )
    })

    return (
      <div
        className="modal fade"
        id="loadFromModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="loadFromModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body pt30 px30 pb0">
              <h5 className="m-0 mx-auto font-weight-bold pb20">
                Load [procedures/phase]
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </h5>
              <p className="m-0">
                Select from the list of surgeons to copy their{' '}
                <b>[procedures/phase]</b>
              </p>
            </div>
            <div className="modal-body px30 pt-0">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group mb15">
                    <label className=" mb10">Search</label>
                    <input
                      type="text"
                      name="test"
                      className="form-control"
                      placeholder="Start typing  procedure name"
                      ref={el => (this.searchKeyword = el)}
                      onChange={event => {
                        debounce(event.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped table-fixed border table-hover-bold">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th className="w70" />
                        <th className="font-weight-bold w500 pl0">
                          &nbsp;Procedure Name
                        </th>
                        <th className="font-weight-bold px10">View</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        float: 'left',
                        width: '100%',
                        overflow: 'auto',
                        maxHeight: '244px',
                      }}
                    >
                      {procedure_list}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-body mb-0 px30 pb0 pt-0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                data-dismiss="modal"
                onClick={() => {
                  this.props.loadSurgeonQuicknotes(this.state.selected)
                  this.props.loadFrom()
                }}
              >
                Add
              </div>
              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoadFromProcedure
