import React, { Component } from 'react'
import logo from '../assets/images/logo-gradient.png'
import mobile_logo from '../assets/images/logos/corh-logo-single.png'
import { getSpecailtiesData, setSpecialty, logoutUser } from '../actions'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import Button from './commons/forms/Button'
import Raven from 'raven-js'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: '',
    }
  }

  isActive(param) {
    console.log(this.props.router.location, this.props.router.location == param)
    if (this.props.router.location.pathname == param) return 'active'
    return ''
  }
  componentDidMount() {
    this.props.getSpecailtiesData()

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    } else {
      const { user } = this.props.auth
      if(user){
        Raven.setUserContext({
          email: user.email,
          id: user.id,
          username:user.username
        })
      }
    }
  }

  componentDidUpdate = () => {}

  componentWillReceiveProps() {
    console.log('my props received')
  }

  logout = () => {
    this.props.logoutUser()
  }

  renderList() {
    const { items, loading } = this.props.specialty
    if (!loading)
      return items.map(data => {
        return (
          <li className="list-inline-item col-md-2" key={data.id}>
            <Link
              to={{
                pathname: `/surgeon/${data.id}`,
                state: {
                  specialty: data,
                },
              }}
            >
              {`${data.name}`}
            </Link>
          </li>
        )
      })

    return <li className="list-inline-item col-md-2 text-dark">Loading...</li>
  }

  render() {
    const procedures = [1, 4]
    const medical_inventory = [1, 4]
    const master_page = [1, 4]
    const settings = [1, 4]
    const user_database = [1, 2]
    const user = this.props.auth.user

    return (
      <div className="light-theme">
        <nav className="navbar navbar-expand-lg navbar-light navigation">
          <div className="container">
            <Link className="navbar-brand nav-logo" to="/">
              <img src={logo} className="nav-logo-img d-none d-lg-block" />
              <img
                src={mobile_logo}
                className="nav-logo-img d-block d-lg-none py8"
              />
            </Link>

            <button
              className="navbar-toggler mobile-nav"
              type="button"
              data-toggle="collapse"
              data-target="#corh-header2"
              aria-controls="corh-header2"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu
            </button>

            {/* DESKTOP NAVIGATION */}
            <div
              className="collapse navbar-collapse d-none d-lg-block desktop-nav"
              id="corh-header"
            >
              <ul className="navbar-nav mr-auto">
                {procedures.includes(user.type.id) ? (
                  <li className={`nav-item dropdown`}>
                    <a
                      className="nav-link dropdown-toggle no-caret"
                      href="#"
                      id="medical-inventory-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Procedures
                    </a>
                    <div
                      className="dropdown-menu w200"
                      aria-labelledby="medical-inventory-dropdown"
                    >
                      <Link to={'/procedure-creator'} className="dropdown-item">
                        Procedure creator{' '}
                      </Link>
                      <Link
                        to={'/procedure-database'}
                        className="dropdown-item"
                      >
                        {' '}
                        Procedure database{' '}
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {/* <li className="nav-item"> 
                <a className={`nav-link`} href="#">Education (Coming soon)</a>
              </li>  */}
                {medical_inventory.includes(user.type.id) ? (
                  <li className={`nav-item dropdown`}>
                    <a
                      className="nav-link dropdown-toggle no-caret"
                      href="#"
                      id="medical-inventory-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Medical Inventory
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="medical-inventory-dropdown"
                    >
                      <Link
                        to={`/medical-inventory/supplies`}
                        className="dropdown-item"
                      >
                        Supplies
                      </Link>
                      <Link
                        to={`/medical-inventory-instruments`}
                        className="dropdown-item"
                      >
                        Instruments
                      </Link>
                      <Link
                        to={`/medical-inventory/equipments`}
                        className="dropdown-item"
                      >
                        Equipments
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {master_page.includes(user.type.id) ? (
                  <li className={`nav-item dropdown`}>
                    <a
                      className="nav-link dropdown-toggle no-caret"
                      href="#"
                      id="medical-inventory-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Master pages
                    </a>
                    <div
                      className="dropdown-menu w200"
                      aria-labelledby="medical-inventory-dropdown"
                    >
                      <Link to={'/positioning'} className="dropdown-item">
                        {' '}
                        Positioning{' '}
                      </Link>
                      <Link
                        to={'/positioning-equipments'}
                        className="dropdown-item"
                      >
                        Positioning equipment{' '}
                      </Link>
                      {/* <Link to={'/master-pages/room-setup'}
                        className="dropdown-item">Room Setup</Link> */}
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {user_database.includes(user.type.id) ? (
                  <li className={`nav-item dropdown`}>
                    <a
                      className="nav-link dropdown-toggle no-caret"
                      href="#"
                      id="medical-inventory-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      User database
                    </a>
                    <div
                      className="dropdown-menu w160"
                      aria-labelledby="medical-inventory-dropdown"
                    >
                      <Link
                        to={'/user-database/users'}
                        className="dropdown-item"
                      >
                        {' '}
                        Users{' '}
                      </Link>
                      <Link
                        to={'/user-database/beta-keys'}
                        className="dropdown-item"
                      >
                        {' '}
                        Beta Keys{' '}
                      </Link>
                      {user.type.id == 1 ? (
                        <>
                          <Link
                            to={'/user-database/surgeons'}
                            className="dropdown-item"
                          >
                            {' '}
                            Surgeons{' '}
                          </Link>
                          <Link
                            to={'/user-database/global-users'}
                            className="dropdown-item"
                          >
                            Global OR users{' '}
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {settings.includes(user.type.id) ? (
                  <li className={`nav-item dropdown`}>
                    <a
                      className="nav-link dropdown-toggle no-caret"
                      href="#"
                      id="medical-inventory-dropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Settings
                    </a>
                    <div
                      className="dropdown-menu w200"
                      aria-labelledby="medical-inventory-dropdown"
                    >
                      {user.type.id == 1 ? (
                        <>
                          <Link
                            to={`/settings/medical-inventory`}
                            className="dropdown-item"
                          >
                            Medical inventory
                          </Link>
                          <Link
                            to={`/settings/surgical-tree`}
                            className="dropdown-item"
                          >
                            Surgical tree
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                      <Link
                        to={`/settings/specialties`}
                        className="dropdown-item"
                      >
                        Specialties
                      </Link>
                      {user.type.id == 1 ? (
                        <>
                          <Link
                            to={`/settings/quicknotes`}
                            className="dropdown-item"
                          >
                            Quick notes
                          </Link>
                          <Link
                            to={`/settings/icon-library`}
                            className="dropdown-item"
                          >
                            Icon library
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </li>
                ) : (
                  ''
                )}
              </ul>

              <ul className="navbar-nav ml-auto with-separator">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle d-flex user-nav"
                    href="#"
                    id="user-profile-desktop-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.props.auth.user.avatar ? (
                      <img
                        className="bg-dark icon-circle icon-sm user-icon"
                        src={this.props.auth.user.avatar}
                      />
                    ) : (
                      <img
                        className="bg-dark icon-circle icon-sm user-icon"
                        src={user}
                      />
                    )}
                    <span>{this.props.auth.user.name}</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="user-profile-desktop-dropdown"
                  >
                    <Link className="dropdown-item" to="/account-settings">
                      Account settings
                    </Link>
                    <Link className="dropdown-item" to="#">
                      Switch profile
                    </Link>
                    {/* {
                    this.props.auth.user.premium ? null : <a className="dropdown-item upgrade-item" href="#" data-toggle="modal" data-target="#upgradePlanModal">Upgrade</a>
                  } */}

                    <Link
                      className="dropdown-item"
                      to="/login"
                      onClick={this.logout.bind(this)}
                    >
                      Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </div>

            {/* MOBILE NAVIGATION */}
            <div
              className="collapse navbar-collapse mobile-nav-container"
              id="corh-header2"
            >
              <ul className="navbar-nav mx-auto mobile-nav">
                <li className="nav-item dropdown user-item">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="user-profile-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.props.auth.user.avatar ? (
                      <img
                        className="bg-dark icon-circle icon-sm user-icon"
                        src={this.props.auth.user.avatar}
                      />
                    ) : (
                      <img
                        className="bg-dark icon-circle icon-sm user-icon"
                        src={user}
                      />
                    )}
                    <span>
                      Welcome &nbsp;<b>{this.props.auth.user.name}</b>
                    </span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="user-profile-dropdown"
                  >
                    <Link className="dropdown-item" to="/lounge">
                      The lounge
                    </Link>
                    <Link className="dropdown-item" to="/my-records">
                      My records
                    </Link>
                    <Link className="dropdown-item" to="/account-settings">
                      Account settings
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/login"
                      onClick={this.logout.bind(this)}
                    >
                      Logout
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown search-dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="search-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Search</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="search-dropdown"
                  >
                    <div className="form-group">
                      <input
                        type="search"
                        autoComplete="off"
                        className="form-control"
                        placeholder="Start search here..."
                        value={this.state.search_value}
                        onChange={e => {
                          this.setState({ search_value: e.target.value })
                        }}
                      />
                      <Button
                        secondary
                        url={`/search?q=${this.state.search_value}`}
                        link
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/surgeon">
                    Surgeon preferences
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="search-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Medical inventory</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="search-dropdown"
                  >
                    <Link
                      className="dropdown-item"
                      to="/medical-inventory/supplies"
                    >
                      Supplies
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/medical-inventory-instruments"
                    >
                      Instruments
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/medical-inventory/equipments"
                    >
                      Equipments
                    </Link>
                  </div>
                </li>

                {/* <li className="nav-item">
                <Link className="nav-link" to="/education">Education</Link>
              </li>  */}

                <li className="nav-item">
                  <Link className="nav-link" to="/quality">
                    Quality
                  </Link>
                </li>

                {/* <li className="nav-item upgrade-item">
                {
                  this.props.auth.user.premium ? null : <a className="nav-link text-center" href="#" data-toggle="modal" data-target="#upgradePlanModal">Upgrade</a>
                }
              </li> */}

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/login"
                    onClick={this.logout.bind(this)}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

const mapStateToProps = ({ specialty, auth }) => {
  return { specialty, auth }
}

export default connect(
  mapStateToProps,
  { getSpecailtiesData, setSpecialty, logoutUser }
)(Header)

{
  /* 
  <nav className="navbar navbar-expand-lg bg-header py-0 mh50">
        <div className="container">
          <Link
            to={{
              pathname: `/`,
            }}
            className="navbar-brand p-0"
            style={{ marginRight: '30px' }}
          >
            <div className="btn btn-light rounded-0">CORH</div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#masterhead"
            aria-controls="masterhead"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="masterhead">
            <ul className="navbar-nav dropdown mr-auto w-100">
              {procedures.includes(user.type.id) ? (
                <li className="nav-item dropdown-toggle dropdown no-caret h-100 bg-navheader hover-dropdown">
                  <a
                    className="nav-link h-100"
                    href="javascript:;"
                    id="user-dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Procedures
                  </a>
                  <div
                    className="dropdown-menu fit-dropdown bg-navdropdown animated fadeIn pb-0 w180"
                    aria-labelledby="user-dropdown"
                  >
                    <ul className="list-group bg-transparent border-0 list-group-flush w-100 py5">
                      <li className="list-group-item bg-transparent border-left-0 border-right-0 border-top-0 py15">
                        <Link to={'/procedure-creator'}>Procedure creator</Link>
                      </li>

                      <li className="list-group-item bg-transparent border-left-0 border-right-0 border-top-0 py15">
                        <Link to={'/procedure-database'}>
                          Procedure database
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                ''
              )}
              {medical_inventory.includes(user.type.id) ? (
                <li className="nav-item dropdown-toggle dropdown no-caret h-100 bg-navheader hover-dropdown">
                  <a
                    className="nav-link h-100"
                    href="javascript:;"
                    id="user-dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Medical Inventory
                  </a>
                  <div
                    className="dropdown-menu fit-dropdown bg-navdropdown animated fadeIn pb-0"
                    aria-labelledby="user-dropdown"
                  >
                    <ul className="list-group bg-transparent py5">
                      <li className="list-group-item bg-transparent py15 border-left-0 border-right-0 border-top-0">
                        <Link to={`/medical-inventory/supplies`}>Supplies</Link>
                      </li>
                      <li className="list-group-item bg-transparent py15 border-left-0 border-right-0 border-top-0">
                        <Link to={`/medical-inventory-instruments`}>
                          Instruments
                        </Link>
                      </li>
                      <li className="list-group-item bg-transparent py15 border-0">
                        <Link to={`/medical-inventory/equipments`}>
                          Equipments
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                ''
              )}
              {master_page.includes(user.type.id) ? (
                <li className="nav-item dropdown-toggle dropdown no-caret h-100 bg-navheader hover-dropdown">
                  <a
                    className="nav-link h-100"
                    href="javascript:;"
                    id="user-dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Master pages
                  </a>
                  <div
                    className="dropdown-menu fit-dropdown bg-navdropdown animated fadeIn pb-0 w200"
                    aria-labelledby="user-dropdown"
                  >
                    <ul className="list-group bg-transparent w-100 py5">
                      <li className="list-group-item bg-transparent py15 border-left-0 border-right-0 border-top-0">
                        <Link to={'/positioning'}>Positioning</Link>
                      </li>
                      <li className="list-group-item bg-transparent py15 border-left-0 border-right-0 border-top-0">
                        <Link to={'/positioning-equipments'}>
                          Positioning equipment
                        </Link>
                      </li>
                      <li className="list-group-item bg-transparent py15 border-0"><Link to={'/master-pages/room-setup'}>Room Setup</Link></li> 
                    </ul >
                  </div >
                </li >
              ) : (
  ''
)}

{
  user_database.includes(user.type.id) ? (
    <li className="nav-item dropdown-toggle dropdown no-caret h-100 bg-navheader hover-dropdown">
      <a
        className="nav-link h-100"
        href="javascript:;"
        id="user-dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        User database
                  </a>
      <div
        className="dropdown-menu fit-dropdown bg-navdropdown animated fadeIn pb-0 w150"
        aria-labelledby="user-dropdown"
      >
        <ul className="list-group bg-transparent border-0 list-group-flush w-100 py5">
          <li className="list-group-item bg-transparent border-left-0 border-right-0 border-top-0 py15">
            <Link to={'/user-database/users'}>Users</Link>
          </li>
          {user.type.id == 1 ? (
            <li className="list-group-item bg-transparent border-left-0 border-right-0 border-top-0 py15">
              <Link to={'/user-database/surgeons'}>Surgeons</Link>
            </li>
          ) : (
              ''
            )}
          {user.type.id == 1 ? (
            <li className="list-group-item bg-transparent border-left-0 border-right-0 border-top-0 py15">
              <Link to={'/user-database/global-users'}>
                Global OR users
                          </Link>
            </li>
          ) : (
              ''
            )}
        </ul>
      </div>
    </li>
  ) : (
    ''
  )
}

{
  settings.includes(user.type.id) ? (
    <li className="nav-item dropdown-toggle dropdown no-caret h-100 bg-navheader hover-dropdown">
      <a
        className="nav-link h-100"
        href="javascript:;"
        id="user-dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Settings
                  </a>
      <div
        className="dropdown-menu fit-dropdown bg-navdropdown animated fadeIn pb-0 w400"
        aria-labelledby="user-dropdown"
      >
        <ul className="list-inline bg-transparent w400 d-flex flex-wrap px-0 py10">
          {user.type.id == 1 ? (
            <li className="col-lg-4 col-md-5 bg-transparent border-0 mt10 mb20">
              <Link to={`/settings/medical-inventory`}>
                Medical inventory
                          </Link>
            </li>
          ) : (
              ''
            )}
          {user.type.id == 1 ? (
            <li className="col-lg-4 col-md-5 bg-transparent border-0 mt10 mb20">
              <Link to={`/settings/surgical-tree`}>
                Surgical tree
                        </Link>
            </li>) : ''}
          <li className="col-lg-4 col-md-5 bg-transparent border-0 mt10 mb20">
            <Link to={`/settings/specialties`}>Specialties</Link>
          </li>

          {user.type.id == 1 ? (
            <li className="col-lg-4 col-md-5 bg-transparent border-0 mt10 mb20">
              <Link to={`/settings/quicknotes`}>Quick notes</Link>
            </li>
          ) : (
              ''
            )}
          {user.type.id == 1 ? (
            <li className="col-lg-4 col-md-5 bg-transparent border-0 mt10 mb20">
              <a href="/settings/user-permissions">
                User permissions
                          </a>
            </li>
          ) : (
              ''
            )}
          {user.type.id == 1 ? (
            <li className="col-lg-4 col-md-5 bg-transparent border-0 mt10 mb20">
              <Link to={`/settings/icon-library`}>
                Icon library
                          </Link>
            </li>
          ) : (
              ''
            )}
        </ul>
      </div>
    </li>
  ) : (
    ''
  )
}
            </ul >
  <ul className="navbar-nav no-caret click-dropdown has-before-border">
    <li
      className="nav-item dropdown-toggle h-100 bg-navheader"
      id="search_dropdown"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <a className="nav-link h-100" href="#">
        Search
                  <i className="fa fa-search ml5" />
      </a>
    </li>
    <div
      className="dropdown-menu search-dropdown animated fadeIn faster bg-navdropdown"
      aria-labelledby="search_dropdown"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 justify-content-center d-flex offset-1">
            <input
              type="text"
              name="search"
              className="form-control mx-3 border-0"
              placeholder="Type Example..."
            />
            <div className="btn btn-outline-dark align-self-center">
              {' '}
              Go{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ul>
  <ul className="navbar-nav dropdown no-caret has-before-border">
    <li className="nav-item dropdown-toggle no-caret bg-navheader px15 click-dropdown">
      <a
        className="nav-link p-0 h-100 bg-transparent"
        href="#"
        id="user-dropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {this.props.auth.user.avatar ? (
          <img
            src={this.props.auth.user.avatar}
            className="bg-dark rounded-circle mr-2 nurse-icon"
          />
        ) : (
            <img
              src={img}
              className="bg-dark rounded-circle mr-2 nurse-icon"
            />
          )}
        {this.props.auth.user.name}
        <i className="fa fa-chevron-down ml5" />
      </a>
      <div
        className="dropdown-menu fit-dropdown bg-navdropdown animated fadeIn faster px20"
        aria-labelledby="user-dropdown"
      >
        <ul className="list-group list-group-flush">
          <li className="list-group-item bg-transparent">
            <Link to={`/account-settings`}>Account settings</Link>
          </li>
          <li className="list-group-item bg-transparent">
            <a href="#">Switch profile</a>
          </li>
          <li className="list-group-item bg-transparent">
            <Link to="/login" onClick={this.logout.bind(this)}>
              Logout
                      </Link>
          </li>
        </ul>
      </div>
    </li>
  </ul>
          </div >
        </div >
      </nav > */
}
