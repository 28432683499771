import {
  GET_USERS, REGISTER_USER, SET_SUCCESS, UPDATE_USER, SET_USERS, RESET_ERRORS, REGISTER_GLOBAL_USER
} from '../actions/types'

const INITIAL_STATE = {
  users: [],
  errors: [],
  loading: false,
  success: false
}

export default (state=INITIAL_STATE, action) => {
  switch(action.type){
    case GET_USERS:
      return { ...state, users: action.users, loading: action.loading, errors: action.errors }
    case REGISTER_USER:
      return { ...state, success: action.success, errors: action.errors }
    case REGISTER_GLOBAL_USER:
      return { ...state, success: action.success, errors: action.errors }
    case UPDATE_USER:
      return { ...state, success: action.success, errors: action.errors }
    case SET_USERS:
      return { ...state, users: action.users }
    case RESET_ERRORS:
      return { ...state, errors: [] }
    case SET_SUCCESS:
      return {...state, success: action.success}
    default: 
      return {...state}
  }
}