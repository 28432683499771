import { combineReducers } from 'redux'
import SpecialtyReducer from './SpecialtyReducer'
import SurgeonReducer from './SurgeonReducer'
import ItemReducer from './ItemReducer'
import QuicknotesReducer from './QuicknotesReducer'
import AuthReducer from './AuthReducer'
import SurgicalTreeReducer from './SurgicalTreeReducer'
import PositioningReducer from './PositioningReducer'
import UsersReducer from './UsersReducer'
import postReducer from './postReducer'

const rootReducer = combineReducers({
  specialty: SpecialtyReducer,
  surgeon: SurgeonReducer,
  item: ItemReducer,
  quicknotes: QuicknotesReducer,
  auth: AuthReducer,
  surgicaltree: SurgicalTreeReducer,
  positioning: PositioningReducer,
  users: UsersReducer,
  posts : postReducer

})

export default rootReducer
