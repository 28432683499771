import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMedicalInventories, registerCategory, deleteCategory, updateCategory } from '../../actions/ItemAction'
import Confirmation from '../../components/Confirmation'
import $ from 'jquery'
import { Link } from 'react-router'
import { InlineLoader } from '../../components/commons/InlineLoader'
import { TextInput } from '../../components/commons/forms'
import { getArrayError, getError } from '../../utilities'

export class SettingMedicalInventory extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      inventory: 0,
      category: '',
      selected_category_id: null
    }
  }

  componentDidMount = () => {
    this.props.getMedicalInventories()
  }

  registerCategory = () => {
    const { medical_inventories } = this.props
    const { inventory, category } = this.state

    this.props.registerCategory(medical_inventories[inventory].id, category)
  }

  updateCategory = () => {
    const { selected_category_id, category } = this.state

    this.props.updateCategory(selected_category_id, category)
  }

  registerCategoryModal = () => {
    const { category } = this.state
    const { errors } = this.props
    return(
      <div className="modal fade font-weight-normal" id="registerNewCategory" tabIndex={-1} role="dialog" aria-labelledby="registerNewCategoryTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content bg-light">
            <div className="modal-body pt30 px30 pb-0">
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">Register new category</h5>
              <p className="mb20">Category will appear alphabetically in the stack</p>
              <div className="form-group">
                <TextInput
                  label={'Category Name'}
                  value={category}
                  placeholder={'Category'}
                  errorMessage={getArrayError(errors, 'name', 0)}
                  containerClass={`mb30`}
                  onValueChange={(event) => {this.setState({category: event.target.value})}}
                />
              </div>
            </div>
            <div className="modal-body text-center pb30">
              <div className="btn btn-primary px30 mx15" onClick={() => {this.registerCategory()}}>Save</div>
              <div className="btn btn-outline-dark px30 mx15" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  updateCategoryModal = () => {
    const { category } = this.state
    const { errors } = this.props
    return(
      <div className="modal fade font-weight-normal" id="updateCategoryModal" tabIndex={-1} role="dialog" aria-labelledby="updateCategoryModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content bg-light">
            <div className="modal-body pt30 px30 pb-0">
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">Register new category</h5>
              <p className="mb20">Category will appear alphabetically in the stack</p>
              <div className="form-group">
                <TextInput
                  label={'Category Name'}
                  value={category}
                  placeholder={'Category'}
                  errorMessage={getError(errors, 'name')}
                  containerClass={`mb30`}
                  onValueChange={(event) => {this.setState({category: event.target.value})}}
                />
              </div>
            </div>
            <div className="modal-body text-center pb30">
              <div className="btn btn-primary px30 mx15" onClick={() => {this.updateCategory()}}>Save</div>
              <div className="btn btn-outline-dark px30 mx15" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  render() {
    const { medical_inventories, loading } = this.props
    const { inventory, selected_category_id } = this.state
    let categories = null
    if(medical_inventories[inventory]){
      categories = medical_inventories[inventory].categories.map((category,index) => {
        if(category.id  == 'all')
          return
          
        return(
          <tr>
            <td>{category.name}</td>
            <td>
              <Link href="#" className="mr10" 
                data-toggle="modal" 
                data-target="#updateCategoryModal" 
                onClick={() => {this.setState({selected_category_id: category.id, category: category.name})}}  
              >Edit</Link> 

              <Link href="#" data-toggle="modal" 
                data-target="#confirmationModal" 
                onClick={() => {this.setState({selected_category_id: category.id})}}>Delete</Link>
            </td>
          </tr>
        )
      })
    }


    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mt-0 mb15">Medical Inventory</h1> 
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item mr30">
                <a className="nav-link active border-0 font-weight-bold bg-transparent px-0 py5 fs20" 
                  id="supplies-tab" 
                  data-toggle="tab" 
                  href="#supplies" 
                  role="tab" 
                  onClick={() => {this.setState({inventory: 0})}}
                  aria-controls="supplies" aria-selected="true" style={{textDecoration: 'none'}}>Supplies</a>
              </li> 
              <li className="nav-item mr30">
                <a className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs20" 
                  id="instruments-tab" 
                  data-toggle="tab"
                  href="#instruments" 
                  role="tab" 
                  onClick={() => {this.setState({inventory: 1})}}
                  aria-controls="instruments" aria-selected="true" style={{textDecoration: 'none'}}>Instruments</a>
              </li> 
              <li className="nav-item mr30">
                <a className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs20" 
                  id="equipments-tab" 
                  data-toggle="tab" 
                  href="#equipments" 
                  role="tab" 
                  onClick={() => {this.setState({inventory: 2})}}
                  aria-controls="equipments" aria-selected="true" style={{textDecoration: 'none'}}>Equipments</a>
              </li> 
            </ul>
            <div className="tab-content">
              <div className="tab-pane animated fadeIn active" id="supplies" role="tabpanel" aria-labelledby="supplies-tab">
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="mt20 mb10 d-flex align-items-center">
                      <b>Categories</b>
                      <div class="btn btn-outline-primary ml-auto" data-toggle="modal" data-target="#registerNewCategory" onClick={() => {this.setState({category: ''})}}>Register category</div>
                    </h6>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-75">Name</th>
                          <th>Edits</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? <InlineLoader /> : null}
                        {categories}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> 
              <div className="tab-pane animated fadeIn" id="instruments" role="tabpanel" aria-labelledby="instruments-tab">
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="mt20 mb10 d-flex align-items-center">
                      <b>Categories</b>
                      <div class="btn btn-outline-primary ml-auto" data-toggle="modal" data-target="#registerNewCategory" onClick={() => {this.setState({category: ''})}}>Register category</div>
                    </h6>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-75">Name</th>
                          <th>Edits</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? <InlineLoader /> : null}
                        {categories}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> 
              <div className="tab-pane animated fadeIn" id="equipments" role="tabpanel" aria-labelledby="equipments-tab">
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="mt20 mb10  d-flex align-items-center">
                      <b>Categories</b>
                      <div class="btn btn-outline-primary ml-auto" data-toggle="modal" data-target="#registerNewCategory" onClick={() => {this.setState({category: ''})}}>Register category</div>
                    </h6>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-75">Name</th>
                          <th>Edits</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? <InlineLoader /> : null}
                        {categories}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> 
            </div>
          </div> 
        </div>
        <Confirmation 
          title='Delete'
          message='Are you sure you want to delete?'
          confirmText='Yes'
          cancelText='No'
          confirm={() => {this.props.deleteCategory(selected_category_id)}}
          cancel={() => {$('confirmModal').modal('hide')}}
        />
        {this.registerCategoryModal()} 
        {this.updateCategoryModal()} 
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    medical_inventories: state.item.medical_inventories,
    loading: state.item.loading,
    errors: state.item.errors
  })
}

export default connect(mapStateToProps, {getMedicalInventories, registerCategory, deleteCategory, updateCategory})(SettingMedicalInventory)
