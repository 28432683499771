import React, {Component} from 'react'; 
import {connect} from 'react-redux' 
import ReactDOMServer from 'react-dom/server'  
import $ from 'jquery'
import ViewItemModal from './modals/ViewItemModal';

class EquipInfo extends Component{
  constructor(props) {
    super(props)
  
    this.state = { 
      selectedItem: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
    }
  }
  
 
componentWillReceiveProps(nextProps) {
  setTimeout(() => {
    this.setState({

      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
    })
    $("#viewItemModalSmall").modal('show')
  }, 500);
}
render(){
  const {posts} = this.props;
  const {items} = this.props

  if(!items){
    return null;
  }

  let tags = items.tags.map((tag) => {
    return (
      <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
    )
  })

  return (
    <div>

      <div className="modal fade" id="viewItemModalSmall" tabIndex={-1} role="dialog" aria-labelledby="viewItemModalSmallTitle" aria-hidden="true">

        <div className="modal-dialog modal-dialog-centered modal- px30" role="document">
          <div className="modal-content bg-white shadow rounded pb-0 pb0" style={{ paddingBottom: `${0} !important` }}>
            <div className="card pb0 pb-0 border-0 shadow-0">
              <div className="card-body border-0 shadow-0">
              
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="row">
                  <div className="col-md-4 text-center pr-0">
                    <img src={items.icon} className="bg-dark w-100" />
                  </div>
                  <div className="col">
                    <h6 className="mb15 c-pointer" onClick={() => {
                      this.setState({

                        unique:
                          Math.random()
                            .toString(36)
                            .substring(2, 15) +
                          Math.random()
                            .toString(36)
                            .substring(2, 15)
                      })
                      setTimeout(()=>{
                        $(`#viewItemModal-1`).modal('show');
                      },100)
                    }}>{items.name}</h6>
                    <p>{items.used_for}</p>
                    
                  </div>
                  <div className="col-md-12 mt15">
                    <p className=" mb10">Tags</p>
                    {tags}
                  </div>
                </div>

              </div>
            </div>
          </div>
        
        </div>

      </div>
      
        <ViewItemModal
        idModal="viewItemModal-1"
          key={this.state.unique}
          item={items}
          cancel={() => {
            $('#viewItemModal-1').modal('hide')
          }}
        />
    </div>

  )
   
 }
}
const mapStateToProps = (state) =>{ //the state will have a property called "posts" according sa decalred reducers index
  const info = state.posts.showInfo

  if(!info){
    return {
      posts: state.posts.modalOpen
    }
  }else{
    return {
      posts: state.posts.modalOpen,
      items: state.posts.items.find(item => item.id === info)
    }
  }
}

export default connect(mapStateToProps,{})(EquipInfo);