import React, { Component } from 'react'

class Picker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }
  render() {

    const {
        label,
        value,
        errorMessage = '',
        containerClass = '',
        data
      } = this.props

     

    const options = data.map((item, index) => {
        return (
            <option
              key={`options-${this.state.unique}-${index}`}
              value={item.id}
              selected="false"
            >
              {item.name}
            </option>
          )
    })
    return (
      <div className={`form-group ${containerClass}`}>
        {label ? 
        <label htmlFor={`_${this.state.unique}`}>{label}</label>
        :
        ''
        }
        {errorMessage.length > 0 ? (
          <span className="error-state-icon error">
            <i className="mdi mdi-information mdi-rotate-180" />
            {errorMessage}
          </span>
        ) : null}
        <select
          name={`_${this.state.unique}`}
          id={`_${this.state.unique}`}
          className={`form-control ${errorMessage.length > 0 ? 'is-invalid':''}`}
          onChange={this.props.onChange}
          value={value}
        >
          {options}
        </select>
      </div>
    )
  }
}

export default Picker
