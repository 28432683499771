import React, { Component } from 'react'
import App from '../../components/App'
import axios from 'axios'
import { connect } from 'react-redux'
import { fetchPositions, saveInfo, setEditMode, clearRoom } from '../../actions/roomSetupAction'
import Frame from '../../components/Frame';
import { getError, getArrayError, dataURItoBlob, isBase64 } from '../../utilities'
import { TextInput } from '../../components/commons/forms'
import $ from 'jquery'
import Inbodyloader from '../../components/loader/Inbodyloader'; 
import ReactDOMServer from 'react-dom/server'
import ViewItemModal from '../../components/modals/ViewItemModal';
import Confirmation from '../../components/Confirmation'
import Notifier from '../../components/commons/notifier/Notifier';
import WholeBodyloader from '../../components/loader/WholeBodyloader';


export class RoomSetup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      edit: false,
      rooms: [],
      selected_room: null,
      selected_room_name: '',
      room_name: '',
      saveInfo: 0,
      errors:[],
      loading: false,
      successAppear:false,
      successMessage:'',
      modal_key:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      selectedItem: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
    }

    this.setLoadingState = this.setLoadingState.bind(this);
  }

  componentDidMount = () => {
    this.getRooms()
  }
  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  deleteRoom() { 
    const { selected_room } = this.state
    axios
      .post(`api/room-setup/${selected_room}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.getRooms()
        this.setState({ 
          successAppear: true,
          successMessage: 'Room have been successfully deleted!',
          show: true,
          selected_room: null,
          selected_room_name: '',
          edit: false,
          room_name: '',
        }) 
        this.props.setEditMode(0)
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
        $('#rooms_option').val(0);
//        $($('#rooms_option')[0]).attr('selected', 'selected')
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  getRooms(){
    axios
      .get(`api/room-setup`)
      .then(result => {
        console.log(result.data)
        this.setState({
          rooms: result.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  onChange = event => {
    const id = event.target.value 
    console.log('this.state.rooms', this.state.rooms)
    if (id > 0) {
      const room = this.state.rooms.find(rm => rm.id == id)
      console.log('ROOM', room)
      this.setState(
        {
          selected_room: id,
          selected_room_name: room.name
        },
        () => {
          this.props.fetchPositions(id)
        }
      )
    } else {
      $($('#rooms_option')[0]).attr('selected', true)
      this.props.clearRoom()
      this.setState({
        loading: 0
      })
    }
  }
  saveRoom() {  
    this.setState({
      loading: 1,
      successAppear: true,
      successMessage: 'New room setup added!',
      show: true,
    })
    setTimeout(() => {
      this.setState({
        successAppear: false,
      })
    }, 5000) 
    let url = '/api/admin/save-room'
    let data = new FormData()
    data.append('name', this.state.name)

    axios.post(url, data).then((res) => {
      this.setState({
        loading: 0
      })
      this.getRooms()
      $('#addRoom').modal('hide')
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      this.setState({
        loading: 0,
        errors: error.response.data.errors
      })
    })
  }

  addRoom() {
    return (
      <div className="modal fade" id="addRoom" tabIndex={-1} role="dialog" aria-labelledby="addRoomTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <Inbodyloader progress={this.state.loading} />
            <div className="modal-body pt30 px30"> 
              <h5 className="m-0 mx-auto font-weight-bold pb20 fs20 " onClick={()=>{
                
              }}>Register a room</h5>
              
              <div className="form-group">
                <TextInput
                  label={'Room name'}
                  value={this.state.name}
                  placeholder={'Room name'}
                  errorMessage={getError(this.state.errors, 'name')}
                  containerClass={`mb0`}
                  onValueChange={(event) => { this.setState({ name: event.target.value }) }}
                />
              </div>
            </div>
            <div className="modal-body pb0 px30">
              <div className="btn btn-dark mr30 px30" onClick={() => { this.saveRoom() }}>Save</div>
              <div className="btn btn-light border-dark px30" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  setLoadingState(val) {
    this.setState({
      loading: val
    })
  }

  setRoomClear(){
    this.props.clearRoom()
  }

  componentWillUnmount() { 
  }

  editMenu = () => {
    return (
      <div className="col-md-12 text-right mb20">
        <ul className="list-unstyled d-inline-flex align-items-center h-100 main-card w-100 justify-content-end shadow-sm">
          <li className="border-right border-dark h-75 d-flex align-items-center">
            <a
              href="#"
              className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
              onClick={() => {
                this.setState({ edit: false })
                this.props.setEditMode(0)
              }}
            >
              Cancel
            </a>
          </li>
          <li className="border-right border-dark h-75 d-flex align-items-center">
            <a
              href="javascript:;"
              className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100"
              onClick={()=>{ 
                $('#confirmationModal').modal('show');
              }}
            >
              Delete
            </a>
          </li>
          <li className="mx15 ">
            <a className="btn btn-dark m-0 btn-sm px30 mx15" href="javascript:;"
              onClick={()=>{
                this.props.saveInfo()

                this.setState({
                  successAppear: true,
                  successMessage: 'Room set-up published!',
                  show: true,
                })
                setTimeout(() => {
                  this.setState({
                    successAppear: false,
                  })
                }, 5000)
                this.setState({ edit: false })
                this.props.setEditMode(0)
              }}
             > 
              Publish
            </a> 
            {/* <a className="btn btn-outline-dark m-0 btn-sm px30" href="javascript:;" onClick={()=>{
              this.props.saveInfo()

              this.setState({
                successAppear: true,
                successMessage: 'Room set-up saved!',
                show: true,
              })
              setTimeout(() => {
                this.setState({
                  successAppear: false,
                })
              }, 5000) 
            }}>
              Save
            </a> */}
          </li>
        </ul>
      </div>
    )
  }
  

  componentWillReceiveProps(nextProps) {
    if(nextProps.rooms != this.props.rooms){
      this.setState({
        rooms: nextProps.rooms
      })
    }
  }

  renderTable(){
    const { posts } = this.props
    const rows = posts.map((post, index) => {
      let row = post.item;
      let tags = row.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return (
        <tr key={`row-table2-${index}`}>
          <td className=" w150 px10">{row.catalog_number}</td>
          <th className=" w300 c-pointer px10 float-left" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${row.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${row.name}</h6>
                              <p>${row.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`} onClick={() => {

              this.setState({
                selectedItem: row,
                unique:
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15)
              }, () => {
                  $("#viewItemModal-2").modal('show');

              })
            }}>{row.name}</th>
          <td className=" w150 px10">{row.company}</td>
          <td className=" w150 px10">{row.category ? row.category.name : ''}</td>
          <td className=" w100 px10">{row.size}</td> 
        </tr>
      )
    })

    return (
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th className="w150">Catalogue # <i className="fa fa-sort ml5"></i></th>
            <th className="w300">Name <i className="fa fa-sort ml5"></i></th>
            <th className="w150">Company <i className="fa fa-sort ml5"></i></th>
            <th className="w150">Category <i className="fa fa-sort ml5"></i></th>
            <th className="w100">Size <i className="fa fa-sort ml5"></i></th>
          </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
    )
  }
 
  render() {
    const { rooms } = this.state
    
    console.log('this.props', this.props)
    const options = rooms.map((data, index) => {
      return (
        <option value={data.id} key={`room-key-${index}`}>
          {data.name}
        </option>
      )
    })

    return (
      <div className="container">
        <WholeBodyloader progress={this.state.loading}/>
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">Room setup</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 mx-auto">
            <div className="row mb30">
              <div className="col-md-6">
                <div className="form-group mb-0">
                  <label>View a room setup page</label>
                  <select
                    id="rooms_option"
                    className="form-control"
                    onChange={event => {
                      this.setState({
                        loading: 1
                      })
                      this.onChange(event)
                    }}
                  >
                    <option value="0">Select Room Setup</option>
                    {options}
                  </select>
                </div>
              </div>
              <div className="col d-flex">
                <div className="btn btn-outline-primary align-self-end" onClick={()=>{
                  this.setState({
                    modal_key:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
                  })
                  $("#addRoom").modal('show')
                }}>
                  Register a room
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-center">
                <h3 className="mb20">{this.state.selected_room_name ? this.state.selected_room_name :'[Select room]'}</h3>
                {(!this.state.edit && this.state.selected_room_name.length) ? (
                  <a
                    href="javascript:;"
                    onClick={() => {
                      this.setState({ edit: true })
                      this.props.setEditMode(1)
                    }}
                  >
                    Edit rooms
                  </a>
                ) : null}
              </div> 
              {this.state.edit ? this.editMenu() : null}
              <div className="col-md-12">
                <Notifier show={this.state.successAppear} message={this.state.successMessage} type="success" classStyle="mb20" />
              </div>
              <div className="col-md-12">
              
                <div className="card rounded-0 p0 m0 border border-dark">
                  <div className="card-body rounded-0 p0 m0">
{/*                     {this.state.selected_room ? (
                    ) : null} */}

                    {/* <App id={this.state.selected_room} saveInfo={this.state.saveInfo} /> */}

                    
                    <Frame room_id={this.state.selected_room} setLoadingState={this.setLoadingState}/>  
                  </div>
                </div>
              </div>

              <div className="col-md-12 my20">
                    {this.state.selected_room_name.length > 0 ? this.renderTable():null}
              </div>

            </div>
          </div>
        </div>
         <ViewItemModal
          idModal="viewItemModal-2"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal-2').modal('hide')
          }}
        />
        <Confirmation
          title="Delete Room"
          message="Deleting room will remove it from the database and will have to be added back manually."
          confirmText="Delete room"
          cancelText="Cancel"
          confirm={this.deleteRoom.bind(this)}
          cancel={() => {
            $('confirmModal').modal('hide')
          }}
        />
        {this.addRoom()}
      </div>
    )
  }
}
const mapStateToProps = state => { 
  return {
    posts: state.posts.room_setup_positions
  }
}
export default connect(
  mapStateToProps,
  { fetchPositions, saveInfo, setEditMode, clearRoom }
)(RoomSetup)
