import React, { Component } from 'react'
import axios from 'axios'
import { Media } from '../../components/commons/elements/display'
import { Link } from 'react-router'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import $ from 'jquery'
import Confirmation from '../../components/Confirmation'
import TextPreview from '../../components/previews/TextPreview'
import StandardPreview from '../../components/previews/StandardPreview'
import Preview from './Preview'
import { ViewInjuryModal } from '../../components/commons/elements/display/ViewInjuryModal';

class Positioning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      positions: {
        normal: [],
        archive: [],
      },
      selected_positioning: null,
      loading: false,
      master_id: null,
      progress: 0,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }


  previewInjuryModal() {
    const { selected_positioning } = this.state
    if (!selected_positioning) return null
    return (
      <ViewInjuryModal
        idModal="viewInjuryModal"
        key={`view-injury-${this.state.unique}`}
        title={selected_positioning.media_title}
        data={selected_positioning.pressure_maps}
      />
    )
  }

  componentDidMount = () => {
    this.setState({
      progress: 1,
    })

    let url = `api/admin/all-positions`
    let config = {
      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        this.setState({
          progress: percentCompleted >= 95 ? 95 : percentCompleted,
        })
      },
    }

    axios
      .get(url, config)
      .then(result => {
        this.setState(
          {
            progress: 0,
            positions: result.data,
          },
          () => {
            if (this.props.router.location.state) {
              if (this.props.router.location.state.master_id) {
                this.onPositionChange(
                  this.props.router.location.state.master_id
                )
              }
            }
          }
        )
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  onPositionChange = value => {
    this.setState({
      loading: true,
      progress: 1,
    })

    if (value != '') {
      let url = `api/admin/positioning/${value}?draft=0`
      let config = {
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          this.setState({
            progress: percentCompleted >= 95 ? 95 : percentCompleted,
          })
        },
      }

      axios
        .get(url, config)
        .then(result => {
          if (result.data.data) {
            this.setState({
              progress: 0,
              loading: false,
              selected_positioning: result.data.data,
              master_id: value,
            })
          } else {
            this.setState(
              {
                progress: 0,
              },
              () => {
                this.props.router.push({
                  pathname: `/update-positioning/${value}`,
                })
              }
            )
          }
        })
        .catch(error => {
          console.log(error)
          console.log(error.response)
          this.setState({
            selected_positioning: null,
            loading: false,
            progress: 0,
          })
        })
    } else {
      this.setState({
        selected_positioning: null,
        loading: false,
        progress: 0,
      })
    }
  }

  elements = () => {
    const { positioning_content } = this.state
    let element = positioning_content.map(content => {
      if (content.type == 'text') {
        return <TextPreview content={content} />
      } else if (content.type == 'media') {
        return (
          <Media
            data={content ? content.contents : []}
            title={content.title}
            swiperClass="px0"
            styleClass="mt30"
          />
        )
      } else if (content.type == 'standard') {
        return <StandardPreview content={content} />
      }
    })

    return element
  }

  renderView = () => {
    const { selected_positioning } = this.state

    if (!selected_positioning) return null

    return (
      <div className="row">
        <div className="col-md-12 pt30">
          <h4 className="mb-0 d-flex align-items-end">
            {`[${selected_positioning.name}]`}
            <Link
              className="btn btn-link ml-auto p-0 align-items-end text-dark"
              to={{
                pathname: `/update-positioning/${this.state.master_id}`,
              }}
            >
              {'Edit notes'}
            </Link>
          </h4>
        </div>
        <div className="col-md-12 profile-card pt-0">
          <div className="accordion" id="itemListAccordion">
            <div className="card p-0 shadow-sm border-0 mt20">
              <div
                className="card-header bg-white p15 border-0"
                id="note-headeing-1"
              >
                <h5 className="mb-0 d-flex align-items-center">
                  Posititioning references
                </h5>
              </div>
              <div className="card-body pt-0 mt5 px15">
                <div className="row">
                  <div className="col-md-6">
                    <label>Image</label>
                    <img
                      src={selected_positioning.position_image}
                      className="w-100 h200 bg-dark"
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Pressure map</label>
                    <img
                      src={selected_positioning.pressure_map_image}
                      className="w-100 h200 bg-dark"
                    />
                    <label className="w-100 text-center pt10 m-0">
                      <a
                        href="#"
                        className=" mx-auto" 
                        onClick={() => {
                          this.setState({
                            unique:
                              Math.random()
                                .toString(36)
                                .substring(2, 15) +
                              Math.random()
                                .toString(36)
                                .substring(2, 15),
                          }, () => {

                            $('#viewInjuryModal').modal('show')
                          })
                        }}
                      >
                        View injury prevention information
                      </a>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-0 shadow-sm border-0 mt30">
              <Media
                title={selected_positioning.media_title}
                data={selected_positioning.media}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  onDelete = () => {
    const data = new FormData()

    data.append('_method', 'DELETE')
    this.setState({
      progress: 5,
    })
    axios
      .post(`api/admin/positioning/${this.state.master_id}`, data)
      .then(result => {
        this.setState({
          progress: 0,
          selected_positioning: null,
          master_id: null,
          positions: this.state.positions.filter(postion => {
            return postion.id != this.state.master_id
          }),
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          progress: 0,
        })
      })
  }

  render() {
    const { positions, selected_positioning } = this.state
    const options = positions.normal.map((item, index) => {
      return (
        <option key={`options-${index}`} value={item.id} >
          {`${item.name} ${item.draft ? '- [ Draft]' : ''}`}
        </option>
      )
    })

    const archives = positions.archive.map((item, index) => {
      return (
        <option key={`options-${index}`} value={item.id}>
          {`${item.name} ${item.draft ? '- [ Draft]' : ''}`}
        </option>
      )
    })
    return (
      <div className="container">
        <Confirmation
          title="Delete Postion"
          message="Deleting item will remove it from the database and will have to be added back manually."
          confirmText="Delete Postion"
          cancelText="Cancel"
          confirm={() => {
            this.onDelete()
          }}
          cancel={() => {
            $('confirmationModal').modal('hide')
          }}
        />
        <WholeBodyloader progress={this.state.progress} />
        {this.previewInjuryModal()}
        

        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">Positioning page</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-5">
                <div className="form-group m-0">
                  <label>View a position</label>
                  <select
                    className="form-control m-0"
                    onChange={event => {
                      this.onPositionChange(event.target.value)
                    }}
                  >
                    <option value={''}> Select Positioning</option>
                    {options}
                    {
                      positions.archive.length > 0 ? (<optgroup label="Archives List">{archives}</optgroup>):''
                    }
                    
                  </select>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-end">
                <Link
                  to="/create-positioning"
                  className="btn btn-outline-dark px30 mr20"
                >
                  Register a position
                </Link>

                {/* <button
                  className="btn btn-outline-dark px30"
                  data-toggle="modal"
                  data-target="#confirmationModal"
                >
                  Delete
                </button> */}
              </div>
            </div>
            {this.renderView()}
          </div>
        </div>
      </div>
    )
  }
}

export default Positioning
