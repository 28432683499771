import React, { Component } from 'react'
import {
  getItems,
  getMedicalInventoryCategories,
  getItemsPerCategory,
  setItems,
} from '../../actions/ItemAction'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import { Picker } from '../../components/commons/forms'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'

export class AssignItemsDefault extends Component {
  constructor(props) {
    super(props)

    this.state = {
      category: 'all',
      selected_items: [],
      keyword: '',
      inventory: 3,
      page: 1,
    }
    this.searchData = _.debounce(this.getItemResults, 600)

  }

  componentDidMount = () => {
    this.props.getMedicalInventoryCategories(3)
    this.props.getItemsPerCategory(
      'all',
      this.props.selected_items,
      this.state.inventory,
      '',
      this.state.page
    )
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.change != nextProps.change) {
      this.setState(
        {
          paage: 1,
          keyword: '',
          category: 'all',
          selected_items: nextProps.selected_items,
        },
        () => {
          this.props.getItemsPerCategory(
            'all',
            this.state.selected_items,
            this.state.inventory,
            '',
            this.state.page
          )
        }
      )
    }
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState(
      {
        page: page + 1,
      },
      () => {
        this.props.getItemsPerCategory(
          this.state.category,
          this.state.selected_items,
          this.state.inventory,
          '',
          this.state.page
        )
      }
    )
  }

  checkItem = id => {
    let { items } = this.props
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, e => {
      return e.id == id
    })
    if (index >= 0) {
      _.remove(selected_items, e => {
        return e.id == id
      })
    } else {
      index = _.findIndex(items, e => {
        return e.id == id
      })
      if (!items[index].qty) {
        items[index].qty = 1
      }
      selected_items.unshift(items[index])
    }

    this.setState({
      selected_items: selected_items,
    })
  }

  checkItemExistence = id => {
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, e => {
      return e.id == id
    })

    if (index >= 0) {
      return true
    }

    return false
  }

  getItemResults = () => {
    this.props.getItemsPerCategory(
      this.state.category,
      this.state.selected_items,
      this.state.inventory,
      this.state.keyword,
      this.state.page
    )
  }
  searchItems = ({ target: { value } }) => {
    this.setState({ keyword: value, page: 1 }, () => {
      this.searchData()
    })
  }

  render() {
    const { items } = this.props

    const itemlist = items.map((item, index) => {
      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })
      return (
        <tr>
          <td className="w150 px5">{item.catalog_number}</td>
          <td className="w240 px5">
            <Link
              href={`/item/${item.id}`}
              target="_blank"
              className="text-dark fs-16"
              data-container="body"
              data-trigger="hover"
              data-toggle="popover"
              data-placement="right"
              data-html="true"
              data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            >
              {item.name}
            </Link>
          </td>
          <td className="w140 px5">
            {' '}
            <p className="m-0 p-0 float-left w-100 fs14">
              {item.company}{' '}
            </p>{' '}
          </td>
          <td className="w140 px5">
            {' '}
            <p className="m-0 p-0 float-left w-100 fs14">
              {item.category ? item.category.name : ''}
            </p>{' '}
          </td>
          <td className="px-0 text-center w80">{item.size}</td>
          <td className="w100 px5">
            <div className="d-flex align-items-center justify-content-center">
              <input
                type="text"
                name
                className="form-control text-center p-0 mx10 bg-white"
                value={item.qty != null ? item.qty : 1}
                onChange={event => {
                  let { selected_items } = this.state

                  let index = _.findIndex(selected_items, e => {
                    return e.id == item.id
                  })

                  if (index > -1) {
                    selected_items[index].qty = event.target.value
                  }

                  this.setState({
                    selected_items: selected_items,
                  })

                  item.qty = event.target.value
                  items[index] = item
                  this.props.setItems(items)
                }}
              />
            </div>
          </td>

          <td className="w80 text-center pt15 px5">
            <a
              href="#"
              className="text-dark mt15"
              onClick={event => {
                event.preventDefault()
                this.checkItem(item.id)
              }}
            >
              {this.checkItemExistence(item.id) ? 'Remove' : 'Add'}
            </a>
          </td>
        </tr>
      )
    })
    return (
      <div
        className="modal fade"
        id="assignItemsDefault"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="assignItemsDefaultTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content pb0">
            <div className="modal-body">
              <h5 className="m-0 pb0 mb0 h3bold">Assigning items </h5>
            </div>
            <div className="modal-body pt30 pb30">
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Search</label>
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Start typing name, catalogue #..."
                      onChange={this.searchItems.bind(this)}
                      value={this.state.keyword}
                    />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <Picker
                      containerClass={`mb30`}
                      onChange={event => {
                        this.setState(
                          {
                            category: event.target.value,
                            keyword: '',
                            page: 1,
                          },
                          () => {
                            this.props.getItemsPerCategory(
                              this.state.category,
                              this.state.selected_items,
                              this.state.inventory,
                              '',
                              this.state.page
                            )
                          }
                        )
                      }}
                      label={`Filter by category`}
                      value={this.state.category}
                      data={this.props.inventory_categories}
                    />
                  </div>
                </div>
              </div>
              <div
                className="card-body border p-0"
                style={{ minHeight: '350px' }}
              >
                <div className="w-100 of-auto">
                  <table className="table table-striped table-fixed m-0 min-table-width-935">
                    <thead>
                      <tr>
                        <th className="w150 px5"> Catalogue # </th>
                        <th className="w240 px5"> Name </th>
                        <th className="w140 px5"> Company </th>
                        <th className="w140 px5"> Category </th>
                        <th className="w80 px5"> Size </th>
                        <th className="w100 text-center"> Qty </th>
                        <th className="w80" />
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        float: 'left',
                        width: '100%',
                        overflow: 'hidden',
                        maxHeight: '400px',
                        height: '400px',
                      }}
                    >
                      <InfiniteScroll
                        dataLength={this.props.items.length}
                        next={() => {
                          this.getMoreItems()
                        }}
                        hasMore={this.props.more}
                        loader={
                          <p className="px5 py5 text-center">
                            <b>Loading</b>
                          </p>
                        }
                        height={400}
                        endMessage={
                          <p className="px5 py5 text-center">
                            <b>Nothing to load</b>
                          </p>
                        }
                      >
                        {itemlist}
                      </InfiniteScroll>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-body mb-0 pb30 pt0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                data-dismiss="modal"
                onClick={() => {
                  this.props.setNewState({
                    selected_items: this.state.selected_items,
                  })
                }}
              >
                Assign items
              </div>
              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left   m-xst20 m-xsb30"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    items: state.item.items,
    inventory_categories: state.item.inventory_categories,
    more: state.item.more,
  }
}

export default connect(
  mapStateToProps,
  { getItems, getMedicalInventoryCategories, getItemsPerCategory, setItems }
)(AssignItemsDefault)
