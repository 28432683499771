import axios from 'axios'
import $ from 'jquery'


export const fetchItems = () => async dispatch =>{
 
  axios.get(`/api/get-items`)
  .then(res => dispatch({
      type: 'GET_ITEMS',
      payload: res.data.data
  })
  ).catch(error => console.log(error));

};

export const fetchPositions = (room_id) => async dispatch =>{
 
  axios.get(`/api/get-arm-data/${room_id}`).then(res =>{ 
    console.log('res.data.data', res.data)
    dispatch({
            type: 'FETCH_POSITIONS',
            payload: res.data.data
            
    })
  }).catch(error => console.log(error));

};

export const fetchPositionsAdmin = (room_id) => async dispatch => {

  axios.get(`/api/admin/get-arm-data/${room_id}`).then(res => {
    console.log('res.data.data', res.data)
    dispatch({
      type: 'FETCH_POSITIONS',
      payload: res.data.data

    })
  }).catch(error => console.log(error));

};/*this code is the same thing as the one below. since there are no other
    variabes inside this function and there is only a return, you can just remove
        the return. Only place return if there are other variables*/


/* export const fetchPost = () => {
return async dispatch =>{
    const response = await jsonPlaceholder.get('/posts');
    dispatch ({type: 'FETCH_POST', payload: response})
};
};
 */

export const postPositions = (positionData, room_id) => async dispatch =>{
 
  axios.post(`/api/save-arm-data/${room_id}`, positionData)
  .then(res => dispatch({
      type: 'ADD_POSITION',
      save_info: false
  })
  ).catch(error => console.log(error));

};


export const postStagePositions = (positionData, stage_id) => async dispatch => {
  positionData.append('_method', 'PATCH')
  axios.post(`/api/admin/room-setup/${stage_id}`, positionData)
    .then(res => dispatch({
      type: 'ADD_POSITION_STAGE',
      save_info: false
    })
    ).catch(error => console.log(error));

};



export const saveInfo = () => async dispatch => {
  dispatch({ type: 'SAVE_INFO' })
};


export const setPostData = (data) => async dispatch => {
  dispatch({ 
        type: 'SET_POST_DATA',
        room_setup_positions: data 
      })
};

export const clearRoom = (clearMode) => async dispatch => {
  dispatch({ 
    type: 'CLEAR_ROOM', 
    clear_room: clearMode
  })
};


export const setEditMode = (editmode) => async dispatch => {
    dispatch({ 
      type: 'SET_EDIT_MODE',
      edit_mode: editmode
    })
  }; 


  export const openTable = () => async dispatch => {
    dispatch({ type: 'OPEN_TABLE' })
  }; 

  export const closeTable = () => async dispatch =>{

    $("#assignItemsRoomSetupModalCenter").modal('hide') 
    dispatch ({type: 'CLOSE_TABLE'})
  
  }; 

  export const openModal = (id) => async dispatch =>{

    dispatch ({
                type: 'OPEN_MODAL',
                payload: id
              })
  };



  
  export const closeModal = () => async dispatch =>{
    dispatch ({type: 'CLOSE_MODAL'})
  };

  export const openProduct = () => async dispatch =>{
   
    dispatch ({type: 'OPEN_PRODUCT'})
  
  }; 

  export const closeProduct = () => async dispatch =>{
    dispatch ({type: 'CLOSE_PRODUCT'})
  };

  //MEMOIZED


  //export const fetchUser = (id) => dispatch => _fetchUser(id, dispatch);
   //call memoize function. so the request wont get repetitive. 
   
//memoized function
/*   const _fetchUser = _.memoize(async (id, dispatch) =>{
    const response = await jsonPlaceholder.get(`/users/${id}`);
    dispatch ({type: 'FETCH_USER', payload: response.data})
  }); */