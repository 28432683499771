import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  getUsers,
  registerGlobalUser,
  updateUser,
  setUsers,
  resetErrors,
} from '../../actions/UsersActions'
import { getError } from '../../utilities'
import { connect } from 'react-redux'
import { TextInput, Picker } from '../../components/commons/forms'
import axios from 'axios'
import moment from 'moment'
import Confirmation from '../../components/Confirmation'
import $ from 'jquery'

export class BetaKey extends Component {
  constructor(props) {
    super(props)

    let randomPassword = Math.random()
      .toString(36)
      .substring(7)

    this.state = {
      page: 1,
      id: null,
      successAppear: false,
      successMessage: '',
      isReverse: false,
      selectedItem: null,
      keys: [],
      errors: [],
    }
  }

  componentDidMount = () => {
    this.getKeys()
  }

  getKeys = () => {
    const { page } = this.state
    this.setState({
      loading: true,
    })

    let url = `/api/admin/beta-keys`

    axios
      .get(url)
      .then(res => {
        console.log(res)

        this.setState({
          keys: page === 1 ? res.data : [...this.state.keys, ...res.data],
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          loading: false,
        })
      })
  }

  submit = () => {
    const { email } = this.state
    axios
      .post(`/api/admin/beta-keys`, {
        email: email,
      })
      .then(result => {
        this.setState(
          {
            keys: [result.data, ...this.state.keys],
          },
          () => {
            $('#addModal').modal('hide')
          }
        )
      })
      .catch(errors => {})
  }

  compareBy(key) {
    if (key == 'type') {
      return function(a, b) {
        if (a.type.name < b.type.name) return -1
        if (a.type.name > b.type.name) return 1
        return 0
      }
    }

    return function(a, b) {
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1
      return 0
    }
  }

  sortBy(key) {
    let arrayCopy = this.state.users
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ users: arrayCopy, isReverse: !this.state.isReverse })
  }

  renderLoading = () => {
    const { loading } = this.props
    if (loading) {
      return (
        <div className="row">
          <div className="col-md-12 text-center pb30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-md-12 text-center mt30">
          <h3 className="mb-15">Nothing else to see...</h3>
        </div>
      </div>
    )
  }

  registerModal = () => {
    const { email, errors } = this.state

    return (
      <div
        className="modal fade"
        id="addModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="cancelModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-0">
            <div className="modal-body pt30 px30">
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">
                Generate key
              </h5>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Email'}
                      value={email}
                      placeholder={'Email'}
                      errorMessage={getError(errors, 'email', 0)}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ email: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body py0 rounded-0">
              <div
                className="btn btn-dark px30 mr30"
                onClick={() => {
                  this.submit()
                }}
              >
                Send key
              </div>
              <div className="btn btn-outline-dark px30" data-dismiss="modal">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  notifySuccess() {
    return (
      <tr>
        <td className="td-notifier" colSpan={6}>
          <span className="error-state-icon positive text-center w-100 d-flex justify-content-center font-weight-normal">
            <i className="mdi mdi-check-circle" />
            {this.state.successMessage}
          </span>
        </td>
      </tr>
    )
  }
  notification = () => {
    const { users } = this.props
    console.log(users)
    return (
      <tr>
        <td className="td-notifier" colSpan={6}>
          <span className="error-state-icon positive text-center w-100 d-flex justify-content-center font-weight-normal">
            <i className="mdi mdi-check-circle" /> Saving success
          </span>
        </td>
      </tr>
    )
  }

  deleteItem() {
    const { id } = this.state
    axios
      .post(`api/admin/users/block/${id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          isCreate: false,
          successAppear: true,
          successMessage: 'User successfully deleted!',
          show: true,
        })
        this.props.getUsers(this.state.page, 'globalusers')
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  bannedUser = () => {
    const { users, selectedItem } = this.state

    axios
      .post(`api/admin/users/block/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(res => {
        let data = users.find(x => x.id == selectedItem.id)
        data.isDeleted = true
        this.setState({
          users: users.splice(0),
        })
      })
  }

  restoreUser = () => {
    const { users, selectedItem } = this.state

    axios
      .post(`api/admin/users/restore/${selectedItem.id}`, {
        _method: 'PATCH',
      })
      .then(res => {
        let data = users.find(x => x.id == selectedItem.id)
        data.isDeleted = false
        this.setState({
          users: users.splice(0),
        })
      })
  }
  render() {
    const { success } = this.props
    const { keys, page } = this.state

    let key_list = keys.map(beta => {
      return (
        <tr>
          <td>{beta.email}</td>
          <td>{beta.key}</td>
          <td>{beta.claim_by_id ? 'Claim' : ''}</td>
          <td>{beta.created_at.date}</td>
          <td>
            {beta.claim_by_id && beta.updated_at
              ? beta.updated_at.date
              : ''}
          </td>
        </tr>
      )
    })
    return (
      <div className="container">
        <div className="row">
          <Confirmation
            idModal="deleteModal"
            title="Delete User"
            message="Deleting user will remove it from the database and will have to be added back manually."
            confirmText="Delete user"
            cancelText="Cancel"
            confirm={this.deleteItem.bind(this)}
            cancel={() => {
              $('#deleteModal').modal('hide')
            }}
          />
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30 d-flex align-items-end">
              Beta Keys
              <a
                href="#"
                className="ml-auto fs16 pb5"
                data-toggle="modal"
                data-target="#addModal"
                onClick={() => {
                  this.setState({
                    email: '',
                    errors: [],
                  })
                }}
              >
                Generate Key
              </a>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <InfiniteScroll
              className=""
              dataLength={keys.length} //This is important field to render the next data
              next={() => {
                this.setState({ page: page + 1 }, () => {
                  // this.getKeys()
                })
              }}
              hasMore={true}
            >
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th onClick={() => this.sortBy('name')}>
                      Email Address
                      <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('email')}>
                      Key <i className="fa fa-sort ml5" />
                    </th>
                    <th>Status</th>
                    <th onClick={() => this.sortBy('date')}>
                      Date Claim <i className="fa fa-sort ml5" />
                    </th>
                    <th onClick={() => this.sortBy('date')}>
                      Date Created <i className="fa fa-sort ml5" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {success ? this.notification() : null}
                  {this.state.successAppear ? this.notifySuccess() : null}
                  {key_list}
                </tbody>
              </table>
            </InfiniteScroll>
            {this.renderLoading()}
          </div>
        </div>
        {this.registerModal()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    loading: state.users.loading,
    errors: state.users.errors,
    success: state.users.success,
  }
}

export default connect(
  mapStateToProps,
  { getUsers, registerGlobalUser, updateUser, setUsers, resetErrors }
)(BetaKey)
