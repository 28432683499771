import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

function UsedAndDesc(data) {
  

  return (
   
                    <Grid /* Root Grid */
                container
                direction="row"
                justify="center"
                alignItems="stretch"
                spacing={24}
                
            >        
        
           
                <Grid item sm>
                    <Card>
                    <CardContent>
                        <Typography variant="body2" gutterBottom>
                            <b>Used For</b>
                        </Typography>
                        <Typography component="p">
                        {data.theProps.usedfor}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item sm>
                <Card>
                    <CardContent>
                        <Typography variant="body2" gutterBottom>
                            <b>Description</b>
                        </Typography>
                        <Typography component="p">
                        {data.theProps.desc}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>

            </Grid>
        
    
    
    
  );
}


export default UsedAndDesc;