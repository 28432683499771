import React, { Component } from 'react'

export class SpecialtyCards extends Component {
    render() {
        const { styleClass } = this.props
        return (
            <div className={`specialty-card ${styleClass}`} onClick={this.props.onClick} id={this.props.id}>
                <div className="card">
                    <div className="card-body text-center">
                        <img src={this.props.icon.replace('public/', '')} className="" />
                        <h6 className="mt15 card-subtitle">{this.props.name}</h6>

                    </div>
                </div>
            </div>
        )
    }
}

export default SpecialtyCards
