import {
  GET_SURGICAL_CATEGORIES, SET_SURGICAL_CATEGORIES
} from '../actions/types'

const INITIAL_STATE = {
  categories: [],
  loading: true
}

export default (state=INITIAL_STATE, action) => {
  switch(action.type){
    case GET_SURGICAL_CATEGORIES:
      return {...state, categories: action.categories, loading: action.loading}
    case SET_SURGICAL_CATEGORIES:
      return {...state, categories: action.categories, loading: action.loading}
    default: 
      return {...state}
  }
}