import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSpecialtyCategories } from '../actions/SpecialtyActions'
import { createSurgeonSpecialty } from '../actions/SurgeonActions'
import SpecailtyCard from './specailty_card'
import { Confirmation } from './Confirmation'
import $ from 'jquery'
import { Link } from 'react-router'
import axios from 'axios'

class SpecialtySection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      specialty: null,
      procedures: [],
      category: null,
      selected_categories: [],
      selected_procedures: [],
      current_categories: [],
      current_procedures: []
    }
  }

  componentDidMount = () => {
    const { selected_categories, selected_procedures } = this.state
    if(this.props.surgeon.specialties){
      this.props.surgeon.specialties.map((specialty) => {
        specialty.categories.map((category) => {
          selected_categories.push([category.category.id, specialty.specialty.id])
          let temp = []
          category.surgeonProcedures.map((procedure) => {
            temp.push(procedure.procedure.id)
          })
          selected_procedures.push(temp)
        })
      })  
    }
    
  }

  procedures = id => {
    let { selected_categories, selected_procedures } = this.state
    let { specialty } = this.props
    this.setState({
      category: id,
      procedure: []
    })
    this.props.categories.map(category => {
      if (category.id == id) {
        if (!selected_categories.includes(id)) {
          selected_categories.push(id)
          selected_procedures.push([])
        }
        this.setState({
          procedures: category.procedures,
          selected_categories,
        })
      }
    })
  }

  selectedProcedures = id => {
    let { 
      selected_procedures,
      selected_categories, 
      category,
      current_categories,
      current_procedures
    } = this.state

    let index = selected_categories.indexOf(category)
    if (selected_procedures[index] && index != -1) {
      if (selected_procedures[index].includes(id)) {
        selected_procedures[index] = selected_procedures[index].filter(
          e => e !== id
        )
      } else {
        selected_procedures[index].push(id)
      }
    }
    this.setState({
      selected_procedures,
    })
  }

  check = id => {
    let { selected_procedures, selected_categories, category } = this.state
    let index = selected_categories.indexOf(category)
    if (selected_procedures[index] && index != -1) {
      if (selected_procedures[index].includes(id)) {
        return true
      } else {
        return false
      }
    }
  }

  save = () => {
    this.props.createSurgeonSpecialty(
      this.state.selected_categories,
      this.state.selected_procedures
    )
    
    $('#specialty-modal').modal('hide')
  }

  checkActive = id => {
    let status = false
    this.props.surgeon.specialties.map(specialty => {
      if (specialty.specialty.id == id) {
        status = true
      }
    })
    return status
  }

  getCategories = (data) => {
    this.setState({
      procedures: [],
      category: null
    })
    this.props.getSpecialtyCategories(data.id)
  }

  saveprocedure = () => {
    let data = new FormData()
    data.append('category_id', this.state.category)
    data.append('name[]', this.state.newprocedure)
    let url = 'api/specialty-procedure'

    let index = this.state.selected_categories.indexOf(this.state.category)
    let { selected_procedures, procedures } = this.state

    axios.post(url, data).then((res) => {
      console.log(res)
      selected_procedures[index].push(res.data[0].id)
      procedures.push(res.data[0])
      this.setState({
        selected_procedures,
        procedures,
        newprocedure: ''
      })
      $('#procedure-modal').modal('hide')
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }

  render() {
    console.log('state')
    console.log(this.state)

    const cards = this.props.items.map(data => {
      return (
        <SpecailtyCard
          key={data.id}
          icon={data.icon}
          name={data.name}
          active={this.checkActive(data.id)}
          getSpecialtyCategories={() => {
            this.getCategories(data)
          }}
        />
      )
    })

    const categories = this.props.categories.map(category => {
      console.log('categoriessss',category,this.state.selected_categories,
      this.state.selected_procedures)
      const selected = this.state.selected_categories.findIndex((el) => (el === category.id))
      console.log(selected)
      let count = 0
      if(selected >= 0 ){
        if(this.state.selected_procedures[selected].length != undefined)
          count = this.state.selected_procedures[selected].length
      }
      return ( 
        <tr key={category.id} onClick={() => {
          this.procedures(category.id);
          $(`#${category.id}`).closest('table').find('tr').removeClass('_active_fixed');
          $(`#${category.id}`).closest('tr').addClass('_active_fixed');
          $(`#${category.id}`).closest('tr').find('label').click();
        }}>
          <td class="text-left w50 pr-0"> 
            <div class="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: -35, paddingLeft: 35 }}>
              <input type="radio" type="radio"
                name="radiobtn"
                id={category.id}
                onChange={() => {
                  this.procedures(category.id);
                  $(`#${category.id}`).closest('table').find('tr').removeClass('_active_fixed');
                  $(`#${category.id}`).closest('tr').addClass('_active_fixed');
                }}
                
                class="custom-control-input  c-pointer" />
              <label class="custom-control-label  c-pointer" for={category.id}>&nbsp;</label>
            </div>
          </td>
          <td class="text-left" for={category.id} style={{width: 'calc(100% - 50px)'}}>
            <label class="c-pointer font-weight-normal" for={category.id}>{category.name}</label>
            {
             count > 0 ?  (<span class="badge badge-pill badge-secondary float-right px15 py5">{count}</span>):''
            }
          </td>
        </tr>
      )
    })

    const procedures = this.state.procedures.map(procedure => {
      return (
        <tr class={this.check(procedure.id) ? '_active_fixed' : ''} onClick={() => {
          this.selectedProcedures(procedure.id)
          $(`#procedure-${procedure.id}`).closest('tr').find('label').click();
        }}>
          <td class="text-left w50 pr-0">
            <div class="custom-control custom-checkbox"
              style={{ marginRight: -35, paddingLeft: 35 }}>
              <input type="checkbox" class="custom-control-input c-pointer" id={`procedure-${procedure.id}`}
                onChange={() => {
                  this.selectedProcedures(procedure.id)
                }}
                checked={this.check(procedure.id)} />                <label class="custom-control-label c-pointer" for={`procedure-${procedure.id}`}>&nbsp;</label>
            </div> 
          </td>
          <td style={{ width: 'calc(100% - 50px)' }}>
            <label
              class="c-pointer font-weight-normal"
              for={`procedure-${procedure.id}`}
            >{procedure.name}</label></td>
        </tr>
      )
    })

    return (
      <div className="row">
        <div className="col-md-12">
          <h1 className="font-weight-bold">Add new Surgeon</h1>
          <h4 className="my30">Add specialty and procedures</h4>
        </div>

        {cards}

        <div
          class="modal fade"
          id="specialty-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="specialty-modalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header border-0 px30 pt30">
                <h4
                  class="fs20 font-weight-bold m-0"
                  id="specialty-modalLabel" 
                >
                  Select Procedures
                </h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body px30 py-0">
                <p>Select a category to see all of the procedures available</p>
                <div class="row">
                  <div class="col-md-6">
                    <table className="table table-striped table-fixed border">
                      <thead className="text-white">
                        <tr>
                          <th className="w30 text-center w50" />
                          <th className="font-weight-bold w-75">Surgical Area</th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: 260, overflow: 'auto' }}>
                        {categories}
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    <table class="table table-striped table-fixed border">
                      <thead class="bg-dark text-white">
                        <tr>
                          <th className="text-left w50 pr-0" />
                          <th className="font-weight-bold w-75">Procedures</th>
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: 260, overflow: 'auto' }}>
                        {procedures}
                        <tr>
                          <td colSpan="2">&nbsp;
                            {this.state.category ? <button className="btn btn-link ml30 text-dark font-weight-bold py0 pl15" data-toggle="modal" data-target="#procedure-modal">Add new procedure +</button> : null }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    
                  </div>
                </div>
                <div class="form-group mb-0 pt40">
                  <div
                    class="btn btn-dark mr30 btn-element"
                    href="#"
                    onClick={() => {
                      this.save()
                    }}
                  >
                    Save and continue
                    </div>
                  <button class="btn btn-outline-dark px30" onClick={() => {$('#specialty-modal').modal('hide')}}>Back</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div
          class="modal fade"
          id="procedure-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="specialty-modalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content pt0">
              <div class="modal-header border-0 pt30 px30 pb0">
                <h4
                  className="font-weight-bold mb-0"
                  id="specialty-modalLabel" 
                >
                  Adding new procedure
                </h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body pt0 px30" style={{minHeight: 450}} >
                <p class="mb20">You're adding a new procedure under <b>{this.state.specialty} - {this.state.category}</b></p>
                <div class="row">
                  <div class="col-md-6">
                  <label className="mb10">Name procedure</label>
                  <input type="text"
                    value={this.state.newprocedure}
                    onChange={(event) => {
                        this.setState({newprocedure: event.target.value})
                    }} class="form-control" />
                  </div>
                 
                </div>
                <div class="form-group mt30"> 
                  <div
                    class="btn btn-dark px30 mr30"
                    onClick={() => {
                      this.saveprocedure()
                    }}
                  >
                    Save
                  </div>
                  <button class="btn btn-outline-dark px30" onClick={() => {$('#procedure-modal').modal('hide')}}>Back</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Confirmation
          title='Conf'
          subTitle=''
          confirmText=''
          cancelText=''
          confirm='4\4'
          cancel=''
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: state.specialty.categories,
    items: state.specialty.items,
    surgeon: state.surgeon.surgeon,
    specialty: state.specialty.specialty
  }
}

export default connect(
  mapStateToProps,
  { getSpecialtyCategories, createSurgeonSpecialty }
)(SpecialtySection)
