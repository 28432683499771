import React, { Component } from 'react'
import { Link } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'
import $ from 'jquery'
import axios from 'axios'
import Confirmation from '../../components/Confirmation'
class ProcedureDatabase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      procedures: [],
      page: 1,
      loading: false,
      category: 'all',
      specialty: 'all',
      specialties: [],
      categories: [],
      searchKeyword: '',
      selectedItem: null,
    }
  }

  componentDidMount = () => {
    this.makeRequest()
    axios
      .get(`api/admin/specialty`)
      .then(result => {
        this.setState({
          specialties: result.data,
        })
        console.log('routerrrrss', result)
      })
      .catch(error => {
        console.log(error)
      })
  }
  onSpecialtyChange = id => {
    if (id == 'all') {
      this.setState({
        categories: [],
      })
      return
    }
    axios
      .get(`api/admin/specialty/${id}`)
      .then(result => {
        this.setState(
          {
            categories: result.data,
            specialty: id,
            category: 'all',
            page: 1,
          },
          () => {
            this.makeRequest()
          }
        )
        console.log(result)
      })
      .catch(error => {
        console.log(error)
      })
  }
  makeRequest = () => {
    const { page, category, specialty, searchKeyword } = this.state
    this.setState({
      loading: true,
    })
    axios
      .get(
        `api/admin/procedures?page=${page}&specialty=${specialty}&category=${category}&keyword=${searchKeyword}`
      )
      .then(res => {
        this.setState({
          procedures:
            page === 1
              ? res.data.data
              : [...this.state.procedures, ...res.data.data],
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  onCategoryChange = id => {
    this.setState(
      {
        category: id,
        page: 1,
      },
      () => {
        this.makeRequest()
      }
    )
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRequest()
      }
    )
  }

  searchItem() {
    this.setState(
      {
        searchKeyword: this.searchKeyword.value,
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        this.makeRequest()
      }
    )
  }

  deleteItem = () => {
    const { procedures, selectedItem } = this.state
    axios
      .post(`api/admin/procedures/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(res => {
        const data = procedures.filter(x => {
          return x.id != selectedItem.id
        })
        this.setState({
          procedures: data.splice(0),
        })
      })
  }

  render() {
    const search = _.debounce(keyword => {
      this.searchItem(keyword)
    }, 500)

    const specialty_options = this.state.specialties.map((specialty, index) => {
      return (
        <option key={`specialties-${index}`} value={specialty.id}>
          {specialty.name}
        </option>
      )
    })
    const category_options = this.state.categories.map((specialty, index) => {
      return (
        <option key={`specialties-${index}`} value={specialty.id}>
          {specialty.name}
        </option>
      )
    })
    const tableData = this.state.procedures.map((procedure, index) => {
      return (
        <tr key={`procedure-key-${index}`}>
          <td>{procedure.name}</td>
          <td>{procedure.category.name}</td>
          <td>
            {procedure.category.specialty
              ? procedure.category.specialty.name
              : ''}
          </td>
          <td>{procedure.user ? procedure.user.name : ''}</td>
          <td>
            <Link
              to={{ pathname: '/procedure-creator', state: procedure }}
              className="mr15"
            >
              Edit
            </Link>

            <a
              href="javascript:;"
              data-target="#confirmationModal"
              data-toggle="modal"
              onClick={() => this.setState({ selectedItem: procedure })}
            >
              Delete
            </a>
          </td>
        </tr>
      )
    })
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">Procedure Database</h1>
          </div>
        </div>

        <div className="row mb20">
          <div className="col-md-3">
            <div className="form-group mb-0">
              <label>Search</label>
              <input
                className="form-control"
                placeholder="Start typing name, catalogue #..."
                value={this.state.keyword}
                ref={el => (this.searchKeyword = el)}
                onChange={search}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-0">
              <label>Filter procedures by specialty</label>
              <select
                className="form-control"
                onChange={event => {
                  this.onSpecialtyChange(event.target.value)
                }}
              >
                <option value="all">All</option>
                {specialty_options}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-0">
              <label>Filter procedures by category</label>
              <select
                className="form-control"
                value={this.state.category}
                onChange={event => {
                  this.onCategoryChange(event.target.value)
                }}
              >
                <option value="all">All</option>
                {category_options}
              </select>
            </div>
          </div>
          <div className="col-md-3 d-flex align-items-end ">
            <Link
              className="btn btn-outline-dark ml-auto"
              to="/settings/specialties"
            >
              Register a procedure
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <InfiniteScroll
              className=""
              dataLength={this.state.procedures.length} //This is important field to render the next data
              next={this.loadMoreData.bind(this)}
              hasMore={true}
            >
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>
                      Procedure name <i className="fa fa-sort ml5" />{' '}
                    </th>
                    <th>
                      Category <i className="fa fa-sort ml5" />{' '}
                    </th>
                    <th>
                      Specialty <i className="fa fa-sort ml5" />{' '}
                    </th>
                    <th>
                      Author <i className="fa fa-sort ml5" />{' '}
                    </th>
                    <th>
                      Edits <i className="fa fa-sort ml5" />{' '}
                    </th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>

        <Confirmation
          title="Delete Item"
          message="Deleting item will remove it from the database and will have to be added back manually."
          confirmText="Delete item"
          cancelText="Cancel"
          confirm={this.deleteItem.bind(this)}
          cancel={() => {
            $('confirmModal').modal('hide')
          }}
        />
      </div>
    )
  }
}

export default ProcedureDatabase
