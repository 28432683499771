import React, { Component } from "react";
import {withRouter} from 'react-router';
import SurgeonForm from "../../components/surgeon_form";
import SurgeonPreview from "../../components/surgeon_preview";
import { connect } from 'react-redux'
import Confirmation from '../../components/Confirmation'
import Notification from '../../components/Notification'
import { createSurgeon, clearSurgeonData, setIsNext } from '../../actions/SurgeonActions'
import SpecialtySection from "../../components/specialty_section";
import $ from 'jquery'

class SurgeonCreate extends Component {

  
  constructor(props){
        super(props)
        this.state = {
            isNext: false,
            page:1
        }
    }
  componentWillReceiveProps(nextProps) {
      
    }
    routerWillLeave(nextLocation) {
    /*  if(nextLocation.action == "POP"){
        
      } */
    }

    componentWillMount() {

      window.scrollTo({
        top: 0,
        left: 0, 
      });
    }

    componentDidMount = () => {
      this.props.clearSurgeonData()
  //    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  
      window.scrollTo({
        top: 0,
        left: 0, 
      });
      window.history.pushState({ page: 1 }, "", "#surgeon_info");
 
      window.onpopstate = (event) => {
        event.preventDefault()
        
        if (this.props.isNext) {
          window.history.pushState({ page: 1 }, "", "#surgeon_info");
          this.back()
        }else{
          $('.cancel-button').click();
        }

      };
    }
    renderRightSide(){
        if(this.props.isNext)
            return <SpecialtySection />
        return <SurgeonForm />
    }
    back = () => {
        this.props.setIsNext(false)
    }

    render() {
      return (
        <div className="container">
          <div className="row">

            <div className="col-md-12 d-block d-md-none">
              <h1 className="h1bold page-title">
                {this.props.isEditMode ? 'Edit Surgeon' : 'Add new Surgeon'}
              </h1>
              <h4 className="h2med page-sub-title">Personal Information</h4>
            </div>

            <div className="col-lg-3 col-md-4 mb30">
              <SurgeonPreview />
            </div>
            <div className="col">
              { this.renderRightSide()}
               <div className="form-group m-0">
                  <h5 className="">&nbsp;</h5>
                {this.props.isNext ? <div className="btn btn-primary  mr30 mt10 m-xsx0" data-toggle="modal" data-target="#notificationModal">Save surgeon</div> : <div className="btn btn-primary mr30 mt10 m-xsx0" onClick={() => {
                  this.props.createSurgeon(); }}>Save and continue</div>}
                {this.props.isNext ? <button className="btn btn-outline-primary mr30 mt10 m-xsx0" onClick={() => { this.back() }}>Back</button> : <button className="btn btn-outline-primary cancel-button mr30 mt10 mr30 mt10 mw125 m-xsx0 m-xst20" onClick={() => {this.props.router.goBack()}}>Cancel</button> }
                </div>
							</div>
							<Confirmation 
								title='Cancel'
								message='Are you sure you want to cancel?'
								confirmText='Yes'
								cancelText='No'
								confirm={() => {this.props.router.goBack()}}
								cancel={() => {$('confirmModal').modal('hide')}}
							/>
							<Notification
								title='Success'
								message='Surgeon successfully save.'
								confirmText='Ok'
								confirm={() => {this.props.router.push('/surgeon')}}
							/>
            </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return({
        isNext: state.surgeon.isNext
    })
}

export default connect(mapStateToProps, {createSurgeon, clearSurgeonData, setIsNext})(SurgeonCreate)
