import React, { Component } from 'react'
import { connect } from 'react-redux'

export class SurgeonSpecialties extends Component {
  render() {
    const { surgeon } = this.props
    let specialties = surgeon.specialties.map((specialty) => {
      let categories = specialty.categories.map((category) => {
        console.log(category.category.name)
        let procedures = category.surgeonProcedures.map((procedure) => {
          return(
            <li className="mb10"><a href="#" className="text-dark font-weight-normal">{procedure.procedure.name}</a></li>
          )
        })
        return(
          <div className="mb10">
            <div className="mb10 p-0" id={`heading${category.id}`}>
              <a href="#" className="text-dark fs16 p-0 m-0" data-toggle="collapse" data-target={`#collapse${category.id}`} aria-expanded="true" aria-controls="collapseOne">
                {category.category.name}
              </a>
            </div>
            <div id={`collapse${category.id}`} className="collapse" aria-labelledby={`heading${category.id}`} data-parent="#itemListAccordion">
              <div>
                <ul className="list-unstyled p-0 m-0">
                  {procedures}
                </ul>
              </div>
            </div>
          </div>  
        )
      })
      return( 
        <li className="px-0  pb20 bg-transparent">
          <div className="media px-0 mb15">
            <img src={specialty.specialty.icon} className="align-self-center mr10 bg-transparent" alt="..." style={{width: '40px'}} />
            <div className="media-body align-self-center m-0">
              <h5 className="m-0 fw500 fs18">{specialty.specialty.name}</h5> 
            </div>
          </div>
          <div className="accordion" id="categoryListAccordion">
            {categories}
          </div>
        </li>  
      )
    })


    return (
      <div className="card side-card border-0 mt20">
        <div className="card-body border-0">
          <div className="row">
            <div className="col-md-12 text-center px-0 pb20">
              <h5 className="m-0 font-weight-bold fs18">Specialties &amp; Procedures</h5>
            </div>
            <div className="col-md-12">
              <ul className="list-unstyled m-0 pb10">
                
                {specialties}

              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    surgeon: state.surgeon.surgeon
  })
}

export default connect(mapStateToProps)(SurgeonSpecialties)
