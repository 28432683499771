import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import Create from './Create'
import axios from 'axios'
import Vimeo from '@u-wave/react-vimeo'
import img from '../../assets/images/img.png'
import error_img from '../../assets/images/error_img.png'
import MediaOnly from '../../components/commons/elements/display/MediaOnly'
import { Media } from '../../components/commons/elements/display'

export class Item extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gallerySwiper: null,
      thumbnailSwiper: null,
      editMode: false,
      item: {},
      loading: false,
      errorInVimeo: false,
      selected_media: false
    }
    this.setSelectedMediaInProps = this.setSelectedMediaInProps.bind(this)
  }

  setSelectedMediaInProps = media => {
    console.log('this.state.selected_media', this.state.selected_media)
    this.props.setSelectedMedia(media)

  }

  componentWillReceiveProps(nextProps) {
		/* this.setState({
			item:this.props.item
		});
		if (nextProps.params.id === this.props.params.id) {
      return
    } else {
      this.makeRemoteRequest()
    } */
  }
  componentDidMount() {
    //this.makeRemoteRequest()
    this.setState({
      item: this.props.item
    }); 
  }

  makeRemoteRequest = () => {
    this.setState({ loading: true })
    const { params } = this.props
    axios
      .get(`api/admin/items/${params.id}`)
      .then(res => {
        console.log(res)
        this.setState({
          item: res.data.data,
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false, hasMore: false })
      })
  }

  editItemSuccess(item) {
    this.setState({
      editMode: false,
      item,
    })
  }

  openEditTab(e) {
    e.preventDefault()
    this.setState({
      editMode: true,
    })
  }

  loadMedias() {
    const { item, loading } = this.state
    console.log('medias', item)
    if (item.parent_media && item.media) {
      if (item.parent_media.length > 0 && !item.media.length) {
        return (
          <Media setSelectedMedia={this.setSelectedMediaInProps} no_bigpiture={true} data={item.parent_media ? item.parent_media : []} title="CORH Media" innerProps={`gallery`} swiperClass="px0" styleClass="mb20" />
        )
      } else if (item.media.length > 0) {
        return (
          <div>
            <Media setSelectedMedia={this.setSelectedMediaInProps} no_bigpiture={true} data={item.parent_media ? item.parent_media : []} title="CORH Media" innerProps={`gallery`} swiperClass="px0" styleClass="mb20" />
            <Media setSelectedMedia={this.setSelectedMediaInProps} no_bigpiture={true} data={item.media ? item.media : []} title="My Media" innerProps={`gallery`} swiperClass="px0" styleClass="mb20" />
          </div>
        )
      }
    }
    if (!item.parent_media && item.media) {
      let title = item.manageable ? 'My Media' : 'CORH Media';
      return (<Media setSelectedMedia={this.setSelectedMediaInProps} no_bigpiture={true} data={item.media ? item.media : []} title={title} innerProps={`gallery`} swiperClass="px0" styleClass="mb20" />)
    }
    return (
      <Media setSelectedMedia={this.setSelectedMediaInProps} no_bigpiture={true} data={item.media ? item.media : []} innerProps={`gallery`} title="CORH Media" swiperClass="px0" styleClass="mb20" />
    )
  }

  render() {
    const { loading } = this.state
    const { item } = this.props

    console.log('ppu', this.props)
    if (loading || item == null)
      return (
        <div className="container animated fadeIn">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )
    let temp = item.tags ? item.tags : []
    let tags = temp.map(tag => {
      return (
        <span className="badge badge-secondary badge-pill px15 py5 mr10 mb10 surgeon-badge specialty-pill font-weight-normal">
          {tag.name}
        </span>
      )
    })

    if (this.state.editMode)
      return (
        <Create
          editItemSuccess={item => this.editItemSuccess(item)}
          item={item}
          modalCancel={() => {
            this.setState({
              editMode: false,
            })
          }}
        />
      )

    return (
      <div className="container">
        <div className="row">

          <div class="col-md-12 d-block d-md-none d-lg-none d-xl-none">
            <h4 class="mb20 h2bold">
              <div className="d-flex">
                <span className="mb0 justify-content-between m-xst30 m-mdt30">
                  {item.name} - {item.catalog_number}
                </span>
                <div class="ml-auto text-dark font-weight-bold m-xst30 m-mdt30 c-pointer" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">&times;</span>
                </div>
              </div>
              <p class="text-right float-right d-none">
                <a
                  href="#"
                  class="btn btn-link text-dark"
                  onClick={this.openEditTab.bind(this)}
                >
                  <u>Edit details</u>
                </a>
              </p>

            </h4>
          </div>
          <div className="col-md-3">
            <div className="card border-0 side-card mb20">
              <div className="image-div">

                <img src={item.icon} className="card-img-top bg-dark" alt="..." />
              </div>
              <div className="card-body border-0 p20">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h6 className="font-weight-bold mb15">{item.name}</h6>
                    <h6 className="font-weight-bold mb15">
                      {item.catalog_number}
                    </h6>
                    <h6 className="font-weight-bold mb15">
                      {item.size ? item.size : '-'}
                    </h6>
                    <h6 className="font-weight-normal mb15">{item.company}</h6>
                    <h6 className="font-weight-bold mb15">
                      {item.category ? item.category.name : ''}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-0 side-card d-none d-md-block">
              <div className="card-body border-0 p15">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="font-weight-bold mb10">Tags</h6>
                    {tags}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div class="row mb20 d-none d-md-block d-lg-block d-xl-block">
              <div class="col-md-12">
                <h4 class="mb20 h2bold">
                  <div className="d-flex">
                    <span className="mb0 justify-content-between m-xst30">
                      {item.name} - {item.catalog_number}
                    </span>
                    <div class="ml-auto text-dark font-weight-bold m-xst30" data-dismiss="modal" aria-label="Close" >
                      <span aria-hidden="true">&times;</span>
                    </div>
                  </div>
                  <p class="text-right float-right d-none">
                    <a
                      href="#"
                      class="btn btn-link text-dark"
                      onClick={this.openEditTab.bind(this)}
                    >
                      <u>Edit details</u>
                    </a>
                  </p>

                </h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card border-0 bg-transparent">
                  <div className="card-body p-0 bg-transparent">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 mb20">
                        <div className="card border-0 h-100 shadow-sm">
                          <div className="card-body">
                            <h6 className="mb15 font-weight-bold">Used for</h6>
                            <p dangerouslySetInnerHTML={{__html: item.used_for ? item.used_for : '-'}}> 
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 mb20">
                        <div className="card border-0 h-100 shadow-sm">
                          <div className="card-body">
                            <h6 className="mb15 font-weight-bold">
                              Description
                            </h6>
                            <p dangerouslySetInnerHTML={{__html:item.description
                                ? item.description
                                : ' '}}>  
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 mb20 d-block d-md-none">
                        <div className="card border-0 shadow-sm">
                          <div className="card-body border-0 p15">
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="font-weight-bold mb10">Tags</h6>
                                {tags}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    {this.loadMedias()}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Item
