import {
  FETCH_SPECIALTIES_DATA,
  FETCH_SPECIALTIES_DATA_COMPLETE,
  SET_SELECTED_SPECIALTY,
  GET_SPECIALTY_CATEGORIES,
  RESET_SPECIALTY_ERRORS
} from './types'

import axios from 'axios'
import $ from 'jquery'



export const getSpecailtiesData = () => {
  return (dispatch, getState) => {
    const { user } = getState().auth
    dispatch({ type: FETCH_SPECIALTIES_DATA })

    if(user.user_type_id == 2) return
    axios.get(`api/admin/specialties`).then(function (result) {
      console.log(result)
      dispatch({ 
        type: FETCH_SPECIALTIES_DATA_COMPLETE, 
        payload:result.data.data,
        errors: []
      })
    }).catch(function (error) {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const setSpecialty = (data) => {
  return (dispatch) => {
    dispatch({ 
      type: SET_SELECTED_SPECIALTY,
      payload:data 
    })
  }
}

export const localGetSpecialties = (dispatch) => {
  dispatch({ 
    type: FETCH_SPECIALTIES_DATA,
  })

  axios.get(`api/admin/specialties`).then(function (result) {
    dispatch({ 
      type: FETCH_SPECIALTIES_DATA_COMPLETE, 
      payload:result.data.data,
      errors: [],
      loading: 0
    })
    $('#addSpecialtyModal').modal('hide')
  }).catch(function (error) {
    console.log(error)
    console.log(error.response)
  })
}

export const resetSpecialtyErrors = () => {
  return(dispatch) => {
    dispatch({
      type: RESET_SPECIALTY_ERRORS
    })
  }
}

export const addSpecialty = (icon, name) => {
  return(dispatch, getState) => {
    const { items } = getState().specialty

    dispatch({
      type: FETCH_SPECIALTIES_DATA_COMPLETE,
      payload: items,
      errors: [],
      loading: 1
    })

    let data = new FormData()
    data.append('icon[]', icon)
    data.append('name[]', name)
    data.append('color[]', '#00000')

    let url = '/api/admin/specialty'

    axios.post(url, data).then((res) => {
      localGetSpecialties(dispatch)      
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({ 
        type: FETCH_SPECIALTIES_DATA_COMPLETE, 
        payload: items,
        errors: error.response.data.errors,
        loading: 1
      })

    })
  }
}

export const updateSpecialty = (id, icon, name) => {
  return(dispatch, getState) => {
    const { items } = getState().specialty

    dispatch({
      type: FETCH_SPECIALTIES_DATA_COMPLETE,
      payload: items,
      errors: [],
      loading: 1
    })

    let data = new FormData()
    data.append('icon', icon)
    data.append('name', name)
    data.append('color', '#00000')
    data.append('_method', 'PATCH')

    let url = `/api/admin/specialty/${id}`

    axios.post(url, data).then((res) => {
      localGetSpecialties(dispatch)      
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({ 
        type: FETCH_SPECIALTIES_DATA_COMPLETE, 
        payload: items,
        errors: error.response.data.errors,
        loading: false
      })
    })
  }
}

export const deleteSpecialty = (id) => {
  return(dispatch, getState) => {
    const { items } = getState().specialty

    dispatch({
      type: FETCH_SPECIALTIES_DATA_COMPLETE,
      payload: items,
      errors: [],
      loading: 1
    })

    let url = `/api/admin/specialty/${id}`

    axios.post(url, {_method: 'DELETE'}).then((res) => {
      localGetSpecialties(dispatch)      
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({ 
        type: FETCH_SPECIALTIES_DATA_COMPLETE, 
        payload: items,
        errors: error.response.data.errors,
        loading: false
      })
    })
    

  }
}

export const addSpecialtyCategory = (id, icon, name) => {
  return(dispatch, getState) => {
    const { categories } = getState().specialty

    let data = new FormData()
    data.append('specialty_id', id)
    data.append('icon[]', icon)
    data.append('name[]', name)

    let url = '/api/admin/specialty-categories'

    axios.post(url, data).then((res) => {
      $('#addCategoryModal').modal('hide')
      localGetSpecialtyCategories(id, dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: GET_SPECIALTY_CATEGORIES,
        categories: categories,
        errors: error.response.data.errors
      })
    })
  }
}

export const updateSpecialtyCategory = (s_id, id, icon, name) => {
  return(dispatch, getState) => {
    const { categories } = getState().specialty

    let data = new FormData()
    data.append('icon', icon)
    data.append('name', name)
    data.append('_method', 'PATCH')

    let url = `/api/admin/specialty-categories/${id}`

    axios.post(url, data).then((res) => {
      $('#addCategoryModal').modal('hide')
      localGetSpecialtyCategories(s_id, dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: GET_SPECIALTY_CATEGORIES,
        categories: categories,
        errors: error.response.data.errors
      })
    })
  }
}

export const deleteSpecialtyCategory = (s_id, id) => {
  return(dispatch, getState) => {
    const { categories } = getState().specialty

    let url = `/api/admin/specialty-categories/${id}`

    axios.post(url, {_method: 'DELETE'}).then((res) => {
      localGetSpecialtyCategories(s_id, dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: GET_SPECIALTY_CATEGORIES,
        categories: categories,
        errors: error.response.data.errors
      })
    })
  }
}

export const addCategoryProcedure = (id, icon, name) => {
  return(dispatch, getState) => {
    const { categories } = getState().specialty

    let data = new FormData()
    data.append('category_id', id)
    data.append('icon[]', icon)
    data.append('name[]', name)

    let url = '/api/admin/specialty-procedure'

    axios.post(url, data).then((res) => {
      $('#addProcedureModal').modal('hide')
      localGetSpecialtyCategories(id, dispatch)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      dispatch({
        type: GET_SPECIALTY_CATEGORIES,
        categories: categories,
        errors: error.response.data.errors
      })
    })
  }
}

const localGetSpecialtyCategories = (id, dispatch) => {
  dispatch({
    type: GET_SPECIALTY_CATEGORIES,
    categories: [],
    errors: []
  })
  let url = `/api/admin/specialty-categories-by-specialty/${id}`
  axios.get(url).then((res) => {
    dispatch({
      type: GET_SPECIALTY_CATEGORIES,
      categories: res.data.data,
      specialty: id,
      errors: []
    })
  }).catch((error) => {
    console.log(error)
    console.log(error.response)
  })
}

export const getSpecialtyCategories = (id) => {
  return(dispatch) => {
    dispatch({
      type: GET_SPECIALTY_CATEGORIES,
      categories: [],
      errors: []
    })
    let url = `/api/admin/specialty-categories-by-specialty/${id}`
    axios.get(url).then((res) => {
      dispatch({
        type: GET_SPECIALTY_CATEGORIES,
        categories: res.data.data,
        specialty: id,
        errors: []
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })

  }
}