import React, { Component } from 'react'

class TextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }
  render() {
    const {
      label,
      value,
      errorMessage = '',
      containerClass = '',
      inputType = 'text',
      placeholder = '',
    } = this.props
    return (
      <div className={`form-group ${containerClass}`}>
        {
          label ? 
          <label htmlFor={`_${this.state.unique}`}>{label}</label>
          :null
        }
        {errorMessage.length > 0 ? (
          <span className="error-state-icon error">
            <i className="mdi mdi-information mdi-rotate-180" />
            {errorMessage}
          </span>
        ) : null}

        <input
          type={inputType}
          name={`_${this.state.unique}`}
          id={`_${this.state.unique}`}
          value={value}
          onChange={this.props.onValueChange ? this.props.onValueChange:this.props.onChange}
          placeholder={placeholder}
          className={
            errorMessage.length > 0 ? 'form-control is-invalid' : 'form-control'
          }
          onKeyPress={this.props.onKeyPress}
          onBlur={this.props.onBlur}
          
        />
      </div>
    )
  }
}

export default TextInput
