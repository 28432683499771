import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import 'airbnb-js-shims'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import $ from 'jquery'
import Popper from 'popper.js'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducers from './reducers'
import routes from './routes'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import CKEditor from 'ckeditor4-react'
import Raven from 'raven-js'
import { sentry_url } from './config/sentry-config'
import { ENVIROMENT } from './config/settings'


if (process.env.NODE_ENV === 'production') {
  Raven.config(sentry_url).install()
  console.log = () =>{}
  console.warn = () =>{}
  console.error = () =>{}
}



CKEditor.editorUrl = '/ckeditor/ckeditor.js'

const persistedReducer = persistReducer(ENVIROMENT.persistConfig, reducers)

const store = createStore(
  persistedReducer,
  {},
  ENVIROMENT.devTools
)

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {routes}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
