import React, { Component } from 'react';
import { Link} from 'react-router'
import img from '../assets/images/img.png'

class MedicalInventoryCard extends Component {

    render() {
        return (
            <div className="row">
                <Link className="col-md-4 medical-inventory-card" to="/medical-inventory/supplies">
                    <div className="text-dark">
                        <div className="card">
                            <img src={img} className="bg-dark card-img-top h200" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title mb20">
                                    <Link to="/medical-inventory/supplies">Supplies</Link>
                                </h5>
                                <p className="card-text pb30 font-weight-normal">Our list of supplies that you find for XXXXXX how is supplies different from others.</p>
                                <a href="#" className="text-dark font-weight-bold align-self-baseline pt30"><u>View database</u></a>
                            </div>
                        </div>
                    </div>
                </Link>

                <div className="col-md-4">
                    <div className="text-dark">
                        <div className="card">
                            <img src={img} className="bg-dark card-img-top h200" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title mb20">
                                    <Link to="/instruments">Instruments</Link>
                                </h5>
                                <p className="card-text pb30">Our list of supplies that you find for XXXXXX how is supplies different from others.</p>
                                <a href="#" className="text-dark font-weight-bold align-self-baseline pt30"><u>View database</u></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="text-dark">
                        <div className="card">
                            <img src={img} className="bg-dark card-img-top h200" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title mb20">
                                    <Link to="/equipments">Equipment</Link>
                                </h5>
                                <p className="card-text pb30">Our list of supplies that you find for XXXXXX how is supplies different from others.</p>
                                <a href="#" className="text-dark font-weight-bold align-self-baseline pt30"><u>View database</u></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MedicalInventoryCard;