import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import error_img from '../../../../assets/images/error_img.png'
import Vimeo from '@u-wave/react-vimeo'
import BigPictureModal from '../../../modals/BigPictureModal'
import $ from 'jquery'

export class MediaOnly extends Component {
	constructor(props) {
		super(props)

		this.state = {

			galleryRef: null,
			thumbRef: null,
			media_data: {},
			unique:
				Math.random()
					.toString(36)
					.substring(2, 15) +
				Math.random()
					.toString(36)
					.substring(2, 15),
			video_loaded: true
		}
		this.galleryRef = this.galleryRef.bind(this)
		this.thumbRef = this.thumbRef.bind(this)
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextState.gallerySwiper && nextState.thumbnailSwiper) {
			const { gallerySwiper, thumbnailSwiper } = nextState

			gallerySwiper.controller.control = thumbnailSwiper
			thumbnailSwiper.controller.control = gallerySwiper
		}
	}

	galleryRef(ref) {
		if (ref) this.setState({ gallerySwiper: ref.swiper })
	}

	thumbRef(ref) {
		if (ref) this.setState({ thumbnailSwiper: ref.swiper })
	}

	goNext() {
		if (this.swiper) this.swiper.slideNext()
	}

	goPrev() {
		if (this.swiper) this.swiper.slidePrev()
	}

	renderVideo(gallery) {
		let vimeoError = false;

		if (gallery.disk == 'local' || !gallery.disk) {
			return (
				<video className="bg-dark w-100 align-items-center h-100" controls>
					<source src={gallery.source} type="video/mp4" />
					<source src={gallery.source} type="video/webM" />
					<source src={gallery.source} type="video/ogg" />
					Your browser does not support the video tag.
				</video>
			)
		}

		const source = gallery.source
		if (vimeoError) {
			return <img class="bg-light w-100 h-100" src={error_img} />
		} else {
			return(
				<Vimeo 
					video={source} height={450} className="bg-dark w-100 align-items-center h-100" 
					onError={() => {
						vimeoError = false;
						this.setState({
							errorInVimeo: true,
							video_loaded: false
						})
					}} 

					onLoaded={() => {
						this.setState({
							video_loaded: true
						})
					}}
				/>
			)
			

		}
	}

	mediaitem = media => {
		const { no_bigpiture } = this.props 
		console.log('media-item', media)
		if (media.gallery) {
			return (
				<div className="d-flex pb20 align-items-stretch flex-column" key={media.gallery.id} data-toggle='modal' data-target={`#${this.props.index}media-big-picture`} onClick={() => {
					this.setState({
						media_data: media.gallery, 
					}, () => {
						if(no_bigpiture){
							this.props.setSelectedMedia(this.state.media_data)
						}else{
							$(`#media-big-picture-${this.state.unique}`).modal('show')
						}
					})
				}}>
					<div className="row">
						<div className="col-lg-8 col-md-12 of-hidden text-center d-flex align-items-center">
							{this.state.video_loaded ? null : <p className="text-danger">Video is converting please wait, refresh page in a minute.</p>}
							{media.gallery.type == 'video' ? (
								this.renderVideo(media.gallery)
							) : (
									<img
										src={media.gallery.source}
										className="bg-dark w-100 align-items-center h-100"
									/>
								)}
						</div>
						<div className="col-lg-4 col-md-12 of-auto pl-lg-0 pl-xl-0">
							<p className="font-weight-bold mb15 m-xst20 m-mdt20">
								{media.gallery.title ? media.gallery.title : '  -'}
							</p>
							<div className="media-description of-auto h220 pr10"
								dangerouslySetInnerHTML={{
									__html: media.gallery.caption
								}}
							/>
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className="d-flex pb20 align-items-stretch flex-column" key={media.id} data-toggle='modal' data-target={`#${this.props.index}media-big-picture`} onClick={() => {
					this.setState({
						media_data: media, 
					}, () => {

						if (no_bigpiture) {
							this.props.setSelectedMedia(this.state.media_data)
						} else {
							$(`#media-big-picture-${this.state.unique}`).modal('show')
						}
				
					})
				}}>
					<div className="row">
						<div className="col-lg-8 col-md-12 of-hidden text-center d-flex align-items-center">
							{this.state.video_loaded ? null : <p className="text-danger">Video is converting please wait, refresh page in a minute.</p>}
							{media.type == 'video' ? (
								this.renderVideo(media)
							) : (
									<img
										src={media.source}
										className="bg-dark w-100 align-items-center h-100"
									/>
								)}
						</div>
						<div className="col-lg-4 col-md-12 of-auto pl-lg-0 pl-xl-0">
							<p className="font-weight-bold mb15 m-xst20 m-mdt20">
								{media.title ? media.title : '  -'}
							</p>
							<div className="media-description"
								dangerouslySetInnerHTML={{
									__html: media.caption
										? media.caption.length > 300 ? media.caption.substr(0, 300) + '...' : media.caption
										: '-',
								}}
							/>
						</div>
					</div>
				</div>
			)
		}
	}

	mediathumbs = media => {
		if (media.gallery) {
			return (
				<div className="col-md-3 px-0" key={media.gallery.id}>
					<img src={media.gallery.thumbnail} className="bg-dark w-100 h130" />
				</div>
			)
		} else {
			return (
				<div className="col-md-3 px-0" key={media.id}>
					<img src={media.thumbnail} className="bg-dark w-100 h130" />
				</div>
			)
		}
	}

	mediapreview = item => {
		const { styleClass, containerStyle, title, data, innerProps } = this.props
		const gallerySwiperParams = {
			spaceBetween: 20,
			freeMode: false,
			navigation: {
				nextEl: '.swiper-button-next.main-swipebtn',
				prevEl: '.swiper-button-prev.main-swipebtn',
			}
		}

		const thumbnailSwiperParams = {
			spaceBetween: 10,
			centeredSlides: true,
			slidesPerView: 4,
			touchRatio: 0.2,
			freeMode: false,
			slideToClickedSlide: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		}
		let gallery = item.map((media, index) => {
			if (innerProps) {
				return this.mediaitem(media[innerProps], index)
			}
			return this.mediaitem(media)
		})

		let thumbs = item.map((media, index) => {
			if (innerProps) {
				return this.mediathumbs(media[innerProps], index)
			}
			return this.mediathumbs(media)
		})

		return (
			<div className={`bg-white h-100 shadow-sm float-left w-100 ${styleClass ? styleClass :'p15'} ${containerStyle}`}>
				<Swiper
					{...gallerySwiperParams}
					ref={this.galleryRef}
					shouldSwiperUpdate
				>
					{gallery}
				</Swiper>
				<div className="w-100 pt0 thumbnail-swiper">
					<Swiper
						{...thumbnailSwiperParams}
						ref={this.thumbRef}
						shouldSwiperUpdate
					>
						{thumbs}
					</Swiper>
				</div>
			</div>
		)
	}

	render() {
		const { styleClass, title, data, no_bigpiture } = this.props
		return (
			<div className={styleClass}>
				{data.length > 0 ? this.mediapreview(data) : null}
				{
					no_bigpiture ? null :
						<BigPictureModal idModal={`media-big-picture-${this.state.unique}`} data={this.state.media_data} />
				}
			</div>
		)
	}
}

export default MediaOnly
