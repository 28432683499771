import React, { Component } from 'react'
import { Picker } from '../commons/forms'
import { Images, PressureMap, Medias } from '../../components/positioning'
import axios from 'axios'
import Confirmation from '../Confirmation'
import { positioningUpdate } from '../../actions'
import { connect } from 'react-redux'
import Preview from '../../containers/positioning/Preview'
import { dataURItoBlob, getError } from '../../utilities'
import TextEditor from '../../components/TextEditor'
import MediaEditor from '../../components/MediaEditor'
import img from '../../assets/images/img.png'
import StandardEditor from '../../components/StandardEditor'
import TextPreview from '../previews/TextPreview'
import MediaPreview from '../previews/MediaPreview'
import StandardPreview from '../previews/StandardPreview'
import $ from 'jquery'
import InlineNotification from '../commons/notifier/InlineNotification'
import MediaOnly from '../commons/elements/display/MediaOnly'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import WholeBodyloader from '../loader/WholeBodyloader'
import { Media } from '../commons/elements/display'

export class PositioningLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      positioning_list: [],
      positioning: { id: '' },
      positioning_content: [],
      editor: false,
      selected_temp: '',
      selected: {
        id: '',
      },
      draft_success: false,
      repopulate: true,
      preview: false,
      errors: [],
      progress: 0,
    }

    const SortableItem = SortableElement(({ media, index2 }) => {
      const index = index2
      switch (media.type) {
        case 'text':
          return (
            <li>
              <TextEditor
                data={{
                  title: media.title,
                  content: media.contents.caption,
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                textContentChange={event => {
                  this.textContentChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        case 'media':
          return (
            <li>
              <MediaEditor
                data={{
                  title: media.title,
                  medias: media.contents,
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onContentTitleChange={(event, i) => {
                  this.onContentTitleChange(event, i, index)
                }}
                onContentCaptionChange={(event, i) => {
                  this.onContentCaptionChange(event, i, index)
                }}
                onContentMediaChange={(event, i) => {
                  this.onContentMediaChange(event, i, index)
                }}
                onContentThumbnailChange={(event, i) => {
                  this.onContentThumbnailChange(event, i, index)
                }}
                onContentSortChange={(contents, i) => {
                  this.onContentSortChange(contents, index)
                }}
                addNewMedia={event => this.addNewMedia(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                onDelete={event => this.deleteElement(event, index)}
                onDeleteMediaElement={this.deleteMediaElement}
              />
            </li>
          )
          break
        case 'standard':
          return (
            <li>
              <StandardEditor
                data={{
                  title: media.title,
                  text: media.contents ? media.contents.caption:'',
                  media: {
                    thumbnail:  media.contents && media.contents.gallery
                      ? media.contents.gallery.thumbnail
                      : '',
                    source: media.contents &&  media.contents.gallery
                      ? media.contents.gallery.source
                      : '',
                  },
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onStandardTextChange={event => {
                  this.standardTextChange(event, index)
                }}
                onStandardThumbnailChange={thumbnail => {
                  this.standardThumbnailChange(thumbnail, index)
                }}
                onMediaChange={event => {
                  this.mediaStandardChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                progressMedia={() => {}}
                progressComplete={() => {}}
              />
            </li>
          )
          break
        default:
          break
      }
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              media={value}
            />
          ))}
        </ul>
      )
    })
  }

  componentDidMount = () => {
    this.getAllPoisitioning()
    if (this.props.stage_details.position) {
      Object.keys(this.props.stage_details.position).forEach(e => {
        this.props.positioningUpdate({
          prop: e,
          value: this.props.stage_details.position[e],
        })
      })

      this.setState({
        positioning: this.props.stage_details.position,
        positioning_content: this.props.stage_details.elements,
      })
    }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.stage_master != nextProps.stage_master) {
      if (!nextProps.stage_master.draft) {
        this.setState({
          editor: false,
          item_list: nextProps.item_list,
          positioning_content: nextProps.stage_details.elements
            ? nextProps.stage_details.elements
            : [],
        })
      }
    }

    if (nextProps.stage_details.position && this.state.repopulate) {
      Object.keys(nextProps.stage_details.position).forEach(e => {
        this.props.positioningUpdate({
          prop: e,
          value: nextProps.stage_details.position[e],
        })
      })

      this.setState({
        repopulate: false,
        positioning: nextProps.stage_details.position,
        positioning_content: nextProps.stage_details.elements,
        selected: {
          id: nextProps.stage_details.position_id,
        },
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }

  savePositioning = (draft = 0) => {
    const { positioning_content } = this.state

    const data = new FormData()
    data.append('_method', 'PATCH')
    data.append('draft', draft)
    data.append('surgeon_id', this.props.stage_master.surgeon_id)
    if (this.state.selected && this.state.selected.id > 0) {
      data.append('position', this.state.selected.id)
    }
    positioning_content.map((position, index) => {
      data.append(
        `elements[${index}][title]`,
        position.title ? position.title : ''
      )
      data.append(`elements[${index}][type]`, position.type)

      switch (position.type) {
        case 'text':
          data.append(`elements[${index}][values]`, position.contents.caption)
          break
        case 'standard':
            data.append(
              `elements[${index}][media-title]`,
              position.contents.title
            )
           
          data.append(
            `elements[${index}][media-text]`,
            position.contents.caption
          )
          data.append(`elements[${index}][media-id]`, 0)
          data.append(`elements[${index}][media-updated]`, 1)

          data.append(
            `elements[${index}][media-value]`,
            position.contents.gallery.source
          )
          data.append(
            `elements[${index}][media-origin]`,
            position.contents.gallery.originSource
          )
          data.append(
            `elements[${index}][media-type]`,
            position.contents.gallery.type
          )
          break
        case 'media':
          position.contents.map((content, i) => {
            data.append(
              `elements[${index}][media-text][]`,
              content.gallery.caption ? content.gallery.caption : ''
            )
            data.append(
              `elements[${index}][media-title][]`,
              content.gallery.title ? content.gallery.title : ''
            )
            data.append(
              `elements[${index}][media-type][]`,
              content.gallery.type
            )

            data.append(`elements[${index}][media-id][]`, 0)
            data.append(`elements[${index}][media-updated][]`, 1)
            data.append(
              `elements[${index}][media-value][${i}]`,
              content.gallery.source
            )
            data.append(
              `elements[${index}][media-origin][${i}]`,
              content.gallery.originSource ? content.gallery.originSource : ''
            )
          })
          break
        default:
          break
      }
    })

    let url = `api/admin/stage-positioning/${this.props.stage_master.id}`
    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        this.setState({
          progress: percentCompleted >= 95 ? 95 : percentCompleted,
        })
      },
    }

    axios
      .post(url, data, config)
      .then(result => {
        console.log(result)
        if (!draft) {
          this.setState({
            preview: false,
            editor: false,
            repopulate: true,
            draft_success: false,
            progress: 0,
            errors: [],
          })
          this.props.getProcedureDetail(0)
        } else {
          this.setState({
            draft_success: true,
            progress: 0,
            errors: [],
          })
        }
      })
      .catch(errors => {
        console.log(errors)
        this.setState(
          {
            progress: 0,
            errors: errors.response.data.errors,
          },
          () => {
            let targetOffset = $('.is-invalid').offset().top
            $('html, body').animate({ scrollTop: targetOffset }, 500)
          }
        )
      })
  }

  getAllPoisitioning = () => {
    let url = `/api/admin/stage-positioning`

    axios
      .get(url)
      .then(res => {
        console.log(res)

        const list = res.data.map(i => {
          return {
            id: i.id,
            name: i.name,
          }
        })
        list.unshift({
          id: 0,
          name: 'Select positioning',
        })
        this.setState({
          positioning_list: list,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  getPositioning = (id, draft = 0) => {
    if (id != '') {
      let url = `/api/edit/${id}/positioning`

      axios
        .get(url)
        .then(res => {
          console.log('tes', res)
          this.setState({
            positioning: res.data.data,
            selected: {
              id: id,
            },
          })

          Object.keys(res.data.data).forEach(e => {
            this.props.positioningUpdate({
              prop: e,
              value: res.data.data[e],
            })
          })
        })
        .catch(error => {
          console.log(error)
          console.log(error.response)
        })
    } else {
      this.setState({
        positioning: { id: 0 },
        positioning_content: [],
      })
    }
  }

  getEditPositioning = (draft = 0) => {
    const { positioning } = this.state
    let url = `api/edit/${positioning.id}/positioning`

    axios
      .get(url)
      .then(res => {
        Object.keys(res.data.data).forEach(e => {
          this.props.positioningUpdate({
            prop: e,
            value: res.data.data[e],
          })
        })
      })
      .catch(err => {
        console.log(err)
        console.log(err.response)
      })
  }

  getLayout = () => {
    const { editor, positioning, preview } = this.state
    if (preview) {
      return this.previewContent()
    }

    if (editor) {
      return this.editor()
    } else {
      return this.view()
    }
  }

  deleteElement = (event, index) => {
    const { positioning_content } = this.state
    event.preventDefault()
    positioning_content.splice(index, 1)
    this.setState({
      errors: [],
      positioning_content: positioning_content.splice(0),
    })
  }

  deleteMediaElement = (event, index, subIndex) => {
    event.preventDefault()
    const { positioning_content } = this.state

    positioning_content[index].contents.splice(subIndex, 1)

    this.setState({
      errors: [],
      positioning_content: positioning_content.splice(0),
    })
  }

  newTextSection = () => {
    const { positioning_content } = this.state
    let textsection = {
      title: null,
      type: 'text',
      contents: [
        {
          content: null,
        },
      ],
    }
    positioning_content.push(textsection)
    this.setState({
      positioning_content,
    })
  }

  newMediaSection = () => {
    const { positioning_content } = this.state
    let mediasection = {
      title: null,
      type: 'media',
      contents: [
        {
          title: null,
          caption: null,
          gallery: {},
        },
      ],
    }
    positioning_content.push(mediasection)
    this.setState({
      positioning_content,
    })
  }

  newStandardSection = () => {
    const { positioning_content } = this.state
    let standardsection = {
      title: null,
      type: 'standard',
      contents: {
        gallery: {
          source: null,
          thumbnail: null,
          type: null,
        },
        caption: '',
      },
    }

    positioning_content.push(standardsection)
    this.setState({
      positioning_content,
    })
  }

  newSectionMenu = () => {
    return (
      <div className="row mt30">
        <div className="col-12">
          <label
            className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 cursor-pointer py30"
            style={{ border: '2px dashed rgb(132, 134, 136)' }}
          >
            {/* <img src="img.png" class="h50" id="">  */}
            <div className="row w-100">
              <div className="col-md-12 text-center">
                <h4 className="m-0">Add a new section:</h4>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newTextSection()
                  }}
                >
                  <u>Text only</u>
                </div>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newMediaSection()
                  }}
                >
                  <u>Pictures and videos</u>
                </div>
              </div>
              <div className="col-md-4 text-center pt30 pb0">
                <div
                  className="btn btn-link text-dark"
                  onClick={() => {
                    this.newStandardSection()
                  }}
                >
                  <u>Text and Media</u>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ positioning_content }) => ({
      errors: [],
      positioning_content: arrayMove(positioning_content, oldIndex, newIndex),
    }))
  }

  onContentSortChange = (contents, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents = contents

    this.setState({
      errors: [],
      positioning_content: positioning_content.splice(0),
    })
  }
  //helper functions
  titleChange = (event, index) => {
    console.log('title changing')
    const { positioning_content } = this.state
    positioning_content[index].title = event.target.value

    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  textContentChange = (event, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents.caption = event.editor.getData()

    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  standardTextChange = (event, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents.caption = event.editor.getData()

    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  mediaStandardChange = (event, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents.gallery.source = event.data
    positioning_content[index].contents.gallery.type = event.type
    if (event.type == 'image')
      positioning_content[index].contents.gallery.thumbnail = event.data

    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  standardThumbnailChange = (thumbnail, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents.gallery.thumbnail = thumbnail
    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  onContentTitleChange = (event, item_index, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents[item_index].gallery.title =
      event.target.value
    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  onContentCaptionChange = (event, item_index, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents[
      item_index
    ].gallery.caption = event.editor.getData()
    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  onContentMediaChange = (event, item_index, index) => {
    console.log(event, item_index, index)
    const { positioning_content } = this.state
    positioning_content[index].contents[item_index].gallery.source = event.data
    positioning_content[index].contents[item_index].gallery.type = event.type
    if (event.type == 'image')
      positioning_content[index].contents[item_index].gallery.thumbnail =
        event.data
    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  onContentThumbnailChange = (event, item_index, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents[item_index].gallery.thumbnail = event
    this.setState({
      positioning_content: positioning_content.splice(0),
    })
  }

  addNewMedia = (event, index) => {
    console.log('add media', event, index)
    event.preventDefault()
    const { positioning_content } = this.state
    positioning_content[index].contents.push({
      gallery: {
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      },
    })
    this.setState(
      {
        positioning_content: positioning_content.splice(0),
      },
      () => {
        console.log(this.state.positioning_content)
      }
    )
  }

  editor = () => {
    const { positioning, positioning_content } = this.state

    return (
      <div className="w-100 float-left mt40">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <h1 className="h2med mb20">Edit - Positioning page</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="row">
              <div className="col-md-12">
                <ul className="list-unstyled d-inline-flex align-items-center main-card w-100 justify-content-end toolbar-options flex-wrap">
                  <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center">
                    <a
                      href="#"
                      className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 c-pointer  text-xs-underline"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({
                          editor: false,
                          repopulate: true,
                          draft_success: false,
                        })
                        this.props.getProcedureDetail()
                      }}
                    >
                      Cancel
                    </a>
                  </li>
                  <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center">
                    <a
                      href="#"
                      className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 c-pointer  text-xs-underline"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({
                          repopulate: true,
                        })
                        this.savePositioning(1)
                      }}
                    >
                      Save
                    </a>
                  </li>
                  <li className="border-right border-dark d-flex align-items-center flex-fill my10 px15 border-xs-0 justify-content-center border-md-0">
                    <a
                      href="#"
                      className="mx15 text-dark font-weight-bold  d-flex align-items-center h-100 c-pointer  text-xs-underline"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({
                          preview: true,
                          draft_success: false,
                        })
                      }}
                    >
                      Preview
                    </a>
                  </li>
                  <li className="d-flex align-items-center flex-fill my10 px15 border-xs-0 text-center justify-content-md-start justify-content-lg-center justify-content-xl-center justify-content-center">
                    <div
                      class="btn btn-primary m-0 btn-sm"
                      onClick={() => {
                        this.savePositioning()
                      }}
                    >
                      Update
                    </div>
                  </li>
                </ul>
              </div>

              {this.state.draft_success ? (
                <InlineNotification message={'Saved to drafts!'} />
              ) : null}
              <div className="col-md-5">
                <div className="form-group mb0 mt20">
                  <Picker
                    label="Select Position  "
                    onChange={event => {
                      $('#confirmModal').modal('show')
                      this.setState({
                        selected_temp: event.target.value,
                      })
                    }}
                    value={this.state.selected.id}
                    data={this.state.positioning_list}
                    errorMessage={getError(this.state.errors, 'position')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 profile-card pt-0">
                {positioning && positioning.id > 0 ? (
                  <Preview widthStyle="col-md-12 pt0" />
                ) : (
                  ''
                )}
              </div>
            </div>
            <ul className="list-unstyled">
              {positioning && positioning.id > 0 ? (
                <this.SortableList
                  items={positioning_content}
                  onSortEnd={this.onSortEnd}
                  distance={1}
                  useDragHandle
                />
              ) : (
                ''
              )}
            </ul>
            {positioning && positioning.id > 0 ? this.newSectionMenu() : ''}
          </div>
        </div>
      </div>
    )
  }

  previewContent = () => {
    return (
      <div>
        <div className="bd-callout bd-callout-warning sticky-top">
          <p className="m-0">
            <b>Preview mode</b> - If you like what you see, you can{' '}
            <u
              className="font-weight-bold c-pointer"
              onClick={() => {
                this.savePositioning()
              }}
            >
              Update positioning
            </u>{' '}
            or make edits by{' '}
            <u
              className="font-weight-bold c-pointer"
              onClick={() => {
                this.setState({
                  preview: false,
                })
              }}
            >
              Closing preview
            </u>
          </p>
        </div>
        {this.props.stage_details.position ? (
          <Preview widthStyle="col-md-12" />
        ) : (
          ''
        )}

        {this.elements()}
      </div>
    )
  }

  positioningContent = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4 className="mb-0 d-flex align-items-end fs20 d-none" />
        </div>
        <div className="col-md-12 profile-card pt-0">
          {this.props.stage_details.position ? (
            <Preview widthStyle="col-md-12" />
          ) : (
            ''
          )}

          {this.elements()}
        </div>
      </div>
    )
  }

  elements = () => {
    const { positioning_content } = this.state
    let element = positioning_content.map(content => {
      if (content.type == 'text') {
        return <TextPreview content={content} />
      } else if (content.type == 'media') {
        return (
          <Media
            data={content ? content.contents : []}
            title={content.title}
            swiperClass="px0"
            styleClass="mt30"
          />
        )
      } else if (content.type == 'standard') {
        return <StandardPreview content={content} />
      }
    })

    return element
  }

  view = () => {
    const { positioning } = this.state
    return (
      <div className="w-100 float-left">
        <div className="row mt40">
          <div className="col-md-12 mx-auto d-flex flex-column flex-md-row justify-content-between align-items-center mb0">
            <h3 className="h2med mb0 w-xs-100">Positioning page</h3>

            <a
              href="javascript:;"
              className="btn btn-link ml-auto p-0 text-dark fs14 m-xst20 text-xs-underline"
              onClick={e => {
                e.preventDefault()
                this.setState(
                  {
                    editor: true,
                    repopulate: true,
                  },
                  () => {
                    // this.getPositioning(this.state.positioning.id)
                    this.props.getProcedureDetail(1)
                  }
                )
              }}
            >
              {this.props.title} Edit
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="row">
              <div className="col-md-5">
                {this.props.stage_details ? (
                  ''
                ) : (
                  <p
                    onClick={() => {
                      this.setState({
                        editor: true,
                        errors: [],
                      })
                    }}
                  >
                    Edit mode
                  </p>
                )}
              </div>
            </div>
            {this.props.stage_details ? this.positioningContent() : ''}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="col-lg-9 mx-auto">
        <div className="row">
          <div className="col-md-12 mx-auto">
            {this.getLayout()}
            <Confirmation
              title="Change"
              message="Are you sure you want to change, the changes in positioning equipment will be overridden?"
              confirmText="Yes"
              cancelText="No"
              idModal={'confirmModal'}
              confirm={() => {
                this.getPositioning(this.state.selected_temp)
              }}
              cancel={() => {
                $('confirmModal').modal('hide')
              }}
            />
          </div>
        </div>
        <WholeBodyloader
          message="Saving! please wait..."
          progress={this.state.progress}
          type="spinner"
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    positioning: state.positioning,
  }
}

export default connect(
  mapStateToProps,
  { positioningUpdate }
)(PositioningLayout)
