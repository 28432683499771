import axios from 'axios'
import { ENVIROMENT } from '../config/settings'
import { dataURItoBlob } from './helpers'

const VimeoUpload = require('vimeo-upload')

export const uploadVideov1 = (fileData, onProgress, xhr) => {
  const video = window.document.createElement('video')
  const fileUrl = window.URL.createObjectURL(fileData)
  video.src = fileUrl + '#t=0.3'
  const canvas = window.document.createElement('canvas')
  canvas.width = 640
  canvas.height = 480

  return new Promise(function(resolve, reject) {
    axios
      .post('api/check-file', {
        name: fileData.name,
        type: fileData.type,
        size: fileData.size,
      })
      .then(result => {
        setTimeout(() => {
          const context = canvas.getContext('2d')
          context.drawImage(video, 0, 0, canvas.width, canvas.height)
          const dataURI = canvas.toDataURL('image/jpeg')

          if (!result.data.exists) {
            const uploader = new VimeoUpload({
              file: fileData,
              token: ENVIROMENT.vimeoKey,
              description: 'Corh Description',
              private: 'unlisted',
              name: result.data.file.id,
              xhr: xhr,
              onProgress: progress => onProgress(progress),
              onComplete: data => {
                axios
                  .post('api/upload-file', {
                    name: fileData.name,
                    type: fileData.type,
                    size: fileData.size,
                    source: data.link,
                    disk: 'vimeo',
                  })
                  .then(result => {
                    return resolve({
                      source: data.link,
                      thumbnail: dataURI,
                    })
                  })
                  .catch(error => {
                    return reject(error)
                  })
              },
            })

            uploader.upload()
          } else {
            const dataResult = {
              source: result.data.file.source,
              thumbnail: dataURI,
            }
            console.log('dataResult', dataResult)
            return resolve(dataResult)
          }
        }, 3000)
      })
      .catch(error => {
        return reject(error)
      })
  })
  return axios
    .post('api/check-file', {
      name: fileData.name,
      type: fileData.type,
      size: fileData.size,
    })
    .then(result => {
      video.addEventListener(
        'loadeddata',
        function() {
          const context = canvas.getContext('2d')
          context.drawImage(video, 0, 0, canvas.width, canvas.height)
          const dataURI = canvas.toDataURL('image/jpeg')

          if (!result.data.exists) {
            const uploader = new VimeoUpload({
              file: fileData,
              token: ENVIROMENT.vimeoKey,
              description: 'Corh Description',
              private: 'unlisted',
              name: result.data.file.id,
              xhr: xhr,
              onProgress: progress => onProgress(progress),
              onComplete: data => {
                axios
                  .post('api/upload-file', {
                    name: fileData.name,
                    type: fileData.type,
                    size: fileData.size,
                    source: data.link,
                    disk: 'vimeo',
                  })
                  .then(result => {
                    return Promise.resolve({
                      source: data.link,
                      thumbnail: dataURI,
                    })
                  })
                  .catch(error => {
                    return Promise.reject(error)
                  })
              },
            })

            uploader.upload()
          } else {
            const dataResult = {
              source: result.data.file.source,
              thumbnail: dataURI,
            }
            console.log('dataResult', dataResult)
            return Promise.resolve(dataResult)
          }
        },
        false
      )
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export const uploadVideov2 = (fileData, onProgress,cancelToken) => {
  const video = window.document.createElement('video')
  const fileUrl = window.URL.createObjectURL(fileData)
  video.src = fileUrl + '#t=0.3'
  const canvas = window.document.createElement('canvas')
  canvas.width = 640
  canvas.height = 480

  

  return new Promise(function(resolve, reject) {
    axios
      .post('api/check-file', {
        name: fileData.name,
        type: fileData.type,
        size: fileData.size,
      })
      .then(result => {
        setTimeout(() => {
          const context = canvas.getContext('2d')
          context.drawImage(video, 0, 0, canvas.width, canvas.height)
          const dataURI = canvas.toDataURL('image/jpeg')

          if (!result.data.exists) {
            console.log('fileData',fileData)
            const form = new FormData()
            const file = fileData
            form.append('name', file.name)
            form.append('type', file.type)
            form.append('size', file.size)
            form.append('video', file, file.name)

            const uri = `api/upload-video`
            const config = {
              cancelToken,
              headers: {
                'content-type': 'multipart/form-data',
              },

              onUploadProgress: progressEvent => onProgress(progressEvent),
            }

            return axios
              .post(uri, form, config)
              .then(res => {
                return resolve({
                  source: res.data.source,
                  thumbnail: dataURI,
                })
              })
              .catch(error => {
                return reject(error)
              })
          } else {
            console.log(result.data)
            const dataResult = {
              source: result.data.file.source,
              thumbnail: dataURI,
            }
            console.log('dataResult', dataResult)

            return resolve(dataResult)
          }
        }, 3000)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

export const uploadVideo = (fileData, onProgress,cancelToken) => {
 

  

  return new Promise(function(resolve, reject) {
    axios
      .post('api/check-file', {
        name: fileData.name,
        type: fileData.type,
        size: fileData.size,
      })
      .then(result => {
        if (!result.data.exists) {
          console.log('fileData',fileData)
          const form = new FormData()
          const file = fileData
          form.append('name', file.name)
          form.append('type', file.type)
          form.append('size', file.size)
          form.append('video', file, file.name)

          const uri = `api/upload-video`
          const config = {
            cancelToken,
            headers: {
              'content-type': 'multipart/form-data',
            },

            onUploadProgress: progressEvent => onProgress(progressEvent),
          }

          return axios
            .post(uri, form, config)
            .then(res => {
              return resolve({
                source: res.data.source,
                thumbnail: res.data.thumbnail,
              })
            })
            .catch(error => {
              return reject(error)
            })
        } else {
          console.log(result.data)
          const dataResult = {
            source: result.data.file.source,
            thumbnail: result.data.thumbnail,
          }
          console.log('dataResult', dataResult)

          return resolve(dataResult)
        }
      })
      .catch(error => {
        return reject(error)
      })
  })
}

