import React, { Component } from 'react'
import $ from 'jquery'
import axios from 'axios'
import { connect } from 'react-redux'
import { getSurgicalCategories, saveCategoriesOrder } from '../../actions/SurgicalTreeActions'
import { getError } from '../../utilities'
import { TextInput } from '../../components/commons/forms'
import { Confirmation } from '../../components/Confirmation'
import {
  SortableContainer, 
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import arrayMove from 'array-move' 

const DragHandle = SortableHandle(() => {
  return(
    <div className="bg-dark w40 h70 d-flex align-items-center justify-content-center rounded-left">
      <i className="fa fa-arrows text-white fa-2x" />
    </div>
  )
})

const SortableItem = SortableElement(({category, setNewState}) => (
    <li className="media d-flex mb10 align-items-center">
      <DragHandle />
      <div className="media main-card p10" style={{width: '40%'}}>
        <img src={category.icon.replace('public', '')} className="mr-3 bg-dark w50 h50" alt="..." />
        <div className="media-body h50 d-flex align-items-center">
          <h6 className="m-0"><u>{category.name}</u></h6> 
        </div>
      </div>
      <div className>
        <a href="javascript:;" className="mx30" data-toggle="modal" data-target="#addNewPhaseModalCenter"
          onClick={() => {
            setNewState({
              editMode: true,
              icon: {
                icon: category.icon.replace('public', '')
              },
              icon_id: category.id,
              name: category.name,
              image: category.icon.replace('public', ''),
              stage: category.id,
              errors: []
            })
          }}
        >Edit</a>
        <a href="javascript:;" className="mr30" data-toggle="modal" data-target="#deleteModal" onClick={() => {
          setNewState({
            stage: category.id
          })
        }}>Remove</a>
      </div>
    </li>
  )
)

const SortableItemDraping = SortableElement(({category, setNewState}) => (
  <li className="media d-flex mb10 align-items-center flex-column">
    <div className="float-left w-100 align-items-center d-flex mb10">
      <DragHandle />
      <div className="media main-card p10" style={{ width: '40%' }}>
        <img src={category.icon.replace('public', '')} className="mr-3 bg-dark w50 h50" alt="..." />
        <div className="media-body h50 d-flex align-items-center">
          <h6 className="m-0"><u>{category.name}</u></h6>
        </div>
      </div>
      <div className>
        <a href="javascript:;" className="mx30" data-toggle="modal" data-target="#addNewPhaseModalCenter"
          onClick={() => {
            setNewState({
              editMode: true,
              icon: {
                icon: category.icon.replace('public', '')
              },
              icon_id: category.id,
              name: category.name,
              image: category.icon.replace('public', ''),
              stage: category.id,
              errors: []
            })
          }}
        >Edit</a>
        <a href="javascript:;" className="mr30" data-toggle="modal" data-target="#deleteModal" onClick={() => {
          setNewState({
            stage: category.id
          })
        }}>Remove</a>
      </div> 
    </div>
    
    <div className="w-100 float-left">
      <a className="media py20 d-flex align-items-center justify-content-center" style={{width: '40%', border: '2px dashed #979797'}} data-toggle="modal" data-target="#addNewPhaseModalCenter" onClick={() => {
        setNewState({
          editMode: false,
          icon: '',
          icon_id: '',
          image: '',
          name: '',
          errors: []
        })
      }}>
        <u className="font-weight-bold c-pointer">Add new stage +</u>
    </a>
    </div>
  </li>
)
)

const SortableList = SortableContainer(({categories, setNewState}) => {
  return (
    <ul className="list-unstyled mb30 pb20">
      {categories.map((category, index) => {
        if(category.name == 'Draping'){
          return <SortableItemDraping key={`item-${index}`} index={index} category={category} setNewState={setNewState}/>
        }
        
        return(
          <SortableItem key={`item-${index}`} index={index} category={category} setNewState={setNewState}/>
        )
      })
      }
    </ul>
  )
})

export class SettingSurgicalTree extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      categories: [],
      editMode: false,
      stage: '',
      stage: [],
      loading: 0,
      icons: [],
      keyword:'',
      icon:'',
      name:'',
      image:'',
      icon_id: '',
      errors: [],
      fresh: true,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount = () => {
    this.props.getSurgicalCategories()
    this.getIcons()
  }

  componentDidUpdate = () => {
    if(!this.props.loading && this.state.categories.length == 0 && this.state.fresh){
      this.setState({
        categories: this.props.categories,
        fresh: false
      })
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.state.categories != nextProps.categories){
      this.setState({
        categories: nextProps.categories,
      })
    }
  }

  getIcons = () => {
    let url = `/api/admin/icon-library?search=${this.state.keyword}`

    axios.get(url).then((res) => {
      this.setState({
        icons: res.data.data,
        loading: 0
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
      this.setState({
        loading: 0  
      })
    })
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({categories}) => ({
      categories: arrayMove(categories, oldIndex, newIndex),
    }), () => { 
      this.props.saveCategoriesOrder(this.state.categories)
    })
  }

  saveStage = (update) => {
    const { icon, name } = this.state

    let data = new FormData()
    data.append('icon', icon.source)
    data.append('name', name)
    
    let url = '/api/admin/surgical-trees'

    axios.post(url, data).then((res) => {
      console.log('asdkjasdkajsdlkjadslkj')
      this.props.getSurgicalCategories()
      $('#addNewPhaseModalCenter').modal('hide')
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
      this.setState({
        errors: err.response.data.errors
      })
    })
  }

  updateStage = () => {
    const { stage } = this.state

    const { icon, name } = this.state

    let data = new FormData()
    if(icon.source){
      data.append('icon', icon.source)

    }
    data.append('name', name)
    data.append('_method', 'PATCH')

    let url = `/api/admin/surgical-trees/${stage}`

    axios.post(url, data).then((res) => {
      this.props.getSurgicalCategories()
      $('#addNewPhaseModalCenter').modal('hide')
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
      this.setState({
        errors: err.response.data.errors
      })
    })
  }

  deleteStage = () => {
    const { stage } = this.state

    let url = `/api/admin/surgical-trees/${stage}`

    axios.post(url, {_method: 'DELETE'}).then((res) => {
      this.props.getSurgicalCategories()
      $('#deleteModal').modal('hide')
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
      this.setState({
        errors: err.response.data.errors
      })
    })
  }

  newPhaseModal = () => {
    return(
      <div className="modal fade" id="addNewPhaseModalCenter" tabIndex={-1} role="dialog" aria-labelledby="addNewPhaseModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30" style={{ height: '360px', overflow: 'auto' }}>
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">
              {
                !this.state.editMode ?
                'Add new stage':'Edit stage'
              }
              </h5>
              { this.state.icon_id ? 

                <div className="icon-item w120 h120 mr30 mb30 p5" id={`icon-item-${this.state.icon.id}`} onClick={() => { $("#addNewPhaseModalCenter2").modal('show');}}>
                  <img src={this.state.icon.icon} class=" c-pointer w-100" /></div> :
                <div className="d-flex justify-content-center align-items-center mb20 c-pointer" style={{ width: '120px', height: '120px', border: '2px dashed #979797' }} 
                  onClick={() => {
                    this.setState({  
                      unique:
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15),
                    })
                    $("#addNewPhaseModalCenter2").modal('show');
                  }}
                >
                  Assign icon
              </div>
            
              }
              <div className="form-group w-50">
                <TextInput
                  label={'Name of Phase'}
                  value={this.state.name}
                  placeholder={'Name'}
                  errorMessage={getError(this.state.errors, 'name')}
                  containerClass={`mb30`}
                  onValueChange={(event) => {this.setState({name: event.target.value})}}
                />
              </div>
            </div>
            <div className="modal-body pb0 px30">
              <div className="btn btn-dark mr30 px30" onClick={() => { this.state.editMode ? this.updateStage() : this.saveStage()}}>Save</div>
              <div className="btn btn-light border-dark px30" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  newPhaseModalIcons = () => {
    const { icons } = this.state 
    let iconlist = icons.map((icon) => {
      return (
        <div className="icon-item w120 h120 mr30 mb30 p5" id={`icon-item-${icon.id}`} >
          <img src={icon.icon} class=" c-pointer w-100" data-toggle='modal' title={icon.name}
            data-target='#editIcon'
            onClick={() => {
              $('.icon-item').removeClass('bg-dark')
              $('.icon-item img').removeClass('icon-selected')
              $(`#icon-item-${icon.id}`).addClass('bg-dark')
              $(`#icon-item-${icon.id} img`).addClass('icon-selected')
              $('#addNewPhaseModalCenter2').modal('hide')
              this.setState({
                icon: icon,
                image: icon.icon,
                icon_id: icon.id
              })
            }} />
        </div>
      )
    })
    return(
      <div className="modal fade" id="addNewPhaseModalCenter2" tabIndex={-1} role="dialog" aria-labelledby="addNewPhaseModalCenter2Title" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30">
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">Add new phase</h5>
              <div className="row">
                <div className="col-md-12 d-flex flex-wrap pr-0" style={{height: '330px', overflow: 'auto'}}>
                  
                  {iconlist}
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

    )
  }
  
  render() {
    const { categories } = this.state
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">Surgical tree</h1> 
          </div>
        </div>
        <div className="row"> 
          <div className="col-md-8"> 
            <h4 className="mt5 fs20 mb15">Edit stages</h4>
            <p className="mb15 fs14">Changing labels will affect all current and future procedures. Any changes will be approved by system admin.</p>
            <SortableList categories={categories} onSortEnd={this.onSortEnd} setNewState={(state) => {this.setState(state)}} useDragHandle/>
          </div>
        </div>
        {this.newPhaseModal()}
        {this.newPhaseModalIcons()}
        <Confirmation
					idModal="deleteModal"
					title="Remove Stage?"
					message="Removing stage will remove it from the database and will have to be added back manually."
					confirm={() => {
						this.deleteStage()
					}}
					cancel={()=>{
						$("#deleteModal").modal('hide')
					}}
					confirmText="Confirm"
					cancelText="Cancel"
				/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    categories: state.surgicaltree.categories,
    loading: state.surgicaltree.loading
  })
}

export default connect(mapStateToProps, {getSurgicalCategories, saveCategoriesOrder})(SettingSurgicalTree)
