import React, { Component } from 'react'
import { connect } from 'react-redux' 
import { getSurgeonQuicknotes } from '../actions/QuicknotesActions'

export class QuicknotesCategories extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
  

  render() {
    const { categories, quicknotes_id, edit,preview } = this.props
    let categorylist = categories.map((category) => {
      console.log(category.id, quicknotes_id)
      if(category.id != quicknotes_id && (edit || preview)){
        return(
          <div className="specialty-card mt20 disabled-specialty-card"
            onClick={() => {this.props.getSurgeonQuicknotes(category.id)}}>
            <div className="card">
              <div className="card-body text-center"> 
                <img src={category.icon} className="" /> 
                <h6 className="mt10 card-subtitle mx10">{category.name}</h6>
              </div>
            </div> 
          </div> 
        )
      }

      return(
        <div className={`specialty-card mt20 ${ category.id == quicknotes_id ? 'selected' : null}`}
          onClick={() => {this.props.getSurgeonQuicknotes(category.id)}}>
          <div className="card">
            <div className="card-body text-center"> 
              <img src={category.icon} className="" /> 
              <h6 className="mt10 card-subtitle mx10">{category.name}</h6>
            </div>
          </div> 
        </div> 
      )
      
    }) 
    return (
      <div className="col-md-12 d-flex justify-content-between mb20"> 
        {categorylist}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    categories: state.quicknotes.categories,
    quicknotes_id: state.quicknotes.quicknotes_id
  })
} 

export default connect(mapStateToProps, {getSurgeonQuicknotes})(QuicknotesCategories)
