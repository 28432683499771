import React, { Component } from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
import '../assets/scss/App.scss';
import '../assets/css/app.css';
import axios from 'axios'
import { connect } from 'react-redux'
import { logoutUser } from '../actions'

import $ from 'jquery'
import { BASE_URL } from '../config/constant';
class Main extends Component {
    componentWillMount(){
        const { token} = this.props
        if(!token){
            this.props.router.push('/login')
            return
        }
        
        axios.defaults.baseURL = BASE_URL
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

        axios.interceptors.response.use((response) => {
            return response
          }, (error) => {
            const urlArray = error.response.config.url.split('/');
            if(!urlArray.includes('login')){
                if(error.response.status == 401 || error.response.status == 403){
                    $('.modal').modal('hide')
                    this.props.logoutUser()
                    this.props.router.push('/login')
                }
            }
           
                
                
            return Promise.reject(error)
          })
    }

    render() {
        if(!this.props.token){
            return null
        }
        return (
            <div className="section">
                <Header />
                <main role="main" className="flex-shrink-0 d-flex">
                    {this.props.children}
                </main>
                <Footer />
                
            </div>
        );
    }
}


const mapStateToProps = ({ auth }) => {
    const {token } = auth
  
    return {token }
  }
  
  export default connect(
    mapStateToProps,
    { logoutUser}
  )(Main)
  