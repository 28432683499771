import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  SortableContainer, 
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Confirmation from '../../components/Confirmation'
import { Link } from 'react-router'
import { 
  getQuicknotesCategories,
  deleteQuicknotesCategory,
  saveQuicknotesCategory,
  saveQuicknotesCategoryOrder,
  updateQuicknotesCategory,
  resetQuicknotesErrors
} from '../../actions/QuicknotesActions'
import { TextInput } from '../../components/commons/forms'
import $ from 'jquery'
import { getError, getArrayError, dataURItoBlob } from '../../utilities'
import { Image } from '../../components/commons/elements/inputs/'
import _ from 'lodash'

const DragHandle = SortableHandle(() => {
  return(
    <div className="bg-dark w40 h70 d-flex align-items-center justify-content-center rounded-left">
      <i className="fa fa-arrows text-white fa-2x" />
    </div>
  )
})

const SortableItem = SortableElement(({category, setCategory}) => {
  return(
    <li className="media d-flex mb10 align-items-center">
      <DragHandle />
      <div className="media main-card p10 rounded-right" style={{width: '40%'}}>
        <img src={category.icon} className="mr-3 bg-dark w50 h50" alt="..." />
        <div className="media-body h50 d-flex align-items-center">
          <h6 className="m-0"><u>{category.name}</u></h6> 
        </div>
      </div>
      <div className>
        <Link className="mx30 c-pointer" data-toggle="modal" data-target="#updateQuicknotesCategory" onClick={() => {setCategory(category.id)}}>Edit</Link>

        <Link className="mr30 c-pointer" data-toggle="modal" data-target="#confirmationModal" onClick={() => {setCategory(category.id)}}>Remove</Link>
      </div>
    </li> 
  )
})

const SortableList = SortableContainer(({categories, setCategory, setNewState}) => {
  return (
    <ul className="list-unstyled mb30 pb20">
      <li className="media d-flex mb10 align-items-center c-pointer">
        <a className="media py20 d-flex align-items-center justify-content-center " 
          style={{width: '40%', border: '2px dashed #979797'}} 
          data-toggle="modal" data-target="#addNewQuicknotesCategory"
          onClick={() => {setNewState({
            new_category: '',
            image: ''
          })}}>
          <u className="font-weight-bold">Add new category +</u>
        </a> 
      </li>
      {categories.map((category, index) => (
        <SortableItem key={`item-${index}`} index={index} category={category} setCategory={setCategory}/>
      ))}
    </ul>
  )
})

export class SettingQuicknotes extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      categories: [],
      category: null,
      image: '',
      new_category: '',
      selected_category: null
    }
  }

  componentDidMount = () => {
    this.props.getQuicknotesCategories()
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps != this.props){
      this.setState({
        categories: this.props.categories
      })
    }
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({categories}) => ({
      categories: arrayMove(categories, oldIndex, newIndex),
    }), () => {this.props.saveQuicknotesCategoryOrder(this.state.categories)})
  }

  setCategory = (id) => {
    this.props.resetQuicknotesErrors()
    this.setState({
      image: '',
      new_category: null,
      category: id
    })
  }

  saveCategory = () => {
    const { image, new_category } = this.state
    
    this.props.saveQuicknotesCategory(dataURItoBlob(image), new_category)
  }

  updateCategory = () => {
    const { image, category, new_category } = this.state

    this.props.updateQuicknotesCategory(category, dataURItoBlob(image), new_category)
  }

  setNewState = (states) => {
    this.props.resetQuicknotesErrors()
    this.setState(states)
  }
  // <div className="d-flex justify-content-center align-items-center mb20 c-pointer" 
  //               style={{width: '120px', height: '120px', border: '2px dashed #979797'}} 
  //               data-toggle="modal" data-target="#addNewPhaseModalCenter2"
  //               onClick={() =>  {this.refs.file.click()}} 
  //             >
  //               {image ? <img src={image} /> : 'Assign icon'}
  //             </div>

  newQuicknotesCategoryModal = () => {
    const { image, new_category } = this.state
    const { errors } = this.props
    return(
      <div className="modal fade" id="addNewQuicknotesCategory" tabIndex={-1} role="dialog" aria-labelledby="addNewQuicknotesCategoryTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30">
              <input type="file" ref="file" className="d-none" onChange={(event) => {this.setState({image: this.refs.file.files[0]})}}/>
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">Add new quicknotes category</h5>
              <Image 
                label={`Quicknotes Icon`}
                media_data={ {
                    source: image
                }}
                size={[120, 120]}
                wrapperStyle="mb20 c-pointer"
                previewStyle="d-flex justify-content-center align-items-center bg-light of-hidden m-0 w150 h150"
                onMediaChange={ (event) => {this.setState({image: event.data})
                }}
              />
              <div className="form-group w-50">
                <TextInput
                  label={'Name of category'}
                  value={new_category}
                  placeholder={'Category'}
                  errorMessage={getArrayError(errors, 'name', 0)}
                  containerClass={`mb30`}
                  onValueChange={(event) => {this.setState({new_category: event.target.value})}}
                />
              </div>
            </div>
            <div className="modal-body pb30 px30">
              <div className="btn btn-dark mr30 px30" onClick={() => {this.saveCategory()}}>Save</div>
              <div className="btn btn-light border-dark px30" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  updateQuicknotesCategoryModal = () => {
    let { image, new_category, categories, category } = this.state
    const { errors } = this.props
   
    
    let category_item = _.find(categories, (e) => { return e.id == category})
    if(!image){
      image = category_item ? category_item.icon : ''
    }

    if(new_category == null){
      new_category = category_item ? category_item.name : ''
    }
    
    return(
      <div className="modal fade" id="updateQuicknotesCategory" tabIndex={-1} role="dialog" aria-labelledby="updateQuicknotesCategoryTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30">
              <input type="file" ref="file" className="d-none" onChange={(event) => {this.setState({image: this.refs.file.files[0]})}}/>
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">Edit quicknotes category</h5>
              <Image 
                label={`Quicknotes Icon`}
                media_data={ {
                    source: image
                }}
                size={[120, 120]}
                wrapperStyle="mb20 c-pointer"
                previewStyle="d-flex justify-content-center align-items-center bg-light of-hidden m-0 w150 h150"
                onMediaChange={ (event) => {this.setState({image: event.data})
                }}
              />
              <div className="form-group w-50">
                <TextInput
                  label={'Name of category'}
                  value={new_category}
                  placeholder={'Category'}
                  errorMessage={getError(errors, 'name')}
                  containerClass={`mb30`}
                  onValueChange={(event) => {this.setState({new_category: event.target.value})}}
                />
              </div>
            </div>
            <div className="modal-body pb30 px30">
              <div className="btn btn-dark mr30 px30" onClick={() => {this.updateCategory()}}>Save</div>
              <div className="btn btn-light border-dark px30" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  render() {
    const { categories, category } = this.state
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="font-weight-bold mb30">Quicknotes</h1> 
          </div>
        </div>
        <div className="row"> 
          <div className="col-md-8"> 
            <h4 className="mt5 fs20 mb15">Edit categories</h4>
            <p className="mb20 fs16">Changing names will affect all future Surgeon quick notes produced for new Surgeons. <br /> Any changes will be approved by system admin.</p>
             
            <SortableList categories={categories} onSortEnd={this.onSortEnd} setCategory={this.setCategory} setNewState={this.setNewState} useDragHandle/>
          </div>
          <div className="col-md-" />
        </div>
        <Confirmation 
          title='Delete'
          message='Are you sure you want to delete?'
          confirmText='Yes'
          cancelText='No'
          confirm={() => {this.props.deleteQuicknotesCategory(category)}}
          cancel={() => {$('confirmModal').modal('hide')}}
        />
        {this.newQuicknotesCategoryModal()}
        {this.updateQuicknotesCategoryModal()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    categories: state.quicknotes.categories,
    errors: state.quicknotes.errors
  })
}

export default connect(mapStateToProps, {
  getQuicknotesCategories, 
  deleteQuicknotesCategory,
  saveQuicknotesCategory,
  saveQuicknotesCategoryOrder,
  updateQuicknotesCategory,
  resetQuicknotesErrors
})(SettingQuicknotes)
