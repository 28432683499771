import React, { Component } from 'react'

export class QuicknotesText extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
  
  render() {
    let items = this.props.quicknotes.contents.map((content) => {
      return(
        <div
          dangerouslySetInnerHTML={{
            __html: content.content
          }}
        />
      )
    })
    return (
      <div className="card p-0 shadow-sm border-0 mt30"> 
        <div className="card-header bg-white p15 border-0" id="note-headeing-1">
          <h5 className="mb-0 d-flex align-items-center">
            {this.props.quicknotes.title}
          </h5>
        </div>
        <div className="card-body pt-0 mt5 px15">
          {items}
        </div>
      </div>
    )
  }
}

export default QuicknotesText
