import React, { Component } from 'react'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import CollapsePanel from '../../components/commons/panel/CollapsePanel'
import { SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import arrayMove from 'array-move'

import ConsumableLayout from '../../components/stages/ConsumableLayout'
import StandardLayout from '../../components/stages/StandardLayout'
import InstrumentsLayout from '../../components/stages/InstrumentsLayout'
import PositioningLayout from '../../components/stages/PositioningLayout'
import RoomSetupLayout from '../../components/stages/RoomSetupLayout'
import PositioningEquipmentLayout from '../../components/stages/PositioningEquipmentLayout'


import { Picker, TextInput } from '../../components/commons/forms'
import { getError } from '../../utilities'
import Confirmation from '../../components/Confirmation'
import Swiper from 'react-id-swiper'
import Notifier from '../../components/commons/notifier/Notifier'
import LoadFromProcedure from '../../components/modals/LoadFromProcedure'
import Notification from '../../components/Notification'

const DragHandle = SortableHandle(() => {
  return(
    <div className="bg-dark w40 h70 d-flex align-items-center justify-content-center rounded-left">
      <i className="fa fa-arrows text-white fa-2x" />
    </div>
  )
})

const SortableItem = SortableElement(({ category, setNewState }) => (
  <li className="media d-flex mb10 align-items-center c-pointer">
    <DragHandle />
    <div className="media main-card p10" style={{ minWidth: 310 }}>
      <img
        src={
          category.stage.icon ? category.stage.icon.replace('public', '') : ''
        }
        className="mr-3 bg-dark w50 h50"
        alt="..."
      />
      <div className="media-body h50 d-flex align-items-center">
        <h6 className="m-0">
          <u>{category.stage.name}</u>
        </h6>
      </div>
    </div>
    <div className>
      <a
        className="mx30 c-pointer"
        data-toggle="modal"
        data-target="#updateStageModal"
        onClick={e => {
          e.preventDefault()
          setNewState({
            stage_data: category,
            stage_name: category.stage.name,
            stage_type: category.type,
            errors: [],
            icon: {
              icon: category.stage.icon,
            },
          })
        }}
      >
        Edit
      </a>
      <a
        href="#"
        className="mr30"
        data-toggle="modal"
        data-target="#confirmationModal"
        onClick={e => {
          e.preventDefault()
          setNewState({
            stage_data: category,
          })
        }}
      >
        Remove
      </a>
    </div>
  </li>
))

const SortableList = SortableContainer(({ categories, setNewState }) => {
  return (
    <ul className="list-unstyled mb-0">
      {categories.map((category, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            category={category}
            setNewState={setNewState}
          />
        )
      })}
    </ul>
  )
})

class ProcedureCreator extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name:'',
      peError:'',
      loading: 0,
      specialties: [],
      categories: [],
      procedures: [],
      procedure: null,
      procedure_name: '',
      settings: true,
      icons: [],
      stage_details: {},
      rearrange: false,
      stage_data: {},
      errors: [],
      icon: {},
      stage_type: '',
      stage_types: [
        { id: '', name: 'Please Select' },
        { id: 'positioning', name: 'Positioning' },
        { id: 'positioning-equipment', name: 'Positioning-Equipment' },
        { id: 'room-setup', name: 'Room-Setup' },
        { id: 'standard', name: 'Standard' },
      ],
      stage_name: '',
      active: 0,
      stage: {},
      data: {
        type: 'consumable',
      },
      surgical_trees: [],
      surgical_tree_first: [],
      surgical_tree_second: [],
      custom_stages_final: [],
      custom_stages: [],
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      key_load:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      swiperunique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      showSuccess: false,
      selected_specailty:'',
      select_area:''
    } 
  }

  componentDidMount() {
    this.getSpecialties()
    this.getIcons()

    console.log('routerrrrss', this.props.router.location.state)
    if(this.props.router.location.state){
      console.log('routerrrrss', this.props.router.location.state)
      const procedure = this.props.router.location.state
      const cat_id = procedure.category ?  procedure.category.id:''
      if(cat_id != ''){
        this.getAreas(procedure.category.specialty_id)
        this.getProcedure(procedure.category.id)
          this.getSettings(procedure.id)
        
      }
      this.setState({
        selected_specailty:procedure.category.specialty_id,
        select_area:procedure.category.id,
        procedure_name: procedure.name
      })
    }else{
      console.log('routerrrrss', 'noooo')
    }
  }



  getIcons = () => {
    let url = `/api/admin/icon-library`

    axios
      .get(url)
      .then(res => {
        this.setState({
          icons: res.data.data,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  setNewstate = state => {
    this.setState(state)
  }

  getSpecialties() {
    this.setState({ loading: 1 })
    let url = `/api/admin/specialties`
    axios
      .get(url)
      .then(res => {
        let specialties = res.data.data
        //console.log('specialties', specialties)
        this.setState({
          specialties: specialties,
        })
        this.setState({ loading: 0 })
      })
      .catch(error => {
        //console.log(error)
        //console.log(error.response)
      })
  }

  //get areas of specialty (specialty_id)
  getAreas(id) {
    this.setState({ loading: 1, categories: [], procedures: [] })
    let url = `/api/admin/specialty-categories-by-specialty/${id}`
    axios
      .get(url)
      .then(res => {
        let categories = res.data.data
        //console.log('categories', categories)
        this.setState({
          categories: categories,
        })
        this.setState({ loading: 0 })
      })
      .catch(error => {
        //console.log(error)
        //console.log(error.response)
      })
  }

  //get procedure of area (area_id)
  getProcedure(id) {
    this.setState({ loading: 1 })
    let url = `/api/admin/specialty-procedures-by-category/${id}`
    axios
      .get(url)
      .then(res => {
        let procedures = res.data.data
        //console.log('procedures', procedures)
        this.setState({
          procedures: procedures,
        })
        this.setState({ loading: 0 })
      })
      .catch(error => {
        //console.log(error)
        //console.log(error.response)
      })
  }

  //getSettings of the procedure (procedure_id)
  getSettings(id) {
    let flag = false
    if(this.state.procedure) flag = true
    this.setState({ loading: 1, procedure: id })

    let url = `/api/admin/surgical-trees`
    axios
      .get(url)
      .then(res => {
        let surgical_trees = res.data.data
        let out = surgical_trees.findIndex((stage) => { return stage.name == 'Draping'})
        let temp = surgical_trees
        let first = temp.slice(0, out)
        // let second = temp.splice(out, temp.length - 1)
        this.setState({
          surgical_trees: surgical_trees,
          surgical_tree_first: temp.slice(0, out + 1),
          surgical_tree_second: temp.slice(out + 1, temp.length),
        })
        this.getCustomStages(id, 1)
        if(flag){
          this.setState({
            stage: this.state.surgical_trees ? this.state.surgical_trees[0] : {},
            settings: false
          }, () => {
            this.getProcedureDetail(0)
          })
          setTimeout(() => {
            this.setState({
              loading: 0,
            })
          }, 1000)
        }else{
          setTimeout(() => {
            this.setState({
              loading: 0,
              settings: true
            })
          }, 1000)
        }
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })

   
  }

  getCustomStages = (id, draft = 0) => {
    let url = `/api/admin/custom-stage?procedure_id=${id}&draft=${draft}`

    axios
      .get(url)
      .then(res => {
        this.setState({
          custom_stages: res.data.data,
          custom_stages_final: res.data.data,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  saveStage = () => {
    const { icon, stage_name, stage_type, procedure } = this.state

    let data = new FormData()
    data.append('procedure_id', procedure)
    data.append('name', stage_name)
    data.append('icon', icon.source)
    data.append('type', stage_type)

    let url = '/api/admin/custom-stage'

    axios
      .post(url, data)
      .then(res => {
        this.getCustomStages(procedure, 1)
        $('#addStageModal').modal('hide')
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors,
        })
      })
  }

  updateStage = () => {
    const { icon, stage_name, stage_type, procedure, stage_data } = this.state

    let data = new FormData()
    data.append('procedure_id', procedure.id)
    data.append('name', stage_name)
    if (icon.source) {
      data.append('icon', icon.source)
    }
    data.append('type', stage_type)
    data.append('_method', 'PATCH')

    let url = `/api/admin/custom-stage/${stage_data.id}`

    axios
      .post(url, data)
      .then(res => {
        this.getCustomStages(procedure.id, 1)
        $('#updateStageModal').modal('hide')
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors,
        })
      })
  }

  deleteStage = () => {
    const { stage_data, procedure } = this.state

    let url = `/api/admin/custom-stage/${stage_data.id}`

    axios
      .post(url, { _method: 'DELETE' })
      .then(res => {
        this.getCustomStages(procedure, 1)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  saveSorting = () => {
    const { custom_stages, procedure } = this.state
    let data = new FormData()
    custom_stages.map(stage => {
      data.append('stage_ids[]', stage.id)
    })
    data.append('procedure_id', procedure)
    data.append('_method', 'PATCH')

    let url = '/api/admin/custom-stage-sort'

    axios
      .post(url, data)
      .then(res => {
        this.setState({
          rearrange: false,
          showSuccess: true,
        })
        this.getCustomStages(procedure, 1)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  getProcedureDetail = (draft = 0, type = 'main') => {
    const { procedure, stage } = this.state
    let data = new FormData()
    data.append('procedure_id', procedure)
    data.append('stage_id', stage.id)
    data.append('stage_type', stage.type)
    data.append('draft', draft)

    let url = `/api/admin/stage`

    axios
      .post(url, data)
      .then(res => {
        this.setState({
          stage_details: res.data,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response )

        if( error.response && error.response.status == 404 && error.response.data && error.response.data.position && error.response.data.archive){
          this.setState({
            peError:error.response.data.msg,
            stage_details: error.response.data.detail
          }, () => {
            $('#notificationModal').modal('show')
          })
        }
        if( error.response && error.response.status == 404 && error.response.data && error.response.data.pe && error.response.data.archive){
          this.setState({
            peError:error.response.data.msg
          }, () => {
            $('#notificationModal').modal('show')
          })
        }
      })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ custom_stages }) => ({
        custom_stages: arrayMove(custom_stages, oldIndex, newIndex),
      }),
      () => {
        // this.saveSorting()
      }
    )
  }

  loadExistingProcedureContent() {
    return (
      <div>
        <p className="mb15">
          You can save time by loading an existing procedure
        </p>
        <div
          className="btn btn-outline-primary mb15"
          onClick={() => {
            this.setState(
              {
                key_load:
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15),
              },
              () => {
                $('#loadFromModalCenter').modal('show')
              }
            )
          }}
        >
          Load procedure from...
        </div>
      </div>
    )
  }

  getStageData = id => {
    this.setState({
      data: {
        type: 'consumable',
      },
      settings: false,
    })
    // let url = ''

    // axios.get(url).then((res) => {
    // 	console.log(res)
    // 	this.setState({
    // 		data: res.data
    // 	})
    // }).catch((error) => {
    // 	console.log(error)
    // 	console.log(error.response)
    // })
  }

  newStageModal = () => {
    return (
      <div
        className="modal fade"
        id="addStageModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addStageModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  px30" role="document">
          <div className="modal-content bg-light">
            <div className="modal-body pt30 px30 pb0">
              <h3 className="m-0 mx-auto font-weight-bold pb0 fs20 mb0">
                Add new stage
              </h3>
            </div>
            <div className="modal-body px30 pt-0 pb0">
              <div className="form-group mb20">
                <label
                  className="w-100 float-left d-flex mb20"
                  data-toggle="collapse"
                  data-target="#collapseInfo"
                >
                  Assign stage type
                  <i className="mdi mdi-information ml-auto" />
                </label>
                <div className="w-100 float-left">
                  <div className="collapse" id="collapseInfo">
                    <h6 className="mb10"> Stage types: </h6>
                    <h6 className="mb10"> Standard </h6>
                    <p className="mb20">
                      {' '}
                      Contains item list and media and text elements used for a
                      majority of standard phase formats{' '}
                    </p>
                    <h6 className="mb10"> Positioning </h6>
                    <p className="mb20">
                      {' '}
                      Additional positioning phase if required by procedure. (An
                      additional Positioning equipment phase is also added){' '}
                    </p>
                    <h6 className="mb10"> Equipment / Room setup</h6>
                    <p className="mb30">
                      {' '}
                      Additional room setup if required by procedure{' '}
                    </p>
                  </div>
                </div>
                <Picker
                  onChange={event => {
                    this.setState({ stage_type: event.target.value })
                  }}
                  value={this.state.stage_type}
                  data={this.state.stage_types}
                  errorMessage={getError(this.state.errors, 'type')}
                />
              </div>
              {this.state.stage_type != '' ? (
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="float-left d-flex justify-content-center align-items-center fs18 flex-column text-center mr30 mb20 c-pointer"
                        data-toggle="modal"
                        data-target="#selectStageIconModal"
                        style={{
                          width: '120px',
                          height: '120px',
                          border: '2px dashed #979797',
                        }}
                      >
                        {this.state.icon.icon ? (
                          <img src={this.state.icon.icon} className="w-100 p5" />
                        ) : (
                          'Assign icon'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb30">
                    <TextInput
                      label={'Name new stage'}
                      value={this.state.stage_name}
                      placeholder={'Name'}
                      errorMessage={getError(this.state.errors, 'name')}
                      containerClass={`mb30`}
                      onValueChange={event => {
                        this.setState({ stage_name: event.target.value })
                      }}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="row">
                <div className="col-md-12 text-center">
                  {this.state.stage_type != '' ? (
                    <div
                      className="btn btn-primary mr20"
                      onClick={() => {
                        this.saveStage()
                      }}
                    >
                      Add stage
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="btn btn-outline-dark" data-dismiss="modal">
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  updateStageModal = () => {
    return (
      <div
        className="modal fade"
        id="updateStageModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="updateStageModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  px30" role="document">
          <div className="modal-content bg-light">
            <div className="modal-body pt30 px30 pb0">
              <h3 className="m-0 mx-auto font-weight-bold pb10 fs20">
                Update stage
              </h3>
            </div>
            <div className="modal-body px30 pt-0 pb30">
              <div className="form-group mb20">
                <label className="w-100 float-left">
                  Assign stage type
                  <i className="mdi mdi-information ml-auto" />
                </label>
                <Picker
                  onChange={event => {
                    this.setState({ stage_type: event.target.value })
                  }}
                  value={this.state.stage_type}
                  data={this.state.stage_types}
                  errorMessage={getError(this.state.errors, 'type')}
                />
              </div>
              {this.state.stage_type != '' ? (
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="float-left d-flex justify-content-center align-items-center fs18 flex-column text-center mr30 mb20 c-pointer"
                        data-toggle="modal"
                        data-target="#selectStageIconModal"
                        style={{
                          width: '120px',
                          height: '120px',
                          border: '2px dashed #979797',
                        }}
                      >
                        {this.state.icon.icon ? (
                          <img src={this.state.icon.icon} className="w-100" />
                        ) : (
                          'Assign icon'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb30">
                    <TextInput
                      label={'Name new stage'}
                      value={this.state.stage_name}
                      placeholder={'Name'}
                      errorMessage={getError(this.state.errors, 'name')}
                      containerClass={`mb30`}
                      onValueChange={event => {
                        this.setState({ stage_name: event.target.value })
                      }}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="row">
                <div className="col-md-12 text-center">
                  {this.state.stage_type != '' ? (
                    <div
                      className="btn btn-primary mr20"
                      onClick={() => {
                        this.updateStage()
                      }}
                    >
                      Update stage
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="btn btn-outline-dark" data-dismiss="modal">
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  iconModal = () => {
    let iconlist = this.state.icons.map(icon => {
      return (
        <img
          src={icon.icon}
          className="bg-dark mb30 mr30 h120 w120 c-pointer"
          data-dismiss="modal"
          onClick={() => {
            this.setState({ icon: icon })
          }}
        />
      )
    })
    return (
      <div
        className="modal fade"
        id="selectStageIconModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="selectStageIconModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  px30 modal-lg" role="document">
          <div className="modal-content bg-light">
            <div className="modal-body pt30 px30 pb10">
              <h3 className="m-0 mx-auto font-weight-bold pb10 fs20">
                Select Icon
              </h3>
            </div>
            <div className="modal-body px30 pt-0 pb0">
              <div
                className="col-md-12 d-flex flex-wrap px0 mb30"
                style={{ height: '370px', overflow: 'auto' }}
              >
                {iconlist}
              </div>
              <div className="col-md-12 px0">
                <div className="btn btn-outline-dark" data-dismiss="modal">
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  setNewState = states => {
    this.setState(states)
  }

  stagesContent() {
    let firstlist = this.state.surgical_tree_first.map((item, index) => {
      return (
        <li className="media d-flex mb10 align-items-center" key={`item-${index}`}>
          <div className="media main-card p10" style={{ minWidth: 350 }}>
            <img
              src={item.icon.replace('public', '')}
              className="mr-3 bg-dark w50 h50"
              alt="..."
            />
            <div className="media-body h50 d-flex align-items-center">
              <h6 className="m-0">
                <u>{item.name}</u>
              </h6>
            </div>
          </div>
        </li>
      )
    })

    //   <div className>
    //   <a href="#" className="mx30">
    //     Edit
    //   </a>
    //   <a
    //     href="#"
    //     className="mr30"
    //     data-toggle="modal"
    //     data-target="#deleteModalCenter"
    //   >
    //     Remove
    //   </a>
    // </div>

    let customlist = this.state.custom_stages.map(category => {
      return (
        <li className="media d-flex mb10 align-items-center">
          <div className="media main-card p10" style={{ minWidth: 350 }}>
            <img
              src={
                category.stage
                  ? category.stage.icon.replace('public', '')
                  : ''
              }
              className="mr-3 bg-dark w50 h50"
              alt="..."
            />
            <div className="media-body h50 d-flex align-items-center">
              <h6 className="m-0">
                <u>{category.stage ? category.stage.name : ''}</u>
              </h6>
            </div>
          </div>
          <div className>
            <a
              className="mx30 c-pointer"
              data-toggle="modal"
              data-target="#updateStageModal"
              onClick={e => {
                e.preventDefault()
                this.setState({
                  stage_data: category,
                  stage_name: category.stage.name,
                  stage_type: category.type,
                  errors: [],
                  icon: {
                    icon: category.stage.icon,
                  },
                })
              }}
            >
              Edit
            </a>
            <a
              href="#"
              className="mr30"
              data-toggle="modal"
              data-target="#confirmationModal"
              onClick={e => {
                e.preventDefault()
                this.setState({
                  stage_data: category,
                })
              }}
            >
              Remove
            </a>
          </div>
        </li>
      )
    })

    let secondlist = this.state.surgical_tree_second.map(item => {
      return (
        <li className="media d-flex mb10 align-items-center">
          <div className="media main-card p10" style={{ minWidth: 350 }}>
            <img
              src={item.icon.replace('public', '')}
              className="mr-3 bg-dark w50 h50"
              alt="..."
            />
            <div className="media-body h50 d-flex align-items-center">
              <h6 className="m-0">
                <u>{item.name}</u>
              </h6>
            </div>
          </div>
        </li>
      )
    })

    return (
      <div>
        <p className="my15">
          If the procedure requires, you can add new stages after draping to fit
          your style.
        </p>
        {this.state.showSuccess ? (
          <Notifier show={1} message="Order updated" type="success" />
        ) : null}
        <ul className="list-unstyled">
          {firstlist}
          <li
            className="media d-flex mb10 align-items-center"
            onClick={() => {
              this.setState({ icon: '', stage_name: '', stage_type: '' })
            }}
          >
            <a
              className="media py20 d-flex align-items-center justify-content-center"
              style={{ minWidth: 350, border: '2px dashed #979797' }}
              data-toggle="modal"
              data-target="#addStageModal"
            >
              <u className="font-weight-bold c-pointer">Add new stage +</u>
            </a>
          </li>
          {this.state.rearrange ? (
            <SortableList
              categories={this.state.custom_stages}
              onSortEnd={this.onSortEnd}
              setNewState={this.setNewState}
              useDragHandle
            />
          ) : (
            customlist
          )}
          
          {secondlist}
          {this.state.rearrange ? (
            <div>
              <div
                className="btn btn-outline-dark mr20 mt20"
                onClick={() => {
                  this.saveSorting()
                }}
              >
                Update arrangement
              </div>
              <div
                className="btn btn-link mr20 mt20"
                onClick={() => {
                  this.setState({ rearrange: false })
                }}
              >
                Cancel Arrangement
              </div>
            </div>
          ) : (
            <div
              className="btn btn-primary mr20 mt20"
              onClick={() => {
                this.setState({ rearrange: true })
              }}
            >
              Arrange stage
            </div>
          )}
        </ul>
      </div>
    )
  }

  deleteProcedureContent() {
    return (
      <div>
        <p className="my15">
          If for any reason you need to remove this procedure, you can do so
          here.
        </p>
        <a href="javascript:;">I would like to delete this procedure</a>
      </div>
    )
  }

  loadSettings() {
    return (
      <div className="row animated fadeIn">
        <div className="col-md-12 mb20">
          <CollapsePanel
            idPanel="settings1"
            title="Load an exisiting procedure"
            content={this.loadExistingProcedureContent()}
          />
        </div>

        <div className="col-md-12 mb20">
          <CollapsePanel
            idPanel="settings2"
            title="Add new stages"
            content={this.stagesContent()}
          />
        </div>
      </div>
    )
  }

  dataLayout = () => {
    const { stage_details } = this.state
    let type = stage_details.stage ? stage_details.stage.layout_type : '' 
    switch (type) {
      case 'consumable':
        return (
          <ConsumableLayout
            setNewstate={this.setNewstate}
            edit={this.state.edit}
            key={this.state.unique}
            getProcedureDetail={this.getProcedureDetail}
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            title={this.state.name}

          />
        )
      case 'instruments':
        return(
          <InstrumentsLayout 
            setNewstate={this.setNewstate}
            edit={this.state.edit}
            key={this.state.unique}
            getProcedureDetail={this.getProcedureDetail}
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            trays={stage_details.options.trays}
            title={this.state.name}

          />
        )
      case 'positioning':
        return(
          <PositioningLayout
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            getProcedureDetail={this.getProcedureDetail}
            title={this.state.name}
          />
        )
      case 'positioning-equipment':
        return(
          <PositioningEquipmentLayout
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            getProcedureDetail={this.getProcedureDetail}
            title={this.state.name}

          />
        )
      case 'room-setup':
        return (
          <RoomSetupLayout
            stage={this.state.stage}
            setNewstate={this.setNewstate}
            edit={this.state.edit}
            stage_details={stage_details.detail}
            stage_master={stage_details.stage} 
            getProcedureDetail={this.getProcedureDetail}
            title={this.state.name}

            />
        )
      case 'standard':
        return (
          <StandardLayout
            stage={this.state.stage}
            setNewstate={this.setNewstate}
            edit={this.state.edit}
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            getProcedureDetail={this.getProcedureDetail}
            title={this.state.name}

          />
        )
      default:
        return ''
    }
  }

  loadFromModal = () => {
    const { procedure, key_load } = this.state

    return (
      <LoadFromProcedure procedure_load={procedure} key={key_load} />
    )
  }

  render() {
    console.log('sugistate', this.state, this.props)

    const specialties = this.state.specialties.map((specialty, index) => {
      return (
        <option
          key={`specialty-${this.state.unique}-${index}`}
          value={specialty.id}
        >
          {specialty.name}
        </option>
      )
    })

    const categories = this.state.categories.map((category, index) => {
      return (
        <option
          key={`category-${this.state.unique}-${index}`}
          value={category.id}
        >
          {category.name}
        </option>
      )
    })

    const procedures = this.state.procedures.map((procedure, index) => {
      return (
        <option
          key={`procedure-${this.state.unique}-${index}`}
          value={procedure.id}
        >
          {procedure.name}
        </option>
      )
    })

    let firstlist = this.state.surgical_tree_first.map(
      (surgical_tree, index) => {
        return (
          <div
            className={`specialty-card m15 w120 h120  ${
              this.state.active == index ? '' : ''
            } ${this.state.stage.id == surgical_tree.id ? 'selected' : ''}`}
            key={`surgical_tree-${surgical_tree.id}-${index}`}
            onClick={() => {
              this.setState(
                {
                  stage: surgical_tree,
                  settings: false,
                  name:surgical_tree.name
                },
                () => {
                  this.getProcedureDetail(0)
                }
              )
            }}
          >
            <div className="card">
              <div className="card-body text-center">
                <img
                  src={surgical_tree.icon.replace('public', '')}
                  className=""
                />
                <h6 className="mt12 card-subtitle px10 pb10">
                  {surgical_tree.name}
                </h6>
              </div>
            </div>
          </div>
        )
      }
    )

    let customlist = this.state.custom_stages_final.map((surgical_tree, index) => {
      return (
        <div
          className={`specialty-card m15 w120 h120`}
          // key={`surgical_tree-${surgical_tree.stage.id}-${index}`}
          onClick={() => {
            this.setState(
              {
                stage: surgical_tree.stage,
                settings: false,
                name:surgical_tree.stage.name
              },
              () => {
                this.getProcedureDetail(0, 'custom')
              }
            )
          }}
        >
          <div className="card">
            <div className="card-body text-center">
              <img
                src={surgical_tree.stage ? surgical_tree.stage.icon.replace('public', '') : ''}
                className=""
              />
              <h6 className="mt12 card-subtitle">
                {surgical_tree.stage ? surgical_tree.stage.name : ''}
              </h6>
            </div>
          </div>
        </div>
      )
    })

    let secondlist = this.state.surgical_tree_second.map(
      (surgical_tree, index) => {
        return (
          <div
            className={`specialty-card m15 w120 h120 ${
              this.state.stage.id == surgical_tree.id ? 'selected' : ''
            }`}
            key={`surgical_tree-${surgical_tree.id}-${index}`}
            onClick={() => {
              this.setState(
                {
                  stage: surgical_tree,
                  name:surgical_tree.name,
                  settings: false,
                },
                () => {
                  this.getProcedureDetail(0)
                }
              )
            }}
          >
            <div
              className="card"
              data-toggle="modal"
              data-target="#specialty-modal"
            >
              <div className="card-body text-center">
                <img
                  src={surgical_tree.icon.replace('public', '')}
                  className=""
                />
                <h6 className="mt12 card-subtitle">{surgical_tree.name}</h6>
              </div>
            </div>
          </div>
        )
      }
    )

    // const surgical_trees = this.state.surgical_trees.map((surgical_tree, index) => {
    // 	return (
    // 		<div
    // 			className={`specialty-card m15 ${this.state.active == index ? '' : 'disabled-specialty-card'} ${this.state.stage.id == surgical_tree.id ? 'selected' : ''}`}
    // 			key={`surgical_tree-${surgical_tree.id}-${index}`}
    // 			onClick={() => {
    // 				this.setState({
    // 					stage: surgical_tree
    // 				}, () => { this.getProcedureDetail(this.state.procedure, surgical_tree.id, surgical_tree.type, 0)})
    // 			}}
    // 		>
    // 			<div
    // 				className="card"
    // 				data-toggle="modal"
    // 				data-target="#specialty-modal"
    // 			>
    // 				<div className="card-body text-center">
    // 					<img src={surgical_tree.icon.replace('public', '')} className="" />
    // 					<h6 className="mt15 card-subtitle">{surgical_tree.name}</h6>
    // 				</div>
    // 			</div>
    // 		</div>
    // 	)
    // })

    const swiperParams = {
      // spaceBetween: '3.5%',
      centeredSlides: true,
      slidesPerView: 'auto',
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.swiper-button-next.card-swiper-next',
        prevEl: '.swiper-button-prev.card-swiper-prev ',
      },
    }

    return (
      <div className="container" id="home">
        <WholeBodyloader progress={this.state.loading} />
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="row">
              <div className="col-md-12">
                <h1 className="font-weight-bold mb30">Procedure page</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group m-0">
                  <label>Select Specialty</label>
                  <select
                    className="form-control m-0"
                    onChange={event => {
                      this.getAreas(event.target.value)
                      this.setState({
                        stage: {id: ''},
                        procedure: '',
                        procedure_name: '',
                        surgical_tree_first: [],
                        surgical_tree_second: [],
                        custom_stages_final: [],
                        stage_details: [],
                        settings: true,
                        selected_specailty:event.target.value
                      })
                    }}
                    value={this.state.selected_specailty}
                  >
                    <option value="" selected>
                      Select Specialty
                    </option>
                    {specialties}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group m-0">
                  <label>Select Surgical Areas</label>
                  <select
                    className="form-control m-0"
                    value={this.state.select_area}
                    onChange={event => {
                      if(event.target.value){
                        this.setState({
                          procedure: '',
                          procedure_name: '',
                          surgical_tree_first: [],
                          surgical_tree_second: [],
                          custom_stages_final: [],
                          stage: {id: ''},
                          stage_details: [],
                          settings: true,
                          select_area:event.target.value,

                        })
                        this.getProcedure(event.target.value)
                      }else{
                        this.setState({
                          procedure: '',
                          procedures: [],
                          procedure_name: '',
                          surgical_tree_first: [],
                          surgical_tree_second: [],
                          custom_stages_final: [],
                          stage: {id: ''},
                          stage_details: [],
                          settings: true
                        })
                      }
                     
                    }}
                  >
                    <option value="" selected>
                      Select Category
                    </option>
                    {categories}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group m-0">
                  <label>Select Procedure</label>
                  <select
                    className="form-control m-0"
                    value={this.state.procedure}
                    onChange={event => {
                      if(event.target.value){
                        this.getSettings(event.target.value)
                        let procedure = _.find(this.state.procedures, e => {
                          return e.id == event.target.value
                        })
                        this.setState({
                          procedure_name: procedure.name,
                        })
                        setTimeout(() => {
                          this.setState({
                            swiperunique:
                              Math.random()
                                .toString(36)
                                .substring(2, 15) +
                              Math.random()
                                .toString(36)
                                .substring(2, 15),
                          })
                        }, 500)
                      }else{
                        this.setState({
                          procedure: '',
                          procedure_name: '',
                          surgical_tree_first: [],
                          surgical_tree_second: [],
                          custom_stages_final: [],
                          stage: {id: ''},
                          stage_details: [],
                          settings: true
                        })
                      }
                    }}
                  >
                    <option value="" selected>
                      Select Procedure
                    </option>
                    {procedures}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-9 mx-auto">
                <div className="row">
                  <div className="col-md-12 pt30">
                    <h4 className="mb20 d-flex align-items-end align-items-center">
                      {this.state.procedure_name
                        ? this.state.procedure_name
                        : '[Select a procedure]'}{' '}

                      {this.state.procedure ? 
                        <a href="#settings">
                          <i
                            className="mdi mdi-settings ml15"
                            onClick={() => {
                              this.setState({ settings: true, stage: {} })
                            }}
                          />{' '}
                        </a> 
                      : null}
                      
                    </h4>
                  </div>
                  <div className="col-md-12 card-swiper">
                    <Swiper
                      {...swiperParams}
                      key={this.state.swiperunique}
                      shouldSwiperUpdate={true}
                    >
                      {firstlist}
                      {customlist}
                      {secondlist}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>

            {!this.state.settings ? (
              <div className="row">{this.dataLayout()}</div>
            ) : null}

            {this.state.settings ? (
              <div className="row" id="settings">
                <div className="col-md-9 mx-auto">
                  <div className="row">
                    <div className="col-md-12 pt30">
                      <h4 className="mb20 d-flex align-items-end">Settings</h4>
                    </div>
                  </div>

                  {this.state.procedure ? (
                    this.loadSettings()
                  ) : (
                    <div className="row">
                      <div className="col">
                        <p>Please select procedure first!</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {this.newStageModal()}
        {this.updateStageModal()}
        {this.iconModal()}
        {this.loadFromModal()}
        <Confirmation
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.deleteStage()
          }}
          cancel={() => {
            $('#confirmationModal').modal('hide')
          }}
        />
        <Notification
       
          message={this.state.peError}
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
          }}
         
        />
      </div>
    )
  }
}

export default ProcedureCreator
