import React, { Component } from 'react'
import img from '../../assets/images/img.png'
import { connect } from 'react-redux'
import axios from 'axios'
import { TextInput } from '../../components/commons/forms'
import { loginUpdate } from '../../actions'
import { getError, dataURItoBlob } from '../../utilities'
import SpecialtyCards from './SpecialtyCards'

import Notification from '../../components/Notification'
import $ from 'jquery'

import 'rc-cropping/assets/index.css'
import _ from 'lodash'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import 'rc-dialog/assets/index.css'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import Notifier from '../../components/commons/notifier/Notifier'
import ImagePicker from '../../components/commons/elements/inputs/ImagePicker'
import { BASE_URL } from '../../config/constant';

export class AccountSettings extends Component {

	constructor(props) {
		super(props)

		this.state = {
			firstname: '',
			lastname: '',
			email: '',
			specialties: [],
			firstname: '',
			job_title: '',
			errors: [],
			old_password: '',
			new_password: '',
			new_password_confirmation: '',
			avatar: null,
			image: '',
			croppedFile: null,
			show: false,
			filename: '',
			progress: 0,
			loading: 0,
			showNotification: false,
			notificationMessage: '',
		}
		this.handleClick = this.handleClick.bind(this)
		this.toggleShow = this.toggleShow.bind(this)
	}

	toggleShow(val, message = '') {
		this.setState({
			showNotification: val,
			message: message
		})
	}

	handleClick(e) {
		this.setState({
			show: true,
		})
		this.refs.file.click()
	}

	croppeFile = fileBlob => {
		if (!fileBlob) {
			this.setState({
				show: false,
				file: null,
				croppedFile: null,
				isNext: false,
				page: 1,
			})
			return
		}
		const reader = new FileReader()
		reader.readAsDataURL(fileBlob)
		let filename = this.state.file.name
		reader.onload = () => {
			this.setState({
				show: false,
				file: null,
				croppedFile: null,
				isNext: false,
				page: 1,
				filename: filename,
				avatar: reader.result
			})
		}
		setTimeout(() => {
			this.saveProfile()
		}, 500)
	}

	renderCropper() {
		return (
			<input
				type="file"
				ref="file"
				onChange={() => {
					this.setState({
						image: this.refs.file.file[0]
					})
				}}
				className="d-none"
			/>
		)
	}

	componentDidMount() {
		this.setState({
			job_title: this.props.auth.user.job_title,
			firstname: this.props.auth.user.firstname,
			lastname: this.props.auth.user.lastname,
			email: this.props.auth.user.email,
			avatar: this.props.auth.user.avatar,
			specialties: this.props.auth.user.specialties,

		})
		setTimeout(() => {
			this.runCardSelectedMode()
		}, 1000)
	}
	componentWillReceiveProps(nextProps) {
		setTimeout(() => {
			this.runCardSelectedMode()
		}, 1000)
	}
	runCardSelectedMode() {
		this.state.specialties.map(data => {
			$(`#specialty-card-${data.id}`).addClass('selected')
		})
	}

	componentWillMount() {
		axios.defaults.baseURL = BASE_URL
	}


	saveProfile() {
		this.setState({
			progress: 0
		})
		const {
			job_title,
			firstname,
			lastname,
			email,
			avatar,
		} = this.state

		let config = {
			headers: {
				'content-type': 'multipart/form-data',
			},

			onUploadProgress: (progressEvent) => {

				if (avatar) {
					if (avatar.indexOf('base64') >= 0) {
						var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
						console.log('percentCompleted:' + percentCompleted)
						this.setState({
							progress: percentCompleted >= 95 ? 95 : percentCompleted,
						})
					}
				}
			}
		}
		const form = new FormData()
		if (avatar) {
			if (avatar.indexOf('base64') >= 0) {
				form.append('avatar', dataURItoBlob(avatar))
			}
		}
		form.append('job_title', job_title)
		form.append('firstname', firstname)
		form.append('lastname', lastname)
		form.append('email', email)
		axios
			.post('api/admin/save-account-settings', form, config)
			.then(result => {
				console.log(result.data.data)
				this.props.loginUpdate({
					prop: 'user',
					value: result.data.data
				})

				if (avatar) {
					if (avatar.indexOf('base64') >= 0) {
						this.setState({
							progress: 100,
							showNotification: true,
							notificationMessage: 'Profile photo updated!'
						})
					}
				}
				setTimeout(() => {
					this.setState({
						progress: 0
					})
				}, 1000)
			})
			.catch(errors => {
				console.log('errors', errors)
				this.setState({
					errors: errors.response.data.errors,
					loading: false,
				})
				this.setState({
					progress: 0
				})
			})
	}

	setSpecialtySelected(specialty) {
		const data = {
			specialty: specialty.id
		}
		axios
			.post('api/admin/nurse/specialty', data)
			.then(result => {
				console.log('result.data.specialties', result.data)
				this.props.loginUpdate({
					prop: 'user',
					value: result.data
				})
				this.setState({
					specialties: result.data.specialties
				})
			})
			.catch(errors => {
				console.log('errors', errors)
			})
		var hasSelectedClass = $(`#specialty-card-${specialty.id}`).hasClass('selected')
		if (hasSelectedClass) {
			$(`#specialty-card-${specialty.id}`).removeClass('selected')
		} else {
			$(`#specialty-card-${specialty.id}`).addClass('selected')
		}
	}

	saveChangePassword() {
		const data = {
			old_password: this.state.old_password,
			new_password: this.state.new_password,
			new_password_confirmation: this.state.new_password_confirmation,
		}
		axios
			.post('api/admin/change-password', data)
			.then(result => {
				console.log(result)
				$('#changePasswordModal').modal('hide')
				setTimeout(() => {
					$("#notificationModal").modal('show')
				}, 500)
				this.setState({
					old_password: '',
					new_password: '',
					new_password_confirmation: '',
				})
			})
			.catch(errors => {
				console.log(errors)
				this.setState({
					errors: errors.response.data.errors,
					loading: false,
				})
			})
	}

	loadSpecialyCards() {
		const { specialty } = this.props
		const cards = specialty.items.map(data => {
			return <SpecialtyCards
				icon={data.icon}
				name={data.name}
				styleClass="my15 m-lgr30 m-xlr30 m-mdr20 mr30"
				id={`specialty-card-${data.id}`}
				onClick={() => {
					this.setSpecialtySelected(data)
				}}
			/>
		})
		return (
			<div className="col-md-12 d-flex flex-wrap">
				{cards}
			</div>
		)
	}
	loadNurseSpecialties() {
		if (this.state.specialties.length == 0) {
			return null
		} else {
			const specialties = this.state.specialties.map(specialty => {
				return (
					<span className="badge badge-secondary badge-pill mr10 mb10 p10">{specialty.name}</span>
				)
			})
			return (
				<div className="row mt20">
					<div className="col-12">
						<label>Specialties</label>
						<div className="w-100 tags">
							{specialties}
						</div>
					</div>
				</div>
			)
		}
	}

	loadUserCard() {
		return (
			<div className="card shadow-sm border-0">
				<div className="card-body">
					<div className="media">
						{
							this.state.avatar ?
								<img src={this.state.avatar} className="align-self-center bg-dark rounded-circle" alt="..." style={{ width: '100px', height: '100px' }} />
								:
								<img src={img} className="align-self-center bg-dark rounded-circle" alt="..." style={{ width: '100px', height: '100px' }} />
						}

						<div className="media-body pl20 align-self-center">
							<h5 className="m-0 fs20 font-weight-bold">
								{this.props.auth.user.firstname ?
									`${this.props.auth.user.firstname} ${this.props.auth.user.lastname}` : `${this.props.auth.user.name}`
								}
							</h5>
							{this.state.job_title ?
								<p className="mb-0 lead fs16 mt5">{this.state.job_title}</p>
								: null
							}
						</div>
					</div>
					{this.loadNurseSpecialties()}
				</div>
			</div>
		)
	}

	changePasswordModal() {
		return (
			<div className="modal" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="changePasswordModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content rounded-0">
						<div className="modal-header pb0 border-0">
							<h5 className="modal-title pb0 mb0" id="changePasswordModalLabel">Change password</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times</span>
							</button>
						</div>
						<div className="modal-body py30">
							<TextInput
								inputType="password"
								label={'Old password'}
								labelclassName="fs16 font-weight-bold w-100"
								value={this.state.old_password}
								errorMessage={getError(this.state.errors, 'old_password')}
								containerClass={`form-group mb20`}
								onValueChange={(event) => {
									this.setState({
										old_password: event.target.value
									})
								}}
							/>

							<TextInput
								inputType="password"
								label={'New password'}
								labelclassName="fs16 font-weight-bold w-100"
								value={this.state.new_password}
								errorMessage={getError(this.state.errors, 'new_password')}
								containerClass={`form-group mb20`}
								onValueChange={(event) => {
									this.setState({
										new_password: event.target.value
									})
								}}
							/>

							<TextInput
								inputType="password"
								label={'Confirm new password'}
								labelclassName="fs16 font-weight-bold w-100"
								value={this.state.new_password_confirmation}
								errorMessage={getError(this.state.errors, 'new_password_confirmation')}
								containerClass={`form-group mb0`}
								onValueChange={(event) => {
									this.setState({
										new_password_confirmation: event.target.value
									})
								}}
							/>
						</div>
						<div className="modal-footer pt0 border-0 d-flex flex-wrap justify-content-between">
							<div className="btn btn-primary mw100" onClick={() => {
								this.saveChangePassword()
							}}>Save</div>
							<button type="button" className="btn btn-outline-primary mw100 m-xst20 mx-0" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
	render() {
		return (
			<div className="container">
				<WholeBodyloader progress={this.state.progress} type="progress" />
				{this.changePasswordModal()}
				<Notification
					title="Password changed"
					message="You password has been successfully changed."
					confirmText="Okay"

				/>
				<div className="row">
					<div className="col-md-12">
						<h1 className="font-weight-bold m0 mb15 fs28">Account settings</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<ul className="nav nav-tabs" id="myTab" role="tablist">
							<li className="nav-item mr30">
								<a className="nav-link active border-0 font-weight-bold bg-transparent px-0 py5 fs20" id="my_profile-tab" data-toggle="tab" href="#my_profile" role="tab" aria-controls="my_profile" aria-selected="true" style={{ textDecoration: 'none' }}>My profile</a>
							</li>
							<li className="nav-item mr30">
								<a className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs20" id="my_account_details-tab" data-toggle="tab" href="#my_account_details" role="tab" aria-controls="my_account_details" aria-selected="true" style={{ textDecoration: 'none' }}>My account details</a>
							</li>
							<li className="nav-item mr30">
								<a className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs20" id="billing-tab" data-toggle="tab" href="#billing" role="tab" aria-controls="billing" aria-selected="true" style={{ textDecoration: 'none' }}>Billing details</a>
							</li>

						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="my_profile" role="tabpanel" aria-labelledby="my_profile-tab">
								<div className="row mt20">
									<div className="col-lg-4 col-md-5 mb20">
										{this.loadUserCard()}
									</div>
									<div className="col">
										<div className="row mb20">
											<div className="col-md-12">
												{
													this.state.showNotification ?
														<Notifier show={1} message={this.state.notificationMessage} type="success" styleClass="mb20"
															toggleShow={this.toggleShow} />
														: null
												}
												<div className="row">
													<div className="col-lg-6 col-md-8">
														<TextInput
															label="First name"
															labelclassName="fs16 font-weight-bold w-100"
															value={this.state.firstname}
															errorMessage={getError(this.state.errors, 'firstname')}
															containerClass={`form-group mb10`}
															onValueChange={event => {
																this.setState({
																	firstname: event.target.value
																})
															}}
															onBlur={() => {
																this.saveProfile()
															}}
														/>
													</div>
												</div>

												<div className="row">
													<div className="col-lg-6 col-md-8">

														<TextInput
															label="Last name"
															labelclassName="fs16 font-weight-bold w-100"
															value={this.state.lastname}
															errorMessage={getError(this.state.errors, 'lastname')}
															containerClass={`form-group mb10`}
															onValueChange={event => {
																this.setState({
																	lastname: event.target.value
																})
															}}
															onBlur={() => {
																this.saveProfile()
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="row mb20">
											<div className="col-md-12">
												<label className="fs16 font-weight-bold w-100">Upload a profile photo</label>
												<ImagePicker aspectRatio={3 / 3}
													getImage={(image) => { this.setState({ avatar: image }, () => { this.saveProfile() }) }}
												/>
											</div>
										</div>
										<div className="row mb20">
											<div className="col-lg-6 col-md-8">

												<TextInput
													label={'Add a job title'}
													labelclassName="fs16 font-weight-bold w-100"
													value={this.state.job_title}
													errorMessage={getError(this.state.errors, 'job_title')}
													containerClass={`form-group`}
													onValueChange={event => {
														this.setState({
															job_title: event.target.value
														})
													}}
													onBlur={() => {
														this.saveProfile()
													}}
												/>
											</div>
										</div>
										<div className="row mb20">
											<div className="col-md-12">
												<label className="fs16 font-weight-bold w-100">Add your specialties</label>
											</div>
											{this.loadSpecialyCards()}
										</div>
									</div>
								</div>
							</div>

							<div className="tab-pane" id="my_account_details" role="tabpanel" aria-labelledby="my_account_details-tab">
								<div className="row mt20">
									<div className="col-md-5">
										<h4 className="mb20 font-weight-bold fs20">My account details</h4>
										<div className="row mb20">
											<div className="col-md-12">
												<TextInput
													label={'Email address'}
													labelclassName="fs16 font-weight-bold w-100"
													value={this.state.email}
													errorMessage={getError(this.state.errors, 'email')}
													containerClass={`form-group mb10`}
													onValueChange={event => {
														this.setState({
															email: event.target.value
														})
													}}
													onBlur={() => {
														this.saveProfile()
													}}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12">
												<label className="w-100 mb10">Password management</label>
												<div className="btn btn-outline-primary" onClick={() => { $("#changePasswordModal").modal('show') }}>Change password</div>
											</div>
										</div>
										<hr className="my30" />
										<div className="row">
											<div className="col-md-12">
												<h4 className="mb20 fs20">Notifications</h4>
												<p className="mb20 fs20">Receive E-mail notifications for:</p>
												<div className="form-group mb30">
													<h5 className>Announcements from Global OR</h5>
													<div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
														<label className="btn btn-outline-dark w-50">
															<input type="radio" name="handedness" id="room_environment" autoComplete="off" defaultValue="left" /> Yes
                                                        </label>
														<label className="btn btn-outline-dark w-50">
															<input type="radio" name="handedness" id="room_environment" autoComplete="off" defaultValue="right" /> No thanks
                                                        </label>
													</div>
												</div>
												<div className="form-group mb30">
													<h5 className>Procedure updates</h5>
													<div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
														<label className="btn btn-outline-dark w-50">
															<input type="radio" name="handedness" id="room_environment" autoComplete="off" defaultValue="left" /> Yes
                                                        </label>
														<label className="btn btn-outline-dark w-50">
															<input type="radio" name="handedness" id="room_environment" autoComplete="off" defaultValue="right" /> No thanks
                                                        </label>
													</div>
												</div>
												<div className="form-group mb30">
													<h5 className>Global OR’s marketing partners</h5>
													<div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
														<label className="btn btn-outline-dark w-50">
															<input type="radio" name="handedness" id="room_environment" autoComplete="off" defaultValue="left" /> Yes
                                                        </label>
														<label className="btn btn-outline-dark w-50">
															<input type="radio" name="handedness" id="room_environment" autoComplete="off" defaultValue="right" /> No thanks
                                                        </label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane animated " id="billing" role="tabpanel" aria-labelledby="billing-tab">
								<div className="row mt20">
									<div className="col-md-5">
										<h4 className="mb30 font-weight-bold">Credit card details</h4>
										<div className="card">
											<div className="card-body bg-light" style={{ height: '400px' }}>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>

			</div>
		)
	}
}

const mapStateToProps = ({ specialty, auth }) => {
	return { specialty, auth }
}

export default connect(
	mapStateToProps,
	{ loginUpdate }
)(AccountSettings)
