import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import $ from 'jquery'
export class AssignTraysProcedureInstrument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_trays: [],
      trays: [],
      keyword: '',
      assignOnly: false,
    }
  }

  componentDidMount = () => {
    this.getTrays()
  }

  componentWillReceiveProps = (nextProps) => {
    console.log('rec')
    this.setState({
      selected_trays: nextProps.selected_trays
    })
  }

  checkTray = id => {
    let { selected_trays, trays } = this.state

    console.log('sesss', selected_trays, trays, id)
    let tray = trays.find(e => e.id === id)

    if (selected_trays.find(e => e.tray_id === id)) {
      this.setState({
        selected_trays: selected_trays.filter(x => x.tray_id !== id),
      })
    } else {
      this.setState({
        selected_trays: [...selected_trays, tray],
      })
    }
  }

  checkTrayExistence = id => {
    let { selected_trays } = this.state

    let exist = false
    if (selected_trays.filter(e => e.tray_id === id).length > 0) {
      exist = true
    }else if(selected_trays.filter(e => e.id === id).length > 0){
      exist = true
    } else {
      exist = false
    }

    return exist
  }

  getTrays() {
    let url = `api/tray?keyword=${this.state.keyword}`
    axios
      .get(url)
      .then(res => {
        console.log('trays', res)
        this.setState({
          trays: res.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  allocateItem = () => {
    const { stage_master } = this.props
    const { selected_trays } = this.state
    const data = new FormData()
    data.append(`_method`, 'PATCH')
    selected_trays.map(tray => {
      data.append(`tray_ids[]`, tray.tray_id ? tray.tray_id : tray.id)
    })
    axios
      .post(`api/admin/stage-instrument-assign-tray/${stage_master.id}`, data)
      .then(result => {
        this.props.getProcedureDetail(1)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
    this.props.assignNewState({ selected_trays: selected_trays })
    $('#assignTraysModal').modal('hide')
  }

  render() {
    let traylist = this.state.trays.map(tray => {
      if (this.state.assignOnly) {
        if (this.checkTrayExistence(tray.id)) {
          return (
            <tr>
              <td className="px10 w200">{tray.specialty.name}</td>
              <td className="px10 w-50">{tray.name}</td>
              <th className="px10 text-center w100">
                <a
                  href="#"
                  className="text-dark"
                  onClick={e => {
                    e.preventDefault()
                    this.checkTray(tray.id)
                  }}
                >
                  {this.checkTrayExistence(tray.id) ? 'Remove' : 'Assign'}
                </a>
              </th>
            </tr>
          )
        }
      } else {
        return (
          <tr>
            <td className="px10 w200">{tray.specialty.name}</td>
            <td className="px10 w-50">{tray.name}</td>
            <th className="px10 text-center w100">
              <a
                href="#"
                className="text-dark"
                onClick={e => {
                  e.preventDefault()
                  this.checkTray(tray.id)
                }}
              >
                {this.checkTrayExistence(tray.id) ? 'Remove' : 'Assign'}
              </a>
            </th>
          </tr>
        )
      }
    })

    const search = _.debounce(() => {
      this.getTrays()
    }, 500)

    return (
      <div
        className="modal fade"
        id="assignTraysModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="assignTraysModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-xl px30" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30 pb15">
              <h5 className="m-0 mx-auto font-weight-bold pb10 fs20">
                Step 1 - Assign items
              </h5>
            </div>
            <div className="modal-body px30 pt-0 pb30">
              <div className="row pb20">
                <div className="col-md-4 form-group m-0">
                  <label className="mb10">Search</label>
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    placeholder="Start typing name, catalogue #..."
                    onChange={event => {
                      this.setState({ keyword: event.target.value }, () => {
                        search()
                      })
                    }}
                  />
                </div>
                <div className="col-md-4 form-group m-0 d-flex align-items-end">
                  <div
                    className="btn  btn-outline-dark"
                    onClick={() => {
                      this.setState({
                        assignOnly: !this.state.assignOnly,
                      })
                    }}
                  >
                    {this.state.assignOnly
                      ? 'View all'
                      : 'View assigned trays only'}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped table-fixed border">
                    <thead className="bg-dark text-white text-left">
                      <tr>
                        <th className="px10 w200">Specialty</th>
                        <th className="px10 w-50">Name</th>
                        <th className="px10 w100" />
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        float: 'left',
                        width: '100%',
                        overflow: 'auto',
                        maxHeight: '358px',
                        height: '358px',
                      }}
                    >
                      {traylist}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                onClick={() => this.allocateItem()}
              >
                Allocate items
              </div>
              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AssignTraysProcedureInstrument
