import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router'
import $ from 'jquery'
import { Picker } from '../commons/forms'
import { getArrayError } from '../../utilities'
import axios from 'axios'
export class AllocateItemsProcedure extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      isReverse: false,
      errors: []
    }
  }

  sortBy(key) {
    let arrayCopy = [...this.props.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setSelected(arrayCopy)
    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'item_category'){
      return function (a, b) {
        if (a.item_category < b.item_category) return -1
        if (a.item_category > b.item_category) return 1
      }
    }
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  subtractQty = (item, index) => {
    let { selected_items } = this.props

    if (item.qty > 1) {
      item.qty -= 1
    }

    selected_items[index] = item
    this.props.setSelected(selected_items)
  }

  changeQty = (qty, item, index) => {
    let { selected_items } = this.props

    item.qty = qty

    selected_items[index] = item
    this.props.setSelected(selected_items)
  }

  addQty = (item, index) => {
    let { selected_items } = this.props

    item.qty += 1

    selected_items[index] = item
    this.props.setSelected(selected_items)
  }

  assignItem = () => {
    const { stage_master, item_categories, selected_items } = this.props

    console.log('master', stage_master)
    const formData = new FormData()
    formData.append(`_method`, 'PATCH')
    formData.append('draft', 1)
    item_categories.map((category, index) => {
      if (index > 0) {
        formData.append(`consumables[${index}]`, category.id)
        selected_items.map(item => {
          if (category.id == item.item_category) {
            formData.append(`item_ids[${index}][]`, item.id)
            formData.append(`qty[${index}][]`, item.qty)
          }
        })
      }
    })
    axios
      .post(`api/admin/stage-consumable-add-item/${stage_master.id}`, formData)
      .then(result => {
        console.log(result)
        this.props.setNewState({
          selected_items: this.props.selected_items,
          selected_flag: true,
        })
        $('.modal').modal('hide')
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  validate = () => {
    const { selected_items } = this.props
    this.setState({
      errors: []
    })

    let data = new FormData()
    data.append('_method', 'PATCH')
    selected_items.map((item) => {
      data.append('consumables[]', item.item_category ? item.item_category : '')
    })

    let url = '/api/client/stage-consumable-validate'

    axios.post(url, data).then((res) => {
      console.log('popo5', res)
      this.assignItem()
      
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
      this.setState({
        errors: err.response.data.errors
      })
    })
  }

  render() {
    let itemlist = this.props.selected_items.map((item, index) => {
      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })
      return (
        <tr>
          <td className="w150 px10">
            <Picker
              containerClass="m0"
              onChange={event => {
                let i = this.props.selected_items
                item.item_category = event.target.value
                i[index] = item
                this.props.setSelected(i)
              }}
              value={item.item_category ? item.item_category : ''}
              data={this.props.item_categories}
              errorMessage={getArrayError(this.state.errors, 'consumables', index)}
            />
          </td>
          <td className="w150 px10">{item.catalog_number}</td>
          <td
            className="w250 font-weight-bold c-pointer text-dark fs-16 px5"
            data-container="body"
            data-trigger="hover"
            data-toggle="popover"
            data-placement="right"
            data-html="true"
            data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            onClick={() => {
              this.setState(
                {
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                },
                () => {
                  $(`#${this.state.unique}`).modal('show')
                }
              )
            }}
          >
            {item.name}
          </td>
          <td className="text-center w70 px5">
            <p className="m-0 p-0 float-left w-100 fs14">{item.size} </p>
          </td>
          <td className=" text-center w100 px5 d-flex">
            <input
              type="text"
              defaultValue={1}
              className="form-control rounded-0 text-center form-control-sm px-0"
              value={item.qty}
              onChange={(e) => {
                this.changeQty(e.target.value, item, index)
              }}
            />
          </td>
          <td className="w85 text-center pt15 px5">
            <a
              href="#"
              className="text-dark mt15"
              onClick={event => {
                event.preventDefault()
                this.props.checkItem(item.id)
              }}
            >
              {this.props.checkItemExistence(item.id) ? 'Remove' : 'Add'}
            </a>
          </td>
        </tr>
      )
    })
    return (
      <div
        className="modal fade"
        id="allocateItemsModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="allocateItemsModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-body pt30 px30 pb0">
              <h5 className="m0 h3bold pb0">
                Step 2 - Allocate items
              </h5>
            </div>
            <div className="modal-body px30 pt-0 pb30">
              <div className="row pb10">
                <div className="col-md-12 form-group m-0">
                  <p className="m-0">
                    Please allocate new items to a consumable category.{' '}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="w-100 of-auto">
                    <table className="table table-striped table-fixed border min-table-width-825">
                      <thead className="bg-dark text-white text-left">
                        <tr>
                          <th onClick={() => this.sortBy('item_category')}
                            class="w150 px10" >Category <i class="fa fa-sort ml5" />
                          </th>
                          <th
                            onClick={() => this.sortBy('catalog_number')}
                            class="w150 px10"
                          >
                            Catalogue # <i class="fa fa-sort ml5" />
                          </th>
                          <th
                            onClick={() => this.sortBy('name')}
                            class="w250 px5"
                          >
                            Name # <i class="fa fa-sort ml5" />
                          </th>
                          <th className="px5 w70">Size</th>
                          <th className="px5 w100 text-center">Qty</th>
                          <th className="px5 w90"></th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          float: 'left',
                          width: '100%',
                          overflow: 'auto',
                          maxHeight: '435px',
                          height: '435px',
                        }}
                      >
                        {itemlist}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body mb-0 px30 pb0 pt0 border-0 justify-content-start">
              <div
                className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                onClick={() => {
                  this.validate()
                }}
              >
                Assign items
              </div>
              <div
                className="btn btn-light border-dark px30 mt-0 mb-0 float-left m-xst30"
                onClick={() => {
                  $('#allocateItemsModalCenter').modal('hide')
                  $('#assignItemsModalCenter').modal('show')
                }}
              >
                Go Back
              </div>
              <div
                className="btn btn-link px30 mt-0 mb-0 float-left m-xst30"
                data-dismiss="modal"
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AllocateItemsProcedure
