import React, { Component } from 'react'

export class Confirmation extends Component {
  render() {
    const { idModal } = this.props
    return (
      <div className="modal fade" id={idModal ? idModal:'confirmationModal'} tabIndex={-1} role="dialog" aria-labelledby="confirmationModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h5 className="m-0 mx-auto font-weight-bold text-center modal-title mb0 pb20">{this.props.title}</h5>
              <p className="m-0 modal-text">{this.props.message}</p>
            </div>
            <div className="modal-body text-center">
              <div className="btn btn-primary mr30" data-dismiss="modal" onClick={this.props.confirm}>{this.props.confirmText}</div>
              <div className="btn btn-outline-primary" data-dismiss="modal" onClick={this.props.cancel}>{this.props.cancelText ? this.props.cancelText:'Cancel' }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Confirmation
