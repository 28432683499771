import React, { Component } from 'react'
import Frame from './Frame';
import ProductPage from './ProductPage'
import ProdGallery from './productpagecomponents/ProdGallery'
import VideoCaption from './productpagecomponents/VideoCaption'

export class App extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       frameChild: null
    }
  }
  
  componentWillReceiveProps(nextProps) { 
    if (this.props.saveInfo){
      alert('save a')
      this.state.frameChild.current.saveThis()
    }
  }


  render() {
    


    return (
      <div>
        <Frame room_id={this.props.id} ref={this.state.frameChild} />
        <ProductPage />
      </div>
    )
  }
}

export default App
