import React, { Component } from 'react'
import { 
  getItems, 
  getMedicalInventoryCategories,
  getItemsPerCategory,
  setItems
} from '../../actions/ItemAction'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import Inbodyloader from '../loader/Inbodyloader'
import { Picker } from '../../components/commons/forms'
import { connect } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component"
import $ from 'jquery'
import _ from 'lodash'
import ViewItemModal from './ViewItemModal';


export class AssignItemsModalInstruments extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      category: 'all',
      selected_items: [],
      keyword: '',
      inventory: 2,
      page: 1,
      selectedItem: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount = () => {
    this.props.getMedicalInventoryCategories(2)
    
    this.props.getItemsPerCategory('all', this.props.selected_items, this.state.inventory, '', this.state.page)
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.change != nextProps.change){
      this.setState({
        paage: 1,
        keyword: '',
        category: 'all',
        selected_items: nextProps.selected_items
      }, () => {
        this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory, '',this.state.page)
      })
    }
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
    if(_.difference(this.props.selected_items, this.state.selected_items).length > 0){
      this.setState({
        selected_items: this.props.selected_items,
        category: 'all',
        keyword: '',
        page: 1
      })
    }
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState({
      page: page + 1
    }, () => { this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page) })
  }


  checkItem = (id) => {
    let { items } = this.props
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, (e) => { return e.id == id })
    if(index >= 0){
      _.remove(selected_items, (e) => { return e.id == id})
    }else{
      index = _.findIndex(items, (e) => { return e.id == id})
      if(!items[index].qty){
        items[index].qty = 1
      }
      selected_items.unshift(items[index])
    }

    this.setState({
      selected_items: selected_items
    })
  }

  checkItemExistence = (id) => {
    let { items } = this.props
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, (e) => { return e.id == id })

    if(index >= 0){
      return true
    }

    return false
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.props.setItems(arrayCopy)
    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  render() {
    const { category, keyword } = this.state
    const { inventory_categories, items } = this.props 
    const search = _.debounce(() => {
      this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, this.state.keyword, this.state.page)
    }, 500)

    const itemlist = items.map((item, index) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr>
          <td className="w140 px5" >{item.catalog_number}</td>
          <td  className="w230 px5">
            <a href='#' className="text-dark fs-16" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`} onClick={() => {
                this.setState({
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                }, () => {
                  $('.popover').popover('hide')
                  $('#viewItemModal-45').modal('show')
                })

              }}>
              {item.name}
            </a>
          </td>
          <td className="w100 px5"> <p className="m-0 p-0 float-left w-100 fs14">{item.company} </p> </td>
          <td className="w100 px5"> <p className="m-0 p-0 float-left w-100 fs14">{item.category ? item.category.name : ''}</p> </td>
          <td className="px10 text-left  w80">{item.size ? item.size : '-'}</td> 
          <td className="w150 px5" >
            <div className="w130 d-flex align-items-center justify-content-center">
              <input type="text" name className="form-control text-center p-0 mx10 bg-white" value={item.qty ? item.qty : 1}
                onChange={(event) => {
                  let { selected_items } = this.state
                
                  let index = _.findIndex(selected_items, (e) => { return e.id == item.id})
                  
                  if(index > -1){
                    selected_items[index].qty = event.target.value
                  }
  
                  this.setState({
                    selected_items: selected_items
                  })
                  
                  item.qty = event.target.value
                  items[index] = item
                  this.props.setItems(items)
                }}
              />
            </div>
          </td>
         
          <td className="w70 text-center pt15 px5">
            <a href="#" className="text-dark mt15" onClick={(event) =>  {event.preventDefault(); this.checkItem(item.id)}}>{this.checkItemExistence(item.id) ? 'Remove' : 'Add'}</a>
          </td>
        </tr>
      )
    })
    return (
      <div> 
      <div className="modal fade" id="assignItemsModalCenter" tabIndex={-1} role="dialog" aria-labelledby="assignItemsModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl px30 p-xsx0" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="h2bold mb0 d-flex">
                Assigning items 
                <div class="ml-auto text-dark font-weight-bold" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">&times;</span>
                </div> 
              </h5>
            </div>
            <div className="modal-body pt30 pb0">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Search</label>
                    <input type="text" name="search" 
                      className="form-control" 
                      placeholder="Start typing name, catalogue #..." 
                      onChange={(event) => {
                        this.setState({keyword: event.target.value, page: 1}, () => {search()})
                      }}
                    />
                  </div>
                </div>
               
                <div className="col-md-4">
                  <div className="form-group">
                  <Picker
                    containerClass={`mb30`}
                    onChange={event => {
                      this.setState({ category: event.target.value, keyword: '', page: 1}, () => {
                        this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                      })
                    }}
                    label={`Filter by category`}
                    value={this.state.category}
                    data={this.props.inventory_categories}
                  />
                  </div>
                </div>
              </div>
              <div className="card-body border p-0 rounded-0" style={{minHeight: '300px'}}>

                <div className="row">
                  <div className="col-md-12 of-auto">
                    <div className="card of-auto border-0 rounded-0">
                      <table className="table table-striped table-fixed m-0 min-table-width-900">
                        <thead>
                          <tr>
                            <th onClick={() => this.sortBy('catalog_number')} className="w140 c-pointer px5">
                              <i className="fa fa-sort  mr5" />
                              Catalogue #
                          </th>
                            <th className="w230 c-pointer px5" onClick={() => this.sortBy('name')}>
                              <i className="fa fa-sort mr5 " />
                              Name
                          </th>
                            <th onClick={() => this.sortBy('company')} className="w100 c-pointer px5">
                              <i className="fa fa-sort mr5 " />
                              Company
                          </th>
                            <th onClick={() => this.sortBy('category')} className="w100 c-pointer px5">
                              <i className="fa fa-sort mr5 " />
                              Category
                            </th>
                            <th className="w80 px5"> Size </th>
                            <th className="w150 text-center px5"> Qty </th>
                            <th className="w0 px10" />
                          </tr>
                        </thead>
                        <tbody style={{ float: 'left', width: '100%', overflow: 'hidden', maxHeight: '400px', height: '400px' }}>
                          <Inbodyloader progress={this.props.loading} type="table" />
                          <InfiniteScroll
                            dataLength={this.props.items.length}
                            next={() => { this.getMoreItems() }}
                            hasMore={this.props.more}
                            loader={
                              <p className="px5 py5 text-center">
                                <b>Loading</b>
                              </p>
                            }
                            height={400}
                            endMessage={
                              <p className="px5 py5 text-center">
                                <b>Nothing to load</b>
                              </p>
                            }
                          >
                            {itemlist}
                          </InfiniteScroll>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body my0 py0 border-0 justify-content-start"> 
              <div className="btn btn-dark mr30 mt30 px30 mb-0 float-left" data-dismiss="modal" onClick={() => {this.props.assign(this.state.selected_items)}}>Assign items</div>
              <div className="btn btn-light border-dark px30 mt30 mb-0 float-left" data-dismiss="modal">Cancel</div>
            </div>
          </div>
        </div>
        </div>
        <ViewItemModal
          idModal="viewItemModal-45"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal-45').modal('hide')
          }}
        />
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return({
    items: state.item.items,
    inventory_categories: state.item.inventory_categories,
    loading: state.item.loading,
    more: state.item.more
  })
}

export default connect(mapStateToProps, { getItems, getMedicalInventoryCategories, getItemsPerCategory, setItems })(AssignItemsModalInstruments)
