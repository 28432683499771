import React from 'react'
import { Route, IndexRoute, Router, browserHistory } from 'react-router'

import Login from './containers/auth/Login'
import ResetPassword from './containers/auth/ResetPassword'
import ConfirmResetPassword from './containers/auth/ConfirmResetPassword'

import Surgeon from './containers/surgeon'
import SurgeonCreate from './containers/surgeon/create'
import SurgeonEdit from './containers/surgeon/edit'

import Main from './containers/main'
import Welcome from './containers/welcome'
import Items from './containers/items'
import ItemEdit from './containers/items/Edit'

import MedicalInventories from './containers/medical_inventories'

import Instruments from './containers/instruments'
import RegisterNewTray from './containers/instruments/RegisterNewTray'

import Specialties from './containers/specialties'
import ItemCreate from './containers/items/Create'
import SurgeonQuicknotes from './containers/quicknotes/SurgeonQuicknotes'


//settings
import SettingSpecialties from './containers/settings/SettingSpecialties'
import SettingSurgicalTree from './containers/settings/SettingSurgicalTree'
import SettingMedicalInventory from './containers/settings/SettingMedicalInventory'
import SettingQuicknotes from './containers/settings/SettingQuicknotes'
import SettingIconLibrary from './containers/settings/SettingIconLibrary'
import SettingUserPermissions from './containers/settings/SettingUserPermissions'

//userdb
import Users from './containers/userdb/Users'
import GlobalUsers from './containers/userdb/GlobalUsers'
import Surgeons from './containers/userdb/Surgeons'


// items
import Item from './containers/items/Item'

//Master Page
//Positioning
import CreatePositioning from './containers/positioning/CreatePositioning'
import Positioning from './containers/positioning/Positioning'
import RoomSetup from './containers/masterpages/RoomSetup';
//Positioning Equipments


import PositioningEquipment from './containers/positioning_equipment/Index'
import UpdatePositioningEquipment from './containers/positioning_equipment/UpdatePositioningEquipment'
import EditPositioningEquipment from './containers/positioning_equipment/EditPositioningEquipment'

//Procedures
import ProcedureCreator from './containers/procedures/ProcedureCreator'
import ProcedureDatabase from './containers/procedures/ProcedureDatabase'
import RegisterProcedure from './containers/procedures/RegisterProcedure'

//AccountSettings
import AccountSettings from './containers/account/AccountSettings';
import Invitations from './containers/account/Invitations';
import BetaKey  from './containers/userdb/BetaKey'


export default (
  <Router history={browserHistory}>
    <Route path="/login" component={Login} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/reset-password-confirm/:token" component={ConfirmResetPassword} />
    <Route path="/invitations/:id" component={Invitations} />
    <Route path="/" component={Main}>
      <IndexRoute component={Welcome} />
      <Route path="/surgeon" component={Surgeon} />
      <Route path="/surgeon/:id" component={Surgeon} />
      <Route path="/surgeon-create" component={SurgeonCreate} />
      <Route path="/surgeon-update/:id" component={SurgeonEdit} />

      <Route path="/medical-inventory" component={MedicalInventories} />
      <Route path="/surgeon-preferences" component={Specialties} />
      <Route path="/medical-inventory/:inventory" component={Items} />
      
      <Route path="/medical-inventory-instruments" component={Instruments} />
      <Route path="/medical-inventory-instruments/new-tray" component={RegisterNewTray} />
      <Route path="/medical-inventory-instruments/edit-tray/:id" component={RegisterNewTray} />

      <Route path="/medical-inventory-create" component={ItemCreate} />
      <Route path="/item/:id" component={Item} />
      <Route path="/quicknotes/:id" component={SurgeonQuicknotes} />
      <Route path="/update-item" component={ItemEdit} />

      
      <Route path="/create-positioning" component={CreatePositioning} />
      <Route path="/update-positioning/:id" component={CreatePositioning} />
      <Route path="/positioning" component={Positioning} />
      <Route path="/positioning-equipments" component={PositioningEquipment} />
      <Route path="/positioning-equipments/update/:id" component={UpdatePositioningEquipment} />
      <Route path="/positioning-equipments/edit" component={EditPositioningEquipment} />



      <Route path="/master-pages/room-setup" component={RoomSetup} />

      <Route path="/user-database/users" component={Users} />
      <Route path="/user-database/global-users" component={GlobalUsers} />
      <Route path="/user-database/beta-keys" component={BetaKey} />
      <Route path="/user-database/surgeons" component={Surgeons} />
      
      <Route path="/settings/medical-inventory" component={SettingMedicalInventory} />
      <Route path="/settings/surgical-tree" component={SettingSurgicalTree} />
      <Route path="/settings/specialties" component={SettingSpecialties} />
      <Route path="/settings/quicknotes" component={SettingQuicknotes} /> 
      <Route path="/settings/user-permissions" component={SettingUserPermissions} /> 
      

      //Icon library
      <Route path="/settings/icon-library" component={SettingIconLibrary} />

      //PROCEDURES
      <Route path="/procedure-creator" component={ProcedureCreator} />
      <Route path="/procedure-database" component={ProcedureDatabase} />
      <Route path="/register-procedure" component={RegisterProcedure} />

      <Route path="/account-settings" component={AccountSettings} />

    </Route>
  </Router>
)
