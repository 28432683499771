import React, { Component } from 'react'

import axios from 'axios'
import ReactDOMServer from 'react-dom/server'
import $ from 'jquery'
import ViewItemModal from '../modals/ViewItemModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router'
import default_avatar from '../../assets/images/default_profile.jpg'
import { Text } from '../commons/elements/display'
import InfoText from '../commons/display/InfoText'

class Notifies extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notifications: [],
      selectedItem: null,
      page: 1,
      loading: false,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
    $('#viewItemModal').modal('hide')
    this.makeRemoteRequest()
  }

  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map((data, index) => {
        return (
          <span
            key={`tag-${data.id}-key-${index}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }
  
  makeRemoteRequest = () => {
    const { page } = this.state
    this.setState({
      loading: true,
    })
    const { data_uri } = this.props
    
    axios
      .get(`${data_uri}?page=${page}`)
      .then(result => {
        console.log('12', result)
        this.setState({
          notifications:
            page === 1
              ? result.data.data
              : [...this.state.notifications, ...result.data.data],
          loading: false,
        })
        $('[data-toggle="popover"]').popover()
        $('[data-toggle="popover"]').popover('hide')
        $('#viewItemModal').modal('hide')
      })
      .catch(error => {
        console.log(error)
      })
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div class="row">
          <div class="col-md-12 text-center pb30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )
    return null
  }

  renderItem = (data, key) => {
    const item = data.data
    const nurse = data.by_user
    let medical_inventory = 'N/A'
    let intro = 'Register a new item'
    if (item.category) {
      switch (item.category.medical_inventory_id) {
        case 1:
          medical_inventory = 'Supplies'
          break
        case 2:
          medical_inventory = 'Instruments'
          break

        default:
          medical_inventory = 'Equipments'
          break
      }
    }
    if (data.action_type == 'Updated') {
      intro = 'Update an Item'
    }
    return (
      <li className="media p15 bg-white mb30" key={key}>
        <img
          src={item.icon ? item.icon : default_avatar}
          className="w100 h100 bg-dark mr30 rounded-circle"
          alt="..."
        />
        <div className="media-body">
          <h5 className="mt-0 mb10 font-weight-bold">{nurse ? nurse.name : ''}</h5>
          <div className="row">
            <div className="col-md-8">
              <p className="m-0">
                {`${intro}`}{' '}

                <b> <InfoText item={item} onClick={() => {
                  this.props.onItemClick(item)
                }}> {`[${item.name}]`} </InfoText>
                </b>
                
                for <u className="font-weight-bold">[{medical_inventory}]</u>{' '}
                Medical Inventory
              </p>
            </div>
            <div className="col-md-4 d-flex align-items-end">
              <p className="m-0">{data.date}</p>
            </div>
          </div>
        </div>
      </li>
    )
  }

  renderSurgeon = (data, key) => {
    const item = data.data
    const nurse = data.by_user
    let intro = 'Register a new surgeon'
    if (data.action_type == 'Updated') {
      intro = 'Update an surgeon'
    }
    return (
      <li className="media p15 bg-white mb30" key={key}>
        <img
          src={item.avatar ? item.avatar : default_avatar}
          className="w100 h100 bg-dark mr30 rounded-circle"
          alt="..."
        />
        <div className="media-body">
          <h5 className="mt-0 mb10 font-weight-bold">{nurse ? nurse.name : 'No nurse'}</h5>
          <div className="row">
            <div className="col-md-8">
              <p className="m-0">
                {`${intro}`}{' '}
                <Link
                  className="font-weight-bold"
                  to={{
                    pathname: `/quicknotes/${item.id}`,
                  }}
                >
                  <u className="font-weight-bold">
                    {`[${item.firstname} ${item.lastname}]`}
                  </u>
                </Link>{' '}
              </p>
            </div>
            <div className="col-md-4 d-flex align-items-end">
              <p className="m-0">{data.date}</p>
            </div>
          </div>
        </div>
      </li>
    )
  }

  renderProcedure(data, key) {
    const nurse = data.by_user

    return (
      <li className="media p15 bg-white mb30" key={key}>
        {data.data.surgeon ? (
          <img
            src={data.data.surgeon.avatar ? data.data.surgeon.avatar : default_avatar}
            className="w100 h100 bg-dark mr30 rounded-circle"
            alt="..."
          />
        ) : null}
        <div className="media-body">
          <h5 className="mt-0 mb10 font-weight-bold">{nurse.name}</h5>
          <div className="row">
            <div className="col-md-8">
              <p className="m-0">
                {`${data.action_type} Procedure`}{' '}
                <u className="font-weight-bold">{`[${data.data.name}]`}</u>
                {data.data.surgeon ? ' for ' : null}
                {data.data.surgeon ? (
                  <Link
                    className="font-weight-bold"
                    to={{
                      pathname: `/quicknotes/${data.data.surgeon.id}`,
                    }}
                  >
                    <u className="font-weight-bold">
                      [
                      {`${data.data.surgeon.firstname} ${
                        data.data.surgeon.lastname
                      }`}
                      ]
                    </u>
                  </Link>
                ) : null}
              </p>
            </div>
            <div className="col-md-4 d-flex align-items-end">
              <p className="m-0">{data.date}</p>
            </div>
          </div>
        </div>
      </li>
    )
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  render() {
    const { notifications } = this.state
    const notificationList = notifications.map((data, index) => {
      switch (data.model) {
        case 'Item':
          if (data.data) return this.renderItem(data, index)
          break
        case 'Procedure':
          if (data.data) return this.renderProcedure(data, index)
        case 'Surgeon':
          if (data.data) return this.renderSurgeon(data, index)
        default:
          return null
          break
      }
    })
    return (
      <div className="col-md-12">
        <InfiniteScroll
          className="px20"
          dataLength={this.state.notifications.length}
          next={this.loadMoreData.bind(this)}
          hasMore={true}
        >
          <ul className="list-unstyled">{notificationList}</ul>
        </InfiniteScroll>
        {this.renderLoading()}
      </div>
    )
  }
}

export default Notifies
