import React, { Component } from 'react'

export class Inbodyloader extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { progress, style } = this.props
        if (progress > 0){
            
            return (
                <div className="inbody-loader" style={style}>
                    <div className="w-100 text-center mb20 d-flex flex-column text-white font-weight-bold">
                        Loading! Please wait...
                </div>
                    <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

            )
        }else{
            return null;
        }

    }
}

export default Inbodyloader
