import React, { Component } from 'react'
import { Picker } from '../commons/forms'
import { connect } from 'react-redux'
import _ from 'lodash'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server'
import { getMedicalInventories, getItemsPerCategory, setItems} from '../../actions/ItemAction'
import { getBodyAreas } from '../../actions/PostioningActions'
import { Link } from 'react-router'
import ReviewItems from './ReviewItemsPositioning'
import ViewItemModal from '../../components/modals/ViewItemModal'

export class AssignItems extends Component {
  constructor(props) {
    super(props)
  
    this.state = { 
      inventory: {},
      category: 'all',
      categories: [],
      counters: {
        supplies: 0,
        instruments: 0,
        equipments: 0
      },
      keyword: '',
      selected_items: [],
      change: false,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
    }
  }

  componentDidMount = () => {
    this.props.getBodyAreas()
    this.props.getMedicalInventories()
    this.props.getItemsPerCategory('all', this.props.selected_items, 1, '')
    this.setState({
      selected_items: this.props.selected_items
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.change != nextProps.change){
      this.setState({
        inventory: {},
        category: 'all',
        categories: [],
        counters: {
          supplies: 0,
          instruments: 0,
          equipments: 0
        },
        keyword: '',
        selected_items: []
      })

      this.props.getMedicalInventories()
      this.props.getItemsPerCategory('all', nextProps.selected_items, 1, '')
      this.setState({
        selected_items: nextProps.selected_items
      })
    }
  }

  componentDidUpdate = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')

    let counters = {
      supplies: 0,
      instruments: 0,
      equipments: 0
    }

    this.state.selected_items.map((item) => {
      switch(item.category.medical_inventory_id){
        case 1:
          counters.supplies++
          break
        case 2:
          counters.instruments++
          break
        case 3:
          counters.equipments++
          break
        default: 
          break
      }
    })

    if(!_.isEqual(counters, this.state.counters)){
      this.setState({
        counters: counters
      })
    }
    
  }

  checkItem = (id) => {
    let { selected_items } = this.state
    const { items } = this.props

    let item = items.find((e) => e.id === id)
    if(item){
      if(!item.qty){
        item.qty = 1
      }
    }else{
      item = selected_items.find((e) => e.id === id)
    }
    
    if(selected_items.find((e) => e.id === id)){
      this.setState({
        selected_items: selected_items.filter(x => x.id !== id),
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? -1:0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? -1:0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? -1:0),
        },
      })
    }else{
      this.setState({
        selected_items: [...selected_items, item],
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? 1 : 0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? 1 : 0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? 1 : 0),
        },
      })
    }
  }

  checkItemExistence = (id) => {
    let { selected_items } = this.state

    let exist = false
    if(selected_items.filter((e) => e.id === id).length > 0){
      exist = true
    }else{
      exist = false
    }

    return exist
  }

  isChecked = (id) => {
    let { selected_items } = this.state
    const { items } = this.props

    let item_local = selected_items.find((e) => e.id === id)
    let item_store = items.find((e) => e.id === id)

    let checked = false
    if(item_local){
      if(item_local.prn){
        checked = true
      }
    }else{
      if(item_store){
        if(item_store.prn){
          checked = true
        }
      }
      
    }

    return checked
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  setSelected = (selected_items) => {
    this.setState({
      selected_items: selected_items
    })
  }

  render() {
    const { medical_inventories, items } = this.props
    let { categories } = this.state

    if(!this.state.categories.length){
      if(medical_inventories[0]){
        this.setState({
          categories: medical_inventories[0].categories
        })
      }
    }

    if(!this.state.inventory){
      if(medical_inventories[0]){
        this.setState({
          inventory: medical_inventories[0]
        })
      }
    }
   
    const search = _.debounce(() => {
      this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory.id, this.state.keyword)
    }, 500)

    const item_list = items.map((item, index) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr>
          <td className="w150 px12">{item.catalog_number}</td>
          <td className="w280 font-weight-bold c-pointer text-dark fs-16 px12"  
              data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`} onClick={() => {

                this.setState({
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15)
                }, () => {
                    $(`#${this.state.unique}`).modal('show');

                })
              }}> 
                {item.name} 
            </td>
          <td className=" w170 px12"> <p className="m-0 p-0 float-left w-100 fs14">{item.company} </p> </td>
          <td className=" w170 px12"> <p className="m-0 p-0 float-left w-100 fs14">{item.category ? item.category.name : ''}</p> </td>
          <td className=" text-center w100">{item.size}</td> 
          <td className="w100 text-center pt15">
            <a href="#" className="text-dark mt15" onClick={(event) =>  {event.preventDefault(); this.checkItem(item.id)}}>{this.checkItemExistence(item.id) ? 'Remove' : 'Add'}</a>
          </td>
        </tr>
      )
    })
    
    return (
      <div>

        <div className={`modal fade`} id="assignItems" tabIndex={-1} role="dialog" aria-labelledby="assignItemsTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl px30" role="document">
            <div className={`modal-content ${this.change ? 'modal-opacity-0' : ''}`}>
              <div className="modal-body pt30 px30 pb15">
                <h5 className="m-0 mx-auto font-weight-bold pb20">Assigning items </h5>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item mr30">
                    <a className="nav-link active border-0 font-weight-bold bg-transparent px-0 py5 fs16" id="supplies-tab" data-toggle="tab" href="#supplies"
                      role="tab" aria-controls="supplies"
                      onClick={() => {
                        this.setState({ categories: medical_inventories[0].categories, inventory: medical_inventories[0], keyword: '' }, () => {
                          this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory.id, '')
                        })
                      }}
                      aria-selected="true" style={{ textDecoration: 'none' }}>Supplies
                    <span className="badge badge-secondary badge-pill counter-pill ml15">{this.state.counters.supplies}</span>
                    </a>
                  </li>
                  <li className="nav-item mr30">
                    <a className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs16" id="instruments-tab" data-toggle="tab" href="#instruments"
                      role="tab" aria-controls="instruments"
                      onClick={() => {
                        this.setState({ categories: medical_inventories[1].categories, inventory: medical_inventories[1], keyword: '' }, () => {
                          this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory.id, '')
                        })
                      }}
                      aria-selected="true" style={{ textDecoration: 'none' }}>Instruments
                    <span className="badge badge-secondary badge-pill counter-pill ml15">{this.state.counters.instruments}</span>
                    </a>
                  </li>
                  <li className="nav-item mr30">
                    <a className="nav-link border-0 font-weight-bold bg-transparent px-0 py5 fs16" id="equipments-tab" data-toggle="tab" href="#equipments"
                      role="tab" aria-controls="equipments"
                      onClick={() => {
                        this.setState({ categories: medical_inventories[2].categories, inventory: medical_inventories[2], keyword: '' }, () => {
                          this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory.id, '')
                        })
                      }}
                      aria-selected="true" style={{ textDecoration: 'none' }}>Equipments
                    <span className="badge badge-secondary badge-pill counter-pill ml15">{this.state.counters.equipments}</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane animated fadeIn active" id="supplies" role="tabpanel" aria-labelledby="supplies-tab">
                    <div className="row pt20">
                      <div className="col-md-4 form-group m-0">
                        <label className="mb10">Search</label>
                        <input type="text" name="search" id="search"
                          className="form-control"
                          value={this.state.keyword}
                          onChange={(event) => { this.setState({ keyword: event.target.value }, () => { search() }) }}
                          placeholder="Start typing name, catalogue #..." />
                      </div>
                      <div className="col-md-4 form-group m-0">
                        <Picker
                          containerClass={``}
                          onChange={event => {
                            this.setState({ category: event.target.value, keyword: '' }, () => {
                              this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory.id)
                            })
                          }}
                          label={`Filter by category`}
                          value={this.state.category}
                          data={categories}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-striped table-fixed border">
                          <thead className="bg-dark text-white text-left">

                            <tr>
                              <th onClick={() => this.sortBy('catalog_number')} class="w150 px12">Catalogue # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('name')} class="w280 px12">Name # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('company')} class="w170 px12">Company # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('category')} class="w170 px12">Category <i class="fa fa-sort ml5"></i></th>
                              <th class="w100 px12 text-center">Size <i class="fa fa-sort ml5"></i></th>
                              <th class="w100 px12"></th>
                            </tr>
                          </thead>
                          <tbody style={{ float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '388px' }}>
                            {item_list}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane animated fadeIn " id="instruments" role="tabpanel" aria-labelledby="instruments-tab">
                    <div className="row py20">
                      <div className="col-md-4 form-group m-0">
                        <label className="mb10">Search</label>
                        <input type="text" name="search" id="search"
                          className="form-control"
                          value={this.state.keyword}
                          onChange={(event) => { this.setState({ keyword: event.target.value }, () => { search() }) }}
                          placeholder="Start typing name, catalogue #..." />
                      </div>
                      <div className="col-md-4 form-group m-0">
                        <Picker
                          containerClass={`mb30`}
                          onChange={event => {
                            this.setState({ category: event.target.value, keyword: '' }, () => {
                              this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory.id)
                            })
                          }}
                          label={`Filter by category`}
                          value={this.state.category}
                          data={categories}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-striped table-fixed border">
                          <thead className="bg-dark text-white text-left">

                            <tr>
                              <th onClick={() => this.sortBy('catalog_number')} class="w150 px12">Catalogue # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('name')} class="w280 px12">Name # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('company')} class="w170 px12">Company # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('category')} class="w170 px12">Category <i class="fa fa-sort ml5"></i></th>
                              <th class="w100 px12 text-center">Size <i class="fa fa-sort ml5"></i></th>
                              <th class="w100 px12"></th>
                            </tr>
                          </thead>
                          <tbody style={{ float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '388px' }}>
                            {item_list}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane animated fadeIn " id="equipments" role="tabpanel" aria-labelledby="equipments-tab">
                    <div className="row py20">
                      <div className="col-md-4 form-group m-0">
                        <label className="mb10">Search</label>
                        <input type="text" name="search" id="search"
                          className="form-control"
                          value={this.state.keyword}
                          onChange={(event) => { this.setState({ keyword: event.target.value }, () => { search() }) }}
                          placeholder="Start typing name, catalogue #..." />
                      </div>
                      <div className="col-md-4 form-group m-0">
                        <Picker
                          containerClass={`mb30`}
                          onChange={event => {
                            this.setState({ category: event.target.value, keyword: '' }, () => {
                              this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory.id)
                            })
                          }}
                          label={`Filter by category`}
                          value={this.state.category}
                          data={categories}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-striped table-fixed border">
                          <thead className="bg-dark text-white text-left">

                            <tr>
                              <th onClick={() => this.sortBy('catalog_number')} class="w150 px12">Catalogue # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('name')} class="w280 px12">Name # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('company')} class="w170 px12">Company # <i class="fa fa-sort ml5"></i></th>
                              <th onClick={() => this.sortBy('category')} class="w170 px12">Category <i class="fa fa-sort ml5"></i></th>
                              <th class="w100 px12 text-center">Size <i class="fa fa-sort ml5"></i></th>
                              <th class="w100 px12"></th>
                            </tr>
                          </thead>
                          <tbody style={{ float: 'left', width: '100%', overflow: 'auto', maxHeight: '344px', height: '388px' }}>
                            {item_list}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>
              </div> 
              <div className="modal-body mb-0 px30 pb30 pt-0 border-0 justify-content-start">
                <div className="btn btn-dark mr30 mt-0 px30 mb-0 float-left" onClick={() => {
                  this.setState({
                    change: !this.state.change,
                    unique:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15)
                  })
                  $('#assignItemsModalCenter').modal('hide'); 
                  $('#reviewItemsModalCenter').modal('show');

                }}>Review items</div>
                <div className="btn btn-light border-dark px30 mt-0 mb-0 float-left" data-dismiss="modal">Cancel</div>
              </div>
            </div>
          </div>
        </div>
        <ReviewItems selected_items={this.state.selected_items}
          checkItem={this.checkItem}
          checkItemExistence={this.checkItemExistence}
          setSelected={this.setSelected}
          body_areas={this.props.body_areas}
          assign={this.props.assign}
          change={this.state.change} 
        />

        <ViewItemModal
          idModal={this.state.unique}
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal').modal('hide')
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    medical_inventories: state.item.medical_inventories,
    items: state.item.items,
    body_areas: state.positioning.body_areas
  })
}

export default connect(mapStateToProps, {getMedicalInventories, getItemsPerCategory, setItems, getBodyAreas})(AssignItems)
